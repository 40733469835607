import { Environment } from './permissions';

export const SERVER_URL = process.env.VUE_APP_SERVER_URL || 'http://127.0.0.1:8000';

export const ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT || Environment.DEV;

export const POSTHOG_API_KEY = process.env.VUE_APP_POSTHOG_API_KEY || '';
export const PRODUCT_FRUITS_API_KEY = process.env.VUE_APP_PRODUCT_FRUITS_API_KEY || '';

export const DATA_FETCHING_INTERVAL = 1000;
