export enum BroomStickMutation {
  SET_BROOM_STICK = 'setBroomStick',
  CLEAR_BROOM_STICK = 'clearBroomStick',
}

export enum BroomStickAction {
  FETCH_BROOM_STICK = 'fetchBroomStick',
}

export type TdData = {
  md: number;
  hookload: number;
  torque: number;
  frictionFactor: number;
  operation: TdOperation;
};

export enum TdOperation {
  TRIP_OUT = 'tripOut',
  TRIP_IN = 'tripIn',
  ROTATION = 'rotation',
}

export type FrictionFactorEstimates = {
  casedHole: number;
  openHole_slackOff: number;
  openHole_pickUp: number;
  openHole_rotation: number;
};

export type BroomStick = {
  broomstick: TdData[];
};
