import {
  DirectionalPlotTabMutation,
  LegendItem, LegendData,
  DirectionaPlotSurveyHeatmapMetric, DirectionalPlotHeatmapMetricConfig,
  CameraView3dPlot, DirectionalPlotTooltipConfig,
} from './types';

import { SurveyData, SurveyZoom } from '@/store/modules/survey/types';
import { WellPlan } from '@/store/modules/well_plan/types';
import { WellId } from '@/store/modules/types';

import { queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

const VALUE_STEP = 1000;

@Module
export class DirectionalPlotModule extends VuexModule {
  _wellIds: WellId[] = [];
  _surveys: { [key: WellId]: SurveyData } | null = null;
  _wellPlans: { [key: WellId]: WellPlan } | null = null;
  _isSettingsModalActive = false;
  _isTargetAzimuthModalActive = false;
  _isRecommendationModalActive = false;
  _isGoToModalActive = false;
  _isWellPlanEditorModalActive = false;
  _isPseudoSurveysActive = true;
  _targetAzimuthInput: number | null = null;
  _selectedDepthRange: [number, number] | null = null; // can be changed by: Slide Sheet, Section, local filter
  _legendData: Map<string, LegendData> | null = null;
  _surveyHeatmapConfig: Map<DirectionaPlotSurveyHeatmapMetric, DirectionalPlotHeatmapMetricConfig> = new Map([
    [DirectionaPlotSurveyHeatmapMetric.SLIDE_SEEN, { field: 'slideSeen', range: [0, 90] }],
    [DirectionaPlotSurveyHeatmapMetric.SLIDE_AHEAD, { field: 'slideAhead', range: [0, 90] }],
    [DirectionaPlotSurveyHeatmapMetric.SLIDE_ROP_SEEN, { field: 'slideRopSeen', range: [0, 200] }],
    [DirectionaPlotSurveyHeatmapMetric.ROTATE_ROP_SEEN, { field: 'rotateRopSeen', range: [0, 200] }],
    [DirectionaPlotSurveyHeatmapMetric.C_2_C, { field: 'distance2plan', range: [0, 100], idx: 0 }],
    [DirectionaPlotSurveyHeatmapMetric.MOTOR_YIELD, { field: 'sbr', range: [0, 20], idx: 0 }],
    [DirectionaPlotSurveyHeatmapMetric.ROTARY_BUILD, { field: 'rbr', range: [0, 5], idx: 0 }],
    [DirectionaPlotSurveyHeatmapMetric.ROTARY_WALK, { field: 'rtr', range: [0, 5], idx: 0 }],
  ]);

  _selectedSurveyHeatmapMetric = DirectionaPlotSurveyHeatmapMetric.SLIDE_SEEN;
  _cameraView3dPlot: CameraView3dPlot = {
    x: 1.25, y: 1.25, z: 1.25,
  };

  _tooltipConfig: DirectionalPlotTooltipConfig = {
    surveyData: [],
    recommendation: [],
    wellPlan: [],
    projection: [],
  };

  get legendData(): Map<string, LegendData>  | undefined {
    return this._legendData || undefined;
  }

  get directionalPlotSurveys(): { [key: WellId]: SurveyData } | undefined {
    return this._surveys || undefined;
  }

  get directionalPlotWellPlans(): { [key: WellId]: WellPlan } | undefined {
    return this._wellPlans || undefined;
  }

  get directionalPlotGoToModalActive(): boolean {
    return this._isGoToModalActive;
  }

  get wellPlanEditorModalActive(): boolean {
    return this._isWellPlanEditorModalActive;
  }

  get directionalPlotSettingsModalActive(): boolean {
    return this._isSettingsModalActive;
  }

  get directionalPlotTargetAzimuthModalActive(): boolean {
    return this._isTargetAzimuthModalActive;
  }

  get directionalPlotRecommendationModalActive(): boolean {
    return this._isRecommendationModalActive;
  }

  get directionalPlotPseudoSurveysActive(): boolean {
    return this._isPseudoSurveysActive;
  }

  get directionalPlotOffsetWellIds(): WellId[] {
    return this._wellIds;
  }

  get directionalPlotTargetAzimuthInput(): number | undefined {
    return !_.isNil(this._targetAzimuthInput) ? this._targetAzimuthInput : undefined;
  }

  get directionalPlotSelectedDepthRange(): [number, number] | undefined {
    return this._selectedDepthRange || undefined;
  }

  get directionalPlotSelectedSurveyHeatmapMetric(): DirectionaPlotSurveyHeatmapMetric {
    return this._selectedSurveyHeatmapMetric;
  }

  get directionalPlotTooltipConfig(): DirectionalPlotTooltipConfig {
    return this._tooltipConfig;
  }

  get directionalPlotSurveyHeatmapConfig(): Map<DirectionaPlotSurveyHeatmapMetric, DirectionalPlotHeatmapMetricConfig> {
    return this._surveyHeatmapConfig;
  }

  get cameraView3dPlot(): CameraView3dPlot {
    return this._cameraView3dPlot;
  }

  get directionalPlotDataBorders(): SurveyZoom {
    const nsBorderslist = [];
    const tvdBorderslist = [];
    const ewBorderslist = [];
    const vsBorderslist = [];
    const surveyData = store.getters.surveyData;
    if(surveyData?.md?.length > 0) {
      const nsList = surveyData.ns.filter((val: number) => val !== null);
      const tvdList = surveyData.tvd.filter((val: number) => val !== null);
      const ewList = surveyData.ew.filter((val: number) => val !== null);
      const vsList = surveyData.vs.filter((val: number) => val !== null);
      nsBorderslist.push(_.min(nsList));
      nsBorderslist.push(_.max(nsList));
      tvdBorderslist.push(_.min(tvdList));
      tvdBorderslist.push(_.max(tvdList));
      ewBorderslist.push(_.min(ewList));
      ewBorderslist.push(_.max(ewList));
      vsBorderslist.push(_.min(vsList));
      vsBorderslist.push(_.max(vsList));
    }
    // for multiple well mode
    for(const wellId of store.getters.directionalPlotOffsetWellIds) {
      if(wellId !== store.getters.currentWellId) {
        const data = store.getters.directionalPlotSurveys[wellId];
        const nsList = data.ns.filter((val: number) => val !== null);
        const tvdList = data.tvd.filter((val: number) => val !== null);
        const ewList = data.ew.filter((val: number) => val !== null);
        const vsList = data.vs.filter((val: number) => val !== null);
        nsBorderslist.push(_.min(nsList));
        nsBorderslist.push(_.max(nsList));
        tvdBorderslist.push(_.min(tvdList));
        tvdBorderslist.push(_.max(tvdList));
        ewBorderslist.push(_.min(ewList));
        ewBorderslist.push(_.max(ewList));
        vsBorderslist.push(_.min(vsList));
        vsBorderslist.push(_.max(vsList));
      }
    }
    const liveProjection = store.getters.depthDataLastEstimatedValues;
    if(liveProjection?.estimatedMD?.length > 0) {
      const nsList = liveProjection.estimatedNS.filter((val: number) => val !== null);
      const tvdList = liveProjection.estimatedTVD.filter((val: number) => val !== null);
      const ewList = liveProjection.estimatedEW.filter((val: number) => val !== null);
      const vsList = liveProjection.estimatedVS.filter((val: number) => val !== null);
      nsBorderslist.push(_.min(nsList));
      nsBorderslist.push(_.max(nsList));
      tvdBorderslist.push(_.min(tvdList));
      tvdBorderslist.push(_.max(tvdList));
      ewBorderslist.push(_.min(ewList));
      ewBorderslist.push(_.max(ewList));
      vsBorderslist.push(_.min(vsList));
      vsBorderslist.push(_.max(vsList));
    }
    // TODO: update vs with target azimuth
    return {
      ns: [_.min(nsBorderslist) - VALUE_STEP, _.max(nsBorderslist) + VALUE_STEP],
      tvd: [_.min(tvdBorderslist) - VALUE_STEP, _.max(tvdBorderslist) + VALUE_STEP],
      ew: [_.min(ewBorderslist) - VALUE_STEP, _.max(ewBorderslist) + VALUE_STEP],
      vs: [_.min(vsBorderslist) - VALUE_STEP, _.max(vsBorderslist) + VALUE_STEP],
    };
  }

  @Mutation
  setCameraView3dPlot(view: CameraView3dPlot): void {
    this._cameraView3dPlot = _.clone(view);
  }

  @Mutation
  setDirectionalPlotOffsetWellIds(wellIds: WellId[]): void {
    this._wellIds = wellIds;
  }

  @Mutation
  setDirectionalPlotSurveys(surveys: { [key: WellId]: SurveyData }): void {
    this._surveys = _.cloneDeep(surveys);
  }

  @Mutation
  setDirectionalPlotWellPlans(wellPlans: { [key: WellId]: SurveyData }): void {
    this._wellPlans = _.cloneDeep(wellPlans);
  }

  @Mutation
  setDirectionalPlotSettingsModalActive(active: boolean): void {
    this._isSettingsModalActive = active;
  }

  @Mutation
  setDirectionalPlotTargetAzimuthModalActive(active: boolean): void {
    this._isTargetAzimuthModalActive = active;
  }

  @Mutation
  setDirectionalPlotRecommendationModalActive(active: boolean): void {
    this._isRecommendationModalActive = active;
  }

  @Mutation
  setDirectionalPlotGoToModalActive(active: boolean): void {
    this._isGoToModalActive = active;
  }

  @Mutation
  setWellPlanEditorModalActive(active: boolean): void {
    this._isWellPlanEditorModalActive = active;
  }

  @Mutation
  setDirectionalPlotPseudoSurveysActive(active: boolean): void {
    this._isPseudoSurveysActive = active;
  }

  @Mutation
  setDirectionalPlotTargetAzimuthInput(value: number | string): void {
    if(_.isNil(value)) {
      this._targetAzimuthInput = null;
      return;
    }
    this._targetAzimuthInput = _.toNumber(value);
  }

  @Mutation
  setDirectionalPlotSelectedDepthRange(range: [number, number] | null): void {
    this._selectedDepthRange = range;
  }

  @Mutation
  setDirectionalPlotTooltipConfig(config: DirectionalPlotTooltipConfig): void {
    if(_.isNil(config)) {
      return;
    }
    this._tooltipConfig = _.cloneDeep(config);
  }

  @Mutation
  updateDirectionalPlotTooltipConfig(type: string, traces: string[]): void {
    this._tooltipConfig[type] = traces;
  }

  @Mutation
  switchLegendItemVisibility(data: { wellId: string, label?: string }): void {
    if(this._legendData === null) {
      this._legendData = new Map<string, LegendData>();
    }
    if(!this._legendData.has(data.wellId)) {
      return;
    }
    const legendItem = this._legendData.get(data.wellId);

    let oneOfItemsIsVisible = false;
    legendItem.items.forEach((item: LegendItem, idx: number) => {
      if(!item.label || item.label !== data.label) {
        oneOfItemsIsVisible = item.visible || oneOfItemsIsVisible;
        return;
      }
      item.visible = !item.visible;
      oneOfItemsIsVisible = oneOfItemsIsVisible || item.visible;
      this[idx] = item;
    });
    legendItem.visible = oneOfItemsIsVisible;
    this._legendData.set(data.wellId, legendItem);
    this._legendData = _.cloneDeep(this._legendData);
  }

  @Mutation
  switchWellItemVisibility(wellId: string): void {
    if(this._legendData === null) {
      this._legendData = new Map<string, LegendData>();
    }
    if(!this._legendData.has(wellId)) {
      return;
    }
    const legendItem = this._legendData.get(wellId);

    const wellVisible = !legendItem.visible;
    legendItem.visible = wellVisible;
    legendItem.items.forEach((item: LegendItem, idx: number) => {
      item.visible = wellVisible;
      this[idx] = item;
    });
    this._legendData.set(wellId, legendItem);
    this._legendData = _.cloneDeep(this._legendData);
  }

  @Mutation
  updateLegendItems(data: { wellId: string, items?: LegendItem[], visible?: boolean }): void {
    if(this._legendData === null) {
      this._legendData = new Map<string, LegendData>();
    }
    let legendItem = this._legendData.get(data.wellId);
    if(!legendItem) {
      legendItem = {};
      if(data.visible !== undefined) {
        legendItem.visible = data.visible;
      }
    }

    legendItem.items = data.items;
    this._legendData.set(data.wellId, legendItem);
    this._legendData = _.cloneDeep(this._legendData);
  }

  @Mutation
  deleteLegendItems(wellId: string): void {
    if(!this._legendData || !this._legendData[wellId]) {
      return;
    }
    delete this._legendData[wellId];
    this._legendData = _.cloneDeep(this._legendData);
  }

  @Mutation
  setSelectedSurveyHeatmapMetric(metric: DirectionaPlotSurveyHeatmapMetric) {
    this._selectedSurveyHeatmapMetric = metric;
  }

  @Action({ rawError: true })
  getLegendItemVisibility(payload: { wellId: string, label?: string }): boolean {
    if(!this._legendData[payload.wellId] || !this._legendData[payload.wellId].items) {
      return true;
    }

    let result = true;
    this._legendData[payload.wellId].items.forEach((item: LegendItem) => {
      if(!item.label || item.label === payload.label) {
        result = item.visible;
      }
    });
    return result;
  }

  @Action({ rawError: true })
  getLegendItemsByWellId(wellId: string): LegendItem[] | undefined {
    if(!this._legendData[wellId]) {
      return undefined;
    }
    return this._legendData[wellId].items;
  }

  @Action({ rawError: true })
  async fetchDirectionalPlotSurveys(): Promise<void> {
    const event = 'survey-data/get/compare';
    const params = {
      wellIds: [store.getters.currentWellId, ...store.getters.antiCollisionSettings.offsetWellIds],
    };

    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }
    this.context.commit(DirectionalPlotTabMutation.SET_SURVEYS, resp.data);
  }

  @Action({ rawError: true })
  async fetchDirectionalPlotWellPlans(): Promise<void> {
    const event = 'well-plan/get/compare';
    const params = {
      wellIds: [store.getters.currentWellId, ...store.getters.antiCollisionSettings.offsetWellIds],
    };

    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }

    this.context.commit(DirectionalPlotTabMutation.SET_WELL_PLANS, resp.data);
  }
}
