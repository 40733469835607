










































import WellSettingsPartBase from './Index.vue';
import DeletePresetConfirmation from '@/components/modals/well-settings/DeletePresetConfirmation.vue';

import { Component, Vue } from 'vue-property-decorator';

import * as _ from 'lodash';

import TrashIcon from '@/assets/icons/pure-icons/trash.svg.pure';
import { DashboardPreset } from '@/store/modules/dashboard_preset/types';

@Component({ components: { DeletePresetConfirmation, WellSettingsPartBase, TrashIcon } })
export default class PresetSettingsComponent extends Vue {
  isConfirmModalActive: boolean = false;
  selectedPresetForDelete: string = null;

  get user(): string {
    return this.$store.getters.user.username;
  }

  get presetsNames(): string[] {
    return _.map(this.dashboardPresets, (preset: DashboardPreset) => preset.name);
  }

  get dashboardPresets(): string {
    return this.$store.getters.dashboardPresets;
  }

  isPresetActive(presetName: string): boolean {
    return this.$store.getters.user.activePreset === presetName;
  }

  openConfirmationModal(presetName: string) {
    if(presetName === this.$store.getters.user.activePreset) {
      this.$store.dispatch('alertWarning', { title: 'Preset Settings', message: `Active Preset can't be deleted` });
      return;
    }
    this.selectedPresetForDelete = presetName;
    this.isConfirmModalActive = true;
  }

  closeConfirmationModal(): void {
    this.selectedPresetForDelete = null;
    this.isConfirmModalActive = false;
  }
}
