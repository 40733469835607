






































































import TimeSlider from '@/components/sliders/TimeSlider.vue';

import { DepthSection } from '@/store/modules/types';
import { FormationTop } from '@/store/modules/formation_top/types';
import { MwdRun } from '@/store/modules/mwd_run/types';
import { Bha } from '@/store/modules/bha/types';
import { KeyField } from '@/store/modules/well_summary/types';

import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import * as _ from 'lodash';

const MWD_RUN_DEFAULT_NAME = 'All';
const FORMATION_TOP_DEFAULT_NAME = 'All';

@Component({ components: { TimeSlider } })
export default class TimeFilter extends Vue {
  @Prop({ required: true })
  id!: string;

  @Prop({ required: false, default: true })
  isSliderVisible: boolean;

  @Prop({ required: false, default: true })
  isSectionSelectorVisible: boolean;

  @Prop({ required: false, default: false })
  isMwdSelectorVisible: boolean;

  @Prop({ required: false, default: true })
  isFormationSelectorVisible: boolean;

  @Prop({ required: false, default: null })
  initSection: DepthSection;

  @Prop({ required: false, default: null })
  initTimeRange: [number, number] | null;

  @Prop({ required: false, default: 'Filter' })
  placeHolder: string;

  @Prop({ required: false, default: null })
  initBorders: [number, number] | null;

  @Prop({ required: false })
  inputFormatter: (value: number) => string;

  @Prop({ required: false, default: false })
  isResetVisile: boolean;

  @Prop({ required: false, default: true })
  isTitleVisible: boolean;

  @Prop({ required: false, default: false })
  isLocked: boolean;

  selectedSection: DepthSection = DepthSection.ALL;
  sections = DepthSection;
  selectedMwdRun: MwdRun | null = null;
  sliderValues: [number, number] | null = null;
  timeFilteredRange: [number, number] | null = null; // same as "sliderValues", is used for rerender
  selectedFormationTop: FormationTop | null = null;

  @Watch('initTimeRange')
  onInitValuesChange(): void {
    this.setInitialValues();
  }

  get targetDepth(): number | undefined {
    return this.$store.getters.targetDepth;
  }

  get sliderId(): string {
    return this.id + '-slider';
  }

  get dropdownRef(): string {
    return this.id + 'Dropdown';
  }

  get mwdDropdownRef(): string {
    return this.id + '-mwd-dropdown';
  }

  get formationDropdownRef(): string {
    return this.id + '-formation-dropdown';
  }

  get mwdRuns(): MwdRun[] {
    return this.$store.getters.mwdRuns;
  }

  get mwdRunsNames(): { name: string, id: string }[] {
    const result = [{ name: MWD_RUN_DEFAULT_NAME, id: '0' }];
    this.mwdRuns.forEach((value: MwdRun) => { result.push({ name: value.name, id: value._id }); });
    return result;
  }

  get formationTops(): FormationTop[] {
    const formationTops = this.$store.getters.formationTops;
    if(!formationTops) {
      return [];
    }
    return formationTops;
  }

  get formationTopNames(): { name: string, id: string }[] {
    const result = [{ name: FORMATION_TOP_DEFAULT_NAME, id: '0' }];
    this.formationTops.forEach((value: FormationTop) => { result.push({ name: value.formationName || 'Untitled', id: value._id }); });
    return result;
  }

  get selectedFormationTopName(): string {
    if(this.selectedFormationTop === null) {
      return FORMATION_TOP_DEFAULT_NAME;
    }
    return this.selectedFormationTop.formationName || 'Untitled';
  }

  get bhas(): Bha[] {
    return this.$store.getters.bhas;
  }

  get selectedMwdRunName(): string {
    return this.selectedMwdRun === null ? MWD_RUN_DEFAULT_NAME : this.selectedMwdRun.name;
  }

  get sliderTimeBorders(): [number, number] {
    if(this.initBorders !== null && this.initBorders !== undefined) {
      return this.initBorders;
    }

    this.setTimeRange([new Date(0).getTime(), new Date().getTime()]);

    return this.timeFilteredRange;
  }

  get dataSliderValues(): [number, number] | undefined {
    if(this.sliderValues !== null) {
      return this.sliderValues;
    }

    return [new Date(0).getTime(), new Date().getTime()];
  }

  // TODO: add type
  get filterOptions(): { section: DepthSection, mwdRun: MwdRun, bha: Bha, range: [number, number], formationTop: FormationTop } {
    const bha = this.selectedMwdRun !== null
      ? this.bhas.filter((value: Bha) => { return value.name === this.selectedMwdRun.bhaId; }).pop()
      : null;
    return {
      section: this.selectedSection,
      mwdRun: this.selectedMwdRun,
      bha: bha,
      range: [this.timeFilteredRange[0], this.timeFilteredRange[1]],
      formationTop: this.selectedFormationTop,
    };
  }

  get wellSummaryKeyField(): KeyField {
    return this.$store.getters.wellSummaryKeyField;
  }

  selectFormationTop(formationTopId: string): void {
    const formationTop = _.find(this.formationTops, (formationTop: FormationTop) => formationTop._id === formationTopId);
    if(!formationTop) {
      throw new Error(`Can't find formation top with id: ${formationTopId}`);
    }
    this.selectedMwdRun = null;
    this.selectedSection = DepthSection.ALL;
    this.setTimeRangeByFormationTop(formationTop);
    this.selectedFormationTop = formationTop;
    this.closeDropdown(this.formationDropdownRef);
    this.$emit('apply-filter', this.filterOptions);
  }

  selectSection(section: DepthSection): void {
    this.selectedFormationTop = null;
    this.selectedMwdRun = null;
    this.selectedSection = section;
    this.setTimeRangeBySection(section);
    this.closeDropdown(this.dropdownRef);
    this.$emit('apply-filter', this.filterOptions);
  }

  selectMwdRun(id: string): void {
    const mwdRun = this.mwdRuns.filter((value: MwdRun) => { return value._id === id; }).pop();
    this.selectedSection = DepthSection.ALL;
    this.selectedFormationTop = null;
    if(mwdRun !== undefined) {
      this.selectedMwdRun = mwdRun;
      this.setTimeRangeByMwdRun(mwdRun);
    } else {
      this.selectedMwdRun = null;
    }
    this.closeDropdown(this.mwdDropdownRef);
    this.$emit('apply-filter', this.filterOptions);
  }

  onSliderChange(range: { from: number, to: number }): void {
    if(this.selectedFormationTop !== null) {
      this.selectedFormationTop = null;
    }
    this.sliderValues = [range.from, range.to];
    this.$emit('slider-change', this.sliderValues);
  }

  closeDropdown(ref: string): void {
    // @ts-ignore
    this.$refs[ref].close();
  }

  setTimeRangeBySection(section: DepthSection): void {
    if(section === DepthSection.ALL) {
      this.setTimeRange(this.sliderTimeBorders);
      return;
    }
    const sectionsRanges = this.$store.getters.wellboreSectionsRanges;
    if(sectionsRanges === undefined || sectionsRanges[section] === undefined) {
      this.setTimeRange([this.sliderTimeBorders[0], this.sliderTimeBorders[0]]);
      return;
    }
    this.setTimeRange(sectionsRanges[section]);
  }

  setTimeRangeByFormationTop(formationTop: FormationTop): void {
    if(formationTop === null) {
      this.setTimeRange(this.sliderTimeBorders);
      return;
    }
    this.setTimeRange([formationTop.mdStart, formationTop.mdEnd]);
  }

  setTimeRangeByMwdRun(mwdRun: MwdRun): void {
    if(mwdRun === null) {
      this.setTimeRange(this.sliderTimeBorders);
      return;
    }
    const bha = this.bhas.filter((value: Bha) => { return value.name === mwdRun.bhaId; }).pop();
    if(bha === undefined) {
      this.setTimeRange(this.sliderTimeBorders);
      return;
    }

    this.setTimeRange([bha.startDate, bha.endDate]);
  }

  setTimeRange(range: [number, number] | undefined): void {
    if(!range) {
      return;
    }
    // we shouldn't pan out of depth borders;
    const resultRange: [number, number] = [
      _.max([range[0], this.sliderTimeBorders[0]]),
      _.min([range[1], this.sliderTimeBorders[1]]),
    ];
    this.sliderValues = resultRange;
    this.timeFilteredRange = resultRange;
  }

  onResetClick(): void {
    this.$emit('reset');
  }

  public clearFilters(): void {
    this.selectedSection = DepthSection.ALL;
    this.selectedMwdRun = null;
    this.selectedFormationTop = null;
    this.setTimeRange(this.sliderTimeBorders);
    this.$emit('apply-filter', { ...this.filterOptions, isClear: true });
  }

  public applyFilters(): void {
    this.selectedSection = DepthSection.ALL;
    this.selectedMwdRun = null;
    this.setTimeRange(this.sliderValues);
    this.$emit('apply-filter', this.filterOptions);
  }

  setInitialValues(): void {
    if(this.initSection !== null && this.initSection !== undefined) {
      this.selectedSection = this.initSection;
    }
    if(this.initTimeRange !== null && this.initTimeRange !== undefined) {
      this.sliderValues = this.initTimeRange;
      this.timeFilteredRange = this.initTimeRange;
    }
  }

  created(): void {
    this.setInitialValues();
  }
}
