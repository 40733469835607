















































































import noUiSlider from 'nouislider';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import * as _ from 'lodash';

const DEFAULT_VALUES = [0, 13000];

declare type WrappedSubRange = [number] | [number, number];
export interface IDataAdapter {
  min: string | number;
  max: string | number;
  from: string | number;
  to: string | number;
}

/* EXTENDABLE */
/* Extends into: TimeSlider.vue */
@Component
export default class NvictaSlider extends Vue {
  @Prop({ required: true })
  id!: string;

  @Prop({ required: true })
  min!: number;

  @Prop({ required: true })
  max!: number;

  @Prop({ required: false, default: () => DEFAULT_VALUES })
  valueRange: [number, number] | number;

  @Prop({ required: false, default: 1 })
  step: number;

  @Prop({ required: false })
  inputFormatter: (value: number) => string;

  @Prop({ required: false, default: false })
  isLocked: boolean;

  @Prop({ required: false, default: false })
  enablePins: boolean;

  slider: any = null;
  sliderValues: number[];
  from: number = 0;
  to: number = 0;
  positionBlocked: boolean = false;
  movingInputFromOffset: number = 8;
  movingInputToOffset: number = 36;

  /* Adapter for easy extends */
  get dataAdapter(): IDataAdapter {
    return {
      min: this.dataAdapterConvert(this.min),
      max: this.dataAdapterConvert(this.max),
      from: this.dataAdapterConvert(this.from),
      to: this.dataAdapterConvert(this.to),
    };
  }

  get decimals(): number {
    if(this.step <= 0 || this.step >= 1) {
      return 0;
    }
    return -Math.floor(Math.log10(this.step));
  }

  dataAdapterConvert(value: number): number | string {
    if(_.isNumber(value)) {
      return _.round(value, this.decimals);
    }
    return value;
  }

  dataInputConvert(value: string): number {
    return _.round(+value, this.decimals);
  }

  validatePinPosition(position: number): number {
    if(position < 0) { return 0; }
    if(position > 90) { return 90; }
    return position;
  }

  blockPositionUntilSubmit(): void {
    this.positionBlocked = true;
  }

  fromInputPosition(): string {
    if(this.positionBlocked) { return; }

    const position = (this.from - this.min) / (this.max - this.min) * 90;
    return `calc(${this.validatePinPosition(position)}% + ${this.movingInputFromOffset}px)`;
  }

  toInputPosition(): string {
    if(this.positionBlocked) { return; }

    const position = (this.to - this.min) / (this.max - this.min) * 90;
    return `calc(${this.validatePinPosition(position)}% - ${this.movingInputToOffset}px)`;
  }

  onFromChange({ target: { value }}: { target: { value: string }}): void {
    if(this.slider === null) { return; }

    const convertedStringValue = this.dataInputConvert(value) || -Infinity;
    const correctValue = _.sortBy([convertedStringValue, this.to])[0];
    const valueToShow = correctValue < this.min ? this.min : correctValue;
    this.slider.setHandle(0, valueToShow, true, true);

    this.updatePins();
    this.onChange();
    this.positionBlocked = false;
  }

  onToChange({ target: { value }}: { target: { value: string }}): void {
    if(this.slider === null) { return; }

    const convertedStringValue = this.dataInputConvert(value) || Infinity;
    const correctValue = _.sortBy([this.from, convertedStringValue])[1];
    const valueToShow = correctValue > this.max ? this.max : correctValue;
    this.slider.setHandle(1, valueToShow, true, true);

    this.updatePins();
    this.onChange();
    this.positionBlocked = false;
  }

  @Watch('step')
  onStepChange() {
    if(this.step === undefined) { return; }

    this.slider.updateOptions({ step: this.step });
  }

  @Watch('min')
  onMinChange() {
    if(this.min === undefined) { return; }

    this.updateRange();
  }

  @Watch('max')
  onMaxChange() {
    if(this.max === undefined) { return; }

    this.updateRange();
  }

  @Watch('valueRange', { deep: true })
  onValueRangeChange(newValue, oldValue) {
    if(this.slider === null) { return; }

    if(!_.isEqual(newValue, oldValue)) {
      this.slider.set(this.valueRange);
      this.updatePins();
    }
  }

  updatePins(): void {
    this.from = this.dataInputConvert(this.slider.get(true)[0]);
    this.to = this.dataInputConvert(this.slider.get(true)[1]);
  }

  updateRange(): void {
    this.slider.updateOptions({
      range: {
        min: [this.min],
        max: [this.max],
      },
    });

    this.updatePins();
  }

  onChange() {
    this.$emit('change', { from: this.from, to: this.to });
  }

  mounted() {
    const slider = document.getElementById(`${this.id}`);
    this.slider = noUiSlider.create(slider, {
      start: this.valueRange,
      range: {
        min: this.min,
        max: this.max,
      },
      connect: true,
      step: this.step,
      behaviour: 'tap-drag',
      cssPrefix: 'nvicta-',
    });
    this.updatePins();

    this.slider.on('slide', () => {
      this.updatePins();
    });

    this.slider.on('change', () => {
      this.onChange();
    });
  }
}
