




















































































































































































import WellSettingsPartBase from './Index.vue';

import { Well, WellAction } from '@/store/modules/well/types';
import { emitEngineEvent } from '@/services/socket_service';

import { Component, Prop, Vue } from 'vue-property-decorator';
import * as _ from 'lodash';

const SECTION_DEPTH_KEYS_MAPPINGS = {
  'Surface': 'Surface [ft]',
  'Intermediate #1': 'Intermediate #1 [ft]',
  'Intermediate #2': 'Intermediate #2 [ft]',
  'Curve': 'Curve [ft]',
  'Lateral': 'Lateral [ft]',
};

const WELL_FIELDS = {
  api: { title: 'API' },
  afe: { title: 'AFE' },
  permanentDatum: { title: 'Permanent Datum [ft]' },
  logMeasuredFrom: { title: 'Log Measured From [ft]' },
  heightOfReference: { title: 'Height Of Reference [ft]' },
  kellyBushingElevation: { title: 'Kelly Bushing Elevation [ft]' },
  drillFloorElevation: { title: 'Drill Floor Elevation [ft]' },
  groundLevelElevation: { title: 'Ground Level Elevation [ft]' },
  allowBackwardPushing: { title: 'Allow Backward Pushing', type: 'checkbox' },
};
export enum Direction {
  NORTH = 'N',
  SOUTH = 'S',
  WEST = 'W',
  EAST = 'E',
  NORTH_WEST = 'NW',
  NORTH_EAST = 'NE',
  SOUTH_WEST = 'SW',
  SOUTH_EAST = 'SE',
}
const LOCATION_FIELDS = {
  name: { title: 'Name', type: 'text' },
  field: { title: 'Field', type: 'text' },
  country: { title: 'Country', disabled: true, type: 'text' },
  state: { title: 'State', disabled: true, type: 'text' },
  county: { title: 'County', disabled: true, type: 'text' },
  x: { title: 'X', type: 'number' },
  y: { title: 'Y', type: 'number' },
  section: { title: 'Section', type: 'text' },
  twnship: { title: 'Twnship', type: 'text' },
  rnge: { title: 'Rnge', type: 'text' },
};
const ANGLES_MEASURED_FIELDS = {
  degrees: { title: 'Degrees', type: 'number' },
  minutes: { title: 'Minutes', type: 'number' },
  seconds: { title: 'Seconds', type: 'number' },
  direction: { title: 'Direction', type: 'selector' },
};
const HOLE_DATA_FIELDS = {
  size: { title: 'Size [in]', type: 'number' },
  startDepth: { title: 'Start Depth [ft]', type: 'number' },
  endDepth: { title: 'End Depth [ft]', type: 'number' },
};

@Component({ components: { WellSettingsPartBase } })
export default class GeneralSetting extends Vue {
  well: Well | null = null;
  locationFields = LOCATION_FIELDS;
  anglesMeasuredFields = ANGLES_MEASURED_FIELDS;
  directions = Direction;
  holeDataFields = HOLE_DATA_FIELDS;
  generalWellFields = WELL_FIELDS;

  @Prop({ required: false, default: () => [] })
  highlightedFields: string[];

  // the same as currentWell, it is needed not to fetch all wells
  initialWell: Well | null = null;

  get sectionDepthKeysMappings() {
    return SECTION_DEPTH_KEYS_MAPPINGS;
  }

  get currentWell(): Well | undefined {
    return this.$store.getters.currentWell;
  }

  get sectionDepthKeys(): string[] {
    return _.keys(this.well.sections);
  }

  get locationKeys(): string[] {
    return _.keys(LOCATION_FIELDS);
  }

  get angleMeasureKeys(): string[] {
    return _.keys(ANGLES_MEASURED_FIELDS);
  }

  get holeDataKeys(): string[] {
    return _.keys(HOLE_DATA_FIELDS);
  }

  get generalWellKeys(): string[] {
    return _.keys(WELL_FIELDS);
  }

  addDepthItem(dataType: string): void {
    // dataType: holeData or casingData
    this.well[dataType].push({
      size: 12,
      startDepth: 0,
      endDepth: 0,
    });
  }

  removeLastDepthItem(dataType: string): void {
    this.well[dataType].pop();
  }

  onDirectionSelect(measureType: string, option: Direction): void {
    // TODO: make measureType as enum: latitude or longitude
    this.well.location[measureType].direction = option;
  }

  async onApplyClick(): Promise<void> {
    const well = _.cloneDeep(this.well);
    const wellId = well._id;
    delete well._id;

    const resp = await this.$store.dispatch(
      WellAction.UPDATE_WELL,
      { wellId: this.$store.getters.currentWellId, updatedFields: { ...well }}
    );
    if(!resp) {
      return;
    }
    await emitEngineEvent('well-settings-updated', { wellId, ...well });
    await this.$store.dispatch('alertSuccess', { title: 'General settings', message: `Changes applied` });
  }

  async created(): Promise<void> {
    if(this.$store.getters.currentWell) {
      this.initialWell = this.$store.getters.currentWell;
    } else {
      this.initialWell = await this.$store.dispatch(
        WellAction.FETCH_WELL_BY_ID, this.$store.getters.currentWellId
      );
    }
    this.well = _.cloneDeep(this.initialWell);
  }
}
