










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AddButton extends Vue {
  @Prop({ required: false, default: false })
  disabled!: boolean;

  @Prop({ required: false })
  tooltipLabel!: string;

  onClick(): void {
    if(this.disabled) {
      return;
    }
    this.$emit('click');
  }
}

