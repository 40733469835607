var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.labelConverted)?_c('i',{staticClass:"fa fa-regular nvicta-button",class:[_vm.labelConverted, { disabled: _vm.disabled }],on:{"click":function ($event) {
    if(_vm.disabled) { return; }
    if(_vm.stopPropagation) {
      $event.stopPropagation();
    }
    _vm.onClick($event);
  }}}):_c('input',{staticClass:"nvicta-button",class:[_vm.theme, { active: _vm.active }, _vm.widthClassBySize],attrs:{"type":"button","value":_vm.label,"disabled":_vm.disabled},on:{"click":function ($event) {
    if(_vm.stopPropagation) {
      $event.stopPropagation();
    }
    _vm.onClick($event);
  }}})}
var staticRenderFns = []

export { render, staticRenderFns }