

























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as _ from 'lodash';

type TableDataValue =  {
  type: 'input' | 'checkbox' | 'selector';
  value: number | string | boolean;
  disabled?: boolean;
  style?: { [key: string]: string };
  options?: string[];
};

export type TableData = {
  [columnName: string]: TableDataValue;
};

@Component
export default class NvictaTable extends Vue {
  @Prop({ required: true })
  tableData!: TableData[];

  @Prop({ required: true })
  columnNames!: { [key: string]: string }[]; // table header values

  uidTable: string[] = [];
  invalidFields: { field: string, rowIdx: number }[] = [];

  @Watch('tableData.length', { immediate: true })
  addUID() {
    this.uidTable = [];
    while(this.uidTable.length !== this.tableData.length) {
      this.uidTable.push(this.getUid());
    }
  }

  get extendedColumns(): string[] {
    const keys = this.columnNames.map((item: { [key: string]: string }) => Object.keys(item)[0]);
    return [...keys];
  }

  get readableExtendedColumns(): string[] {
    const keys = this.columnNames.map((item: { [key: string]: string }) => Object.values(item)[0]);
    return [...keys];
  }

  getUid(): string {
    return Math.random().toString(36).substr(2, 12);
  }

  deleteRow(rowIdx: number) {
    this.uidTable.splice(rowIdx, 1);
    _.remove(this.invalidFields, (item: { field: string, rowIdx: number }) => item.rowIdx === rowIdx);
    for(const field of this.invalidFields) {
      if(field.rowIdx > rowIdx) {
        field.rowIdx -= 1;
      }
    }
    this.$emit('deleteRow', rowIdx);
  }

  getComponent(type: string): string {
    switch(type) {
      case 'text':
        return 'span';
      case 'input-number':
        return 'nvicta-input';
      case 'input-text':
        return 'nvicta-input';
      case 'checkbox':
        return 'nvicta-input';
      default:
        throw new Error(`Unknown type of component: ${type}`);
    }
  }

  getInputType(type: string): string {
    switch(type) {
      case 'text':
        return 'text';
      case 'input-number':
        return 'number';
      case 'input-text':
        return 'text';
      case 'checkbox':
        return 'checkbox';
      default:
        throw new Error(`Unknown type of component: ${type}`);
    }
  }

  getHeaderLabel(field: string): string {
    return field;
  }

  getFieldOptions(data: TableData, field: string): string[] | undefined {
    return data[field].options;
  }

  getFieldValue(data: TableData, field: string): string | number | boolean {
    return data[field].value;
  }

  getFieldStyle(field: string): any {
    return this.tableData[0][field]?.style;
  }

  getFieldType(data: TableData, field: string): string {
    return data[field].type;
  }

  isFieldDisabled(data: TableData, field: string): boolean {
    return data[field].disabled || false;
  }

  onFieldValidate(isValid: boolean, field: string, rowIdx: number): void {
    if(isValid) {
      _.remove(
        this.invalidFields,
        (item: { field: string, rowIdx: number }) => item.field === field && item.rowIdx === rowIdx
      );
    } else {
      if(!_.find(this.invalidFields, { field, rowIdx })) {
        this.invalidFields.push({ field, rowIdx });
      }
    }

    this.$emit('validate', _.isEmpty(this.invalidFields));
  }

  onChange(value: number | string | boolean, field: string, rowIdx: number): void {
    this.$emit('onChange', {
      value,
      field,
      rowIdx,
    });
  }
}
