export type WellPlan = {
  wellId: string;
  md: number[];
  inclination: number[];
  azimuth: number[];
  ew: number[];
  ns: number[];
  tvd: number[];
  dls: number[];
  plannedKop: number[];
  vs: number[];
  wellbore: boolean;
  remoteTable: string;
  plannedTrajectoryPoints: {
    KOP: number;
    incKOP: number;
    azmKOP: number;
    dlsKOP: number;
    LP: number;
    incLP: number;
    azmLP: number;
    avgBuildRate: number;
    tvdLP: number;
  };
  name?: string;
  _id: string;
};

export enum WellPlanTrace {
  TVD = 'tvd',
  NS = 'ns',
  EW = 'ew',
  VS = 'vs',
  INCLINATION = 'inclination',
  AZIMUTH = 'azimuth',
  MD = 'md',
  DLS = 'dls',
  PLANNED_KOP = 'plannedKop',
  WELLBORE = 'wellbore',
  REMOTE_TABLE = 'remoteTable',
  PLANNED_TRAJECTORY_POINTS = 'plannedTrajectoryPoints',
}

export enum WellPlanMutation {
  SET_WELL_PLANS = 'setWellPlans',
  SET_DISPLAYED_WELL_PLAN = 'setDisplayedWellPlan',
  CLEAR = 'clearWellPlans',
  APPEND_WELL_PLAN = 'appendWellPlan',
}

export enum WellPlanAction {
  FETCH_WELL_PLANS = 'fetchWellPlans',
  MAKE_WELL_PLAN_WELLBORE = 'makeWellPlanWellbore',
  DELETE_WELL_PLAN = 'deleteWellPlan',
  UPLOAD_WELL_PLAN = 'uploadWellPlan',
  CREATE_WELL_PLAN = 'createWellPlan',
}
