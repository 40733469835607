import { SlideSheet } from '@/store/modules/slide_sheet/types';

/* CORE STARTS */
export enum MultiwellCompareCoreGetter {
  GET_SYNC_STATUS = 'multiwellsCore/multiwellSyncStatus',
  GET_ACTIVE_FILTER_MODAL = 'multiwellsCore/activeFilterModal',
  GET_ACTIVE_COMPARE_TAB = 'multiwellsCore/activeCompareTab',
  GET_MODALS_WITH_CHANGES = 'multiwellsCore/modalsWithChanges',
  GET_CHARTS_PRELOADER = 'multiwellsCore/chartsPreloader',
  PDF_VIEW = 'multiwellsCore/pdfView',
}

export enum MultiwellCompareCoreMutation {
  SET_MULTIWELL_SYNC_STATUS = 'multiwellsCore/setMultiwellSyncStatus',
  SET_ACTIVE_FILTER_MODAL = 'multiwellsCore/setActiveFilterModal',
  SET_ACTIVE_COMPARE_TAB = 'multiwellsCore/setActiveCompareTab',
  SET_CHARTS_PRELOADER = 'multiwellsCore/setChartsPreloader',
  SET_PDF_VIEW = 'multiwellsCore/setPdfView',
}

export enum MultiwellCompareCoreAction {
  CLEAR_ALL_FILTERS = 'multiwellsCore/clearAllFilters',
}

export enum SyncStatuses {
  Initial = 'Initial',
  Fetching = 'Fetching',
  Fetched = 'Fetched',
}

export enum FiltersModalsList {
  MODAL_LOCATION = 'Location',
  MODAL_BHA = 'BHA',
  MODAL_TRAJECTORY = 'Trajectory',
  MODAL_FAILURES = 'Failures',
  MODAL_PERSONNEL = 'Personnel',
  MODAL_MUD_TYPE = 'Mud Type',
  MODAL_DRILLING_PARAMETERS = 'Drilling Parameters',
  CLEAR_CONFIRMATION = 'Confirmation',
}

export enum CompareTabsList {
  TAB_MAP_VIEW = 'Map View',
  TAB_DRILLING_OPTIMIZATION = 'Drilling Optimization',
  TAB_DIRECTIONAL = 'Directional',
  TAB_NPT = 'NPT',
}

export enum ChangesStatus {
  EMPTY = 'empty',
  CHANGED = 'changed',
  SAVED = 'saved',
}
/* CORE ENDS */

/* WELL LISTS START */
export enum MultiwellCompareWellListGetter {
  GET_RAW_DATA = 'multiwellsWellsLists/rawFiltersData',
  GET_ALL_DATA = 'multiwellsWellsLists/allFiltersData',
  GET_TOTAL_WELLS_LIST = 'multiwellsWellsLists/totalWellsList',
  GET_SELECTED_WELLS_IDS = 'multiwellsWellsLists/selectedWellsIds',
  GET_FILTERED_WELLS = 'multiwellsWellsLists/filteredWells',
  GET_SELECTED_WELLS = 'multiwellsWellsLists/selectedWells',
  GET_SELECTED_WELLS_FILTERS = 'multiwellsWellsLists/selectedWellsFilters',
  DEPTH_BORDERS = 'multiwellsWellsLists/depthBorders',
  // TODO: move data, well plans, mwd runs and bhas out of this module
  DATA = 'multiwellsWellsLists/data',
  WELL_PLANS = 'multiwellsWellsLists/wellPlans',
  BHAS = 'multiwellsWellsLists/bhas',
  MWD_RUNS = 'multiwellsWellsLists/mwdRuns',
  MWD_PERSONNEL_NAME_LIST = 'multiwellsWellsLists/mwdPersonnelNameList',
  BHA_PARAMS_LIST = 'multiwellsWellsLists/bhaParamsList',
  SECTIONS_RANGES = 'multiwellsWellsLists/sectionsRanges',
}

export enum MultiwellCompareWellListMutation {
  ADD_WELL = 'multiwellsWellsLists/addWellIdToSelected',
  SET_SELECTED_WELL_IDS = 'multiwellsWellsLists/setSelectedWellIds',
  REMOVE_WELL = 'multiwellsWellsLists/removeWellIdFromSelected',
  SET_DATA = 'multiwellsWellsLists/setData',
  SET_WELL_PLANS = 'multiwellsWellsLists/setWellPlans',
  SET_BHAS = 'multiwellsWellsLists/setBhas',
  SET_MWD_RUNS = 'multiwellsWellsLists/setMwdRuns',
  SET_DEPTH_BORDERS = 'multiwellsWellsLists/setDepthBorders',
  UPDATE_DEPTH_BORDERS_FOR_WELLS = 'multiwellsWellsLists/updateDepthBordersForWells',
  REMOVE_DEPTH_BORDERS_BY_WELL_ID = 'multiwellsWellsLists/removeDepthBordersByWellId',
}

export enum MultiwellCompareWellListAction {
  FETCH_WELL_PLANS = 'multiwellsWellsLists/fetchWellPlans',
  FETCH_BHAS = 'multiwellsWellsLists/fetchBhas',
  FETCH_MWD_RUNS = 'multiwellsWellsLists/fetchMwdRuns',
  FETCH_DATA_BORDERS = 'multiwellsWellsLists/fetchDataBorders',
}

/* WELL LISTS END */

/* FILTERS STARTS */
export enum MultiwellCompareFiltersGetter {
  /* Location */
  GET_LOCATION_COUNTRY = 'multiwellsFilters/location/selectedCountry',
  GET_LOCATION_STATE = 'multiwellsFilters/location/selectedState',
  GET_LOCATION_COUNTY = 'multiwellsFilters/location/selectedCounty',
  GET_LOCATION_ALL_DATA = 'multiwellsFilters/location/accessibleData',

  /* BHA */
  GET_BHA_RANGE_TYPES_LIST = 'multiwellsFilters/bha/rangeTypesList',
  GET_BHA_RANGE_TYPE = 'multiwellsFilters/bha/selectedRangeType',
  GET_BHA_RANGE_DATA = 'multiwellsFilters/bha/selectedRangeData',
  GET_BHA_MOTOR_MANUFACTURER = 'multiwellsFilters/bha/selectedMotorManufacturer',
  GET_BHA_MOTOR_SIZE = 'multiwellsFilters/bha/selectedMotorSize',
  GET_BHA_MOTOR_MODEL_NUMBER = 'multiwellsFilters/bha/selectedMotorModelNumber',
  GET_BHA_MOTOR_STAGES = 'multiwellsFilters/bha/selectedMotorStages',
  GET_BHA_BIT_MANUFACTURER = 'multiwellsFilters/bha/selectedBitManufacturer',
  GET_BHA_BIT_SIZE = 'multiwellsFilters/bha/selectedBitSize',
  GET_BHA_BIT_TYPE = 'multiwellsFilters/bha/selectedBitType',
  GET_BHA_CONTAINS = 'multiwellsFilters/bha/selectedContains',
  GET_BHA_ALL_DATA = 'multiwellsFilters/bha/accessibleData',

  /* Trajectory */
  GET_TRAJECTORY_DEPTH = 'multiwellsFilters/trajectory/selectedRangeDepthData',
  GET_TRAJECTORY_KICK_OFF_POINT = 'multiwellsFilters/trajectory/selectedRangeKickOffPointData',
  GET_TRAJECTORY_LANDING_TVD = 'multiwellsFilters/trajectory/selectedRangeLandingTVDData',
  GET_TRAJECTORY_ALL_DATA = 'multiwellsFilters/trajectory/accessibleData',

  /* Failures */
  GET_FAILURES_RANGE_TYPES_LIST = 'multiwellsFilters/failures/rangeTypesList',
  GET_FAILURES_RANGE_TYPE = 'multiwellsFilters/failures/selectedRangeType',
  GET_FAILURES_RANGE_DATA = 'multiwellsFilters/failures/selectedRangeData',
  GET_FAILURES_MWD = 'multiwellsFilters/failures/selectedMWD',
  GET_FAILURES_BIT = 'multiwellsFilters/failures/selectedBit',
  GET_FAILURES_MOTOR = 'multiwellsFilters/failures/selectedMotor',
  GET_FAILURES_ALL_DATA = 'multiwellsFilters/failures/accessibleData',

  /* Personnel */
  GET_PERSONNEL_RANGE_DATA = 'multiwellsFilters/personnel/selectedRangeData',
  GET_PERSONNEL_FIRST_NAME = 'multiwellsFilters/personnel/selectedFirstName',
  GET_PERSONNEL_LAST_NAME = 'multiwellsFilters/personnel/selectedLastName',
  GET_PERSONNEL_ALL_DATA = 'multiwellsFilters/personnel/accessibleData',

  /* Mud Type */
  GET_MUD_TYPE_RANGE_TYPES_LIST = 'multiwellsFilters/mudType/rangeTypesList',
  GET_MUD_TYPE_RANGE_TYPE = 'multiwellsFilters/mudType/selectedRangeType',
  GET_MUD_TYPE_RANGE_DATA = 'multiwellsFilters/mudType/selectedRangeData',
  GET_MUD_TYPE_MUD_TYPE = 'multiwellsFilters/mudType/selectedMudType',
  GET_MUD_TYPE_MUD_DENSITY = 'multiwellsFilters/mudType/selectedMudDensity',
  GET_MUD_TYPE_ALL_DATA = 'multiwellsFilters/mudType/accessibleData',

  /* Drilling Parameters */
  GET_DRILLING_PARAMETERS_PARAMETERS = 'multiwellsFilters/drillingParameters/drillingParametersParameters',
  GET_DRILLING_PARAMETERS_OPERATOR = 'multiwellsFilters/drillingParameters/drillingParametersFilterOperator',
  GET_DRILLING_PARAMETERS_ALL_DATA = 'multiwellsFilters/drillingParameters/accessibleData',
  GET_DRILLING_PARAMETERS_CONDITIONAL_DEPTH_DATA_COUNT = 'multiwellsFilters/drillingParameters/conditionalDepthDataCount',
  GET_DRILLING_PARAMETERS_IS_IN_PROGRESS = 'multiwellsFilters/drillingParameters/isInProgress',
  GET_DRILLING_PARAMETERS_PROGRESS = 'multiwellsFilters/drillingParameters/progress',
}

export enum MultiwellCompareFiltersMutation {
  /* Location */
  SET_LOCATION_COUNTRY = 'multiwellsFilters/location/setFilterCountry',
  SET_LOCATION_STATE = 'multiwellsFilters/location/setFilterState',
  SET_LOCATION_COUNTY = 'multiwellsFilters/location/setFilterCounty',

  /* BHA */
  SET_BHA_RANGE_TYPE = 'multiwellsFilters/bha/setSelectedRangeType',
  SET_BHA_RANGE_DATA = 'multiwellsFilters/bha/setSelectedRangeData',
  SET_BHA_MOTOR_MANUFACTURER = 'multiwellsFilters/bha/setSelectedMotorManufacturer',
  SET_BHA_MOTOR_SIZE = 'multiwellsFilters/bha/setSelectedMotorSize',
  SET_BHA_MOTOR_MODEL_NUMBER = 'multiwellsFilters/bha/setSelectedMotorModelNumber',
  SET_BHA_MOTOR_STAGES = 'multiwellsFilters/bha/setSelectedMotorStages',
  SET_BHA_BIT_MANUFACTURER = 'multiwellsFilters/bha/setSelectedBitManufacturer',
  SET_BHA_BIT_SIZE = 'multiwellsFilters/bha/setSelectedBitSize',
  SET_BHA_BIT_TYPE = 'multiwellsFilters/bha/setSelectedBitType',
  SET_BHA_CONTAINS = 'multiwellsFilters/bha/setSelectedContains',

  /* Trajectory */
  SET_TRAJECTORY_DEPTH = 'multiwellsFilters/trajectory/setSelectedRangeDepthData',
  SET_TRAJECTORY_KICK_OFF_POINT = 'multiwellsFilters/trajectory/setSelectedRangeKickOffPointData',
  SET_TRAJECTORY_LANDING_TVD = 'multiwellsFilters/trajectory/setSelectedRangeLandingTVDData',

  /* Failures */
  SET_FAILURES_RANGE_TYPE = 'multiwellsFilters/failures/setSelectedRangeType',
  SET_FAILURES_RANGE_DATA = 'multiwellsFilters/failures/setSelectedRangeData',
  SET_FAILURES_MWD = 'multiwellsFilters/failures/setSelectedMWD',
  SET_FAILURES_BIT = 'multiwellsFilters/failures/setSelectedBit',
  SET_FAILURES_MOTOR = 'multiwellsFilters/failures/setSelectedMotor',

  /* Personnel */
  SET_PERSONNEL_RANGE_DATA = 'multiwellsFilters/personnel/setSelectedRangeData',
  SET_PERSONNEL_FIRST_NAME = 'multiwellsFilters/personnel/setSelectedFirstName',
  SET_PERSONNEL_LAST_NAME = 'multiwellsFilters/personnel/setSelectedLastName',

  /* Mud Type */
  SET_MUD_TYPE_RANGE_TYPE = 'multiwellsFilters/mudType/setSelectedRangeType',
  SET_MUD_TYPE_RANGE_DATA = 'multiwellsFilters/mudType/setSelectedRangeData',
  SET_MUD_TYPE_MUD_TYPE = 'multiwellsFilters/mudType/setSelectedMudType',
  SET_MUD_TYPE_MUD_DENSITY = 'multiwellsFilters/mudType/setSelectedMudDensity',

  /* Drilling Parameters */
  SET_DRILLING_PARAMETERS_PARAMETERS = 'multiwellsFilters/drillingParameters/setDrillingParametersParameters',
  SET_DRILLING_PARAMETERS_OPERATOR = 'multiwellsFilters/drillingParameters/setDrillingParametersFilterOperator',
  SET_DRILLING_PARAMETERS_CONDITIONAL_DEPTH_DATA_COUNT = 'multiwellsFilters/drillingParameters/setConditionalDepthDataCount',
  SET_DRILLING_PARAMETERS_IS_IN_PROGRESS = 'multiwellsFilters/drillingParameters/setIsInProgress',
  SET_DRILLING_PARAMETERS_PROGRESS = 'multiwellsFilters/drillingParameters/setProgress',
}

export enum MultiwellCompareFiltersAction {
  /* Location */
  SET_LOCATION_FILTERS = 'multiwellsFilters/location/setLocationFilters',
  CLEAR_LOCATION_FILTERS = 'multiwellsFilters/location/clearLocationFilters',

  /* BHA */
  SET_BHA_FILTERS = 'multiwellsFilters/bha/setBHAFilters',
  CLEAR_BHA_RANGE = 'multiwellsFilters/bha/clearSelectedRange',
  CLEAR_BHA_MOTOR_DETAILS = 'multiwellsFilters/bha/clearMotorDetails',
  CLEAR_BHA_BIT_DETAILS = 'multiwellsFilters/bha/clearBitDetails',
  CLEAR_BHA_CONTAINS = 'multiwellsFilters/bha/clearContains',
  CLEAR_BHA_FILTERS = 'multiwellsFilters/bha/clearBHAFilters',

  /* Trajectory */
  SET_TRAJECTORY_FILTERS = 'multiwellsFilters/trajectory/setTrajectoryFilters',
  CLEAR_TRAJECTORY_FILTERS = 'multiwellsFilters/trajectory/clearSelectedRanges',

  /* Failures */
  SET_FAILURES_FILTERS = 'multiwellsFilters/failures/setFailuresFilters',
  CLEAR_FAILURES_RANGE = 'multiwellsFilters/failures/clearSelectedRange',
  CLEAR_FAILURES_TOGGLES = 'multiwellsFilters/failures/clearSelectedToggles',
  CLEAR_FAILURES_FILTERS = 'multiwellsFilters/failures/clearFailuresFilters',

  /* Personnel */
  SET_PERSONNEL_FILTERS = 'multiwellsFilters/personnel/setPersonnelFilters',
  CLEAR_PERSONNEL_FILTERS = 'multiwellsFilters/personnel/clearPersonnelFilters',

  /* Mud Type */
  SET_MUD_TYPE_FILTERS = 'multiwellsFilters/mudType/setMudTypeFilters',
  CLEAR_MUD_TYPE_RANGE = 'multiwellsFilters/mudType/clearSelectedRange',
  CLEAR_MUD_TYPE_FIELDS = 'multiwellsFilters/mudType/clearMudFields',
  CLEAR_MUD_TYPE_FILTERS = 'multiwellsFilters/mudType/clearMudTypeFilters',

  /* Drilling Parameters */
  SET_DRILLING_PARAMETERS_FILTERS = 'multiwellsFilters/drillingParameters/setDrillingParametersFilters',
  CLEAR_DRILLING_PARAMETERS_FILTERS = 'multiwellsFilters/drillingParameters/clearDrillingParametersFilters',
  FETCH_DRILLING_PARAMETERS_CONDITIONAL_DEPTH_DATA_COUNT = 'multiwellsFilters/drillingParameters/fetchConditionalDepthDataCount',
}

export type TRangeType = 'Depth Range' | 'Date Range';
export interface IRangeData {
  from: number;
  to: number;
}

export enum RangeTypes {
  DEPTH_RANGE = 'Depth Range',
  DATE_RANGE = 'Date Range',
}

export const MultiwellDrillingRigStatesLabels = {
  20: 'All',
  1: 'Rotary Drilling',
  2: 'Slide Drilling',
};

export enum MultiwellDepthTrace {
  DEPTH = 'depth',
  DOC = 'doc',
  MSE = 'mse',
  MSE_TORSION = 'mseTorsion',
  MSE_THRUST = 'mseThrust',
  SPPA_Q2 = 'sppaQ2',
  DOC_WOB_RATIO = 'docWobRatio',
  DRILLING_EFFICIENCY = 'drillingEfficiency',
  ROP = 'rop',
  BIT_AGGRESSIVENESS = 'bitAggressiveness',
  WOB = 'wob',
  RPM = 'rpm',
  RPM_DOWNHOLE = 'rpmDownhole',
  TORQUE = 'torque',
  TORQUE_DOWNHOLE = 'torqueDownhole',
  PUMP_PRESSURE = 'pumpPressure',
  DIFF_PRESSURE = 'diffPressure',
  PUMP_RATE = 'pumpRate',
  RIG_STATE = 'rigState',
  TOOLFACE = 'toolface',
  SLIDE_SCORE = 'slideScore',
  FORMATION_CHANGE_DEPTH = 'formationChangeDepth',
  AVG_SLIDE_TOOLFACE = 'avgSlideToolface',
  SLIDE_DISTANCE = 'slideDistance',
  BIT_USAGE = 'bitUsage',
  INTERPOLATED_TVD = 'interpolatedTvd',
  BIT_TFA = 'bitTFA',
  BIT_SIZE = 'bitSize',
  MOTOR_RPG = 'motorRPG',
  INCLINATION = 'inclination',
  AZIMUTH = 'azimuth',
  TVD = 'tvd',
  NS = 'ns',
  EW = 'ew',
  VS = 'vs',
  HOOKLOAD = 'hookload',
  MAGNETIC_TOOLFACE = 'magneticToolface',
  GRAVITY_TOOLFACE = 'gravityToolface',
  PULSE_AMPLITUDE = 'pulseAmplitude',
  PULSE_CONFIDENCE = 'pulseConfidence',
  SPA = 'SPA',
  SPL = 'SPL',
  SHOCK_LEVEL = 'shockLevel',
  POSSUM = 'possum',
  TFDS = 'tfds',
  STEER = 'steer',
  PROPEFF = 'propeff',
  PRDS = 'prds',
  IN_TRGT = 'in_trgt',
  AZI_TRGT = 'azi_trgt',
  SPM1 = 'spm1',
  SPM2 = 'spm2',
  SPM3 = 'spm3',
}

// TODO: de-duplicate with DEPTH_TRACE_TO_NAME_AND_UNIT_MAPPING
export const MULTIWELL_DEPTH_TRACE_TO_NAME_AND_UNIT_MAPPING = {
  [MultiwellDepthTrace.DEPTH]: { name: 'Depth', unit: 'ft', min: 0, max: 40000 },
  [MultiwellDepthTrace.DOC]: { name: 'DOC', unit: 'in/rev', min: 0, max: 10 },
  [MultiwellDepthTrace.MSE]: { name: 'MSE', unit: 'kpsi', min: 0, max: 100 },
  [MultiwellDepthTrace.MSE_TORSION]: { name: 'MSE Torsion', unit: 'kpsi', min: 0, max: 100 },
  [MultiwellDepthTrace.MSE_THRUST]: { name: 'MSE Thrust', unit: 'kpsi', min: 0, max: 100 },
  [MultiwellDepthTrace.SPPA_Q2]: { name: 'SPPA Q2', unit: '', min: 0, max: 1 },
  [MultiwellDepthTrace.DOC_WOB_RATIO]: { name: 'DOC-WOB', unit: '', min: 0, max: 1 },
  [MultiwellDepthTrace.DRILLING_EFFICIENCY]: { name: 'Drilling Efficiency', unit: '%', min: 0, max: 100 },
  [MultiwellDepthTrace.ROP]: { name: 'ROP', unit: 'ft/hr', min: 0, max: 1000 },
  [MultiwellDepthTrace.BIT_AGGRESSIVENESS]: { name: 'Bit Aggressiveness', unit: '', min: 0, max: 1 },
  [MultiwellDepthTrace.WOB]: { name: 'WOB', unit: 'klb', min: 0, max: 100 },
  [MultiwellDepthTrace.RPM]: { name: 'RPM', unit: 'rpm', min: 0, max: 200 },
  [MultiwellDepthTrace.RPM_DOWNHOLE]: { name: 'RPM Downhole', unit: 'rpm', min: 0, max: 200 },
  [MultiwellDepthTrace.TORQUE]: { name: 'Torque', unit: 'ft.lb', min: 0, max: 50000 },
  [MultiwellDepthTrace.TORQUE_DOWNHOLE]: { name: 'Torque Downhole', unit: 'ft.lb', min: 0, max: 50000 },
  [MultiwellDepthTrace.PUMP_PRESSURE]: { name: 'Pump Pressure', unit: 'psi', min: 0, max: 10000 },
  [MultiwellDepthTrace.DIFF_PRESSURE]: { name: 'Diff Pressure', unit: 'psi', min: 0, max: 5000 },
  [MultiwellDepthTrace.PUMP_RATE]: { name: 'Pump Rate', unit: 'gpm', min: 0, max: 1000 },
  [MultiwellDepthTrace.RIG_STATE]: { name: 'Rig State', unit: '' },
  [MultiwellDepthTrace.TOOLFACE]: { name: 'Toolface', unit: 'deg', min: 0, max: 360 },
  [MultiwellDepthTrace.FORMATION_CHANGE_DEPTH]: { name: 'Formation Change', unit: 'ft.lb', min: 0, max: 20000 },
  [MultiwellDepthTrace.AVG_SLIDE_TOOLFACE]: { name: 'Average Slide Toolface', unit: 'deg', min: 0, max: 360 },
  [MultiwellDepthTrace.SLIDE_DISTANCE]: { name: 'Slide Distance', unit: 'ft', min: 0, max: 100 },
  [MultiwellDepthTrace.BIT_USAGE]: { name: 'Bit Usage', unit: 'ft', min: 0, max: 30000 },
  [MultiwellDepthTrace.INTERPOLATED_TVD]: { name: 'Interpolated TVD', unit: 'ft', min: 0, max: 20000 },
  [MultiwellDepthTrace.BIT_TFA]: { name: 'Bit TFA', unit: 'in^2', min: 0, max: 2 },
  [MultiwellDepthTrace.BIT_SIZE]: { name: 'Bit Size', unit: 'in', min: 0, max: 20 },
  [MultiwellDepthTrace.MOTOR_RPG]: { name: 'Motor RPG', unit: 'rev/gal', min: 0, max: 1 },
  [MultiwellDepthTrace.INCLINATION]: { name: 'Inclination', unit: 'deg', min: 0, max: 360 },
  [MultiwellDepthTrace.AZIMUTH]: { name: 'Azimuth', unit: 'deg', min: 0, max: 360 },
  [MultiwellDepthTrace.TVD]: { name: 'TVD', unit: 'ft', min: 0, max: 20000 },
  [MultiwellDepthTrace.NS]: { name: 'NS', unit: 'ft', min: 0, max: 20000 },
  [MultiwellDepthTrace.EW]: { name: 'EW', unit: 'ft', min: 0, max: 20000 },
  [MultiwellDepthTrace.VS]: { name: 'VS', unit: 'ft', min: 0, max: 20000 },
  [MultiwellDepthTrace.HOOKLOAD]: { name: 'Hookload', unit: 'klb', min: 0, max: 500 },
  [MultiwellDepthTrace.MAGNETIC_TOOLFACE]: { name: 'Magnetic Toolface', unit: 'deg', min: 0, max: 360 },
  [MultiwellDepthTrace.GRAVITY_TOOLFACE]: { name: 'Gravity Toolface', unit: 'deg', min: 0, max: 360 },
  [MultiwellDepthTrace.PULSE_AMPLITUDE]: { name: 'Pulse Amplitude', unit: '', min: 0, max: 10 },
  [MultiwellDepthTrace.PULSE_CONFIDENCE]: { name: 'Pulse Confidence', unit: '', min: 0, max: 10 },
  [MultiwellDepthTrace.SPA]: { name: 'SPA', unit: '', min: 0, max: 100 },
  [MultiwellDepthTrace.SPL]: { name: 'SPL', unit: '', min: 0, max: 100 },
  [MultiwellDepthTrace.SHOCK_LEVEL]: { name: 'Shock Level', unit: '', min: 0, max: 100 },
  [MultiwellDepthTrace.POSSUM]: { name: 'Possum', unit: '', min: 0, max: 100 },
  [MultiwellDepthTrace.TFDS]: { name: 'TFDS', unit: '', min: 0, max: 360 },
  [MultiwellDepthTrace.STEER]: { name: 'Steer', unit: '', min: 0, max: 360 },
  [MultiwellDepthTrace.PROPEFF]: { name: 'Propeff', unit: '', min: 0, max: 100 },
  [MultiwellDepthTrace.PRDS]: { name: 'PRDS', unit: '', min: 0, max: 100 },
  [MultiwellDepthTrace.IN_TRGT]: { name: 'Inc Target', unit: 'deg', min: 0, max: 360 },
  [MultiwellDepthTrace.AZI_TRGT]: { name: 'Azi Target', unit: 'deg', min: 0, max: 360 },
  [MultiwellDepthTrace.SPM1]: { name: 'SPM 1', unit: 'spm', min: 0, max: 1000 },
  [MultiwellDepthTrace.SPM2]: { name: 'SPM 2', unit: 'spm', min: 0, max: 1000 },
  [MultiwellDepthTrace.SPM3]: { name: 'SPM 3', unit: 'spm', min: 0, max: 1000 },
};
/* FILTERS ENDS */

/* TABS STARTS */
export type DrillingOptimizationDepthData = {
  depth: number[]; time: number[]; mse: number[]; wob: number[]; rop: number[]; diffPressure: number[];
};

export type DirectionalTortuosityData = {
  md: []; ti: []; hti: []; lti: []; dls: [];
};

export type NPTWellData = {
  holeDepth: []; correctedTimeStamp: [];
};

export type NptData = {
  numberOfTrips: number;
  averageTripTime: number;
  averageConnectionTime: number;
  totalOffBottomTime: number;
};

export type NPTDepthVsDaysData = {
  [wellId: string]: NPTWellData;
};

export type DrillingOptimizationLineChartData = {
  [wellId: string]: DrillingOptimizationDepthData;
};

export type DirectionalLineChartData = {
  [wellId: string]: DirectionalTortuosityData;
};

export type DirectionalSlideSheetsData = {
  [wellId: string]: SlideSheet[];
};

export enum MultiWellsTabGetter {
  /* Drilling Optimization */
  DRILLING_OPTIMIZATION_LINE_CHARTS_DATA = 'multiwellsTabsList/drillingOptimization/lineChartsData',
  DRILLING_OPTIMIZATION_BAR_CHARTS_DATA = 'multiwellsTabsList/drillingOptimization/barChartsData',
  DRILLING_OPTIMIZATION_CHART_METRICS = 'multiwellsTabsList/drillingOptimization/chartMetrics',
  DRILLING_OPTIMIZATION_DEPTH_BORDERS = 'multiwellsTabsList/drillingOptimization/depthBorders',
  DRILLING_OPTIMIZATION_SELECTED_DEPTH_RANGE = 'multiwellsTabsList/drillingOptimization/selectedDepthRange',
  /* Directional */
  DIRECTIONAL_TORTUOSITY_DATA = 'multiwellsTabsList/directional/tortuosityData',
  DIRECTIONAL_TORTUOSITY_METRICS = 'multiwellsTabsList/directional/tortuosityMetrics',
  DIRECTIONAL_SELECTED_TORTUOSITY_METRIC = 'multiwellsTabsList/directional/selectedTortuosityMetric',
  DIRECTIONAL_SLIDE_SHEETS_DATA = 'multiwellsTabsList/directional/slideSheetsData',
  DIRECTIONAL_SLIDE_SHEETS_METRICS = 'multiwellsTabsList/directional/slideSheetsMetrics',
  DIRECTIONAL_SELECTED_DEPTH_RANGE = 'multiwellsTabsList/directional/selectedDepthRange',
  DIRECTIONAL_SELECTED_DAY_TIME = 'multiwellsTabsList/directional/selectedDayTime',
  /* NPT */
  NPT_DEPTH_VS_DAYS_DATA = 'multiwellsTabsList/npt/depthVsDaysData',
  NPT_DATA = 'multiwellsTabsList/npt/nptData',
  NPT_SELECTED_DEPTH_RANGE = 'multiwellsTabsList/npt/selectedDepthRange',
  NPT_SELECTED_DAY_TIME = 'multiwellsTabsList/npt/selectedDayTime',
}

export enum MultiWellsTabMutation {
  /* Drilling Optimization */
  DRILLING_OPTIMIZATION_SET_LINE_CHART_DATA = 'multiwellsTabsList/drillingOptimization/setLineChartData',
  DRILLING_OPTIMIZATION_SET_BAR_CHART_DATA = 'multiwellsTabsList/drillingOptimization/setBarChartData',
  DRILLING_OPTIMIZATION_APPEND_BAR_CHART_DATA = 'multiwellsTabsList/drillingOptimization/appendBarChartData',
  DRILLING_OPTIMIZATION_REMOVE_BAR_CHART_DATA = 'multiwellsTabsList/drillingOptimization/removeBarChartData',
  DRILLING_OPTIMIZATION_SET_SELECTED_DEPTH_RANGE = 'multiwellsTabsList/drillingOptimization/setSelectedDepthRange',
  /* Directional */
  DIRECTIONAL_SET_TORTUOSITY_DATA = 'multiwellsTabsList/directional/setTortuosityData',
  DIRECTIONAL_SET_SELECTED_DEPTH_RANGE = 'multiwellsTabsList/directional/setSelectedDepthRange',
  DIRECTIONAL_SET_SLIDE_SHEETS_DATA = 'multiwellsTabsList/directional/setSlideSheetsData',
  DIRECTIONAL_SET_SELECTED_TORTUOSITY_METRIC = 'multiwellsTabsList/directional/setSelectedTortuosityMetric',
  DIRECTIONAL_SET_SELECTED_DAY_TIME = 'multiwellsTabsList/directional/setSelectedDayTime',
  /* NPT */
  NPT_SET_DEPTH_VS_DAYS_DATA = 'multiwellsTabsList/npt/setDepthVsDaysData',
  NPT_APPEND_DEPTH_VS_DAYS_DATA_FOR_WELL_ID = 'multiwellsTabsList/npt/appendDepthVsDaysDataForWellId',
  NPT_REMOVE_DEPTH_VS_DAYS_DATA_BY_WELL_ID = 'multiwellsTabsList/npt/removeDepthVsDaysDataByWellId',
  NPT_SET_NPT_DATA = 'multiwellsTabsList/npt/setNptData',
  NPT_APPEND_NPT_DATA_FOR_WELL_ID = 'multiwellsTabsList/npt/appendNptDataForWellId',
  NPT_REMOVE_NPT_DATA_BY_WELL_ID = 'multiwellsTabsList/npt/removeNptDataByWellId',
  NPT_SET_SELECTED_DEPTH_RANGE = 'multiwellsTabsList/npt/setSelectedDepthRange',
  NPT_SET_SELECTED_DAY_TIME = 'multiwellsTabsList/npt/setSelectedDayTime',
}

export enum MultiWellsTabAction {
  /* Drilling Optimization */
  DRILLING_OPTIMIZATION_FETCH_BAR_CHART_DATA = 'multiwellsTabsList/drillingOptimization/fetchBarChartData',
  DRILLING_OPTIMIZATION_FETCH_LINE_CHART_DATA_FOR_ALL_WELLS = 'multiwellsTabsList/drillingOptimization/fetchLineChartDataForAllWells',
  /* Directional */
  DIRECTIONAL_FETCH_TORTUOSITY_DATA_FOR_ALL_WELLS = 'multiwellsTabsList/directional/fetchTortuosityDataForAllWells',
  DIRECTIONAL_FETCH_SLIDE_SHEETS_DATA_FOR_ALL_WELLS = 'multiwellsTabsList/directional/fetchSlideSheetsDataForAllWells',
  /* NPT */
  NPT_FETCH_DEPTH_VS_DAYS_DATA_FOR_ALL_WELLS = 'multiwellsTabsList/npt/fetchDepthVsDaysDataForAllWells',
  NPT_FETCH_NPT_DATA_FOR_ALL_WELLS = 'multiwellsTabsList/npt/fetchNptDataForAllWells',
}
/* TABS ENDS */
