var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'is-robot': _vm.isRobot,
    'well-summary': _vm.isWellSummaryRouteActive,
    'pdf-view': _vm.isPdfViewRouteActive,
    'wells-summary': _vm.isWellsCompareRouteActive
  },attrs:{"id":"app"}},[_c('transition',{attrs:{"name":"preloader-fade"}},[_c('preloader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]})],1),_c('tooltip',{attrs:{"visible":_vm.isTooltipDisplayed,"x":_vm.tooltipX,"y":_vm.tooltipY},domProps:{"innerHTML":_vm._s(_vm.tooltipContent)}}),_vm._l((_vm.leftTabTooltips),function(tooltip,idx){return _c('tooltip',{key:idx,attrs:{"visible":_vm.isLeftTabTooltipDisplayed,"minWidth":"180px","x":_vm.getLeftTabTooltipX(idx),"y":_vm.getLeftTabTooltipY(idx)},domProps:{"innerHTML":_vm._s(_vm.getLeftTabTooltipContent(idx))}})}),_c('notifications',{staticClass:"app-notification",attrs:{"group":"app"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
  var item = ref.item;
  var close = ref.close;
return [_c('div',{staticClass:"vue-notification-template vue-notification",class:[item.type, { oneliner: !item.title || !item.text }]},[_c('div',{staticClass:"vue-notification-icon",class:item.type},[(item.type === 'success')?_c('i',{staticClass:"fa fa-regular fa-circle-check"}):(item.type === 'warning')?_c('i',{staticClass:"fa fa-regular fa-circle-exclamation"}):_c('i',{staticClass:"fa fa-regular fa-ban"})]),_c('img',{staticClass:"vue-notification-close",attrs:{"src":require("@/assets/icons/light_close.svg")},on:{"click":close}}),_c('div',{staticClass:"notification-payload"},[_c('div',{staticClass:"notification-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),(item.text)?_c('div',{staticClass:"notification-content"},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()])])]}}])}),_c('notifications',{staticClass:"engine-notification",attrs:{"group":"engine-alerts"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('div',{staticClass:"notification-body"},[_c('p',{staticClass:"notification-title"},[_vm._v(" Nvicta's Recommendation at "+_vm._s(slotProps.item.data.md)+" ft ")]),_c('div',{staticClass:"btn-row"},[_c('nvicta-button',{attrs:{"label":"Hide"},on:{"click":function($event){return _vm.closeNotification(slotProps.item, slotProps.close)}}}),_c('nvicta-button',{attrs:{"label":"Details"},on:{"click":function($event){return _vm.applyNotification(slotProps.item, slotProps.close)}}})],1)])]}}])}),_c('global-tooltip'),_c('div',{staticClass:"page-container"},[_c('authorized-layout',[_c('router-view',{on:{"loading":_vm.onRouterLoadingChange}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }