import { DirectionalRecommendation } from '../directional_recommendation/types';
import { StateTransitionReport, StateTransitionReportTrace } from '../state_transition_report/types';
import { AdvancedConfigRule } from '../gauge_data/types';
import { MotorStallData } from '../motor_stall_data/types';
import { Survey } from '../survey/types';

export type AbstractNotification = {
  wellId: string;
  userId: string;
  type: NotificationType;
  alertLevel: AlertLevel;
  time: number;
  hidden: boolean;
  _id: string;
};

export type DirectionalRecommendationNotification = AbstractNotification & {
  md: number;
  error: string | null;
  recommendation: DirectionalRecommendation;
  survey: Survey;
};

export type SimpleNotification = AbstractNotification & {
  trace: string;
  value: number;
  threshold: [number, number] | null;
  aggregationType: AggregationType;
  aggregationInterval: TimeAggregationInterval | DepthAggregationInterval | undefined;
};

export type AdvancedNotification = AbstractNotification & {
  values: { [trace: string]: number };
  rules: AdvancedConfigRule[];
};

export type RigStateNotification = AbstractNotification & {
  rigState: number;
};

export type MotorStallNotification = AbstractNotification & {
  motorStall: MotorStallData;
  message: string;
};

export type EngineNotification = AbstractNotification & {
  message: string;
};

export type StateTransitionReportNotification = AbstractNotification & {
  stateTransitionReport: StateTransitionReport;
  message: string;
};

export type Notification = DirectionalRecommendationNotification |
  SimpleNotification |
  AdvancedNotification |
  RigStateNotification |
  MotorStallNotification |
  EngineNotification |
  StateTransitionReportNotification;

export enum NotificationType {
  DIRECTIONAL_RECOMMENDATION = 'directional_recommendation',
  SIMPLE = 'simple',
  ADVANCED = 'advanced',
  RIG_STATE = 'rig_state',
  MOTOR_STALL = 'motor_stall',
  ENGINE = 'engine',
  STATE_TRANSITION_REPORT = 'state_transition_report',
}

export enum NotificationConfigurationType {
  SIMPLE = 'simple',
  ADVANCED = 'advanced',
  RIG_STATE = 'rig_state',
  STATE_TRANSITION_REPORT = 'state_transition_report',
}

export enum ConfigDrillingState {
  ALL = 'all',
  DRILLING = 'drilling',
  NOT_DRILLING = 'not_drilling',
}

export type StateTransitionReportConfiguration = {
  drillingState: ConfigDrillingState;
  rules: StateTransitionReportConfigRule[];
  // TODO: move to base config
  accepted: true;
  enableDashboardNotifications: false;
  enableVoiceNotifications: false;
  enableEmailNotifications: false;
  enableWhatsappNotifications: false;
  wellIdList: [];
  applyAllWells: false;
  userId: string;
  _id: string;
};

export type StateTransitionReportConfigRule = {
  trace: StateTransitionReportTrace;
  // it's either range or value
  range?: [number, number];
  value?: boolean;
};

export enum AlertLevel {
  ERROR = 'Error',
  WARNING = 'Warning',
  OK = 'Ok',
  INFO = 'Info',
}

export enum AggregationType {
  INSTANT = 'Instant',
  MIN = 'Min',
  MAX = 'Max',
  AVG = 'Avg',
}

export enum TimeAggregationInterval {
  ONE_MIN = '1min',
  TEN_MIN = '10min',
  THIRTY_MIN = '30min',
}

export enum DepthAggregationInterval {
  TEN_FT = '10ft',
  THIRTY_FT = '30ft',
  NINETY_FT = '90ft',
  FIVE_HUNDRED_FT = '500ft',
}

export enum NotificationMutation {
  SET_VISIBILITY_STATE = 'setVisibilityState',
  SET_NOTIFICATIONS = 'setNotifications',
  SET_NOTIFICATION_VISIBILITY = 'setNotificationVisibility',
  OPEN_NOTIFICATION_MODAL = 'openNotificationModal',
  CLOSE_MODAL = 'closeModal',
  SET_ALERT_TYPES = 'setFilteredAlertTypes',
  SET_ALERT_TYPES_BUNCH = 'setFilteredAlertTypesBunch',
  SET_ALERT_LEVELS = 'setFilteredAlertLevels',
  SET_ALERT_LEVELS_BUNCH = 'setFilteredAlertLevelsBunch',
  SET_PERIOD_TIME = 'setFilterPeriodTime',
  APPEND_NOTIFICATION = 'appendNotification',
  SET_MULTIPLE_NOTIFICATIONS_VISIBILITY = 'setMultipleNotificationsVisibility',
}

export enum NotificationAction {
  FETCH_NOTIFICATIONS = 'fetchNotifications',
  CHANGE_NOTIFICATION_VISIBILITY = 'changeNotificationVisibility',
  CHANGE_MULTIPLE_NOTIFICATIONS_VISIBILITY = 'changeMultipleNotificationsVisibility',
  CLOSE_NOTIFICATION_MODAL = 'closeNotificationModal',
}

export enum NotificationDatePeriod {
  LAST_12H = 0,
  LAST_24H = 1,
  LAST_7D = 2,
  LAST_30D = 3,
  ALL_PERIOD = 4,
}

export enum NotificationDatePeriodNames {
  LAST_12H = 'Last 12 hours',
  LAST_24H = 'Last 24 hours',
  LAST_7D = 'Last 7 days',
  LAST_30D = 'Last 30 days',
  ALL_PERIOD = 'All',
}

export enum NotificationDatePeriodSeconds {
  LAST_12H = 43200,
  LAST_24H = 86400,
  LAST_7D = 604800,
  LAST_30D = 2592000,
  ALL_PERIOD = 0,
}

export enum NotificationVisibility {
  NEW = 'New',
  ARCHIVE = 'Archive',
}

