import { WellId } from '../types';

export type MwdEmData = {
  wellId: WellId;
  startDepth: number;
  endDepth: number;
  error: string[];
  retry: string[];
  timeout: string[];
  badBlocks: string[];
  IT: string[];
  V20: string[];
  V40: string[];
  _id?: string;
};

export enum MwdEmDataMutation {
  SET_MWD_EM_DATA = 'setMwdEmData',
}

export enum MwdEmDataAction {
  FETCH_MWD_EM_DATA = 'fetchMwdEmData',
}
