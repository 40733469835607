import {
  MultiwellCompareFiltersGetter,
  MultiwellCompareWellListGetter,
  MultiwellCompareWellListMutation,
  RangeTypes,
} from '@/store/modules/multiwell_compare/types';
import { Section, WellId } from '@/store/modules/types';
import { SectionRanges, Well } from '@/store/modules/well/types';
import { WellPlan } from '@/store/modules/well_plan/types';
import { Bha, BhaComponent } from '@/store/modules/bha/types';
import { MwdRun } from '@/store/modules/mwd_run/types';

import { queryServer } from '@/services/socket_service';
import { queryApi } from '@/services/server_service';
import store from '@/store';

import { getRangesFromSections, unionSectionsByFullRanges } from '../well_plan';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class MultiwellCompareWellListsModule extends VuexModule {
  _filteredWells: Well[] = [];
  _selectedWellsIds: WellId[] = [];
  _selectedWellsFilters: { [key: string]: string } = {};
  _wellPlans: WellPlan[] = [];
  _bhas: Bha[] = [];
  _mwdRuns: MwdRun[] = [];
  _depthBorders: null | [number, number] = null;
  _depthBordersForWells: { [wellId: WellId]: [number, number] } = {};
  _mwdPersonnelNameList: string[] = [];

  get wellPlans(): WellPlan[] {
    return this._wellPlans;
  }

  get bhas(): Bha[] {
    return this._bhas;
  }

  get mwdRuns(): MwdRun[] {
    return this._mwdRuns;
  }

  get sectionsRanges(): SectionRanges {
    let ranges: SectionRanges = {
      [Section.SURFACE]: undefined,
      [Section.INTERMEDIATE_1]: undefined,
      [Section.INTERMEDIATE_2]: undefined,
      [Section.CURVE]: undefined,
      [Section.LATERAL]: undefined,
    };
    this.selectedWells.forEach((well: Well) => {
      const wellSections = getRangesFromSections(well.sections);
      ranges = unionSectionsByFullRanges(ranges, wellSections);
    });
    return ranges;
  }

  get bhaParamsList(): { motorSizeList: number[], bitSizeList: number[], motorStageList: number[] } {
    const motorSizeList = [];
    const bitSizeList = [];
    const motorStageList = [];
    this._bhas.forEach((bha: Bha) => {
      motorSizeList.push(+bha.motor.rotorHeadDiameter);
      motorStageList.push(+bha.motor.stages);
      bitSizeList.push(+bha.bit.od);
    });
    return {
      motorSizeList: _.uniq(motorSizeList),
      bitSizeList: _.uniq(bitSizeList),
      motorStageList: _.uniq(motorStageList),
    };
  }

  get mwdPersonnelNameList(): string[] {
    const mwdPersonnelList = [];
    this._mwdRuns.forEach((mwdRun: MwdRun) => {
      mwdPersonnelList.push(mwdRun.operator1);
      mwdPersonnelList.push(mwdRun.operator2);
    });
    return _.uniq(_.filter(mwdPersonnelList, (name: string) => !_.isEmpty(name)));
  }

  get totalWellsList(): Well[] {
    return store.getters.wells || [];
  }

  get filteredWells(): Well[] {
    const total: Well[] = store.getters[MultiwellCompareWellListGetter.GET_TOTAL_WELLS_LIST];

    const countryFilter = store.getters[MultiwellCompareFiltersGetter.GET_LOCATION_COUNTRY];
    const stateFilter = store.getters[MultiwellCompareFiltersGetter.GET_LOCATION_STATE];
    const countyFilter = store.getters[MultiwellCompareFiltersGetter.GET_LOCATION_COUNTY];

    const targetDepthFilter = store.getters[MultiwellCompareFiltersGetter.GET_TRAJECTORY_DEPTH];
    const kopFilter = store.getters[MultiwellCompareFiltersGetter.GET_TRAJECTORY_KICK_OFF_POINT];
    const tvdFilter = store.getters[MultiwellCompareFiltersGetter.GET_TRAJECTORY_LANDING_TVD];

    const mudFilter = !!store.getters[MultiwellCompareFiltersGetter.GET_MUD_TYPE_ALL_DATA];
    const mwdFilteredWells = [];
    if(mudFilter) {
      const range = store.getters[MultiwellCompareFiltersGetter.GET_MUD_TYPE_RANGE_DATA];
      const rangeType = store.getters[MultiwellCompareFiltersGetter.GET_MUD_TYPE_RANGE_TYPE];
      const mudType = store.getters[MultiwellCompareFiltersGetter.GET_MUD_TYPE_MUD_TYPE];
      const mudDensity = store.getters[MultiwellCompareFiltersGetter.GET_MUD_TYPE_MUD_DENSITY];

      this._mwdRuns.forEach((mwdRun: MwdRun) => {
        if(range) {
          const bha = this._bhas.find((bha: Bha) => bha.name === mwdRun.bhaId);
          if(!bha) {
            console.error(`Can't find BHA with id: ${mwdRun.bhaId}`);
            return;
          }
          switch(rangeType) {
            case RangeTypes.DATE_RANGE:
              if(bha.startDate < range.from || bha.endDate > range.to) {
                return;
              }
              break;
            case RangeTypes.DEPTH_RANGE:
              if(bha.startDepth < range.from || bha.endDepth > range.to) {
                return;
              }
              break;
            default:
              throw new Error(`Unknown range type: ${rangeType}`);
          }
        }
        if(mudType && mwdRun.mudData.type !== mudType) {
          return;
        }
        if(mudDensity && mwdRun.mudData.density !== mudDensity) {
          return;
        }
        mwdFilteredWells.push(mwdRun.wellId);
      });
    }

    const personnelFilter = !!store.getters[MultiwellCompareFiltersGetter.GET_PERSONNEL_ALL_DATA];
    if(personnelFilter) {
      const range = store.getters[MultiwellCompareFiltersGetter.GET_PERSONNEL_RANGE_DATA];
      const firstName = store.getters[MultiwellCompareFiltersGetter.GET_PERSONNEL_FIRST_NAME];
      this._mwdRuns.forEach((mwdRun: MwdRun) => {
        if(!_.isNil(firstName) && mwdRun.operator1 !== firstName && mwdRun.operator2 !== firstName) {
          return;
        }
        if(!_.isNil(range.from) || !_.isNil(range.to)) {
          const bha = this._bhas.find((bha: Bha) => bha.wellId === mwdRun.wellId && bha.name === mwdRun.bhaId);
          if(!bha) {
            console.error(`Can't find BHA with id: ${mwdRun.bhaId}`);
            return;
          }

          if(bha.startDate < range.from || bha.endDate > range.to) {
            return;
          }
        }
        mwdFilteredWells.push(mwdRun.wellId);
      });
    }

    const bhaFilter = !!store.getters[MultiwellCompareFiltersGetter.GET_BHA_ALL_DATA];
    const bhaFilteredWells = [];
    if(bhaFilter) {
      this._bhas.forEach((bha: Bha) => {
        const rangeType = store.getters[MultiwellCompareFiltersGetter.GET_BHA_RANGE_TYPE];
        const rangeData = store.getters[MultiwellCompareFiltersGetter.GET_BHA_RANGE_DATA];

        if(rangeType && rangeData) {
          switch(rangeType) {
            case RangeTypes.DATE_RANGE:
              if(bha.startDate < rangeData.from || bha.endDate > rangeData.to) {
                return;
              }
              break;
            case RangeTypes.DEPTH_RANGE:
              if(bha.startDepth < rangeData.from || bha.endDepth > rangeData.to) {
                return;
              }
              break;
            default:
              throw new Error(`Unknown range type: ${rangeType}`);
          }
        }

        const motorManufacturer = store.getters[MultiwellCompareFiltersGetter.GET_BHA_MOTOR_MANUFACTURER];
        if(motorManufacturer !== null) {
          if(bha.motor.manufacturer !== motorManufacturer) {
            return;
          }
        }
        const motorSize = store.getters[MultiwellCompareFiltersGetter.GET_BHA_MOTOR_SIZE];
        if(motorSize !== null) {
          if(+bha.motor.rotorHeadDiameter !== +motorSize) {
            return;
          }
        }
        const motorModelNumber = store.getters[MultiwellCompareFiltersGetter.GET_BHA_MOTOR_MODEL_NUMBER];

        if(motorModelNumber !== null) {
          if(bha.motor.modelNumber !== motorModelNumber) {
            return;
          }
        }
        const motorStages = store.getters[MultiwellCompareFiltersGetter.GET_BHA_MOTOR_STAGES];
        if(motorStages !== null) {
          if(+bha.motor.stages !== +motorStages) {
            return;
          }
        }

        const bitManufacturer = store.getters[MultiwellCompareFiltersGetter.GET_BHA_BIT_MANUFACTURER];
        if(bitManufacturer !== null) {
          if(bha.bit.manufacturer !== bitManufacturer) {
            return;
          }
        }
        const bitType = store.getters[MultiwellCompareFiltersGetter.GET_BHA_BIT_TYPE];
        if(bitType !== null) {
          if(bha.bit.type !== bitType) {
            return;
          }
        }
        const bitSize = store.getters[MultiwellCompareFiltersGetter.GET_BHA_BIT_SIZE];
        if(bitSize !== null) {
          if(+bha.bit.od !== +bitSize) {
            return;
          }
        }

        const components = store.getters[MultiwellCompareFiltersGetter.GET_BHA_CONTAINS];
        if(components.length > 0) {
          const bhaComponentTypes = bha.components.map((component: BhaComponent) => component.type);
          if(!_.intersection(components, bhaComponentTypes).length) {
            return;
          }
        }

        bhaFilteredWells.push(bha.wellId);
      });
    }

    const wellPlans = store.getters[MultiwellCompareWellListGetter.WELL_PLANS];

    const drillingParametersFilter = !!store.getters[MultiwellCompareFiltersGetter.GET_DRILLING_PARAMETERS_ALL_DATA];
    const drillingParametersFilteredWells = [];
    if(drillingParametersFilter) {
      const conditionalDepthDataCount = store.getters[MultiwellCompareFiltersGetter.GET_DRILLING_PARAMETERS_CONDITIONAL_DEPTH_DATA_COUNT];
      _.keys(conditionalDepthDataCount).forEach(
        (wellId: WellId) => {
          if(conditionalDepthDataCount[wellId] && conditionalDepthDataCount[wellId] > 10) {
            drillingParametersFilteredWells.push(wellId);
          }
        }
      );
    }
    return total.filter((well: Well) => {
      const wellPlan = wellPlans.find((wellPlan: WellPlan) => wellPlan.wellId === well._id);

      if(this.selectedWellsIds.includes(well._id)) {
        return false;
      }

      // Location
      if(countryFilter && well.location.country !== countryFilter) {
        return false;
      }
      if(stateFilter && well.location.state !== stateFilter) {
        return false;
      }
      if(countyFilter && well.location.county !== countyFilter) {
        return false;
      }

      // Trajectory
      if(targetDepthFilter.from !== null && targetDepthFilter.to !== null) {
        if(well.targetDepth < targetDepthFilter.from || well.targetDepth > targetDepthFilter.to) {
          return false;
        }
      }
      if(kopFilter.from !== null && kopFilter.to !== null) {
        if(!wellPlan) {
          return false;
        }
        if(wellPlan.plannedTrajectoryPoints.KOP < kopFilter.from && wellPlan.plannedTrajectoryPoints.KOP > kopFilter.to) {
          return false;
        }
      }
      if(tvdFilter.from !== null && tvdFilter.to !== null) {
        if(!wellPlan) {
          return false;
        }
        if(wellPlan.plannedTrajectoryPoints.tvdLP < tvdFilter.from && wellPlan.plannedTrajectoryPoints.tvdLP > tvdFilter.to) {
          return false;
        }
      }

      if(mudFilter && !mwdFilteredWells.includes(well._id)) {
        return false;
      }

      if(personnelFilter && !mwdFilteredWells.includes(well._id)) {
        return false;
      }

      if(bhaFilter && !bhaFilteredWells.includes(well._id)) {
        return false;
      }

      if(drillingParametersFilter && !drillingParametersFilteredWells.includes(well._id)) {
        return false;
      }

      return true;
    });
  }

  get selectedWellsIds(): WellId[] {
    return this._selectedWellsIds;
  }

  get selectedWells(): Well[] {
    const total: Well[] = store.getters[MultiwellCompareWellListGetter.GET_TOTAL_WELLS_LIST];
    const selectedWellsIds: WellId[] = store.getters[MultiwellCompareWellListGetter.GET_SELECTED_WELLS_IDS];

    return selectedWellsIds.map((wellId: WellId) => _.find(total, (well: Well) => well._id === wellId));
  }

  get selectedWellsFilters(): { [key: string]: string } {
    return this._selectedWellsFilters;
  }

  get depthBorders(): [number, number] | undefined {
    return this._depthBorders || undefined;
  }

  get rawFiltersData(): string[] {
    const location = store.getters[MultiwellCompareFiltersGetter.GET_LOCATION_ALL_DATA];
    const bha = store.getters[MultiwellCompareFiltersGetter.GET_BHA_ALL_DATA];
    const trajectory = store.getters[MultiwellCompareFiltersGetter.GET_TRAJECTORY_ALL_DATA];
    const failures = store.getters[MultiwellCompareFiltersGetter.GET_FAILURES_ALL_DATA];
    const personnel = store.getters[MultiwellCompareFiltersGetter.GET_PERSONNEL_ALL_DATA];
    const mudType = store.getters[MultiwellCompareFiltersGetter.GET_MUD_TYPE_ALL_DATA];
    const drillingParameters = store.getters[MultiwellCompareFiltersGetter.GET_DRILLING_PARAMETERS_ALL_DATA];

    return [location, bha, trajectory, failures, personnel, mudType, drillingParameters];
  }

  get allFiltersData(): string {
    return store.getters[MultiwellCompareWellListGetter.GET_RAW_DATA].filter((data: string) => data).join(';\t');
  }

  @Mutation
  addWellIdToSelected({ wellId, filters }: { wellId: WellId, filters: string }): void {
    this._selectedWellsIds.push(wellId);
    this._selectedWellsFilters[wellId] = filters;
  }

  @Mutation
  setSelectedWellIds({ wellIds, filters }: { wellIds: WellId[], filters: string[] }): void {
    this._selectedWellsIds = _.clone(wellIds);
    for(const [idx, wellId] of wellIds.entries()) {
      this._selectedWellsFilters[wellId] = filters[idx];
    }
  }

  @Mutation
  removeWellIdFromSelected(wellId: WellId): void {
    const wellIdPlacement = this._selectedWellsIds.indexOf(wellId);
    this._selectedWellsIds.splice(wellIdPlacement, 1);
    delete this._selectedWellsFilters[wellId];
    store.commit(MultiwellCompareWellListMutation.REMOVE_DEPTH_BORDERS_BY_WELL_ID, wellId);
  }

  @Mutation
  setWellPlans(payload: WellPlan[]): void {
    this._wellPlans = _.cloneDeep(payload);
  }

  @Mutation
  setBhas(payload: Bha[]): void {
    this._bhas = _.cloneDeep(payload);
  }

  @Mutation
  setMwdRuns(payload: MwdRun[]): void {
    this._mwdRuns = _.cloneDeep(payload);
  }

  @Mutation
  updateDepthBordersForWells(data: { [wellId: string]: { depth: [number, number] }}): void {
    for(const wellId in data) {
      this._depthBordersForWells[wellId] = data[wellId].depth;
    }
    store.commit(MultiwellCompareWellListMutation.SET_DEPTH_BORDERS);
  }

  @Mutation
  removeDepthBordersByWellId(wellId: string): void {
    delete this._depthBordersForWells[wellId];
    store.commit(MultiwellCompareWellListMutation.SET_DEPTH_BORDERS);
  }

  @Mutation
  setDepthBorders() {
    const bordersList = [];
    for(const wellId in this._depthBordersForWells) {
      bordersList.push(...this._depthBordersForWells[wellId]);
    }
    this._depthBorders = [_.min(bordersList), _.max(bordersList)];
  }

  @Action({ rawError: true })
  async fetchDataBorders(wellId?: WellId): Promise<void> {
    const url = `api/well-data/borders`;
    const promises = [];

    const wellIds = wellId ? [wellId] : this.selectedWellsIds;
    for(const wellId of wellIds) {
      const resp = queryApi({
        url,
        method: 'GET',
        params: { wellId },
      });
      promises.push(resp);
    }
    const resps = await Promise.all(promises);
    const filteredResps = _.filter(resps, (resp: { data: { depth: number[] }}) => !_.isEmpty(resp.data));
    const res = {};
    for(const resp of filteredResps) {
      res[resp.config?.params?.wellId] = resp.data;
    }

    store.commit(MultiwellCompareWellListMutation.UPDATE_DEPTH_BORDERS_FOR_WELLS, res);
  }

  @Action({ rawError: true })
  async fetchWellPlans(): Promise<void> {
    const event = 'well-plan/get/all';

    const resp = await queryServer(event, {});

    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }

    store.commit(MultiwellCompareWellListMutation.SET_WELL_PLANS, resp.data);
  }

  @Action({ rawError: true })
  async fetchBhas(): Promise<void> {
    const event = 'bha/get/all';

    const resp = await queryServer(event, {});

    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }

    store.commit(MultiwellCompareWellListMutation.SET_BHAS, resp.data);
  }

  @Action({ rawError: true })
  async fetchMwdRuns(): Promise<void> {
    const event = 'mwd-run/get/all';

    const resp = await queryServer(event, {});

    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }

    store.commit(MultiwellCompareWellListMutation.SET_MWD_RUNS, resp.data);
  }
}
