import { MudDataType } from './types';

export const DEFAULT_MWD_RUN = {
  wellId: '',
  name: '',
  operator1: '',
  operator2: '',
  mudData: {
    type: MudDataType.OBM,
    density: 9,
    funnelViscosity: 34,
    plasticViscosity: 12,
    yieldPoint: 12,
    pH: 0.0,
    chWMud: 1100,
    apiFiltrate: 0.0,
    maxMwdTemp: 77,
    consistency: 0.13,
    fluidBehaviorIndex: 0.95,
  },
  bhaId: null,
  logTop: 0,
  logBottom: 0,
  holeSize: 0,
};
