import { queryServer } from '@/services/socket_service';

import { Buckling, BucklingMutation } from './types';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class BucklingModule extends VuexModule {
  _buckling: Buckling | null = null;

  get buckling(): Buckling | undefined {
    if(this._buckling === null) {
      return undefined;
    }
    return this._buckling;
  }

  @Mutation
  setBuckling(buckling: Buckling): void {
    if(buckling === undefined) {
      return;
    }

    this._buckling = _.cloneDeep(buckling);
  }

  @Mutation
  clearBuckling(): void {
    this._buckling = null;
  }

  @Action({ rawError: true })
  async fetchBuckling(): Promise<void> {
    const event = 'buckling/get';
    const params = { wellId: this.context.getters.currentWellId };

    const resp = await queryServer(event, params);

    if(resp === undefined || resp.data === '') {
      return;
    }
    console.log('buckling', resp.data);
    this.context.commit(BucklingMutation.SET_BUCKLING, resp.data);
  }
}
