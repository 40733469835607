import { PulserData, PulserDataMutation } from './types';

import { queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class PulserDataModule extends VuexModule {
  _pulserData: PulserData[] = [];

  get pulserData(): PulserData[] {
    return this._pulserData;
  }

  @Mutation
  setPulserData(pulserData: PulserData[]): void {
    if(_.isEmpty(pulserData)) {
      return;
    }
    this._pulserData = pulserData;
  }

  @Mutation
  appendPulserData(pulserData: PulserData[]): void {
    if(_.isEmpty(pulserData)) {
      return;
    }
    this._pulserData = [...this._pulserData, ...pulserData];
  }

  @Action({ rawError: true })
  async fetchPulserData(maxDocsCount?: number): Promise<void> {
    const event = 'pulser-data/get';
    const params = {
      wellId: this.context.getters.currentWellId,
      maxDocsCount,
    };

    const resp = await queryServer(event, params);
    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }

    this.context.commit(PulserDataMutation.SET_PULSER_DATA, resp.data);
  }
}
