import { Stand, StandMutation, StandGroup, StandAction } from './types';

import { queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

const SECONDS_IN_HOUR = 60 * 60;
const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24;

@Module
export class StandModule extends VuexModule {
  _stands: Stand[] = [];
  _depth: number | undefined;
  _standGroupByOption = StandGroup.NONE;

  get standGroupByOption(): StandGroup {
    return this._standGroupByOption;
  }

  @Mutation
  setStandGroupByOption(standGroupByOption: StandGroup): void {
    this._standGroupByOption = standGroupByOption;
  }

  get stands(): Stand[] {
    return this._stands;
  }

  get standAggregationTime(): number | undefined {
    switch(this._standGroupByOption) {
      case StandGroup.NONE:
        return undefined;
      case StandGroup.HOUR_12:
        return SECONDS_IN_HOUR * 12;
      case StandGroup.DAY:
        return SECONDS_IN_DAY;
      case StandGroup.DAY_7:
        return SECONDS_IN_DAY * 7;
      default:
        throw new Error(`Unknown type of Stand Group: ${this._standGroupByOption}`);
    }
  }

  @Mutation
  setStands(stands: Stand[]): void {
    if(stands === undefined || stands.length === 0) {
      return;
    }

    const depthList = _.map(stands, (el: Stand) => el.startMD);
    const lastDepth = _.max(depthList);
    store.commit(StandMutation.SET_LAST_DEPTH, lastDepth);

    this._stands = [...this._stands, ...stands];
  }

  @Mutation
  setStandsLastDepth(depth: number): void {
    this._depth = depth;
  }

  @Mutation
  clearStands(): void {
    this._stands = [];
  }

  @Action({ rawError: true })
  async refetchStands(): Promise<void> {
    store.commit(StandMutation.SET_LAST_DEPTH, undefined);
    store.commit(StandMutation.CLEAR_STANDS);
    await store.dispatch(StandAction.FETCH_STANDS);
  }

  @Action({ rawError: true })
  async fetchStands(): Promise<void> {
    let from = this._depth;
    if(from !== undefined) {
      from += 1;
    }

    const event = 'stand-metric/get';
    const params = {
      wellId: this.context.getters.currentWellId,
      from,
      aggregationTime: this.standAggregationTime,
    };

    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }

    this.context.commit(StandMutation.SET_STANDS, resp.data);
  }
}
