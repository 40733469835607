




























import { MAIN_FIELDS, DISTANCE_TO_PLAN_FIELDS, DISTANCE_TO_PLAN_RECOMENDATION_FIELD } from '@/components/modals/DirectionalNotification.vue';

import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatValue, getListFromString } from '@/utils';
import { Action, DirectionalRecommendation } from '@/store/modules/directional_recommendation/types';

import * as _ from 'lodash';

const RECOMMENDATION_FIELDS = {
  ...MAIN_FIELDS,
  instructions: 'Instructions',
};

@Component({ filters: { formatValue } })
export default class DirectionalRecommendations extends Vue {
  @Prop({ required: true })
  notificationTime: number;

  get recommendationForNotification(): DirectionalRecommendation {
    const recommendations = this.$store.getters.directionalRecommendations;
    return _.find(
      recommendations,
      (recommendation: DirectionalRecommendation) => Math.ceil(recommendation.time) === Math.ceil(this.notificationTime)
    );
  }

  get recommendationFields(): { [key: string]: string } {
    return RECOMMENDATION_FIELDS;
  }

  get distanceToPlanFields(): string[] {
    return _.keys(DISTANCE_TO_PLAN_FIELDS);
  }

  getRecommendationActions(): string[] {
    const actions = this.recommendationForNotification.recommendedActions;
    const actionsList = getListFromString(actions);
    return actionsList as string[];
  }

  getRecommendationStartMd(idx: number): string {
    const mds = this.recommendationForNotification.recommendedActionStartDepths;
    let depth;
    if(mds === undefined || mds.length === 0 || mds[idx] === undefined) {
      depth = '-';
    } else {
      depth = formatValue(mds[idx], 0);
    }
    return `(${depth} ft)`;
  }

  getRecommendationLength(idx: number): string {
    const lengths = this.recommendationForNotification.recommendedActionLengths;
    const lengthsList = getListFromString(lengths);
    return formatValue(lengthsList[idx], 0) + ' ft';
  }

  getRecommendationToolface(action: string, idx: number): string {
    if(_.lowerCase(action).replace(' ', '') === Action.ROTATE) {
      return '';
    }
    const toolfaces = this.recommendationForNotification.recommendedToolfaces;
    const toolfacesList = getListFromString(toolfaces);
    return formatValue(toolfacesList[idx], 0) + ' deg';
  }

  getRecommendationFieldValue(key: string): number | undefined {
    // TODD: remove duplications
    if(_.includes(this.distanceToPlanFields, key)) {
      const d2p = this.recommendationForNotification[DISTANCE_TO_PLAN_RECOMENDATION_FIELD];
      if(!d2p) {
        return undefined;
      }
      const idx = DISTANCE_TO_PLAN_FIELDS[key];
      return d2p[idx];
    }
    const value = this.recommendationForNotification[key];
    if(_.isArray(value)) {
      return _.first(value);
    }
    return value;
  }
}
