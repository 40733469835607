import { AntiCollisionSettings, AntiCollisionSettingsMutation, AntiCollisionSettingsAction } from './types';

import { DirectionalPlotTabAction } from '@/store/modules/tabs/right_tabs/directional_plot_tab/types';

import { queryApi } from '@/services/server_service';
import { queryServer } from '@/services/socket_service';

import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';

import { AxiosResponse } from 'axios';
import * as _ from 'lodash';

const URL = 'api/anti-collision-settings';

@Module
export class AntiCollisionSettingsModule extends VuexModule {
  _antiCollisionSettings: AntiCollisionSettings | null = null;

  get antiCollisionSettings(): AntiCollisionSettings | undefined {
    if(this._antiCollisionSettings === null) {
      return undefined;
    }
    return this._antiCollisionSettings;
  }

  @Mutation
  setAntiCollisionSettings(antiCollisionSettings: AntiCollisionSettings): void {
    if(!_.isEmpty(antiCollisionSettings)) {
      this._antiCollisionSettings = antiCollisionSettings;
    }
  }

  @Action({ rawError: true })
  async fetchAntiCollisionSettings(): Promise<void> {
    const event = 'anti-collision-settings/get';
    const params = { wellId: this.context.getters.currentWellId };

    const resp = await queryServer(event, params);

    if(resp === undefined) {
      return;
    }

    this.context.commit(AntiCollisionSettingsMutation.SET_ANTI_COLLISION_SETTINGS, resp.data);
  }

  @Action({ rawError: true })
  async updateAntiCollisionSettings(settings: AntiCollisionSettings): Promise<AxiosResponse | undefined> {
    const resp = await queryApi({
      url: URL,
      method: 'POST',
      data: settings,
    });
    if(resp) {
      await this.context.dispatch(AntiCollisionSettingsAction.FETCH_ANTI_COLLISION_SETTINGS);
      await this.context.dispatch(DirectionalPlotTabAction.FETCH_SURVEYS);
      await this.context.dispatch(DirectionalPlotTabAction.FETCH_WELL_PLANS);
    }
    return resp;
  }
}
