
















import { Component, Vue, Prop } from 'vue-property-decorator';

type Option = any;

@Component
export default class TabSwitch extends Vue {
  @Prop({ required: true })
  value!: Option;

  @Prop({ required: true })
  options!: Option[];

  @Prop({ required: false })
  initValue: Option;

  get activeTab() {
    return this.value;
  }

  set activeTab(value) {
    this.value = value;
    this.$emit('input', value);
  }

  onTabClick(tab: Option) {
    this.activeTab = tab;
  }
}

