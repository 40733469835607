




















































import { ExternalSurvey, SurveyAction } from '@/store/modules/survey/types';
import RemoteTableSelector, { isSurveyTableAvailable } from '@/components/utils/RemoteTableSelector.vue';

import { WellAction } from '@/store/modules/well/types';

import { formatValue } from '@/utils';

import { emitEngineEvent } from '@/services/socket_service';

import { Component, Vue } from 'vue-property-decorator';
import * as _ from 'lodash';

const EXTERNAL_SURVEY_FIELDS = [
  'md',
  'inclination',
  'azimuth',
  'tvd',
  'ns',
  'ew',
];

@Component({ components: { RemoteTableSelector }, filters: { formatValue } })
export default class RemoteSurveysTable extends Vue {
  externalSurveyFields = EXTERNAL_SURVEY_FIELDS;
  surveysTable: string = '';
  isTableAvailable = true;

  get surveys(): ExternalSurvey[] {
    return this.$store.getters.externalSurveys;
  }

  async onTableChangeClick(): Promise<void> {
    this.isTableAvailable = await isSurveyTableAvailable(this.surveysTable);
    if(!this.isTableAvailable) {
      return;
    }
    const resp = await this.$store.dispatch(
      WellAction.UPDATE_WELL,
      { wellId: this.$store.getters.currentWellId, updatedFields: { surveysTable: this.surveysTable }}
    );
    if(!resp) {
      return;
    }
    await emitEngineEvent('surveys-table-name-updated', { wellId: this.$store.getters.currentWellId });
    await this.$store.dispatch(SurveyAction.FETCH_EXTERNAL_SURVEYS);
    await this.$store.dispatch('alertSuccess', { title: 'Remote Surveys Table', message: `Well is changed` });
    this.$forceUpdate();
  }

  created(): void {
    if(this.$store.getters.currentWell.surveysTable !== undefined) {
      this.surveysTable = this.$store.getters.currentWell.surveysTable;
    }
  }
}
