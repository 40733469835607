















import { Component, Vue } from 'vue-property-decorator';
import AddModal from '@/components/modals/mwd-tool-check/AddModal.vue';

@Component({ components: { AddModal } })
export default class MWDToolCheckHeader extends Vue {
  isModalActive: boolean = false;

  openModal(): void {
    this.isModalActive = true;
  }

  closeModal(): void {
    this.isModalActive = false;
  }
}
