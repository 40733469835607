

















import { Component, Prop, Vue } from 'vue-property-decorator';

const PALETTE = [
  '#941D2A',
  '#D2331F',
  '#E8712D',
  '#E5A63B',
  '#F1DE54',
  '#AAC33D',
  '#3C8825',
  '#8FE9AE',
  '#4CA8A8',
  '#4C9FDC',
  '#204FE6',
  '#610FF5',
  '#9B1EF5',
  '#E479CC',
  '#C62A72',
  '#E6E6F8',
];

@Component
export default class ColorPicker extends Vue {
  palette: string[] = PALETTE;

  @Prop({ required: false, default: 'Choose another color:' })
  title: string;

  setColor(color: string): void {
    this.$emit('input', { hex: color });
  }
}
