






































































































import FileUploader, { VUFile } from '@/components/FileUploader.vue';
import AssociateCSVModal from '@/components/modals/well-settings/AssociateCSV.vue';

import { formatValue } from '@/utils';

import { Component } from 'vue-property-decorator';

import * as _ from 'lodash';

export type VUFileSettings = VUFile & { configured: boolean };

enum FileStatus {
  ADDED = 'added',
  CONFIGURED = 'configured',
  ACTIVE = 'active',
  ERROR = 'error',
  UPLOADED = 'uploaded',
}

interface IFileStatus {
  status: FileStatus;
  description: string;
}

@Component({ components: { AssociateCSVModal } })
export default class FileUploaderSettings extends FileUploader {
  isAssociationModalActive: boolean = false;
  files: VUFileSettings[] = [];
  openedFiles: VUFileSettings[] = [];
  filesConfigured = false;
  csvUploaderKey = 0;
  isInProgress = false;
  currentCount = 0;
  totalCount = 0;
  progressPercentage = 0;
  fieldsMapping: { [ key: string]: string } = {
    time: 'time',
    bitDepth: 'bitDepth',
    holeDepth: 'holeDepth',
    blockHeight: 'blockHeight',
    hookload: 'hookload',
    wob: 'wob',
    torque: 'torque',
    rpm: 'rpm',
    pumpPressure: 'pumpPressure',
    pumpRate: 'pumpRate',
    diffPressure: 'diffPressure',
    magneticToolface: 'magneticToolface',
    gravityToolface: 'gravityToolface',
    gamma: 'gamma',
    rop: 'rop',
  };

  formatValue = formatValue;

  get isUploadButtonEnabled(): boolean {
    return this.files.filter(
      (file: VUFileSettings) => this.getFileStatus(file).status === FileStatus.CONFIGURED
    ).length > 0;
  }

  get addedFiles(): VUFileSettings[] {
    return this.files.filter((file: VUFileSettings) => this.getFileStatus(file).status === FileStatus.ADDED);
  }

  get shouldDisplayConfigurationError(): boolean {
    return this.addedFiles.length > 0 && !this.filesConfigured;
  }

  getFileStatus(file: VUFileSettings): IFileStatus {
    if(file.error) {
      return {
        status: FileStatus.ERROR,
        description: file.error,
      };
    } else if(!this.filesConfigured) {
      return {
        status: FileStatus.ADDED,
        description: 'Waiting to configure raw data',
      };
    } else if(file.active) {
      return {
        status: FileStatus.ACTIVE,
        description: 'Active',
      };
    } else if(file.success) {
      return {
        status: FileStatus.UPLOADED,
        description: 'File is uploaded',
      };
    } else {
      return {
        status: FileStatus.CONFIGURED,
        description: 'Ready to upload',
      };
    }
  }

  openAssociationModal(): void {
    this.openedFiles = this.addedFiles;
    this.isAssociationModalActive = true;
  }

  closeAssociateModal(): void {
    this.isAssociationModalActive = false;
    this.openedFiles = null;
  }

  onAssociateModalApply(data: { [ key: string]: string }): void {
    this.filesConfigured = true;
    this.fieldsMapping = data;
  }

  emitSubmit() {
    this.$emit('submit', this.fieldsMapping);
  }

  deleteFile(fileToDelete: VUFileSettings): void {
    this.files = this.files.filter((file: VUFileSettings) => file.id !== fileToDelete.id);
  }

  // for more info see `@input-file` section here: https://lian-yue.github.io/vue-upload-component/#/documents
  inputFile(newFile: VUFile, oldFile: VUFile) {
    if(newFile && !oldFile) {
      if(!/\.(csv)$/i.test(newFile.name)) {
        newFile.error = 'Invalid extension. CSV expected';
        return;
      }
    }

    if(!newFile || !oldFile) {
      return;
    }

    // Start upload
    if(newFile.active !== oldFile.active) {
      this.isInProgress = true;
      this.totalCount = newFile.size / 1024 / 1024;
    }

    // Upload progress
    if(newFile.progress !== oldFile.progress) {
      this.progressPercentage = +newFile.progress;
      this.currentCount = this.totalCount * this.progressPercentage * 0.01;
    }

    // Upload error
    if(newFile.error !== oldFile.error) {
      this.isInProgress = false;
    }

    // Uploaded successfully
    if(newFile.success !== oldFile.success) {
      this.isInProgress = false;
    }
  }
}
