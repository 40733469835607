import {
  HydraulicsSimulatorData,
  HydraulicsSimulatorWellSettings,
  HydraulicsSimulatorBhaSettings,
  HydraulicsSimulatorMwdRunSettings,
  HydraulicsSimulatorDatapoint,
  HydraulicsSimulatorMutation,
  HydraulicsHistorySimulation,
} from './types';

import { queryEngine, queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

export const MAX_SIMULATION_HISTORY_ITEMS = 10;

@Module
export class HydraulicsSimulatorModule extends VuexModule {
  _loading = false;
  _hydraulicsSimulatorData: HydraulicsSimulatorData | null = null;
  _history: HydraulicsSimulatorData[] = [];

  get hydraulicsSimulatorLoading(): boolean {
    return this._loading;
  }

  get hydraulicsSimulatorData(): HydraulicsSimulatorData | null {
    return this._hydraulicsSimulatorData;
  }

get hydraulicsSimulaionHistory(): HydraulicsSimulatorData[] {
    return this._history;
  }

  @Mutation
  setHydraulicsSimulatorLoading(loading: boolean): void {
    this._loading = loading;
  }

  @Mutation
  setHydraulicsSimulatorData(hydraulicsSimulatorData: HydraulicsSimulatorData): void {
    this._hydraulicsSimulatorData = hydraulicsSimulatorData;
  }

  @Mutation
  addToHydraulicsSimulaionHistory(hydraulicsSimulatorData: HydraulicsSimulatorData): void {
    this._history.push(hydraulicsSimulatorData);
    this._history = _.orderBy(this._history, 'time', 'desc');
    if(this._history.length > MAX_SIMULATION_HISTORY_ITEMS) {
      this._history = _.dropRight(this._history);
    }
  }

  @Mutation
  setHydraulicsSimulaionHistory(history: HydraulicsSimulatorData[]): void {
    this._history = history;
  }

  @Action({ rawError: true })
  async runHydraulicsSimulation(payload: {
    well: HydraulicsSimulatorWellSettings;
    bha: HydraulicsSimulatorBhaSettings;
    mwdRun: HydraulicsSimulatorMwdRunSettings;
    datapoint: HydraulicsSimulatorDatapoint;historyData;
  }): Promise<void> {
    store.commit(HydraulicsSimulatorMutation.SET_LOADING, true);

    const resp = await queryEngine('simulate-hydraulics', payload);
    if(!_.isNil(resp)) {
      store.commit(HydraulicsSimulatorMutation.SET_DATA, resp);
    }

    store.commit(HydraulicsSimulatorMutation.SET_LOADING, false);
  }

  @Action({ rawError: true })
  async getHydraulicsSimulationHistory(): Promise<void> {
    const userId = this.context.getters.user._id;

    const resp = await queryServer('hydraulics-simulation-history/get', { userId });
    console.log('getHydraulicsSimulationHistory', resp);
    if(!_.isNil(resp?.data)) {
      store.commit(HydraulicsSimulatorMutation.SET_HISTORY, resp.data);
    }
  }

  @Action({ rawError: true })
  async insertHydraulicsSimulation(simulation: HydraulicsHistorySimulation): Promise<void> {
    await queryServer('hydraulics-simulation-history/post', { simulation });
  }
}
