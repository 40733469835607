export type FormationTop = {
  wellId: string;
  formationName: string;
  tvdStart: number;
  tvdEnd: number;
  mdStart: number;
  mdEnd: number;
  baselineMSE: number;
  baselineROP: number;
  color: string;
  _id?: string;
};

export enum FormationTopMutation {
  SET_FORMATION_TOPS = 'setFormationTops',
  REMOVE_FORMATION_TOPS = 'removeFormationTops',
}

export enum FormationTopAction {
  FETCH_FORMATION_TOPS = 'fetchFormationTops',
  UPDATE_FORMATION_TOPS = 'updateFormationTops',
  ADD_FORMATION_TOP = 'addFormationTop',
  DELETE_FORMATION_TOP = 'deleteFormationTop',
}
