export type Country = {
  name: string;
  alias: string;
};

export type State = {
  name: string;
  alias: string;
};

export type County = {
  name: string;
  alias: string;
};

export enum LocationMutation {
  SET_COUNTRIES = 'setCountries',
  SET_STATES = 'setStates',
  SET_CONSTITUENCIES = 'setConstituencies',
}

export enum LocationAction {
  FETCH_COUNTRIES = 'fetchCountries',
  FETCH_STATES = 'fetchStates',
  FETCH_CONSTITUENCIES = 'fetchConstituencies',
}
