import { RigId } from '@/store/modules/rig/types';

export type Config = {
  code: string;
  unit: string;
};

export type WitsConfigs = {
  rigId: RigId;
  bitDepth: Config;
  holeDepth: Config;
  blockHeight: Config;
  rop: Config;
  hookload: Config;
  wob: Config;
  torque: Config;
  rpm: Config;
  pumpPressure: Config;
  spm1: Config;
  spm2: Config;
  spm3: Config;
  pumpRate: Config;
  totalSpm: Config;
  diffPressure: Config;
  liveIncDepth: Config;
  magneticToolface: Config;
  gravityToolface: Config;
  liveInclination: Config;
  liveAzimuth: Config;
  gamma: Config;
  surveyMd: Config;
  surveyInclination: Config;
  surveyAzimuth: Config;
  pinc: Config;
  gammaDepth: Config;
  temp: Config;
  pulseAmplitude: Config;
  pulseConfidence: Config;
  SPA: Config;
  SPL: Config;
  shockLevel: Config;
  possum: Config;
  tfds: Config;
  steer: Config;
  propeff: Config;
  prds: Config;
  incTrgt: Config;
  aziTrgt: Config;
  chokePosition: Config;
  chokePressure: Config;
  desiredPressure: Config;
  flowOut: Config;
  _id?: string;
};

export enum WitsConfigsMutation {
  SET_WITS_CONFIGS = 'setWitsConfigs',
}

export enum WitsConfigsAction {
  FETCH_WITS_CONFIGS = 'fetchWitsConfigs',
  UPDATE_WITS_CONFIGS = 'updateWitsConfigs',
}
