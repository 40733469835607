


















import { GlobalTooltipState } from '@/store/modules/app/types';

import { Component, Vue, Prop } from 'vue-property-decorator';

import * as _ from 'lodash';

@Component
export default class GlobalTooltip extends Vue {
  get globalTooltip(): GlobalTooltipState {
    return this.$store.getters.globalTooltip;
  }

  get isVisible(): boolean {
    return this.globalTooltip.isVisible;
  }

  get positionX(): number {
    return this.globalTooltip.positionX;
  }

  get positionY(): number {
    return this.globalTooltip.positionY;
  }

  get offsetY(): number {
    return this.globalTooltip.offsetY;
  }

  get offsetX(): number {
    return this.globalTooltip.offsetX;
  }

  get formattedContentRows(): string[] {
    if(_.isEmpty(this.content)) {
      return ['no content'];
    }
    return this.content.split('\n');
  }

  get content(): string {
    return this.globalTooltip.content;
  }
}
