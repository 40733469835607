import './directives/tooltip';

import './plugins/vee_validate';
import './plugins/barchart';
import './plugins/linechart';
import './plugins/scatter';
import './plugins/gauge';
import 'bootstrap/dist/js/bootstrap.min.js';

import './global';

import App from './App.vue';
import router from './router';
import store from './store';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import Vue, { CreateElement } from 'vue';
import Notifications from 'vue-notification';

Vue.use(Notifications);

Vue.config.productionTip = false;

// Sentry.init({
//   Vue,
//   // TODO: move to config
//   dsn: 'https://b208bbc16d4b444da0e389f129a8fa8c@snt.corpglory.com/2',
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ['manager.nvicta.dev.corpglory.com', 'sigma.nvicta.ai'],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//   logErrors: true,
// });

// const outerSize = `${window?.outerWidth}-${window?.outerHeight}`;
// const innerSize = `${window?.innerWidth}-${window?.innerHeight}`;
// Sentry.setTag('outer-size', outerSize);
// Sentry.setTag('inner-size', innerSize);

new Vue({
  router,
  store,
  render: (h: CreateElement) => h(App),
}).$mount('#app');
