import { WellId } from '../types';

export type StatisticsObject = {
  count: number;
  mean: number;
  std: number;
  min: number;
  '25%': number;
  '50%': number;
  '75%': number;
  max: number;
};

export type SingleEmObject = {
  Timestamp: number[];
  'FlowAccel (%)': number[];
  'Temp (°C)': number[];
  '20VBatt (V)': number[];
  '40VBatt (V)': number[];
  'SSG (rpm)': number[];
};

export type SingleEmStatistics = {
  'FlowAccel (%)': StatisticsObject;
  'Temp (°C)': StatisticsObject;
  '20VBatt (V)': StatisticsObject;
  '40VBatt (V)': StatisticsObject;
  'SSG (rpm)': StatisticsObject;
};

export type OverallEmData = {
  data: SingleEmObject[];
  statistics: SingleEmStatistics[];
};

export type SingleEyeObject = {
  Timestamp: number[];
  'Incl (deg)': number[];
  'MagNorthAz (deg)': number[];
  'EYEHs (359 dot 92 deg)': number[];
  'aT (mG)': number[];
  'bT (nT)': number[];
  'Dip (deg)': number[];
  'Tmag (°C)': number[];
  'Taccel (°C)': number[];
};

export type SingleEyeStatistics = {
  'Incl (deg)': StatisticsObject;
  'MagNorthAz (deg)': StatisticsObject;
  'EYEHs (359 dot 92 deg)': StatisticsObject;
  'aT (mG)': StatisticsObject;
  'bT (nT)': StatisticsObject;
  'Dip (deg)': StatisticsObject;
  'Tmag (°C)': StatisticsObject;
  'Taccel (°C)': StatisticsObject;
};

export type OverallEyeData = {
  data: SingleEyeObject[];
  statistics: SingleEyeStatistics[];
};

export type SingleGammaObject = {
  Timestamp: number[];
  'NormalizedCountRate (cnt/s)': number[];
  'TotalGamma (gapi)': number[];
};

export type SingleGammaStatistics = {
  'NormalizedCountRate (cnt/s)': StatisticsObject;
  'TotalGamma (gapi)': StatisticsObject;
};

export type OverallGammaData = {
  data: SingleGammaObject[];
  statistics: SingleGammaStatistics[];
};

export type SingleGammaIncObject = {
  Timestamp: number[];
  'Ax (mG)': number[];
  'Ay (mG)': number[];
  'Az (mG)': number[];
  'aT (mG)': number[];
  'Incl (deg)': number[];
  'HsTF (deg)': number[];
  'AccT (°C)': number[];
};

export type SingleGammaIncStatistics = {
  'Ax (mG)': StatisticsObject;
  'Ay (mG)': StatisticsObject;
  'Az (mG)': StatisticsObject;
  'aT (mG)': StatisticsObject;
  'Incl (deg)': StatisticsObject;
  'HsTF (deg)': StatisticsObject;
  'AccT (°C)': StatisticsObject;
};

export type OverallGammaIncData = {
  data: SingleGammaIncObject[];
  statistics: SingleGammaIncStatistics[];
};

export type MwdToolData = {
  wellId: WellId;
  startDepth: number;
  endDepth: number;
  EM: OverallEmData;
  EYE: OverallEyeData;
  Gamma: OverallGammaData;
  GammaInc: OverallGammaIncData;
  _id: string;
};

export enum MwdToolDataMutation {
  SET_MWD_TOOL_DATA = 'setMwdToolData',
  DELETE_LOCAL_MWD_TOOL_DATA = 'deleteLocalMwdToolData',
}

export enum MwdToolDataAction {
  FETCH_MWD_TOOL_DATA = 'fetchMwdToolData',
  DELETE_MWD_TOOL_DATA = 'deleteMwdToolData',
}
