



































































import DirectionalSettingsComponent from '@/components/tabs/DirectionalSettings.vue';

import { DirectionalSettings, DirectionalSettingsMutation } from '@/store/modules/settings/directional_settings/types';

import { Component, Vue } from 'vue-property-decorator';

const DEFAULT_MOTOR_YIELD = 12;
const DEFAULT_RTR = 0;
const DEFAULT_RBR = 0;

@Component({ components: { DirectionalSettingsComponent } })
export default class DirectionalAdvisorSettingsComponent extends Vue {
  dirSettingsRef = 'dirSettingsRef';

  motorYield = DEFAULT_MOTOR_YIELD;
  rtr = DEFAULT_RTR;
  rbr = DEFAULT_RBR;

  get settings(): DirectionalSettings | undefined {
    console.log('Global: ', this.$store.getters.directionalSettings);
    console.log('Local: ', this.$store.getters.directionalSettingsLocal);
    return this.$store.getters.directionalSettings;
  }

  get autoTendencies(): boolean {
    return this.$store.getters.autoTendencies;
  }

  set autoTendencies(value: boolean) {
    this.$store.commit(DirectionalSettingsMutation.SET_AUTO_TENDENCIES, value);
  }

  get directionalSettings(): DirectionalSettings | undefined {
    return this.$store.getters.directionalSettings;
  }

  async onApplyClick(): Promise<void> {
    const values = {
      motor: this.motorYield,
      rtr: this.rtr,
      rbr: this.rbr,
    };

    // @ts-ignore
    const resp = await this.$refs[this.dirSettingsRef].onApplyClick(undefined, values);
    if(!resp) {
      return;
    }
    this.$store.dispatch('alertSuccess', { title: 'Directional Advisor settings', message: `Changes applied` });
  }

  created(): void {
    if(this.directionalSettings !== undefined) {
      this.motorYield = this.directionalSettings.tendencies.motorYield as any;
      this.rtr = this.directionalSettings.tendencies.rtr as any;
      this.rbr = this.directionalSettings.tendencies.rbr as any;
    }
  }
}
