


























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Password extends Vue {
  @Prop({ required: true })
  value!: string;

  @Prop({ required: true })
  id!: string;

  @Prop({ required: true })
  type!: string;

  @Prop({ required: true })
  label!: string;

  @Prop({ required: false, default: false })
  disabled: boolean;

  @Prop({ required: false, default: 'required' })
  rules!: string;

  @Prop({ required: false })
  vid!: string;

  @Prop({ required: false, default: true })
  shouldDisplayErrors!: boolean;

  onInput(event: Event): void {
    if(event.target === null) {
      return;
    }
    const targetElement = event.target as HTMLInputElement;
    this.$emit('input', targetElement.value);
  }
}
