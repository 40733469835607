import { MotorStallData, MotorStallDataMutation } from './types';
import { MotorTabMutation } from '../tabs/right_tabs/motor_tab/types';

import { queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class MotorStallDataModule extends VuexModule {
  _data: MotorStallData[] = [];

  get motorStallData(): MotorStallData[] {
    return this._data;
  }

  @Mutation
  setMotorStallData(data: MotorStallData[]): void {
    if(_.isEmpty(data)) {
      return;
    }
    this._data = _.cloneDeep(data);
    store.commit(MotorTabMutation.SET_LAST_SPIKE_TABLE_PAGE);
  }

  @Mutation
  appendMotorStallItem(data: MotorStallData): void {
    if(_.isEmpty(data)) {
      return;
    }
    this._data.push(_.clone(data));
  }

  @Action({ rawError: true })
  async fetchMotorStallData(): Promise<void> {
    const event = 'motor-stall-data/get';
    const params = {
      wellId: this.context.getters.currentWellId,
    };

    const resp = await queryServer(event, params);

    if(_.isEmpty(resp?.data)) {
      return;
    }

    this.context.commit(MotorStallDataMutation.SET_MOTOR_STALL_DATA, resp.data);
  }
}
