import { DepthTrace, DEPTH_TRACE_TO_NAME_AND_UNIT_MAPPING } from '@/store/modules/depth_data/types';
import { DepthTraceConfigs, TraceConfig, RenderType, DepthChartsMutation, DepthChartsAction } from './types';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';
import { queryServer } from '@/services/socket_service';

// it is not fair, but i'm tired of broken trace selector for every new trace
const DEFAULT_TRACE_COLOR = 'white';
const DEFAULT_TRACE_AUTO_SCALE = true;
const DEFAULT_TRACE_MIN_MAX = 0;
const DEFAULT_TRACE_OFFSET = 0;
const DEFAULT_TRACE_RENDER_TYPE = RenderType.LINE;

@Module
export class DepthChartsModule extends VuexModule {
  _isConfigSet = false;
  _depthTraceConfigs: Map<
    DepthTrace,
    TraceConfig
  > | null = null;

  _depthChartsHistoricalZoomRange: null | [number, number] = null;
  _depthChartsLiveZoomRange: null | [number, number] = null;
  _depthChartsTraces: DepthTrace[][] | null = null;

  get depthChartsTraces(): DepthTrace[][] | undefined {
    if(this._depthChartsTraces === null) {
      return undefined;
    }
    return this._depthChartsTraces;
  }

  get depthChartsZoomRange(): [number, number] | undefined {
    if(store.getters.liveMode) {
      return store.getters.depthChartsLiveZoomRange;
    } else {
      return store.getters.depthChartsHistoricalZoomRange;
    }
  }

  get depthChartsHistoricalZoomRange(): [number, number] | undefined {
    if(this._depthChartsHistoricalZoomRange === null) {
      return undefined;
    }
    return this._depthChartsHistoricalZoomRange;
  }

  get depthChartsLiveZoomRange(): [number, number] | undefined {
    if(this._depthChartsLiveZoomRange === null) {
      return undefined;
    }
    return this._depthChartsLiveZoomRange;
  }

  get depthTraceConfigs(): DepthTraceConfigs {
    return this._depthTraceConfigs;
  }

  get isDepthTraceConfigSet(): boolean {
    return this._isConfigSet;
  }

  get depthTraceLabel(): (trace: DepthTrace) => string {
    return (trace: DepthTrace) => {
      const config = DEPTH_TRACE_TO_NAME_AND_UNIT_MAPPING[trace];
      if(config) {
        const unit = config.unit ? ` [${config.unit}]` : '';
        return `${config.name}${unit}`;
      }
      return trace;
    };
  }

  get depthTraceColor(): (trace: DepthTrace) => string {
    return (trace: DepthTrace) => {
      const color = this._depthTraceConfigs.get(trace)?.color;
      return _.isNil(color) ? DEFAULT_TRACE_COLOR : color;
    };
  }

  get depthTraceOffset(): (trace: DepthTrace) => number {
    return (trace: DepthTrace) => {
      const offset = this._depthTraceConfigs.get(trace)?.offset;
      return _.isNil(offset) ? 0 : offset;
    };
  }

  get depthTraceAutoScale(): (trace: DepthTrace) => boolean {
    return (trace: DepthTrace) => {
      const autoScale = this._depthTraceConfigs.get(trace)?.autoScale;
      return _.isNil(autoScale) ? DEFAULT_TRACE_AUTO_SCALE : autoScale;
    };
  }

  get depthTraceMaxValue(): (trace: DepthTrace) => number {
    return (trace: DepthTrace) => {
      const max = this._depthTraceConfigs.get(trace)?.max;
      return _.isNil(max) ? DEFAULT_TRACE_MIN_MAX : max;
    };
  }

  get depthTraceMinValue(): (trace: DepthTrace) => number {
    return (trace: DepthTrace) => {
      const min = this._depthTraceConfigs.get(trace)?.min;
      return _.isNil(min) ? DEFAULT_TRACE_MIN_MAX : min;
    };
  }

  get depthTraceOffsetValue(): (trace: DepthTrace) => number {
    return (trace: DepthTrace) => {
      const offset = this._depthTraceConfigs.get(trace)?.offset;
      return _.isNil(offset) ? DEFAULT_TRACE_OFFSET : offset;
    };
  }

  get depthTraceField(): (trace: DepthTrace) => string {
    return (trace: DepthTrace) => {
      return trace;
    };
  }

  get depthTraceRenderType(): (trace: DepthTrace) => RenderType {
    return (trace: DepthTrace) => {
      const renderType = this._depthTraceConfigs.get(trace)?.renderType;
      return _.isNil(renderType) ? DEFAULT_TRACE_RENDER_TYPE : renderType;
    };
  }

  @Mutation
  setDepthChartsTraces(traces: DepthTrace[][]): void {
    this._depthChartsTraces = traces.map(
      (chartTraces: DepthTrace[]) => {
        return [
          ...chartTraces.filter(
            (trace: DepthTrace) => trace !== DepthTrace.RIG_STATE
          ),
          DepthTrace.RIG_STATE,
        ];
      }
    );
  }

  @Mutation
  setDepthChartsHistoricalZoomRange(range: [number, number] | null): void {
    if(!range) {
      this._depthChartsHistoricalZoomRange = null;
      return;
    }
    const borders = store.getters.localDepthBorders;
    const updateRange: [number, number] = [Math.max(range[0], borders[0]), Math.min(range[1], borders[1])];
    this._depthChartsHistoricalZoomRange = updateRange;
  }

  @Mutation
  setDepthChartsLiveZoomRange(range: [number, number] | null): void {
    const borders = store.getters.localDepthBorders;
    if(_.isEmpty(range) || _.isEmpty(borders)) {
      this._depthChartsLiveZoomRange = null;
      return;
    }

    const updateRange: [number, number] = [Math.max(range[0], borders[0]), Math.min(range[1], borders[1])];
    this._depthChartsLiveZoomRange = updateRange;
  }

  @Mutation
  setDepthTraceScale(payload: { trace: DepthTrace, autoScale: boolean }): void {
    this._depthTraceConfigs.get(payload.trace).autoScale = payload.autoScale;
  }

  @Action
  updateDepthTraceScale(payload: { trace: DepthTrace, autoScale: boolean }): void {
    store.commit(DepthChartsMutation.SET_DEPTH_TRACE_SCALE, payload);
    const updateQuery = { [`traces.${payload.trace}.autoScale`]: payload.autoScale };
    store.dispatch(DepthChartsAction.UPDATE_DEPTH_TRACE_CONFIG, updateQuery);
  }

  @Mutation
  setDepthTraceMaxValue(payload: { trace: DepthTrace, value: number }): void {
    this._depthTraceConfigs.get(payload.trace).max = payload.value;
  }

  @Action
  updateDepthTraceMaxValue(payload: { trace: DepthTrace, value: number }): void {
    store.commit(DepthChartsMutation.SET_DEPTH_TRACE_MAX_VALUE, payload);
    const updateQuery = { [`traces.${payload.trace}.max`]: payload.value };
    store.dispatch(DepthChartsAction.UPDATE_DEPTH_TRACE_CONFIG, updateQuery);
  }

  @Mutation
  setDepthTraceMinValue(payload: { trace: DepthTrace, value: number }): void {
    this._depthTraceConfigs.get(payload.trace).min = payload.value;
  }

  @Action
  updateDepthTraceMinValue(payload: { trace: DepthTrace, value: number }): void {
    store.commit(DepthChartsMutation.SET_DEPTH_TRACE_MIN_VALUE, payload);
    const updateQuery = { [`traces.${payload.trace}.min`]: payload.value };
    store.dispatch(DepthChartsAction.UPDATE_DEPTH_TRACE_CONFIG, updateQuery);
  }

  @Mutation
  setDepthTraceColor(payload: { trace: DepthTrace, color: string }): void {
    this._depthTraceConfigs.get(payload.trace).color = payload.color;
  }

  @Mutation
  setDepthTraceOffset(payload: { trace: DepthTrace, offset: number }): void {
    this._depthTraceConfigs.get(payload.trace).offset = payload.offset;
  }

  @Action
  updateDepthTraceOffset(payload: { trace: DepthTrace, offset: number }): void {
    store.commit(DepthChartsMutation.SET_DEPTH_TRACE_OFFSET, payload);
    const updateQuery = { [`traces.${payload.trace}.offset`]: payload.offset };
    store.dispatch(DepthChartsAction.UPDATE_DEPTH_TRACE_CONFIG, updateQuery);
  }

  @Action
  updateDepthTraceColor(payload: { trace: DepthTrace, color: string }): void {
    store.commit(DepthChartsMutation.SET_DEPTH_TRACE_COLOR, payload);
    const updateQuery = { [`traces.${payload.trace}.color`]: payload.color };
    store.dispatch(DepthChartsAction.UPDATE_DEPTH_TRACE_CONFIG, updateQuery);
  }

  @Mutation
  setDepthTraceConfigStatus(isSet: boolean): void {
    this._isConfigSet = isSet;
  }

  @Mutation
  setDepthTraceConfigs(config: any): void {
    const traces = _.keys(config.traces);
    if(this._depthTraceConfigs === null) {
      this._depthTraceConfigs = new Map();
    }
    traces.forEach((trace: DepthTrace | string) => {
      this._depthTraceConfigs.set(trace as DepthTrace, config.traces[trace]);
    });
    this._isConfigSet = true;
  }

  // TODO: temporary, move to separate store module
  @Action({ rawError: true })
  async fetchDepthTraceConfig(): Promise<void> {
    const event = 'trace-config/get';
    // TODO: TraceType enum
    const params = { userId: this.context.getters.user._id, presetName: this.context.getters.user.activePreset, type: 'depth' };
    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data) || _.isEmpty(resp.data.traces)) {
      return;
    }
    this.context.commit(DepthChartsMutation.SET_DEPTH_TRACE_CONFIGS, resp.data);
  }

  @Action({ rawError: true })
  async updateDepthTraceConfig(updateQuery: any): Promise<void> {
    const event = 'trace-config/patch';
    const params = {
      userId: this.context.getters.user._id,
      updateQuery,
      presetName: this.context.getters.user.activePreset,
      type: 'depth',
    };
    queryServer(event, params);
  }
}
