import { IRangeData, MultiwellCompareFiltersMutation } from '@/store/modules/multiwell_compare/types';
import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';
import moment from 'moment';

interface IPersonnelFilters {
  rangeData: IRangeData;
  firstName: string;
  lastName: boolean;
}

@Module
export class MultiwellCompareFilterPersonnelModule extends VuexModule {
  _selectedRangeData: IRangeData = { from: null, to: null };
  _selectedFirstName: string = '';
  _selectedLastName: string = '';

  get selectedRangeData(): IRangeData {
    return this._selectedRangeData;
  }

  get selectedFirstName(): string {
    return this._selectedFirstName;
  }

  get selectedLastName(): string {
    return this._selectedLastName;
  }

  get accessibleData(): string {
    const dataStringsArray: string[] = [];

    if(this._selectedRangeData?.from && this._selectedRangeData?.to) {
      const dateFrom = moment.unix(this.selectedRangeData.from).format('MMM DD, YYYY');
      const dateTo = moment.unix(this.selectedRangeData.to).format('MMM DD, YYYY');

      dataStringsArray.push(`Personnel worked from ${dateFrom} to ${dateTo}`);
    }

    if(this.selectedFirstName) {
      dataStringsArray.push(`Personnel First name = ${this.selectedFirstName}`);
    }
    if(this.selectedLastName) {
      dataStringsArray.push(`Personnel Last Name = ${this.selectedLastName}`);
    }

    return dataStringsArray.join(';\t');
  }

  @Mutation
  setSelectedRangeData(rangeData: IRangeData) {
    this._selectedRangeData = rangeData ? ({ ...rangeData }) : undefined;
  }

  @Mutation
  setSelectedFirstName(firstName: string) {
    this._selectedFirstName = firstName;
  }

  @Mutation
  setSelectedLastName(lastName: string) {
    this._selectedLastName = lastName;
  }

  @Action
  setPersonnelFilters(fullPersonnel: IPersonnelFilters): void {
    store.commit(MultiwellCompareFiltersMutation.SET_PERSONNEL_RANGE_DATA, fullPersonnel.rangeData);
    store.commit(MultiwellCompareFiltersMutation.SET_PERSONNEL_FIRST_NAME, fullPersonnel.firstName);
    store.commit(MultiwellCompareFiltersMutation.SET_PERSONNEL_LAST_NAME, fullPersonnel.lastName);
  }

  @Action
  clearPersonnelFilters(): void {
    store.commit(MultiwellCompareFiltersMutation.SET_PERSONNEL_RANGE_DATA, { from: null, to: null });
    store.commit(MultiwellCompareFiltersMutation.SET_PERSONNEL_FIRST_NAME, '');
    store.commit(MultiwellCompareFiltersMutation.SET_PERSONNEL_LAST_NAME, '');
  }
}
