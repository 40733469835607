import { WellId } from '../types';

export type DirectionalData = {
  wellId: WellId;
  time: number;
  liveInclination: number;
  liveAzimuth: number;
  temp: number;
  interpolatedTvd: number;
  holeDepth: number;
  actualDepth: number;
};

export enum DirectionalDataMutation {
  SET_DIRECTIONAL_DATA = 'setDirectionalData',
  APPEND_DIRECTIONAL_DATA = 'appendDirectionalData',
}

export enum DirectionalDataAction {
  FETCH_DIRECTIONAL_DATA = 'fetchDirectionalData',
}
