

























































































import { Option } from '@/components/selectors/SearchableSelector.vue';
import WellSelector from '@/components/selectors/WellSelector.vue';
import FileUploader from '@/components/FileUploader.vue';
import Modal from '../Modal.vue';

import { WellId } from '@/store/modules/types';
import { Well } from '@/store/modules/well/types';
import { MwdToolData, MwdToolDataAction } from '@/store/modules/mwd_tool_data/types';
import { MwdEmData, MwdEmDataAction } from '@/store/modules/mwd_em_data/types';

import { SERVER_URL } from '@/config';

import { Component, Prop, Vue } from 'vue-property-decorator';

import * as _ from 'lodash';

@Component({ components: { Modal, WellSelector, FileUploader } })
export default class MWDToolCheckAddModal extends Vue {
  selectedWellId = null;
  toolUploadUrl = `${SERVER_URL}/api/mwd-tool-data-proxy`;
  emUploadUrl = `${SERVER_URL}/api/mwd-em-data-proxy`;
  isToolFileUploaded = false;
  isInputsDisabled = false;

  startDepth = 0;
  endDepth = 20000;

  @Prop({ required: true, default: false })
  isModalActive: boolean;

  get wellIds(): string[] {
    return this.$store.getters.wells.map((well: Well) => well._id);
  }

  get mwdEmData(): MwdEmData[] {
    return this.$store.getters.mwdEmData;
  }

  get mwdToolData(): MwdToolData[] {
    return this.$store.getters.mwdToolData;
  }

  get isMwdToolItemUniq(): boolean {
    const duplicateItem = _.find(this.mwdEmData, (item: MwdEmData) => {
      return item.wellId === this.selectedWellId && item.startDepth === this.startDepth && item.endDepth === this.endDepth;
    });
    return _.isNil(duplicateItem);
  }

  get isAllInputsValid(): boolean {
    return !_.isNil(this.selectedWellId) && !_.isNil(this.startDepth) && !_.isNil(this.endDepth);
  }

  get isFileUploaderVisible(): boolean {
    return this.isAllInputsValid && this.isMwdToolItemUniq;
  }

  wellSelectorValueFormatter(wellId: WellId): string {
    const well = _.find(this.$store.getters.wells, (well: Well) => well._id === wellId);
    if(_.isNil(well)) {
      throw new Error(`Can't find well with id: ${wellId}`);
    }
    return well.name;
  }

  onWellChange(option: Option): void {
    this.selectedWellId = option.key;
  }

  async onToolFileUpload(): Promise<void> {
    this.isToolFileUploaded = true;
    this.isInputsDisabled = true;
    await this.$store.dispatch(MwdToolDataAction.FETCH_MWD_TOOL_DATA);
    await this.$store.dispatch('alertSuccess', { title: 'MWD Run is generated successfully', message: '' });
  }

  async onEmFileUpload(): Promise<void> {
    await this.$store.dispatch(MwdEmDataAction.FETCH_MWD_EM_DATA);
    await this.$store.dispatch('alertSuccess', { title: 'MWD Em5 Data is generated successfully', message: '' });
    this.close();
  }

  toggleWell(): boolean {
    return true;
  }

  onCloseClick(): void {
    this.close();
  }

  close(): void {
    this.selectedWellId = null;
    this.isToolFileUploaded = false;
    this.isInputsDisabled = false;
    this.startDepth = 0;
    this.endDepth = 20000;

    // @ts-ignore
    this.$refs?.wellSelector?.resetSelector();
    this.$emit('closeModal');
  }
}
