






















import { Component, Vue, Prop } from 'vue-property-decorator';

export const DEFAULT_LABEL_COLOR = '#DBDBDB';

@Component
export default class ToggleSwitch extends Vue {
  @Prop({ required: false })
  id!: string;

  @Prop({ required: false, default: '' })
  label: string;

  @Prop({ required: true })
  value: boolean;

  @Prop({ required: false, default: DEFAULT_LABEL_COLOR })
  highlightedColor: string;

  @Prop({ required: false, default: false })
  reversed: boolean;

  @Prop({ required: false, default: false })
  disabled: boolean;

  get isLabelHighlighted(): boolean {
    return this.value;
  }

  get labelColor(): string {
    if(this.isLabelHighlighted) {
      return this.highlightedColor;
    }
    return DEFAULT_LABEL_COLOR;
  }
}
