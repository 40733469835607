

























































import { Component, Vue, Prop } from 'vue-property-decorator';

export enum ButtonSize {
  SMALL = 'small',
  LARGE = 'large',
}

@Component
export default class NvictaInput extends Vue {
  @Prop({ required: true })
  selectedPage!: number;

  @Prop({ required: true })
  totalPages!: number;

  @Prop({ required: false, default: 5 })
  maxVisibleButtons: number;

  @Prop({ required: false, default: ButtonSize.LARGE })
  buttonSize: ButtonSize;

  get isInFirstPage() {
    return this.selectedPage === 0;
  }

  get isInLastPage() {
    return this.selectedPage === this.totalPages - 1;
  }

  get startPage(): number {
    if(this.selectedPage < this.maxVisibleButtons) {
      return 0;
    }

    if(this.selectedPage >= this.totalPages - this.maxVisibleButtons) {
      return this.totalPages - this.maxVisibleButtons;
    }

    return this.selectedPage - Math.floor(this.maxVisibleButtons / 2);
  }

  get pages(): { number: number, disabled: boolean }[] {
    const range = [];

    for(let i = this.startPage; i < Math.min(this.startPage + this.maxVisibleButtons, this.totalPages); i++) {
      range.push(i);
    }

    return range;
  }

  onManyPagesLeftClick(): void {
    this.$emit('select', Math.max(this.selectedPage - 10, 0));
  }

  onManyPagesRightClick(): void {
    this.$emit('select', Math.min(this.selectedPage + 10, this.totalPages - 1));
  }

  onPageSelect(pageNumber: number): void {
    this.$emit('select', pageNumber);
  }
}
