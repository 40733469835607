import { FilterCondition, FilterOperator, ScatterMutation } from './types';

import { DepthData, DEPTH_TRACE_TO_NAME_AND_UNIT_MAPPING, RigStateLabels } from '@/store/modules/depth_data/types';

import { queryServer } from '@/services/socket_service';
import { DataBucket } from '@/models/bucket';
import { getDepthQueryBorders } from '@/utils';

import store from '@/store';

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import * as _ from 'lodash';

const KEY_FIELD = 'depth';
const READONLY_FIELDS = ['wellId', '_id'];

@Module
export class ScatterModule extends VuexModule {
  _scatterDepthDataBucket: DataBucket<DepthData> = new DataBucket(null, KEY_FIELD);
  _lastFetchedScatterDepth: number | null = null;

  _depthDataFilters: FilterCondition[] | null = null;
  _filterOperator: FilterOperator = FilterOperator.AND;

  get lastFetchedScatterDepth(): number | undefined {
    if(this._lastFetchedScatterDepth === null) {
      return undefined;
    }
    return this._lastFetchedScatterDepth;
  }

  get filteredScatterDepthData(): DepthData {
    if(this._depthDataFilters === null || this._depthDataFilters.length === 0) {
      return this._scatterDepthDataBucket.data as DepthData;
    }
    const data = _.clone(this._scatterDepthDataBucket.data);
    const filterIdxLists: number[][] = [];
    for(const filter of this._depthDataFilters) {
      const idxList: number[] = [];
      _.map(data[filter.field], (value: number, idx: number) => {
        if(filter.rigState || filter.value && value >= filter.value?.from && value <= filter.value?.to) {
          idxList.push(idx);
        }
      });
      filterIdxLists.push(idxList);
    }
    let resultIdxList: number[] = [];
    switch(this._filterOperator) {
      case FilterOperator.AND:
        resultIdxList = _.intersection(...filterIdxLists);
        break;
      case FilterOperator.OR:
        resultIdxList = _.union(...filterIdxLists);
        break;
      default:
        throw new Error(`Unknown filter operator type: ${this._filterOperator}`);
    }
    for(const field in data) {
      if(_.includes(READONLY_FIELDS, field)) {
        continue;
      }
      data[field] = _.filter(data[field], (value: string, idx: number) => _.includes(resultIdxList, idx));
    }
    return data;
  }

  get depthDataFilters(): FilterCondition[] | undefined {
    if(this._depthDataFilters === null) {
      return undefined;
    }
    return this._depthDataFilters;
  }

  get scatterDepthData(): DepthData | undefined {
    return this._scatterDepthDataBucket.data as DepthData;
  }

  get depthDataFilterOperator(): FilterOperator {
    return this._filterOperator;
  }

  get depthDataFilterConditionsString(): string {
    if(this._depthDataFilters === null) {
      return 'Filter empty';
    }
    const conditions = _.map(this._depthDataFilters, (filter: FilterCondition) => {
      let [field, unit] = [filter.field, ''];
      if(DEPTH_TRACE_TO_NAME_AND_UNIT_MAPPING[field]) {
        [field, unit] = [DEPTH_TRACE_TO_NAME_AND_UNIT_MAPPING[field].name, DEPTH_TRACE_TO_NAME_AND_UNIT_MAPPING[field].unit];
      }
      if(filter.field === 'rigState') {
        return `${field} is equal ${RigStateLabels[filter.rigState]}`;
      } else {
        return `${field} is between ${filter.value.from}${unit} and ${filter.value.to}${unit}`;
      }
    });
    return conditions.join(` ${this._filterOperator.toUpperCase()} `);
  }

  @Mutation
  setLastFetchedScatterDepth(depth: number | null): void {
    this._lastFetchedScatterDepth = depth;
  }

  @Mutation
  setScatterDepthData(data: DepthData | null): void {
    if(data === undefined || data.depth.length === 0) {
      return;
    }
    this._scatterDepthDataBucket.setData(data);
  }

  @Mutation
  appendScatterDepthData(data: DepthData): void {
    if(data === undefined || data.depth.length === 0) {
      return;
    }
    const lastFetchedScatterDepth = store.getters.lastFetchedScatterDepth;
    if(this._scatterDepthDataBucket.data === undefined || lastFetchedScatterDepth === undefined) {
      store.commit(ScatterMutation.SET_SCATTER_DEPTH_DATA, data);
      store.commit(ScatterMutation.SET_LAST_FETCHED_SCATTER_DEPTH, _.last((this._scatterDepthDataBucket.data as DepthData).depth));
      return;
    }

    this._scatterDepthDataBucket.appendData(data);

    store.commit(ScatterMutation.SET_LAST_FETCHED_SCATTER_DEPTH, _.last((this._scatterDepthDataBucket.data as DepthData).depth));
  }

  @Mutation
  setDepthDataFilters(filters: FilterCondition[] | null): void {
    this._depthDataFilters = _.cloneDeep(filters);
  }

  @Mutation
  setDepthDataFilterOperator(operator: FilterOperator): void {
    this._filterOperator = operator;
  }

  @Action({ rawError: true })
  async fetchScatterDepthData(refetch = false): Promise<void> {
    const event = 'depth-data/get';
    const liveMode = this.context.getters.liveMode;
    const lastFetchedDepth = this.context.getters.lastFetchedScatterDepth;
    const depthBorders = this.context.getters.depthBorders;

    const [from, to] = getDepthQueryBorders(liveMode, refetch, lastFetchedDepth, depthBorders);

    const params = {
      wellId: this.context.getters.currentWellId,
      from,
      to,
      // TODO: better projection (according to selected fields / filters)
      projection: {
        depth: 1,
        mse: 1,
        doc: 1,
        mseTorsion: 1,
        mseThrust: 1,
        sppaQ2: 1,
        docWobRatio: 1,
        drillingEfficiency: 1,
        rop: 1,
        bitAggressiveness: 1,
        wob: 1,
        rpm: 1,
        rpmDownhole: 1,
        torque: 1,
        torqueDownhole: 1,
        pumpPressure: 1,
        diffPressure: 1,
        pumpRate: 1,
        rigState: 1,
      },
    };

    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data) || _.isEmpty(resp.data.depth)) {
      return;
    }

    if(liveMode && !refetch) {
      this.context.commit(ScatterMutation.APPEND_SCATTER_DEPTH_DATA, resp.data);
    } else {
      this.context.commit(ScatterMutation.SET_SCATTER_DEPTH_DATA, resp.data);
    }
  }
}
