import { queryApi } from '@/services/server_service';

import { DirectionalCompany, DirectionalCompanyMutation } from './types';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

const DIRECTIONAL_COMPANY_URL = `api/directional-company`;

@Module
export class DirectionalCompanyModule extends VuexModule {
  _directionalCompanies: DirectionalCompany[] = [];

  get directionalCompanies(): DirectionalCompany[] {
    return this._directionalCompanies;
  }

  @Mutation
  setDirectionalCompanies(directionalCompanies: DirectionalCompany[]): void {
    this._directionalCompanies = directionalCompanies;
  }

  @Action({ rawError: true })
  async fetchDirectionalCompanies(): Promise<void> {
    const resp = await queryApi({
      url: DIRECTIONAL_COMPANY_URL,
      method: 'GET',
    });

    if(resp === undefined) {
      return;
    }

    this.context.commit(DirectionalCompanyMutation.SET_DIRECTIONAL_COMPANIES, resp.data);
  }
}
