import * as _ from 'lodash';

export class DataBucket<T> {
  private _data: T | T[] | null = null;

  constructor(
    private _limit: number | null,
    private _keyField: string,
    private _isArray: boolean = false
    // _isArray === false -> data: { time: [1,2,3,...] }
    // _isArray === true -> data: [ {time:1}, {time:2}, {time:3}, ...]
  ) {}

  public get data(): T | T[] | undefined {
    if(this._data === null) {
      return undefined;
    }
    return this._data;
  }

  public setData(data: T | T[]) {
    // TODO: trim data if necessary
    this._data = _.cloneDeep(data);
  }

  public get limit(): null | number {
    return this._limit;
  }

  public setLimit(limit: number | null) {
    this._limit = limit;
  }

  protected checkRespDataForValid(data: T | T[]): boolean {
    if(_.isNil(data)) {
      return false;
    }
    if(this._isArray) {
      return !_.isEmpty(data);
    }
    return !_.isEmpty(data[this._keyField]);
  }

  public appendData(data): void {
    if(!this.checkRespDataForValid(data)) {
      return;
    }

    if(this._data === null) {
      this.setData(data);
      return;
    }
    if(this.isKeyDuplicated(data)) {
      return;
    }

    const dropCount = this.valueAmountForDropFromLeft(data);

    if(this._isArray) {
      this._data = _.concat((this._data as T[]), data);
      this._data = _.drop(this._data, dropCount);
      return;
    }

    const dataFields = Object.keys(this._data);
    dataFields.forEach((key: string) => {
      if(!_.isArray(data[key]) || data[key].length === 0) {
        this._data[key] = this._data[key].concat(null);
        return;
      }

      this._data[key] = this._data[key].concat(data[key]);
      this._data[key] = _.drop(this._data[key], dropCount);
    });
  }

  private isKeyDuplicated(data: T | T[]): boolean {
    if(this._isArray) {
      // TODO: add some check? or we don't need it at all
      return false;
    }
    if(data[this._keyField].length > 1) {
      return false; // temporary
    }
    // TODO: do the same for array and filter it
    const currentKeyValues = this._data[this._keyField];
    const key = data[this._keyField][0];
    if(_.includes(currentKeyValues, key)) {
      return true;
    }
    return false;
  }

  private valueAmountForDropFromLeft(respData: T | T[]): number {
    // number of values that should be dropped from the left
    if(this._limit === null) {
      return 0;
    }
    if(this._isArray) {
      const leftBorder = _.last(this._data)[this._keyField] - this._limit;
      return _.findIndex(this._data, (item: T) => item[this._keyField] >= leftBorder);
    }
    const fieldData = this._data[this._keyField];
    const leftBorder = _.last(respData[this._keyField]) - this._limit;
    // TODO: not sure ">" or ">="
    return _.findIndex(fieldData, (val: number) => val >= leftBorder);
  }
}
