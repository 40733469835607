import { AuthMutation } from './types';
import { UserAction } from '../users/types';

import { SERVER_URL } from '@/config';

import { setToken } from '@/services/socket_service';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import axios from 'axios';
import * as _ from 'lodash';

const IS_LOCAL_STORAGE_SUPPORTED = window.localStorage !== undefined;
const QUERY_TIMEOUT = 10000; // ms
const LOCAL_STORAGE_TOKEN_FIELD = 'token';

let localStorageToken: string | null = null;
if(IS_LOCAL_STORAGE_SUPPORTED) {
  localStorageToken = window.localStorage.getItem(LOCAL_STORAGE_TOKEN_FIELD);
  axios.defaults.headers.common.Authorization = localStorageToken;
  setToken(localStorageToken);
}

@Module
export class AuthModule extends VuexModule {
  token: string | null = localStorageToken;

  error: string = '';

  get apiToken(): string | null {
    return this.token;
  }

  get apiError(): string {
    return this.error;
  }

  get isLoggedIn(): boolean {
    // TODO: check if token is still valid
    return !_.isEmpty(this.token);
  }

  @Mutation
  setError(error: string): void {
    this.error = error;
  }

  @Mutation
  setToken(token: string): void {
    if(IS_LOCAL_STORAGE_SUPPORTED) {
      window.localStorage.setItem(LOCAL_STORAGE_TOKEN_FIELD, token);
    }
    this.token = token;
    axios.defaults.headers.common.Authorization = token;
    setToken(token);
  }

  @Mutation
  clearToken(): void {
    if(IS_LOCAL_STORAGE_SUPPORTED) {
      window.localStorage.setItem(LOCAL_STORAGE_TOKEN_FIELD, '');
    }
    window.location.href = '/login';
  }

  @Action({ rawError: true })
  async login(payload: { username: string, password: string }): Promise<void> {
    const resp = await axios({
      url: `${SERVER_URL}/api/login`,
      method: 'POST',
      data: payload,
      timeout: QUERY_TIMEOUT,
    });

    const token = resp.data.token;
    if(token === undefined) {
      throw new Error(`Server didn't return token`);
    }

    this.context.commit(AuthMutation.SET_TOKEN, token);
    await this.context.dispatch(UserAction.FETCH_USER);
  }

  @Action({ rawError: true })
  async logout(): Promise<void> {
    await axios({
      url: `${SERVER_URL}/api/logout`,
      method: 'POST',
      timeout: QUERY_TIMEOUT,
    });

    this.context.commit(AuthMutation.CLEAR_TOKEN);
  }

  @Action({ rawError: true })
  async checkServerConnection(): Promise<void> {
    await axios({
      url: `${SERVER_URL}/api`,
      method: 'GET',
      timeout: QUERY_TIMEOUT,
    });
  }
}
