export enum StandOption {
  CONNECTION_TIME_BREAKDOWNS = 'connection_time_breakdowns',
  SLIDING_EFFICIENCY = 'sliding_efficiency',
  ROTATING_ROP = 'rotating_rop',
  SLIDING_ROP = 'sliding_rop',
  ACTIVITY_BREAKDOWNS = 'activity_breakdowns',
}

export enum RightTabsMutation {
  SET_DIRECTIONAL_PLOT_VIEW = 'setActiveDirectonalPlotView',
  SET_ACTIVE_RIGHT_TAB = 'setActiveRightTab',
  SET_ACTIVE_DRILLING_OPTIMIZATION_SUB_TAB = 'setActiveDrillingOptimizationSubTab',
  SET_ACTIVE_MOTOR_PERFORMANCE_SUB_TAB = 'setActiveMotorPerformanceSubTab',
  TOGGLE_3D_VIEW = 'toggle3dView',
}

export enum DirectionalPlotView {
  EW_NS = 'Plan View',
  VS_TVD = 'Section View',
}

export enum RightTab {
  DIRECTIONAL_PLOT = 'Directional Plot',
  MWD = 'MWD',
  DRILLING_OPTIMIZATION = 'Drilling Optimization',
  MOTOR_PERFORMANCE = 'Motor Performance',
}

export enum DrillingOptimizationSubTab {
  SCATTER = 'Scatter',
  FOUNDER = 'Founder',
  HEATMAP = 'Heatmap',
}

export enum MotorSubTab {
  MOTOR_CURVE = 'Motor Curve',
  STALL_DETECTION = 'Stall Detection',
}
