import { MotorStallSettings, MotorStallSettingsMutation } from './types';

import { queryServer } from '@/services/socket_service';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class MotorStallSettingsModule extends VuexModule {
  _settings: MotorStallSettings | null = null;

  get motorStallSettings(): MotorStallSettings | undefined {
    return this._settings || undefined;
  }

  @Mutation
  setMotorStallSettings(settings: MotorStallSettings): void {
    if(_.isEmpty(settings)) {
      return;
    }
    this._settings = _.cloneDeep(settings);
  }

  @Action({ rawError: true })
  async fetchMotorStallSettings(): Promise<void> {
    const event = 'motor-stall-settings/get';
    const params = {
      wellId: this.context.getters.currentWellId,
    };
    const resp = await queryServer(event, params);

    if(_.isEmpty(resp?.data)) {
      return;
    }
    this.context.commit(MotorStallSettingsMutation.SET_MOTOR_STALL_SETTINGS, resp.data);
  }

  @Action({ rawError: true })
  async updateMotorStallSettings(payload: Partial<MotorStallSettings>): Promise<boolean> {
    const event = 'motor-stall-settings/patch';
    payload.wellId = this.context.getters.currentWellId;
    const resp = await queryServer(event, payload);
    if(resp?.success) {
      this.context.commit(MotorStallSettingsMutation.SET_MOTOR_STALL_SETTINGS, payload);
    }
    return resp?.success;
  }
}
