export type OperatorId = string;

export type Operator = {
  _id: OperatorId;
  name: string;
};

export enum OperatorMutation {
  SET_OPERATORS = 'setOperators',
}

export enum OperatorAction {
  FETCH_OPERATORS = 'fetchOperators',
}
