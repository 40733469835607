import { WellId } from '../types';

export type PulserData = {
  wellId: WellId;
  time: number;
  holeDepth: number;
  actualDepth: number;
  pinc: number;
};

export enum PulserDataMutation {
  SET_PULSER_DATA = 'setPulserData',
  APPEND_PULSER_DATA = 'appendPulserData',
}

export enum PulserDataAction {
  FETCH_PULSER_DATA = 'fetchPulserData',
}
