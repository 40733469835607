export enum ApiTokenMutation {
  SET_API_TOKENS = 'setApiTokens',
  APPEND_API_TOKEN = 'appendApiToken',
}

export enum ApiTokenAction {
  FETCH_API_TOKENS = 'fetchApiTokens',
  CREATE_API_TOKEN = 'createApiToken',
  DELETE_API_TOKEN = 'deleteApiToken',
}

export type TokenId = string;

export type ApiToken = {
  name: string;
  token: string;
  creationTime: number;
  expirationTime?: number;
  _id?: TokenId;
};
