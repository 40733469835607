import { TimeTrace } from '@/store/modules/well_data/types';

export enum TimeChartsMutation {
  SET_TIME_TRACE_SCALE = 'setTimeTraceScale',
  SET_TIME_TRACE_MAX_VALUE = 'setTimeTraceMaxValue',
  SET_TIME_TRACE_MIN_VALUE = 'setTimeTraceMinValue',
  SET_TIME_TRACE_COLOR = 'setTimeTraceColor',
  SET_TIME_TRACE_CONFIGS = 'setTimeTraceConfigs',
  SET_TIME_TRACE_CONFIG_STATUS = 'setTimeTraceConfigStatus',
  SET_TIME_CHARTS_LIVE_ZOOM_RANGE = 'setTimeChartsLiveZoomRange',
  SET_TIME_CHARTS_HISTORICAL_ZOOM_RANGE = 'setTimeChartsHistoricalZoomRange',
  SET_TIME_CHARTS_TRACES = 'setTimeChartsTraces',
}

export enum TimeChartsAction {
  FETCH_TIME_TRACE_CONFIG = 'fetchTimeTraceConfig',
  UPDATE_TIME_TRACE_CONFIG = 'updateTimeTraceConfig',
  UPDATE_TIME_TRACE_COLOR = 'updateTimeTraceColor',
  UPDATE_TIME_TRACE_SCALE = 'updateTimeTraceScale',
  UPDATE_TIME_TRACE_MAX_VALUE = 'updateTimeTraceMaxValue',
  UPDATE_TIME_TRACE_MIN_VALUE = 'updateTimeTraceMinValue',
}

export type TimeTraceConfigs = Map<TimeTrace, TraceConfig>;

export type TraceConfig = {
  color: string;
  autoScale: boolean;
  renderType: RenderType;
  max: number;
  min: number;
};

export enum RenderType {
  LINE = 'line',
  POINT = 'point',
}
