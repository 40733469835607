











import { Component, Prop, Vue } from 'vue-property-decorator';

import moment from 'moment';

@Component
export default class TimePicker extends Vue {
  @Prop({ required: true })
  value: number;

  get time(): Date {
    return moment.unix(this.value).toDate();
  }

  set time(value: Date) {
    this.$emit('input', moment(value).unix());
  }
}
