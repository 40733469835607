

























import WellSettingsPartBase from './Index.vue';
import RemoteSurveysTable from '@/components/tables/RemoteSurveysTable.vue';
import RemoteBhasTable from '@/components/tables/RemoteBhasTable.vue';

import { SurveyAction } from '@/store/modules/survey/types';
import { BhaAction } from '@/store/modules/bha/types';

import { Component, Vue } from 'vue-property-decorator';
import * as _ from 'lodash';

@Component({ components: { RemoteBhasTable, RemoteSurveysTable, WellSettingsPartBase } })
export default class ExternalDataSourcesSettings extends Vue {
  isSurveysTableActive = false;
  isBhasTableActive = false;

  switchSurveysTableActive() {
    this.isSurveysTableActive = !this.isSurveysTableActive;
  }

  switchBhasTableActive() {
    this.isBhasTableActive = !this.isBhasTableActive;
  }

  created(): void {
    this.$store.dispatch(SurveyAction.FETCH_EXTERNAL_SURVEYS);
    this.$store.dispatch(BhaAction.FETCH_EXTERNAL_BHAS);
  }
}
