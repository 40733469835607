import { ToolfaceType } from '../toolface_data/types';

export enum PseudoSurveyMutation {
  SET_PSEUDO_SURVEY_DATA = 'setPseudoSurveyData',
  APPEND_PSEUDO_SURVEY_DATA = 'appendPseudoSurveyData',
  SET_PSEUDO_SURVEYS_AGGREGATION = 'setPseudoSurveysAggregation',
}

export enum PseudoSurveyAction {
  FETCH_PSEUDO_SURVEY_DATA = 'fetchPseudoSurveyData',
}

export type PseudoSurveyData = {
  wellId: string;
  md: number[];
  inclination: number[];
  azimuth: number[];
  ew: number[];
  ns: number[];
  tvd: number[];
  dls: number[];
  time: number[];
  vs: number[];
  cd: number[];
  ca: number[];
  hti: number[];
  lti: number[];
  ti: number[];
  sbr: [number, number][];
  rbr: [number, number][];
  str: [number, number][];
  rtr: [number, number][];
  slideSeen: number[];
  slideAhead: number[];
  tfSeen: number[];
  tfAhead: number[];
  projInc: [number, number][];
  projAzm: [number, number][];
  projMD: [number, number][];
  projTVD: [number, number][];
  projEW: [number, number][];
  projNS: [number, number][];
  projVS: [number, number][];
  projCD: [number, number][];
  projCA: [number, number][];
  section: string[];
  distance2plan: [number, number, number][];
  slideWobSeen: number[];
  slideDiffSeen: number[];
  rotateWobSeen: number[];
  rotateDiffSeen: number[];
  slideRopSeen: number[];
  slideFlowSeen: number[];
  slideRpmSeen: number[];
  rotateRopSeen: number[];
  rotateFlowSeen: number[];
  rotateRpmSeen: number[];
  toolfaceType: ToolfaceType;
};

export type PseudoSurveyChartData = [number, number, number][];

export enum AggregationGroup {
  NONE = 'None',
  FT_50 = '50 ft',
  FT_100 = '100 ft',
  FT_200 = '200 ft',
}
