import { queryServer } from '@/services/socket_service';
import { GaugeConfiguration, GaugesConfigMutation, RepeatTimeInerval } from '@/store/modules/gauge_data/types';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class NotificationsConfigurationModule extends VuexModule {
  _configurations: GaugeConfiguration[] = [];

  get notificationConfigurations(): GaugeConfiguration[] {
    return this._configurations;
  }

  @Mutation
  updateGaugeConfiguration(payload: { gaugeId: string, configuration: GaugeConfiguration }): void {
    const gaugeIdx = _.findIndex(this._configurations, (conf: GaugeConfiguration) => conf._id === payload.gaugeId);
    if(gaugeIdx === -1) {
      return;
    }
    this._configurations[gaugeIdx] = payload.configuration;
  }

  @Mutation
  appendGaugeNotificationConfiguration(configuration: GaugeConfiguration): void {
    const gaugeIdx = _.findIndex(this._configurations, (conf: GaugeConfiguration) => conf._id === configuration._id);
    if(gaugeIdx !== -1) {
      throw new Error(`Alert with id: ${configuration._id} is already exist`);
    }
    this._configurations.push(configuration);
  }

  @Mutation
  removeGaugeNotificationConfiguration(id: string): void {
    const gaugeIdx = _.findIndex(this._configurations, (conf: GaugeConfiguration) => conf._id === id);
    if(gaugeIdx === -1) {
      throw new Error(`No alert with id: ${id}`);
    }
    this._configurations.splice(gaugeIdx, 1);
  }

  @Mutation
  setGaugesConfiguration(configurations: GaugeConfiguration[]): void {
    this._configurations = _.cloneDeep(configurations);
  }

  @Action({ rawError: true })
  async sendNotificationConfigurations(payload: { configs: GaugeConfiguration[], userIds: string[] }): Promise<void> {
    const event = 'notification-configuration/post';
    const configsToSent = _.cloneDeep(payload.configs);
    for(const config of configsToSent) {
      delete config._id;
      config.accepted = false;
      for(const userId of payload.userIds) {
        config.userId = userId;
        const params = { userId, config };
        queryServer(event, params);
      }
    }
  }

  @Action({ rawError: true })
  async createNotificationConfiguration(config: GaugeConfiguration): Promise<void> {
    const event = 'notification-configuration/post';
    delete config._id;
    const params = {
      userId: this.context.getters.user._id,
      config,
    };
    const resp = await queryServer(event, params);
    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }
    config._id = resp.data;
  }

  @Action({ rawError: true })
  async updateNotificationConfiguration(config: GaugeConfiguration): Promise<void>  {
    const event = 'notification-configuration/patch';
    const params = {
      userId: this.context.getters.user._id,
      id: config._id,
      config,
    };
    await queryServer(event, params);
  }

  @Action({ rawError: true })
  async deleteNotificationConfiguration(id: string): Promise<void> {
    const event = 'notification-configuration/delete';
    const params = {
      userId: this.context.getters.user._id,
      id,
    };
    await queryServer(event, params);
  }

  @Action({ rawError: true })
  async fetchGaugesConfig(): Promise<void> {
    const event = 'notification-configuration/get';
    const params = { userId: this.context.getters.user._id };
    const resp = await queryServer(event, params);
    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }

    store.commit(GaugesConfigMutation.SET_GAUGES_CONFIGURATION, resp.data);
  }
}
