export enum StandMutation {
  SET_STANDS = 'setStands',
  CLEAR_STANDS = 'clearStands',
  SET_LAST_DEPTH = 'setStandsLastDepth',
  SET_STAND_GROUP_BY_OPTION = 'setStandGroupByOption',
}

export enum StandAction {
  FETCH_STANDS = 'fetchStands',
  REFETCH_STANDS = 'refetchStands',
}

export type Stand = {
  wellId: string;
  time: number;
  standNumber: number;
  rigStateDistribution: [number, number, number];
  averageSlidingROP: number;
  slideEfficiency: number;
  slideDistribution: [number, number];
  averageRotatingROP: number;
  averageSlidingEfficiency: number;
  averageRotatingEfficiency: number;
  connectionTime: number;
  startMD: number;
  endMD: number;
};

export enum StandGroup {
  NONE = 'None',
  HOUR_12 = '12 hours',
  DAY = '1 day',
  DAY_7 = '7 days',
}
