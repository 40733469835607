import { Component, Vue, Prop } from 'vue-property-decorator';

export type Size = 'sm' | 'md' | 'lr';

@Component
export class Sized extends Vue {
  @Prop({ required: false, default: '' })
  size: Size;

  get sizeClass(): { [className: string]: true } {
    if(this.size === 'lr') {
      return { lr: true };
    }
    return;
  }
}
