import { PumpRecycle, PumpRecycleMutation } from './types';

import { queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class PumpRecycleModule extends VuexModule {
  _pumpRecycles: PumpRecycle[] = [];
  _time: number | undefined;

  get pumpRecycles(): PumpRecycle[] {
    return this._pumpRecycles;
  }

  @Mutation
  setPumpRecycles(pumpRecycles: PumpRecycle[]): void {
    if(pumpRecycles === undefined || pumpRecycles.length === 0) {
      return;
    }

    const timeList = _.map(pumpRecycles, (el: PumpRecycle) => el.time);
    const lastTime = _.max(timeList);
    store.commit(PumpRecycleMutation.SET_LAST_TIME, lastTime);

    this._pumpRecycles = [...this._pumpRecycles, ...pumpRecycles];
  }

  @Mutation
  setPumpRecyclesLastTime(time: number): void {
    this._time = time;
  }

  @Action({ rawError: true })
  async fetchPumpRecycles(): Promise<void> {
    let from = this._time;
    if(from !== undefined) {
      from += 1;
    }

    const event = 'pump-recycle/get/count';
    const params = {
      wellId: this.context.getters.currentWellId,
      from,
    };

    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }

    this.context.commit(PumpRecycleMutation.SET_PUMP_RECYCLES, resp.data);
  }
}
