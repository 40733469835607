






































import Modal from '@/components/modals/Modal.vue';
import NvictaButton from '@/components/ui-kit/NvictaButton.vue';

import { Component, Vue, Prop } from 'vue-property-decorator';
import * as _ from 'lodash';

const DEFAULT_TEXT = 'Are you sure?';

@Component({ components: { Modal, NvictaButton } })
export default class ConfirmModal extends Vue {
  @Prop({ required: true })
  isModalActive: boolean;

  @Prop({ required: false, default: DEFAULT_TEXT })
  confirmText: string;

  @Prop({ required: false, default: false })
  tabRelated: boolean;

  @Prop({ required: false, default: '' })
  titleText: string;

  @Prop({ required: false, default: false })
  isBackgroundTransparent: boolean;

  @Prop({ required: false, default: false })
  windowCenter: boolean;

  @Prop({ required: false, default: 'Apply' })
  applyButtonText: string;

  isFetched = false;

  onApplyClick(): void {
    this.isFetched = true;
    this.$emit('apply');
  }

  onCloseClick(): void {
    this.close();
  }

  close(): void {
    this.isFetched = false;
    this.$emit('close');
  }
}
