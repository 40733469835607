






































import { FiltersModalsList, MultiwellCompareCoreMutation, MultiwellCompareWellListGetter, MultiWellsTabGetter, SyncStatuses } from '@/store/modules/multiwell_compare/types';
import { Component, Vue } from 'vue-property-decorator';
import { WellAction } from '@/store/modules/well/types';
import { RigAction } from '@/store/modules/rig/types';

import * as _ from 'lodash';
import { queryApi } from '@/services/server_service';
import moment from 'moment';

@Component
export default class MultiwellsCompareHeader extends Vue {
  pdfLoading: boolean = false;

  get clearAllModal(): FiltersModalsList {
    return FiltersModalsList.CLEAR_CONFIRMATION;
  }

  get wellIdList(): string[] {
    return this.$store.getters[MultiwellCompareWellListGetter.GET_SELECTED_WELLS_IDS];
  }

  get wellsFilters(): { [key: string]: string } {
    return this.$store.getters[MultiwellCompareWellListGetter.GET_SELECTED_WELLS_FILTERS];
  }

  openFilter(filter: FiltersModalsList): void {
    this.$store.commit(MultiwellCompareCoreMutation.SET_ACTIVE_FILTER_MODAL, filter);
  }

  get drillingOptimizationsSelectedDepthRange(): [number, number] | undefined {
    return this.$store.getters[MultiWellsTabGetter.DRILLING_OPTIMIZATION_SELECTED_DEPTH_RANGE];
  }

  get directionalSelectedDepthRange(): [number, number] | undefined {
    return this.$store.getters[MultiWellsTabGetter.DIRECTIONAL_SELECTED_DEPTH_RANGE];
  }

  get directionalDayTime(): string {
    return this.$store.getters[MultiWellsTabGetter.DIRECTIONAL_SELECTED_DAY_TIME];
  }

  get nptDepthRange(): [number, number] | undefined {
    return this.$store.getters[MultiWellsTabGetter.NPT_SELECTED_DEPTH_RANGE];
  }

  get nptDayTime(): string {
    return this.$store.getters[MultiWellsTabGetter.NPT_SELECTED_DAY_TIME];
  }

  async goToPdf(): Promise<void> {
    const filters = _.map(this.wellIdList, (wellId: string) => this.wellsFilters[wellId] || null);
    this.pdfLoading = true;
    const pageName = 'multi-wells';
    const data = {
      userId: this.$store.getters.user._id,
      wellId: this.wellIdList,
      filters, // TODO: filters
      pageName: pageName,
      pdfUrl: 'multi-wells',
      // @ts-ignore
      drillingOptimizationDepth: this.drillingOptimizationsSelectedDepthRange,
      // @ts-ignore
      directionalDepth: this.directionalSelectedDepthRange,
      directionalDayTime: this.directionalDayTime,
      // @ts-ignore
      nptDepth: this.nptDepthRange,
      nptDayTime: this.nptDayTime,
    };
    const fileName = 'multi-wells.pdf';
    await this.downloadPdfByParams(data, fileName);
    this.pdfLoading = false;
  }

  async downloadPdfByParams(data: any, filename: string) {
    try {
      const resp = await queryApi({
        url: 'api/pdf-gen/by-config',
        method: 'GET',
        responseType: 'arraybuffer',
        params: data,
      });
      if(resp === undefined) {
        return;
      }
      const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', filename);
      document.body.appendChild(fileLink);
      fileLink.click();
    } catch(e) {
      const title = 'PDF file gen';
      if(e.response !== undefined) {
        this.$store.dispatch('alertError', { title: title, message: e.response.data.error });
      } else {
        this.$store.dispatch('alertError', { title: title, message: 'Service unavailable' });
      }
    }
  }

  async syncMultiwells(): Promise<void> {
    this.$store.commit(MultiwellCompareCoreMutation.SET_MULTIWELL_SYNC_STATUS, SyncStatuses.Fetching);

    await Promise.all([
      this.$store.dispatch(WellAction.REFETCH_WELLS),
      this.$store.dispatch(RigAction.FETCH_RIGS),
    ]);

    this.$store.commit(MultiwellCompareCoreMutation.SET_MULTIWELL_SYNC_STATUS, SyncStatuses.Fetched);
  }
}
