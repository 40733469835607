
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"width":"20","height":"20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M11.75 17.73l-7.313-7.313a.635.635 0 01-.145-.209.611.611 0 01-.042-.229c0-.083.014-.16.042-.229a.635.635 0 01.146-.208l7.312-7.313A.79.79 0 0112.333 2a.79.79 0 01.584.23c.166.166.25.364.25.593 0 .23-.084.427-.25.594L6.354 9.979l6.563 6.563a.775.775 0 01.24.593.814.814 0 01-.24.573.811.811 0 01-.594.25.778.778 0 01-.573-.229z","fill":"#DBDBDB"}})])
          )
        }
      }
    