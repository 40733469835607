




















































import Modal from '../Modal.vue';

import DeleteTokenConfirmationModal from '@/components/modals/admin/DeleteTokenConfirmation.vue';

import { ApiToken, ApiTokenAction } from '@/store/modules/api_token/types';

import TrashIcon from '@/assets/icons/pure-icons/trash.svg.pure';

import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ components: { Modal, DeleteTokenConfirmationModal, TrashIcon } })
export default class ApiTokenModal extends Vue {
  isConfirmModalActive: boolean = false;
  selectedTokenNameForDelete: string = null;
  selectedTokenIdForDelete: string = null;

  @Prop({ required: false, default: false })
  isModalActive!: boolean;

  get apiTokens(): any[] {
    return this.$store.getters.apiTokens;
  }

  get deleteTooltipName(): string {
    return this.apiTokens.length === 1 ? `` : ``;
  }

  copyToken(token: string): void {
    navigator.clipboard.writeText(token);
    this.$store.dispatch('alertSuccess', { title: `Token copied` });
  }

  async addToken(): Promise<void> {
    await this.$store.dispatch(ApiTokenAction.CREATE_API_TOKEN);
  }

  openConfirmationModal(tokenToDelete: ApiToken) {
    this.selectedTokenNameForDelete = tokenToDelete.name;
    this.selectedTokenIdForDelete = tokenToDelete._id;
    this.isConfirmModalActive = true;
  }

  closeConfirmationModal() {
    this.selectedTokenNameForDelete = null;
    this.selectedTokenIdForDelete = null;
    this.isConfirmModalActive = false;
  }

  onCloseClick(): void {
    this.close();
  }

  close(): void {
    this.$emit('close');
  }
}
