
import { DirectionalLineChartData, DirectionalSlideSheetsData, DirectionalTortuosityData, MultiwellCompareWellListGetter, MultiWellsTabMutation, MultiWellsTabGetter } from '@/store/modules/multiwell_compare/types';
import { SlideSheet } from '@/store/modules/slide_sheet/types';

import { queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import * as _ from 'lodash';

@Module
export class MultiWellsDirectionalTabModule extends VuexModule {
  _tortuosityData: DirectionalLineChartData = {};
  _tortuosityMetrics: { [metric: string]: { label: string }} = {
    ti: { label: 'TI' },
    hti: { label: 'HTI' },
    lti: { label: 'LTI' },
    dls: { label: 'DLS' },
  };

  _selectedDayTime = 'All';
  _selectedTortuosityMetric = 'ti';
  _slideSheetsMetrics: { [metric: string]: { label: string, unit: string, visible: boolean }} = {
    activity: { label: 'Slide', unit: '%', visible: true },
    toolfaceEfficiency: { label: 'Sliding Efficiency', unit: '%', visible: true },
    averageROP: { label: 'Sliding ROP', unit: 'ft/hr', visible: true },
    trueSlideDistance: { label: 'Distance to Plan', unit: 'ft', visible: true },
  };

  _slideSheetsData: DirectionalSlideSheetsData = {};
  _selectedDepthRange: [number, number] | null = null;

  get tortuosityData(): DirectionalLineChartData {
    return this._tortuosityData;
  }

  get slideSheetsData(): DirectionalSlideSheetsData {
    return this._slideSheetsData;
  }

  get selectedDayTime(): string {
    return this._selectedDayTime;
  }

  get tortuosityMetrics(): { [metric: string]: { label: string }} {
    return this._tortuosityMetrics;
  }

  get slideSheetsMetrics(): { [metric: string]: { label: string }} {
    return this._slideSheetsMetrics;
  }

  get selectedTortuosityMetric(): string {
    return this._selectedTortuosityMetric;
  }

  get selectedDepthRange(): [number, number] | undefined {
    return this._selectedDepthRange || undefined;
  }

  @Mutation
  setTortuosityData(data: DirectionalLineChartData): void {
    this._tortuosityData = _.cloneDeep(data);
  }

  @Mutation
  appendTortuosityDataForWellId(payload: { data: DirectionalTortuosityData, wellId: string }): void {
    this._tortuosityData[payload.wellId] = payload.data;
  }

  @Mutation
  removeTortuosityDataByWellId(wellId: string): void {
    delete this._tortuosityData[wellId];
  }

  @Mutation
  setSlideSheetsData(data: DirectionalSlideSheetsData): void {
    this._slideSheetsData = _.cloneDeep(data);
  }

  @Mutation
  appendSlideSheetsDataForWellId(payload: { data: SlideSheet[], wellId: string }): void {
    this._slideSheetsData[payload.wellId] = payload.data;
  }

  @Mutation
  removeSlideSheetsDataByWellId(wellId: string): void {
    delete this._slideSheetsData[wellId];
  }

  @Mutation
  setSelectedDepthRange(range: [number, number] | null): void {
    this._selectedDepthRange = range;
  }

  @Mutation
  setSelectedTortuosityMetric(metric: string): void {
    this._selectedTortuosityMetric = metric;
  }

  @Mutation
  setSelectedDayTime(dayTime: string): void {
    this._selectedDayTime = dayTime;
  }

  @Action({ rawError: true })
  async fetchTortuosityDataForAllWells(): Promise<void> {
    const event = 'survey-data/get';
    const promises = [];
    const range = store.getters[MultiWellsTabGetter.DIRECTIONAL_SELECTED_DEPTH_RANGE] || [];
    for(const wellId of store.getters[MultiwellCompareWellListGetter.GET_SELECTED_WELLS_IDS]) {
      const params = {
        wellId,
        from: range[0],
        to: range[1],
      };
      promises.push(queryServer(event, params));
    }
    const resps = await Promise.all(promises);
    const filteredResps = _.filter(resps, (resp: any) => !_.isEmpty(resp.data));
    const res = {};
    for(const resp of filteredResps) {
      res[resp.data.wellId] = resp.data;
    }
    store.commit(MultiWellsTabMutation.DIRECTIONAL_SET_TORTUOSITY_DATA, res);
  }

  @Action({ rawError: true })
  async fetchSlideSheetsDataForAllWells(): Promise<void> {
    const event = 'slide-sheet/get';
    const promises = [];
    const range = store.getters[MultiWellsTabGetter.DIRECTIONAL_SELECTED_DEPTH_RANGE] || [];
    const dayTime = this._selectedDayTime !== 'All' ? _.lowerCase(this._selectedDayTime) : undefined;
    for(const wellId of store.getters[MultiwellCompareWellListGetter.GET_SELECTED_WELLS_IDS]) {
      const params = {
        wellId,
        from: range[0],
        to: range[1],
        dayTime,
        timeZome: 0, // TODO: set browser time zone hours
      };
      promises.push(queryServer(event, params));
    }
    const resps = await Promise.all(promises);
    const filteredResps = _.filter(resps, (resp: { data: SlideSheet[] }) => !_.isEmpty(resp.data));
    const res = {};
    for(const resp of filteredResps) {
      if(_.isNil(_.first(resp.data).wellId)) {
        continue;
      }
      res[_.first(resp.data).wellId] = resp.data;
    }
    store.commit(MultiWellsTabMutation.DIRECTIONAL_SET_SLIDE_SHEETS_DATA, res);
  }
}
