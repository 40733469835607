import { queryServer } from '@/services/socket_service';
import { PresetGaugeConfiguration, PresetGaugesConfigMutation } from '@/store/modules/tabs/gauges/types';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class PresetGaugesConfigModule extends VuexModule {
  _configurations: PresetGaugeConfiguration[] = [];
  _isModalActive = false;
  _selectedIdForModal: string | null = null;

  get presetGaugesConfigurations(): PresetGaugeConfiguration[] {
    return this._configurations;
  }

  get isGaugeConfigurationModalActive(): boolean {
    return this._isModalActive;
  }

  get selectedGaugeIdForModal(): string | undefined {
    if(!this._selectedIdForModal) {
      return undefined;
    }
    return this._selectedIdForModal;
  }

  @Mutation
  updatePresetGaugeConfiguration(payload: { gaugeId: string, configuration: PresetGaugeConfiguration }): void {
    const gaugeIdx = _.findIndex(this._configurations, (conf: PresetGaugeConfiguration) => conf.id === payload.gaugeId);
    if(gaugeIdx === -1) {
      return;
    }
    this._configurations[gaugeIdx] = payload.configuration;
  }

  @Mutation
  setPresetGaugesConfiguration(configuractions: PresetGaugeConfiguration[]): void {
    this._configurations = configuractions;
  }

  @Mutation
  displayGaugeConfigurationModal(gaugeId: string): void {
    this._selectedIdForModal = gaugeId;
    this._isModalActive = true;
  }

  @Mutation
  hideGaugeConfigurationModal(): void {
    this._selectedIdForModal = null;
    this._isModalActive = false;
  }

  @Action({ rawError: true })
  async savePresetGaugeData(payload: { gaugeId: string, configuration: PresetGaugeConfiguration }): Promise<void>  {
    store.commit(PresetGaugesConfigMutation.UPDATE_GAUGE_CONFIGURATION, payload);
    const event = 'preset-gauges-configuration/patch';
    const params = {
      userId: this.context.getters.user._id,
      presetName: this.context.getters.user.activePreset,
      configs: this._configurations,
    };
    await queryServer(event, params);
  }

  @Action({ rawError: true })
  async fetchPresetGaugesConfig(): Promise<void> {
    const event = 'preset-gauges-configuration/get';
    const params = { userId: this.context.getters.user._id, presetName: this.context.getters.user.activePreset };

    const resp = await queryServer(event, params);
    if(resp === undefined || _.isEmpty(resp.data) || _.isEmpty(resp.data.configs)) {
      return;
    }

    store.commit(PresetGaugesConfigMutation.SET_GAUGES_CONFIGURATION, resp.data.configs);
  }
}
