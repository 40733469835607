import { PseudoSurveyData, PseudoSurveyMutation, PseudoSurveyChartData, AggregationGroup } from './types';
import { DepthSection } from '../types';

import { queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

const READONLY_FIELDS = ['wellId', '_id'];

@Module
export class PseudoSurveyModule extends VuexModule {
  data?: PseudoSurveyData | null = null;
  _selectedAggregation: AggregationGroup = AggregationGroup.NONE;

  get pseudoSurveyData(): PseudoSurveyData | undefined {
    return this.data || undefined;
  }

  get lastPlottedPseudoSurveyMd(): number | undefined {
    if(this.data === null) {
      return undefined;
    }
    let lastMd = -1;
    if(this.data.md !== undefined && _.last(this.data.md) !== undefined) {
      lastMd = _.last(this.data.md);
    }

    return lastMd;
  }

  get filteredPseudoSurveyData(): PseudoSurveyData {
    const lastPlottedSurveyMd = store.getters.lastPlottedSurveyMd;
    // TODO: separate methods, I'm in hurry :(
    if(store.getters.surveySelectedSection === DepthSection.ALL && lastPlottedSurveyMd === undefined) {
      return this.data;
    }
    const data = _.clone(this.data);

    if(store.getters.surveySelectedSection !== DepthSection.ALL) {
      const idxList: number[] = [];
      _.map(data.section, (value: DepthSection, idx: number) => {
        if(value === store.getters.surveySelectedSection) {
          idxList.push(idx);
        }
      });
      for(const field in data) {
        if(_.includes(READONLY_FIELDS, field)) {
          continue;
        }
        data[field] = _.filter(data[field], (value: string, idx: number) => _.includes(idxList, idx));
      }
    }

    if(lastPlottedSurveyMd !== undefined) {
      const lastMdPseudoIndex = _.findIndex(data.md, (val: number) => val > lastPlottedSurveyMd);

      const fields = Object.keys(data);
      fields.forEach((key: string) => {
        if(lastMdPseudoIndex >= 0) {
          data[key] = data[key].slice(lastMdPseudoIndex);
        } else {
          data[key] = [];
        }
      });
    }

    return data;
  }

  get pseudoSurveysAggregation(): AggregationGroup {
    return this._selectedAggregation;
  }

  get pseudoSurveysAggregationFt(): number | undefined {
    switch(this._selectedAggregation) {
      case AggregationGroup.NONE:
        return undefined;
      case AggregationGroup.FT_50:
        return 50;
      case AggregationGroup.FT_100:
        return 100;
      case AggregationGroup.FT_200:
        return 200;
      default:
        throw new Error(`Unknown type of Pseudo Survey aggregation group: ${this._selectedAggregation}`);
    }
  }

  @Mutation
  setPseudoSurveysAggregation(aggregation: AggregationGroup): void {
    this._selectedAggregation = aggregation;
  }

  @Mutation
  setPseudoSurveyData(data: PseudoSurveyData): void {
    this.data = data;
  }

  @Mutation
  appendPseudoSurveyData(newData: PseudoSurveyData): void {
    const surveyFields = Object.keys(this.data);
    const respFields = Object.keys(newData);

    surveyFields.forEach((key: string) => {
      if(!respFields.includes(key)) {
        throw new Error(`No "${key}" field in /survey-data response`);
      }
      this.data[key] = this.data[key].concat(newData[key]);
    });
  }

  @Action({ rawError: true })
  async fetchPseudoSurveyData(): Promise<void> {
    const event = 'pseudo-survey-data/get';
    const params = {
      wellId: this.context.getters.currentWellId,
      aggregationFt: this.context.getters.pseudoSurveysAggregationFt,
    };

    const resp = await queryServer(event, params);
    if(_.isEmpty(resp?.data?.time)) {
      return;
    }

    this.context.commit(PseudoSurveyMutation.SET_PSEUDO_SURVEY_DATA, resp.data);
  }
}
