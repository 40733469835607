import { BtaTabMutation } from './types';

import { PseudoSurveyData } from '@/store/modules/pseudo_survey/types';

import { queryServer } from '@/services/socket_service';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class BuildTurnAnalysisTabModule extends VuexModule {
  _pseudoSurveys: PseudoSurveyData | null = null;
  _selectedAggregationInterval = 100; //ft

  get btaAggregatedPseudoSurveys(): PseudoSurveyData | undefined {
    return this._pseudoSurveys || undefined;
  }

  @Mutation
  setBtaAggregatedPseudoSurveys(surveyData: PseudoSurveyData): void {
    this._pseudoSurveys = _.cloneDeep(surveyData);
  }

  @Action({ rawError: true })
  async fetchDailyReports(): Promise<void> {
    const event = 'pseudo-survey-data/get';
    const params = {
      wellId: this.context.getters.currentWellId,
      aggregationFt: this._selectedAggregationInterval,
    };

    const resp = await queryServer(event, params);
    if(_.isEmpty(resp?.data?.time)) {
      return;
    }

    this.context.commit(BtaTabMutation.SET_AGGREGATED_PRESUDO_SURVEYS, resp.data);
  }
}
