import {
  MultiwellCompareFiltersGetter,
  MultiwellCompareFiltersMutation,
  MultiwellCompareWellListGetter,
  MULTIWELL_DEPTH_TRACE_TO_NAME_AND_UNIT_MAPPING,
  MultiwellDrillingRigStatesLabels,
} from '@/store/modules/multiwell_compare/types';
import { WellId } from '../../types';
import { FilterCondition, FilterOperator } from '@/store/modules/tabs/right_tabs/drilling_optimization_tab/scatter/types';
import { Well } from '../../well/types';

import { queryServer } from '@/services/socket_service';
import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

interface IDrillingParametersFilters {
  operator: FilterOperator;
  parameters: FilterCondition[];
}

export const DRILLING_PARAMETERS_STATIC_DEPTH = {
  field: 'depth',
  value: {
    from: 0,
    to: 40000,
  },
  rigState: null,
  id: 0,
};

export const DRILLING_PARAMETERS_STATIC_RIG = {
  field: 'rigState',
  value: null,
  rigState: 20,
  id: 1,
};

export const DRILLING_PARAMETERS_DEFAULT_CONDITION = {
  field: 'doc',
  value: {
    from: 0,
    to: 10,
  },
  rigState: null,
  id: 2,
};

@Module
export class MultiwellCompareFilterDrillingParametersModule extends VuexModule {
  _drillingParameters: FilterCondition[] = [];
  _filterOperator: FilterOperator = FilterOperator.AND;
  _isInProgress: boolean = false;
  _progress: number = 0;

  _conditionalDepthDataCount: { [wellId: WellId]: number } = {};

  get drillingParametersParameters(): FilterCondition[] | null {
    return this._drillingParameters;
  }

  get drillingParametersFilterOperator(): FilterOperator {
    return this._filterOperator;
  }

  get conditionalDepthDataCount(): { [wellId: WellId]: number } {
    return this._conditionalDepthDataCount;
  }

  get accessibleData(): string {
    if(this._drillingParameters === null) {
      return '';
    }
    const conditions = _.map(this._drillingParameters, (filter: FilterCondition) => {
      let [field, unit] = [filter.field, ''];
      if(MULTIWELL_DEPTH_TRACE_TO_NAME_AND_UNIT_MAPPING[field]) {
        [field, unit] = [MULTIWELL_DEPTH_TRACE_TO_NAME_AND_UNIT_MAPPING[field].name, MULTIWELL_DEPTH_TRACE_TO_NAME_AND_UNIT_MAPPING[field].unit];
      }
      if(filter.field === 'rigState') {
        return `${field} is equal ${MultiwellDrillingRigStatesLabels[filter.rigState]}`;
      } else {
        return `${field} is between ${filter.value.from}${unit} and ${filter.value.to}${unit}`;
      }
    });
    return conditions.join(` ${this._filterOperator.toUpperCase()} `);
  }

  get progress(): number {
    return this._progress;
  }

  get isInProgress(): boolean {
    return this._isInProgress;
  }

  @Mutation
  setConditionalDepthDataCount(conditionalDepthDataCount: { [wellId: WellId]: number }): void {
    this._conditionalDepthDataCount = _.cloneDeep(conditionalDepthDataCount);
  }

  @Mutation
  setDrillingParametersParameters(filters: FilterCondition[] | null): void {
    this._drillingParameters = _.cloneDeep(filters);
  }

  @Mutation
  setDrillingParametersFilterOperator(operator: FilterOperator): void {
    this._filterOperator = operator;
  }

  @Mutation
  setIsInProgress(progress: boolean): void {
    this._isInProgress = progress;
  }

  @Mutation
  setProgress(progress: number): void {
    this._progress = progress;
  }

  @Action
  setDrillingParametersFilters(fullDrillingParameters: IDrillingParametersFilters): void {
    store.commit(MultiwellCompareFiltersMutation.SET_DRILLING_PARAMETERS_OPERATOR, fullDrillingParameters.operator);
    store.commit(MultiwellCompareFiltersMutation.SET_DRILLING_PARAMETERS_PARAMETERS, fullDrillingParameters.parameters);
  }

  @Action
  clearDrillingParametersFilters(): void {
    store.commit(MultiwellCompareFiltersMutation.SET_DRILLING_PARAMETERS_OPERATOR, FilterOperator.AND);
    store.commit(
      MultiwellCompareFiltersMutation.SET_DRILLING_PARAMETERS_PARAMETERS,
      [_.cloneDeep(DRILLING_PARAMETERS_STATIC_DEPTH), _.cloneDeep(DRILLING_PARAMETERS_STATIC_RIG)]
    );
  }

  @Action({ rawError: true })
  async fetchConditionalDepthDataCount(): Promise<void> {
    // TODO: move to the right place (if rigState is "All", it is useless filter)
    const params = _.cloneDeep(store.getters[MultiwellCompareFiltersGetter.GET_DRILLING_PARAMETERS_PARAMETERS]);
    const rigPararms = params[1];
    if(rigPararms.rigState === 20) {
      params.splice(1, 1);
    }

    let progress = 0;
    store.commit(MultiwellCompareFiltersMutation.SET_DRILLING_PARAMETERS_IS_IN_PROGRESS, true);

    // TODO: (optimization) use filtered Wells
    const wellIds = store.getters[MultiwellCompareWellListGetter.GET_TOTAL_WELLS_LIST].map((well: Well) => well._id);

    const res = {};
    await Promise.all(
      wellIds.map(async (wellId: WellId) => {
        const resp = await queryServer(
          'depth-data/get/count',
          {
            wellId,
            filterConditions: params,
            filterOperator: store.getters[MultiwellCompareFiltersGetter.GET_DRILLING_PARAMETERS_OPERATOR],
          }
        );

        res[wellId] = resp.data || 0;

        store.commit(MultiwellCompareFiltersMutation.SET_DRILLING_PARAMETERS_PROGRESS, ++progress);
      })
    );

    store.commit(MultiwellCompareFiltersMutation.SET_DRILLING_PARAMETERS_CONDITIONAL_DEPTH_DATA_COUNT, res);

    setTimeout(() => {
      store.commit(MultiwellCompareFiltersMutation.SET_DRILLING_PARAMETERS_IS_IN_PROGRESS, false);
      store.commit(MultiwellCompareFiltersMutation.SET_DRILLING_PARAMETERS_PROGRESS, 0);
    }, 3000);
  }
}
