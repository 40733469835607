import { IRangeData } from '@/store/modules/multiwell_compare/types';

export enum ScatterMutation {
  SET_SCATTER_DEPTH_DATA = 'setScatterDepthData',
  APPEND_SCATTER_DEPTH_DATA = 'appendScatterDepthData',
  SET_LAST_FETCHED_SCATTER_DEPTH = 'setLastFetchedScatterDepth',
}

export enum ScatterAction {
  FETCH_SCATTER_DEPTH_DATA = 'fetchScatterDepthData',
}

export enum FilterOperator {
  AND = 'And',
  OR = 'Or',
}

export type FilterCondition = {
  field: string;
  value?: IRangeData;
  rigState?: number;
  id: number;
};
