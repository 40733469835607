
import { DrillingOptimizationLineChartData, DrillingOptimizationDepthData, MultiwellCompareWellListGetter, MultiWellsTabMutation, MultiWellsTabGetter } from '@/store/modules/multiwell_compare/types';
import { WellId } from '@/store/modules/types';

import { queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import * as _ from 'lodash';

@Module
export class MultiwellCompareDrillingOptimizationTabModule extends VuexModule {
  _lineChartsData: DrillingOptimizationLineChartData = {};
  _barChartsData: { [wellId: WellId]: { [key: string]: number }} = {};
  _chartMetrics: { [metric: string]: { label: string, unit: string }} = {
    rop: { label: 'ROP', unit: 'ft/hr' },
    mse: { label: 'MSE', unit: 'kpsi' },
    diffPressure: { label: 'Diff. Pressure', unit: 'psi' },
    rpm: { label: 'RPM', unit: 'rpm' },
    wob: { label: 'WOB', unit: 'klb' },
  };

  _selectedDepthRange: [number, number] | null = null;

  get lineChartsData(): DrillingOptimizationLineChartData {
    return this._lineChartsData;
  }

  get barChartsData(): { [wellId: WellId]: { [key: string]: number }} {
    return this._barChartsData;
  }

  get chartMetrics(): { [metric: string]: { label: string }} {
    return this._chartMetrics;
  }

  get depthBorders(): [number, number] | undefined {
    const depthBorders = [];
    for(const wellId in this._lineChartsData) {
      depthBorders.push(_.min(this._lineChartsData[wellId].depth), _.max(this._lineChartsData[wellId].depth));
    }
    if(depthBorders.length === 0) {
      return undefined;
    }
    return [_.min(depthBorders), _.max(depthBorders)];
  }

  get selectedDepthRange(): [number, number] | undefined {
    return this._selectedDepthRange || undefined;
  }

  @Mutation
  setLineChartData(data: DrillingOptimizationLineChartData): void {
    this._lineChartsData = _.cloneDeep(data);
  }

  @Mutation
  appendLineChartDataForWellId(payload: { data: DrillingOptimizationDepthData, wellId: WellId }): void {
    this._lineChartsData[payload.wellId] = payload.data;
  }

  @Mutation
  removeLineChartDataByWellId(wellId: WellId): void {
    delete this._lineChartsData[wellId];
  }

  @Mutation
  setBarChartData(payload: { [wellId: WellId]: { [key: string]: number }}): void {
    this._barChartsData = _.cloneDeep(payload);
  }

  @Mutation
  appendBarChartData(payload: { data: { [key: string]: number }, wellId: WellId }): void {
    this._barChartsData[payload.wellId] = payload.data;
    this._barChartsData = _.cloneDeep(this._barChartsData); // TOOD: hack, because watch.deep not working
  }

  @Mutation
  removeBarChartData(wellId: WellId): void {
    delete this._barChartsData[wellId];
    this._barChartsData = _.cloneDeep(this._barChartsData); // TOOD: hack, because watch.deep not working
  }

  @Mutation
  setSelectedDepthRange(range: [number, number] | null): void {
    this._selectedDepthRange = range;
  }

  @Action({ rawError: true })
  async fetchLineChartDataForAllWells(): Promise<void> {
    const event = 'depth-data/get';
    const promises = [];
    const range = store.getters[MultiWellsTabGetter.DRILLING_OPTIMIZATION_SELECTED_DEPTH_RANGE] || [];
    for(const wellId of store.getters[MultiwellCompareWellListGetter.GET_SELECTED_WELLS_IDS]) {
      const params = {
        wellId,
        projection: {
          depth: 1,
          correctedDepth: 1,
          currentDepthGap: 1,
          rop: 1,
          wob: 1,
          mse: 1,
          diffPressure: 1,
          rpm: 1,
        },
        from: range[0],
        to: range[1],
      };
      promises.push(queryServer(event, params));
    }
    const resps = await Promise.all(promises);
    const filteredResps = _.filter(resps, (resp: any) => !_.isEmpty(resp.data));
    const res = {};
    for(const resp of filteredResps) {
      res[resp.data.wellId] = resp.data;
    }
    store.commit(MultiWellsTabMutation.DRILLING_OPTIMIZATION_SET_LINE_CHART_DATA, res);
  }

  @Action({ rawError: true })
  async fetchBarChartData(wellId?: WellId): Promise<void> {
    const event = 'depth-data/get/aggregated';

    const res = {};
    const range = store.getters[MultiWellsTabGetter.DRILLING_OPTIMIZATION_SELECTED_DEPTH_RANGE] || [];
    const wellIds = wellId ? [wellId] : store.getters[MultiwellCompareWellListGetter.GET_SELECTED_WELLS_IDS];
    console.log('wellIds', wellIds);
    await Promise.all(
      wellIds.map(async (wellId: WellId) => {
        const params = {
          wellId,
          projection: {
            rop: 1,
            wob: 1,
            mse: 1,
            diffPressure: 1,
            rpm: 1,
          },
          from: range[0],
          to: range[1],
        };
        const resp = await queryServer(event, params);

        if(resp === undefined || _.isEmpty(resp.data)) {
          return;
        }
        res[wellId] = _.cloneDeep(resp.data);
      })
    );
    console.log('res', res);
    if(wellId) {
      store.commit(MultiWellsTabMutation.DRILLING_OPTIMIZATION_APPEND_BAR_CHART_DATA, { data: res[wellId], wellId });
    } else {
      store.commit(MultiWellsTabMutation.DRILLING_OPTIMIZATION_SET_BAR_CHART_DATA, res);
    }
  }
}
