import { DashboardPreset, DashboardPresetMutation } from './types';
import { DirectionalGuidanceTabMutation } from '../tabs/left_tabs/directional_guidance_tab/types';
import { DirectionalPlotTabMutation } from '../tabs/right_tabs/directional_plot_tab/types';
import { TimeChartsMutation } from '../tabs/left_tabs/drilling_data_tab/time_charts/types';
import { DepthChartsMutation } from '../tabs/left_tabs/drilling_data_tab/depth_charts/types';
import { CompareWellsMutation } from '../tabs/left_tabs/drilling_data_tab/compare_wells/types';

import { queryServer } from '@/services/socket_service';
import { RouteName, isRouteAllowed } from '@/permissions';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class DashboardPresetModule extends VuexModule {
  _presets: DashboardPreset[] = [];
  _activePreset: DashboardPreset | null = null;

  get dashboardPresets(): DashboardPreset[] {
    return this._presets;
  }

  get activeDashboardPreset(): DashboardPreset | undefined {
    const activePresetName = store.getters.user.activePreset;
    return _.find(this._presets, (preset: DashboardPreset) => preset.name === activePresetName);
  }

  @Mutation
  setActiveDashboardPreset(presetName: string): void {
    const preset = _.find(this._presets, (preset: DashboardPreset) => preset.name === presetName);
    this._activePreset = preset;
    const tooltipConfig = {
      surveyData: this._activePreset.directionalPlotSurveyTooltip,
      recommendation: this._activePreset.directionalPlotRecomendationTooltip,
      wellPlan: this._activePreset.directionalPlotWellPlanTooltip,
      projection: this._activePreset.directionalPlotProjectionTooltip,
    };
    store.commit(TimeChartsMutation.SET_TIME_CHARTS_TRACES, this._activePreset.timeCharts);
    store.commit(DepthChartsMutation.SET_DEPTH_CHARTS_TRACES, this._activePreset.depthCharts);
    store.commit(CompareWellsMutation.SET_COMPARE_WELLS_TRACES, this._activePreset.compareCharts);
    store.commit(DirectionalGuidanceTabMutation.SET_SELECTED_SURVEY_TRACES, this._activePreset.directionalGuidanceSurveys);
    store.commit(DirectionalGuidanceTabMutation.SET_SELECTED_PSEUDO_SURVEY_TRACES, this._activePreset.directionalGuidancePseudoSurveys);
    store.commit(DirectionalGuidanceTabMutation.SET_SELECTED_SLIDE_SHEETS_TRACES, this._activePreset.directionalGuidanceSlideSheets);
    store.commit(DirectionalPlotTabMutation.SET_TOOLTIP_CONFIG, tooltipConfig);
    // TODO: update trace config
  }

  @Mutation
  setDashboardPresets(presets: DashboardPreset[]): void {
    this._presets = _.cloneDeep(presets);
  }

  @Mutation
  appendDashboardPreset(preset: DashboardPreset): void {
    this._presets.push(preset);
  }

  @Mutation
  removeDashboardPreset(name: string): void {
    this._presets = _.filter(this._presets, (preset: DashboardPreset) => preset.name !== name);
  }

  @Action({ rawError: true })
  async fetchDashboardPresets(): Promise<void> {
    const event = 'dashboard-preset/get/all';
    const params = {
      userId: this.context.getters.user._id,
    };

    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }
    this.context.commit(DashboardPresetMutation.SET_PRESETS, resp.data);
    if(this._activePreset === null) {
      const presetName = this.context.getters.user.activePreset;
      this.context.commit(DashboardPresetMutation.SET_ACTIVE_PRESET, presetName);
    }
  }

  @Action({ rawError: true })
  async updateDashboardPreset(updateQuery: any): Promise<void> {
    // for demo purposes
    if(!isRouteAllowed(RouteName.WELL_SETTINGS)) {
      return;
    }
    const event = 'dashboard-preset/patch';
    const params = {
      userId: this.context.getters.user._id,
      name: this._activePreset.name,
      updateQuery,
    };
    await queryServer(event, params);
  }

  @Action({ rawError: true })
  async addDashboardPreset(name: string): Promise<void> {
    const event = 'dashboard-preset/post';
    const params = {
      userId: this.context.getters.user._id,
      name,
    };
    const resp = await queryServer(event, params);
    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }
    this.context.commit(DashboardPresetMutation.APPEND_PRESET, resp.data);
  }

  @Action({ rawError: true })
  async deleteDashboardPreset(name: string): Promise<void> {
    const event = 'dashboard-preset/delete';
    const params = {
      userId: this.context.getters.user._id,
      name,
    };
    queryServer(event, params);
    this.context.commit(DashboardPresetMutation.REMOVE_PRESET, name);
  }
}
