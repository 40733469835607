






























import Modal from './Modal.vue';
import NotificationHistoryContent from './notification-history-parts/NotificationHistoryContent.vue';
import NotificationHistoryFilters from './notification-history-parts/NotificationHistoryFilters.vue';
import { Notification, NotificationMutation, NotificationVisibility } from '@/store/modules/notification/types';

import { Component, Vue, Prop } from 'vue-property-decorator';

import * as _ from 'lodash';

@Component({ components: { Modal, NotificationHistoryContent, NotificationHistoryFilters } })
export default class NotificationHistoryModal extends Vue {
  visibilities: NotificationVisibility[] = _.values(NotificationVisibility);

  @Prop({ required: true })
  isModalActive: boolean;

  get wellName(): string {
    return this.$store.getters.currentWell &&
      this.$store.getters.currentWell.name || '';
  }

  get selectedVisibility(): NotificationVisibility {
    return this.$store.getters.visibilityState;
  }

  get notifications(): Notification[] {
    return this.$store.getters.notifications;
  }

  get visibleNotifications(): Notification[] {
    return this.$store.getters.visibleNotifications;
  }

  get hiddenNotifications(): Notification[] {
    return this.$store.getters.hiddenNotifications;
  }

  get selectedPeriodTime(): number {
    return this.$store.getters.filterPeriodTime;
  }

  getFilteredNotifications(visibility: NotificationVisibility): Notification[] {
    const notifications = visibility === NotificationVisibility.ARCHIVE ? this.hiddenNotifications : this.visibleNotifications;

    return notifications.filter((notification: Notification) => {
      const type = notification.type || 'Unknown type';
      const level = notification.alertLevel || 'Unknown level';

      return this.$store.getters.filteredAlertTypes.includes(type) &&
        this.$store.getters.filteredAlertLevels.includes(level);
    });
  }

  getNotificationsCount(visibility: NotificationVisibility): number {
    return this.getFilteredNotifications(visibility).length;
  }

  onTypeChange(visibility: NotificationVisibility): void {
    this.$store.commit(NotificationMutation.SET_VISIBILITY_STATE, visibility);
  }

  onCloseClick(): void {
    this.$emit('close');
  }
}
