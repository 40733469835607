import { HydraulicsTimeData, HydraulicsDepthData, HydraulicsDataMutation } from './types';
import { LiveModeTimeInterval } from '../app/types';

import { queryServer } from '@/services/socket_service';
import { DataBucket } from '@/models/bucket';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class HydraulicsDataModule extends VuexModule {
  _timeData: DataBucket<HydraulicsTimeData> = new DataBucket(null, 'time');
  _depthData: HydraulicsDepthData | null = null;
  _timeRange: [number, number] | null = null;
  _selectedTime: number | null = null; // selected time on time chart for depth data
  _liveTimeInterval: LiveModeTimeInterval = LiveModeTimeInterval.ONE_HOUR;

  _timeDataFetchingLastTimestamp: number | null = null;

  _timeDataQueue: HydraulicsTimeData[] = [];

  get hydraulicsTimeData(): HydraulicsTimeData[] {
    return this._timeData.data as HydraulicsTimeData[];
  }

  get hydraulicsDepthData(): HydraulicsDepthData | null {
    return this._depthData;
  }

  get hydraulicsTimeRange(): [number, number] | null {
    if(_.isEmpty(this._timeData.data)) {
      return undefined;
    }
    return this._timeRange;
  }

  get hydraulicsSelectedTime(): number | undefined {
    return this._selectedTime || undefined;
  }

  get hydraulicsLiveTimeInterval(): LiveModeTimeInterval {
    return this._liveTimeInterval;
  }

  // TODO: deduplicate with MotorTab module
  get hydraulicsLiveTimeIntervalInSeconds(): number {
    switch(this._liveTimeInterval) {
      case LiveModeTimeInterval.FIFTEEN_MIN:
        return 15 * 60;
      case LiveModeTimeInterval.ONE_HOUR:
        return 60 * 60;
      case LiveModeTimeInterval.TWO_HOURS:
        return 2 * 60 * 60;
      default:
        throw new Error(`Unknown LiveTimeInterval: ${this._liveTimeInterval}`);
    }
  }

  @Mutation
  setHydraulicsSelectedTime(time: number | null): void {
    this._selectedTime = time;
  }

  @Mutation
  setHydraulicsTimeRange(range: [number, number]): void {
    if(_.isEqual(this._timeRange, range)) {
      return;
    }
    this._timeRange = range;
  }

  @Mutation
  setHydraulicsTimeData(data: HydraulicsTimeData[]): void {
    if(_.isEmpty(data) || _.isEqual(this._timeData.data, data)) {
      return;
    }
    if(store.getters.liveMode) {
      this._timeData.setLimit(store.getters.hydraulicsLiveTimeIntervalInSeconds);
    } else {
      this._timeData.setLimit(null);
    }
    this._timeData.setData(data);
    store.commit(HydraulicsDataMutation.SET_TIME_RANGE, [_.first(data)?.time, _.last(data)?.time]);
  }

  @Mutation
  appendHydraulicsTimeData(data: HydraulicsTimeData[]): void {
    if(_.isEmpty(data)) {
      return;
    }
    if(this._timeDataFetchingLastTimestamp !== null) {
      for(const item of data) {
        if(item.time > this._timeDataFetchingLastTimestamp) {
          this._timeDataQueue.push(item);
        }
      }
      return;
    }

    if(this._timeDataQueue.length > 0) {
      this._timeData.appendData(this._timeDataQueue);
      this._timeDataQueue = [];
    }
    this._timeData.appendData(data);
  }

  @Mutation
  setHydraulicsDepthData(data: HydraulicsDepthData): void {
    if(_.isEmpty(data) || _.isEqual(this._depthData, data)) {
      return;
    }

    if(this._selectedTime !== null && data.time !== this._selectedTime) {
      return;
    }
    this._depthData = _.cloneDeep(data);
  }

  @Mutation
  setHydraulicsTimeDataFetchingLastTimestamp(timestamp: number | null): void {
    this._timeDataFetchingLastTimestamp = timestamp;
  }

  @Mutation
  setHydraulicsLiveTimeInterval(interval: LiveModeTimeInterval): void {
    this._liveTimeInterval = interval;
    this._timeData.setLimit(this.hydraulicsLiveTimeIntervalInSeconds);
  }

  @Mutation
  clearHydraulicsDataLimit(): void {
    this._timeData.setLimit(null);
  }

  @Action({ rawError: true })
  async fetchHydraulicsTimeData(payload?: { from?: number, to?: number }): Promise<void> {
    const event = 'hydraulics-time-data/get';

    const params = {
      wellId: this.context.getters.currentWellId,
      from: payload?.from,
      to: payload?.to,
    };

    this.context.commit(HydraulicsDataMutation.SET_TIME_DATA_FETCHING_LAST_TIMESTAMP, payload?.to || +new Date() / 1000);
    const resp = await queryServer(event, params);
    this.context.commit(HydraulicsDataMutation.SET_TIME_DATA_FETCHING_LAST_TIMESTAMP, null);
    if(_.isNil(resp?.data)) {
      return;
    }

    store.commit(HydraulicsDataMutation.SET_TIME_DATA, resp.data);
  }

  @Action({ rawError: true })
  async fetchHydraulicsDepthData(time?: number): Promise<void> {
    const event = 'hydraulics-depth-data/get';
    console.log('fetchHydraulicsDepthData', time);
    const params = {
      wellId: this.context.getters.currentWellId,
      time,
    };

    const resp = await queryServer(event, params);
    if(_.isNil(resp?.data)) {
      return;
    }
    console.log('fetchHydraulicsDepthData', resp.data);
    store.commit(HydraulicsDataMutation.SET_DEPTH_DATA, resp.data);
  }
}
