












import { Option, Field } from '@/components/selectors/SearchableSelector.vue';
import { Well } from '@/store/modules/well/types';

import { Component, Vue, Prop } from 'vue-property-decorator';

import * as _ from 'lodash';

@Component
export default class WellSelector extends Vue {
  @Prop({ required: true })
  // TODO: rename to `selectedWellIds`
  wellIds: string[];

  @Prop({ required: false, default: 3 })
  maxSelectedWells: number;

  @Prop({ required: false, default: false })
  hideCurrentWell: boolean;

  @Prop({ required: false })
  availableWells: Well[];

  @Prop({ required: false, default: null })
  width: string;

  get options(): Option[] {
    const availableWells = this.availableWells || this.$store.getters.wells;
    const options = availableWells.map((w: Well) => ({
      key: w._id,
      value: w.name,
      disabled: this.isWellDisabled(w._id),
    }));

    if(this.hideCurrentWell) {
      _.remove(options, (option: Option) => option.key === this.$store.getters.currentWellId);
    }

    return _.sortBy(options, (option: Option) => !_.includes(this.wellIds, option.key));
  }

  isWellDisabled(wellId: string): boolean {
    if(this.$store.getters.currentWellId === wellId) {
      return true;
    }
    return false;
  }

  toggleWell(field: Field): void {
    this.$emit('toggle-well', field.option.key);
  }
}
