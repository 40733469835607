









import NvictaHeader from '@/components/layout/headers/NvictaHeader.vue';

import { Component, Vue } from 'vue-property-decorator';

@Component({ components: { NvictaHeader } })
export default class Authorized extends Vue {}
