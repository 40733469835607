





















import { Component, Vue } from 'vue-property-decorator';
import NvictaInput from '@/components/ui-kit/NvictaInput.vue';

@Component
export default class NvictaInputExtendedTextarea extends NvictaInput {
  isTextareaShows: boolean = false;

  setTextareaHeight(): void {
    const textareaRef = this.$refs.textarea as HTMLInputElement;

    textareaRef.style.height = `auto`; // actual height saving. Don't remove
    textareaRef.style.height = `${textareaRef.scrollHeight}px`;
  }

  setTextareaFocus(): void {
    const textareaRef = this.$refs.textarea as HTMLInputElement;

    textareaRef.focus();
    textareaRef.setSelectionRange(textareaRef.value.length,  textareaRef.value.length);
  }

  openTextarea(): void {
    this.isTextareaShows = true;

    Vue.nextTick(() => {
      this.setTextareaFocus();
      this.setTextareaHeight();
    });
  }

  onTextareaInput(event: KeyboardEvent): void {
    this.onInput(event);
    this.setTextareaHeight();
  }

  onEnter(event: KeyboardEvent): void {
    if(event.key === 'Enter') {
      event.preventDefault();
      this.closeTextarea();
    }
  }

  closeTextarea(): void {
    this.isTextareaShows = false;
  }
}

