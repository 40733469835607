








































































































import {
  DirectionalSettings,
  DirectionalSettingsAction,
  DirectionalSettingsMutation,
} from '@/store/modules/settings/directional_settings/types';
import { Survey } from '@/store/modules/survey/types';

import { emitEngineEvent, subscribeOnceToEngineEvent } from '@/services/socket_service';

import { Component, Vue, Prop } from 'vue-property-decorator';

import * as _ from 'lodash';

const RECOMMENDATION_SEARCH_TIMEOUT = 30; // seconds
const DEFAULT_CORRECTION_CRITERIA = {
  inclinationValue: 1,
  azimuthValue: 5,
  distanceValue: [-10, 10, -10, 10],
  lookAhead: 90,
};
const DEFAULT_SETTINGS = {
  correctionCriteria: DEFAULT_CORRECTION_CRITERIA,
  alpha: 0.5,
  dlsConstraint: 4,
  lookAheadDistance: 300,
  motorYield: 12,
  rbr: 0,
  rtr: 0,
};

@Component
export default class DirectionalSettingsComponent extends Vue {
  @Prop({ required: true })
  global: boolean;

  @Prop({ required: true })
  shouldCalculateRecommendation: boolean;

  displayProgress = false;
  progress = 0;

  @Prop({ required: false })
  settings: DirectionalSettings | undefined;

  get distanceField(): (number | undefined)[] {
    if(!this.settings.correctionCriteria.distanceValue) {
      return [undefined, undefined, undefined, undefined];
    }
    return this.settings.correctionCriteria.distanceValue;
  }

  // TODO: move it from here to user-components
  public async onApplyClick(
    survey: Survey,
    options: {
      motor: number;
      rbr: number;
      rtr: number;
    }
  ): Promise<boolean> {
    // Update settings by options

    // tendencies
    this.settings.tendencies.motorYield = options.motor;
    this.settings.tendencies.rbr = options.rbr;
    this.settings.tendencies.rtr = options.rtr;

    if(this.global) {
      this.$store.commit(DirectionalSettingsMutation.SET_DIRECTIONAL_SETTINGS, this.$store.getters.directionalSettingsLocal);
      const resp = await this.$store.dispatch(DirectionalSettingsAction.UPDATE_DIRECTIONAL_SETTINGS, this.settings);
      if(!resp) {
        return false;
      }
    }
    await emitEngineEvent('directional-settings-updated', {
      wellId: this.$store.getters.currentWellId,
      planNewPath: this.shouldCalculateRecommendation,
      settings: this.settings,
      survey,
    });

    if(this.shouldCalculateRecommendation) {
      this.progress = 0;
      this.displayProgress = true;
      this.updateProgress(); // TODO: remove duplications with AddRecommendationModal
      await subscribeOnceToEngineEvent('status', () => this.$emit('close'));
    }

    return true;
  }

  updateProgress(): void {
    const maxProgress = 100;

    this.progress += maxProgress / RECOMMENDATION_SEARCH_TIMEOUT;
    if(this.progress < maxProgress) {
      setTimeout(this.updateProgress.bind(this), 1000);
    }
  }
}
