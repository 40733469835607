import DatePicker from 'vue2-datepicker';
import TimePicker from '@/components/ui-kit/TimePicker.vue';
import Checkbox from '@/components/ui-kit/Checkbox.vue';
import Radio from '@/components/ui-kit/Radio.vue';
import Dropdown from '@/components/ui-kit/Dropdown.vue';
import FilterButton from '@/components/ui-kit/FilterButton.vue';
import IconButton from '@/components/ui-kit/IconButton.vue';
import NvictaButton from '@/components/ui-kit/NvictaButton.vue';
import NvictaInput from '@/components/ui-kit/NvictaInput.vue';
import NvictaTable from '@/components/ui-kit/NvictaTable.vue';
import ProgressBar from '@/components/ui-kit/ProgressBar.vue';
import Selector from '@/components/ui-kit/Selector.vue';
import TabSwitch from '@/components/ui-kit/TabSwitch.vue';
import ToggleSwitch from '@/components/ui-kit/ToggleSwitch.vue';
import ToggleIcon from '@/components/ui-kit/icons/ToggleIcon.vue';
import NvictaPagination from '@/components/ui-kit/NvictaPagination.vue';
import ValidatedInput from '@/components/validated_inputs/ValidatedInput.vue';
import SearchableSelector from '@/components/selectors/SearchableSelector.vue';
import AddButton from '@/components/ui-kit/buttons/AddButton.vue';
import Vue from 'vue';
import NvictaInputExtendedTextarea from '@/components/ui-kit/NvictaInputExtendedTextarea.vue';
import NvictaSearchInput from '@/components/ui-kit/NvictaSearchInput.vue';
import ColorPicker from '@/components/ColorPicker.vue';
import OptionsToggle from '@/components/ui-kit/OptionsToggle.vue';

Vue.component('date-picker', DatePicker);
Vue.component('time-picker', TimePicker);
Vue.component('color-picker', ColorPicker);
Vue.component('checkbox', Checkbox);
Vue.component('radio', Radio);
Vue.component('dropdown', Dropdown);
Vue.component('filter-button', FilterButton);
Vue.component('icon-button', IconButton);
Vue.component('nvicta-button', NvictaButton);
Vue.component('nvicta-input', NvictaInput);
Vue.component('nvicta-search-input', NvictaSearchInput);
Vue.component('nvicta-input-extended-textarea', NvictaInputExtendedTextarea);
Vue.component('nvicta-table', NvictaTable);
Vue.component('progress-bar', ProgressBar);
Vue.component('selector', Selector);
Vue.component('tab-switch', TabSwitch);
Vue.component('toggle-switch', ToggleSwitch);
Vue.component('toggle-icon', ToggleIcon);
Vue.component('options-toggle', OptionsToggle);
Vue.component('nvicta-pagination', NvictaPagination);
Vue.component('validated-input', ValidatedInput);
Vue.component('searchable-selector', SearchableSelector);
Vue.component('add-button', AddButton);

// TODO: unbind where it's possible in the code
// https://stackoverflow.com/questions/36170425/detect-click-outside-element
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if(!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    // @ts-ignore
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});
