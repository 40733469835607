import { queryApi } from '@/services/server_service';
import { queryServer } from '@/services/socket_service';

import { Motor, MotorMutation } from './types';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

const MOTOR_URL = `api/motor`;

@Module
export class MotorModule extends VuexModule {
  _motors: Motor[] = [];

  get motors(): Motor[] {
    return this._motors;
  }

  @Mutation
  setMotors(motors: Motor[]): void {
    this._motors = motors;
  }

  @Mutation
  deleteMotor(motorId: string): void {
    this._motors = this._motors.filter((motor: Motor) => motor._id !== motorId);
  }

  @Action({ rawError: true })
  async deleteMotorById(motorId: string): Promise<void> {
    await queryApi({
      url: MOTOR_URL,
      method: 'DELETE',
      data: { id: motorId },
    });

    this.context.commit(MotorMutation.DELETE_MOTOR, motorId);
  }

  @Action({ rawError: true })
  async fetchMotors(): Promise<void> {
    const resp = await queryApi({
      url: MOTOR_URL,
      method: 'GET',
    });

    if(resp?.data === undefined) {
      return;
    }

    this.context.commit(MotorMutation.SET_MOTORS, resp.data);
  }
}
