import { HydraulicsDepthData, HydraulicsTimeData } from '@/store/modules/hydraulics/types';

export type HydraulicsSimulatorData = {
  hydraulicsTimeData: HydraulicsTimeData;
  hydraulicsDepthData: HydraulicsDepthData;
};

export type HydraulicsSimulatorWellSettings = {
  casingDepth: number;
  casingId: number;
  holeId: number;
};

export type HydraulicsSimulatorSurveySettings = {
  md: number;
  tvd: number;
}[];

export type HydraulicsSimulatorBhaSettings = {
  components: {
    id: number;
    od: number;
    length: number;
    type: string;
  }[];
  bit: {
    tfa: number;
    dischargeCoefficient: number;
  };
  motor: {
    hydraulicPerformanceCurve: {
      flowRate: number;
      pressureDrop: number;
    }[];
  };
};

export type HydraulicsSimulatorMwdRunSettings = {
  mudData: {
    density: number;
    yieldPoint: number;
    consistency: number;
    fluidBehaviorIndex: number;
  };
};

export type HydraulicsSimulatorFormationTopSettings = {
  porePressure: number;
  fractureGradient: number;
};

export type HydraulicsSimulatorDatapoint = {
  time: number;
  holeDepth: number;
  pumpRate: number;
  blockSpeed: number;
  rpm: number;
};

export type HydraulicsSimulationData = {
  well: {
    casingData: [
      { size: number, startDepth: number, endDepth: number },
    ];
    holeData: [
      { size: number, startDepth: number, endDepth: number },
    ];
  };
  surveys: HydraulicsSimulatorSurveySettings;
  bha: HydraulicsSimulatorBhaSettings;
  mwdRun: HydraulicsSimulatorMwdRunSettings;
  datapoint: HydraulicsSimulatorDatapoint;
  // formationTop is used only for chart rendering
  formationTop?: HydraulicsSimulatorFormationTopSettings;
};

export type HydraulicsHistorySimulation = {
  userId: string;
  time: number;
  wellId: string;
  BHAId: string;
  data: HydraulicsSimulationData;
  favorite: boolean;
  _id?: string;
};

export enum HydraulicsSimulatorMutation {
  SET_DATA = 'setHydraulicsSimulatorData',
  SET_LOADING = 'setHydraulicsSimulatorLoading',
  SET_HISTORY = 'setHydraulicsSimulaionHistory',
  ADD_TO_HISTORY = 'addToHydraulicsSimulaionHistory',
}

export enum HydraulicsSimulatorAction {
  RUN_SIMULATION = 'runHydraulicsSimulation',
  GET_HISTORY = 'getHydraulicsSimulationHistory',
  INSERT_SIMULATION = 'insertHydraulicsSimulation',
}
