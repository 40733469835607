export enum WitsmlTemplateMutation {
  SET_WITSML_TEMPLATES = 'setWitsmlTemplates',
  APPEND_WITSML_TEMPLATE = 'appendWitsmlTemplate',
}

export enum WitsmlTemplateAction {
  FETCH_WITSML_TEMPLATES = 'fetchWitsmlTemplates',
  UPDATE_WITSML_TEMPLATE = 'updateWitsmlTemplate',
  CREATE_WITSML_TEMPLATE = 'createWitsmlTemplate',
  DELETE_WITSML_TEMPLATE = 'deleteWitsmlTemplate',
}

export type WitsmlTemplate = {
  name: string;
  username: string;
  password: string;
  wsdlFile: string;
  fields: { [field: string]: string };
  _id: string;
};
