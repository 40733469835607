import {
  CompareTabsList,
  FiltersModalsList,
  MultiwellCompareFiltersAction,
  MultiwellCompareWellListGetter,
  SyncStatuses,
} from '@/store/modules/multiwell_compare/types';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import store from '@/store';

@Module
export class MultiwellCompareCoreModule extends VuexModule {
  _syncStatus: SyncStatuses = SyncStatuses.Initial;
  _activeFilterModal: FiltersModalsList = null;
  _activeCompareTab: CompareTabsList = CompareTabsList.TAB_MAP_VIEW;
  _chartsPreloader: boolean = false;
  _pdfView: boolean = false;

  get pdfView(): boolean {
    return this._pdfView;
  }

  get multiwellSyncStatus(): SyncStatuses {
    return this._syncStatus;
  }

  get activeFilterModal(): FiltersModalsList {
    return this._activeFilterModal;
  }

  get activeCompareTab(): CompareTabsList {
    return this._activeCompareTab;
  }

  get modalsWithChanges(): FiltersModalsList[] {
    const filtersData = store.getters[MultiwellCompareWellListGetter.GET_RAW_DATA];
    const modals = Object.values(FiltersModalsList);

    return filtersData
      .map((item: string, index: number) => item ? modals[index] : null)
      .filter((mappedItem: string) => mappedItem);
  }

  get chartsPreloader(): boolean {
    return this._chartsPreloader;
  }

  @Mutation
  setPdfView(pdfView: boolean): void {
    this._pdfView = pdfView;
  }

  @Mutation
  setMultiwellSyncStatus(newSyncStatus: SyncStatuses): void {
    this._syncStatus = newSyncStatus;
  }

  @Mutation
  setActiveFilterModal(modal: FiltersModalsList): void {
    this._activeFilterModal = modal;
  }

  @Mutation
  setActiveCompareTab(tab: CompareTabsList): void {
    this._activeCompareTab = tab;
  }

  @Mutation
  setChartsPreloader(isActive: boolean): void {
    this._chartsPreloader = isActive;
  }

  @Action
  async clearAllFilters(): Promise<void> {
    await store.dispatch(MultiwellCompareFiltersAction.CLEAR_LOCATION_FILTERS);
    await store.dispatch(MultiwellCompareFiltersAction.CLEAR_BHA_FILTERS);
    await store.dispatch(MultiwellCompareFiltersAction.CLEAR_TRAJECTORY_FILTERS);
    await store.dispatch(MultiwellCompareFiltersAction.CLEAR_FAILURES_FILTERS);
    await store.dispatch(MultiwellCompareFiltersAction.CLEAR_PERSONNEL_FILTERS);
    await store.dispatch(MultiwellCompareFiltersAction.CLEAR_MUD_TYPE_FILTERS);
    await store.dispatch(MultiwellCompareFiltersAction.CLEAR_DRILLING_PARAMETERS_FILTERS);
  }
}
