export enum AppMutation {
  SET_LIVE_MODE = 'setLiveMode',
  SET_LIVE_TIME_INTERVAL = 'setLiveTimeInterval',
  APPEND_WITS_PACKET = 'appendWitsPacket',
  SET_ACTIVE_LEFT_TAB = 'setActiveLeftTab',
  SET_PERFORMANCE_METRICS_DEPTH_RANGE = 'setPerformanceMetricsDepthRange',
  SET_LAST_WITS_DATA = 'setLastWitsData',
  SET_WITS_LIVE_MODE = 'setWitsLiveMode',
  TOGGLE_LIVE_MODE = 'toggleLiveMode',
  SET_WELL_SUMMARY_FETCHING = 'setWellSummaryFetching',
  SET_WELL_SUMMARY_ROBOT = 'setWellSummaryRobot',
  SET_LEFT_TAB_WIDTH = 'setLeftTabWidth',
  SET_GLOBAL_TOOLTIP = 'setGlobalTooltip',
}

export enum LiveModeTimeInterval {
  FIFTEEN_MIN = '15 minutes',
  ONE_HOUR = '1 hour',
  TWO_HOURS = '2 hours',
}

export type WitsPacket = {
  raw: {
    timeStamp: string;
    packet: string;
    wellId: string;
  };
  parsed: {
    time: number;
    bitDepth: number;
    holeDepth: number;
    blockHeight: number;
    rop: number;
    hookload: number;
    wob: number;
    torque: number;
    rpm: number;
    pumpPressure: number;
    pumpRate: number;
    spm1: number;
    spm2: number;
    spm3: number;
    totalSpm: number;
    diffPressure: number;
    magneticToolface: number;
    gravityToolface: number;
    liveInclination: number;
    liveAzimuth: number;
    gamma: number;
    surveyMd: number;
    surveyAzimuth: number;
    surveyInclination: number;
  };
};

export enum LeftTab {
  DRILLING_DATA = 'Drilling Data',
  PERFORMANCE = 'Performance Metrics',
  DIRECTIONAL_GUIDANCE = 'Directional Guidance',
  TORQUE_AND_DRAG = 'Torque and Drag',
  HYDRAULICS = 'Hydraulics',
}

export type WitsData = { azm: number, inc: number };

export enum FetchingStatus {
  DEFAULT = 'Default',
  FETCHING = 'Fetching',
  FETCHED = 'FETCHED',
}

export type GlobalTooltipState = {
  isVisible: boolean;
  positionX: number;
  positionY: number;
  content: string;
  offsetX?: number;
  offsetY?: number;
};
