import { WitsmlTemplate, WitsmlTemplateMutation } from './types';

import { queryApi } from '@/services/server_service';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

const WITSML_TEMPLATE_URL = 'api/witsml-template';

@Module
export class WitsmlTemplateModule extends VuexModule {
  _witsmlTemplates: WitsmlTemplate[] = [];

  get witsmlTemplates(): WitsmlTemplate[] {
    return this._witsmlTemplates;
  }

  @Mutation
  setWitsmlTemplates(witsmlTemplates: WitsmlTemplate[]): void {
    this._witsmlTemplates = _.cloneDeep(witsmlTemplates);
  }

  @Mutation
  appendWitsmlTemplate(witsmlTemplate: WitsmlTemplate): void {
    this._witsmlTemplates.push(witsmlTemplate);
  }

  @Action({ rawError: true })
  async fetchWitsmlTemplates(): Promise<void> {
    const event = 'witsml-template/get';

    const resp = await queryApi({
      url: WITSML_TEMPLATE_URL,
      method: 'GET',
    });

    if(!resp?.data) {
      return;
    }

    this.context.commit(WitsmlTemplateMutation.SET_WITSML_TEMPLATES, resp.data);
  }

  @Action({ rawError: true })
  async updateWitsmlTemplate(payload: { id: string, updateQuery: Partial<WitsmlTemplate> }): Promise<void> {
    const witsmlTemplates = _.cloneDeep(this.context.getters.witsmlTemplates);
    const witsmlTemplateIdx = _.findIndex(witsmlTemplates, (witsmlTemplate: WitsmlTemplate) => witsmlTemplate._id === payload.id);
    if(witsmlTemplateIdx < 0) {
      throw new Error(`Can't find Witsml Template with id: ${payload.id}`);
    }

    const updatedTemplate = {
      ...witsmlTemplates[witsmlTemplateIdx], ...payload.updateQuery,
    };

    const params = {
      id: updatedTemplate._id,
      updateValues: updatedTemplate,
    };

    const resp = await queryApi({
      url: WITSML_TEMPLATE_URL,
      method: 'PATCH',
      data: params,
    });
    if(!resp) {
      return;
    }

    witsmlTemplates[witsmlTemplateIdx] = updatedTemplate;
    this.context.commit(WitsmlTemplateMutation.SET_WITSML_TEMPLATES, witsmlTemplates);
    this.context.dispatch('alertSuccess', { title: 'Witsml Template updated' });
  }

  @Action({ rawError: true })
  async createWitsmlTemplate(payload: { wsdlFile: string, name: string }): Promise<void> {
    const witsmlTemplates = _.cloneDeep(this.context.getters.witsmlTemplates);
    const template = { wsdlFile: payload.wsdlFile, name: payload.name };

    const resp = await queryApi({
      url: WITSML_TEMPLATE_URL,
      method: 'POST',
      data: template,
    });

    if(!resp?.data) {
      return;
    }

    const id = resp.data;

    witsmlTemplates.push({
      ...template,
      _id: id,
    });
    this.context.commit(WitsmlTemplateMutation.SET_WITSML_TEMPLATES, witsmlTemplates);
    this.context.dispatch('alertSuccess', { title: 'Witsml Template created' });
  }

  @Action({ rawError: true })
  async deleteWitsmlTemplate(id: string): Promise<void> {
    let witsmlTemplates = _.cloneDeep(this.context.getters.witsmlTemplates);
    const params = { id };

    const resp = await queryApi({
      url: WITSML_TEMPLATE_URL,
      method: 'DELETE',
      data: params,
    });

    if(!resp) {
      return;
    }

    witsmlTemplates = witsmlTemplates.filter((template: WitsmlTemplate) => template._id !== id);
    this.context.commit(WitsmlTemplateMutation.SET_WITSML_TEMPLATES, witsmlTemplates);
    this.context.dispatch('alertSuccess', { title: 'Witsml Template deleted' });
  }
}
