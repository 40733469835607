import { NotificationType } from '../notification/types';

// XXX: the most of fields here are legacy, we should remove them (related to notifications)
export type GaugeConfiguration = {
  _id: string;
  userId: string;
  trace: string;
  keyPath: KeyPath;
  title?: string;
  unit?: string;
  stops: number[];
  colors: string[];
  types: GaugeThresholdType[];
  defaultColor: string;
  enableDashboardNotifications?: boolean;
  enableVoiceNotifications?: boolean;
  enableEmailNotifications?: boolean;
  enableWhatsappNotifications?: boolean;
  max: number;
  min: number;
  aggregationType: AggregationType;
  aggregationInterval: AggregationInterval;
  wellIdList: string[];
  applyAllWells: boolean;
  type?: NotificationType;
  rigStateList?: number[];
  rules?: any[];
  accepted?: boolean;
  // this field is used only by Simple and Advanced notifications
  repeatTimeInterval?: RepeatTimeInerval;
};

export enum RepeatTimeInerval {
  NEVER = 0,
  MIN_5 = 300,
  MIN_15 = 900,
  MIN_30 = 1800,
  MIN_60 = 3600,
}

export enum KeyPath {
  TIME = 'Time',
  DEPTH = 'Depth',
  SURVEY = 'Survey',
  PUMP_RECYCLES = 'Pump Recycles',
  GAMMA = 'Gamma',
}
export enum GaugeThresholdType {
  ERROR = 'Error',
  WARNING = 'Warning',
  OK = 'Ok',
}
export enum AggregationType {
  INSTANT = 'Instant',
  MIN = 'Min',
  MAX = 'Max',
  AVG = 'Avg',
}
export enum ParameterChange {
  ABSOLUTE = 'Absolute',
  PERCENTAGE = 'Percentage',
}
export enum TimeAggregationInterval {
  ONE_MIN = '1min',
  TEN_MIN = '10min',
  THIRTY_MIN = '30min',
  ONE_HOUR = '1hr',
  TWO_HOURS = '2hrs',
}
export enum DepthAggregationInterval {
  TEN_FT = '10ft',
  THIRTY_FT = '30ft',
  NINETY_FT = '90ft',
  FIVE_HUNDRED_FT = '500ft',
}
export type AggregationInterval = TimeAggregationInterval | DepthAggregationInterval;

export enum GaugesConfigMutation {
  SET_GAUGES_CONFIGURATION = 'setGaugesConfiguration',
  UPDATE_GAUGE_CONFIGURATION = 'updateGaugeConfiguration',
  APPEND_GAUGE_NOTIFICATION_CONFIGURATION = 'appendGaugeNotificationConfiguration',
  REMOVE_GAUGE_NOTIFICATION_CONFIGURATION = 'removeGaugeNotificationConfiguration',
}

export enum GaugesConfigAction {
  FETCH_GAUGES_CONFIG = 'fetchGaugesConfig',
  SAVE_GAUGES_NOTIFICATION_CONFIGURATION = 'saveGaugesNotificationConfiguration',
  CREATE_NOTIFICATION_CONFIGURATION = 'createNotificationConfiguration',
  DELETE_NOTIFICATION_CONFIGURATION = 'deleteNotificationConfiguration',
  UPDATE_NOTIFICATION_CONFIGURATION = 'updateNotificationConfiguration',
  SEND_NOTIFICATION_CONFIGURATIONS = 'sendNotificationConfigurations',
}

export type AdvancedConfigRule = {
  trace: string;
  aggregationType: AggregationType;
  parameterChange: ParameterChange;
  aggregationInterval: TimeAggregationInterval | DepthAggregationInterval | undefined;
  range: [number, number];
};
