export type MwdRunId = string;
type WellId = string;
type BhaId = string;

export type MwdRun = {
  wellId: WellId;
  name: string;
  operator1: string;
  operator2: string;
  mudData: MudData;
  bhaId: null | BhaId;
  logTop: number;
  logBottom: number;
  holeSize: number;
  _id: MwdRunId;
};

export type MudData = {
  type: MudDataType;
  density: number;
  funnelViscosity: number;
  plasticViscosity: number;
  yieldPoint: number;
  pH: number;
  chWMud: number;
  apiFiltrate: number;
  maxMwdTemp: number;
  consistency: number;
  fluidBehaviorIndex: number;
};

export enum MudDataType {
  OBM = 'obm',
  WBM = 'wbm',
}

export enum MwdRunMutation {
  SET_MWD_RUNS = 'setMwdRuns',
  DELETE_MWD_RUN = 'deleteMwdRun',
  APPEND_MWD_RUN = 'appendMwdRun',
}

export enum MwdRunAction {
  FETCH_MWD_RUNS = 'fetchMwdRuns',
  DELETE_MWD_RUN = 'deleteMwdRunById',
  CREATE_MWD_RUN = 'createMwdRun',
  UPDATE_MWD_RUNS = 'updateMwdRuns',
}
