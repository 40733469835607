



























































































import { DEFAULT_FORMATION_TOP } from '@/store/modules/formation_top/defaults';
import { FormationTop, FormationTopAction } from '@/store/modules/formation_top/types';

import { emitEngineEvent } from '@/services/socket_service';

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import * as _ from 'lodash';
import { SurveyAction } from '@/store/modules/survey/types';

const VISIBLE_FIELDS = ['formationName', 'baselineMSE', 'mdStart', 'mdEnd', 'tvdStart', 'tvdEnd', 'baselineROP', 'porePressure', 'fractureGradient'];
const READONLY_FIELDS = ['wellId', '_id'];
const NUMBER_FIELDS = ['tvdStart', 'tvdEnd', 'mdStart', 'mdEnd', 'baselineMSE', 'baselineROP', 'porePressure', 'fractureGradient'];
const ARRAY_FILEDS = [];

const FORMATION_TOP_FIELD_MAPPINGS = {
  wellId: 'Well ID',
  formationName: 'Formation Name',
  tvdStart: 'TVD Start [ft]',
  tvdEnd: 'TVD End [ft]',
  mdStart: 'MD Start [ft]',
  mdEnd: 'MD End [ft]',
  baselineMSE: 'Baseline MSE [psi]',
  baselineROP: 'Baseline ROP [ft/hr]',
  porePressure: 'Pore Pressure [psi/ft]',
  fractureGradient: 'Fracture Gradient [psi/ft]',
  color: 'Color',
  _id: '_id',
};

@Component
export default class FormationTopsSettings extends Vue {
  idChangedList = [];
  expandedFormationTopId: string | null = null;
  componentKey = 0;
  isColorPickerVisible: boolean = true;
  visibleKeys = VISIBLE_FIELDS;
  activeFormationTopId: string | null = null;

  @Prop({ required: false, default: () => [] })
  highlightedFields: string[];

  // TODO: also update active formation top id when tvdStart / tvdEnd changes
  @Watch('formationTops.length')
  onFormationTopsChange(): void {
    this.updateActiveFormationTopId();
  }

  get formationTopFieldMappings() {
    return FORMATION_TOP_FIELD_MAPPINGS;
  }

  async onSaveClick(): Promise<void> {
    const changedFormationTops = _.filter(this.formationTops, (item: FormationTop) => {
      return _.includes(this.idChangedList, item._id);
    });
    const formationTops = _.map(changedFormationTops, (item: FormationTop) => this.getFormattedFormationTop(item));
    const resp = await this.$store.dispatch(FormationTopAction.UPDATE_FORMATION_TOPS, formationTops);
    if(!resp) {
      return;
    }
    await emitEngineEvent('formation-tops-updated', { wellId: this.$store.getters.currentWellId });
    await this.$store.dispatch('alertSuccess', { title: 'Formation top settings', message: `Changes applied` });
    this.idChangedList = [];
  }

  async addFormationTop(): Promise<void> {
    const formationTop = DEFAULT_FORMATION_TOP;
    formationTop['wellId'] = this.wellId;
    await this.$store.dispatch(FormationTopAction.ADD_FORMATION_TOP, formationTop);
    this.rerenderComponent();
  }

  async deleteFormationTop(id: string): Promise<void> {
    await this.$store.dispatch(FormationTopAction.DELETE_FORMATION_TOP, id);
    this.rerenderComponent();
  }

  isDetailsActive(id: string): boolean {
    return this.expandedFormationTopId === id;
  }

  onFormationTopChange(id: string): void {
    if(_.includes(this.idChangedList, id)) {
      return;
    }
    this.idChangedList.push(id);
  }

  itemExpand(id: string): void {
    if(this.isDetailsActive(id) === true) {
      this.expandedFormationTopId = null;
      return;
    }
    this.expandedFormationTopId = id;
  }

  isDetailsInputDisabled(field: string): boolean {
    return _.includes(READONLY_FIELDS, field);
  }

  isFieldHighlighted(field: string): boolean {
    return _.includes(this.highlightedFields, field);
  }

  getFormattedFormationTop(formationTop: FormationTop): FormationTop {
    const formattedFormationTop = _.clone(formationTop);
    _.keys(formationTop).forEach((key: string) => {
      const value = formationTop[key];
      if(this.isDetailsInputDisabled(key) === true) {
        return;
      }
      if(_.includes(NUMBER_FIELDS, key) === true) {
        formattedFormationTop[key] = Number(value);
        return;
      }
      if(_.includes(ARRAY_FILEDS, key) === true && typeof (value) === 'string') {
        formattedFormationTop[key] = _.map(value.split(','), (val: string) => Number(val));
      }
    });
    return formattedFormationTop;
  }

  getInputType(field: string): string {
    if(_.includes(NUMBER_FIELDS, field) === true) {
      return 'number';
    }
    return 'text';
  }

  updateColor(event: any, item: FormationTop): void {
    item.color = event.hex;
    this.onFormationTopChange(item._id);
  }

  get wellId(): string {
    return this.$store.getters.currentWellId;
  }

  get formationTops(): FormationTop[] {
    return this.$store.getters.formationTops;
  }

  // TODO: refactor
  // it's a hack: componentKey is used in :key on the top-level div inside the template
  // componentKey change forces the component to re-render
  rerenderComponent(): void {
    this.componentKey += 1;
  }

  updateActiveFormationTopId(): void {
    let currentTvd = 0;
    if(this.$store.getters.surveyData.md.length < 2) {
      currentTvd = this.$store.getters.currentWell.holeDepth;
    } else {
      currentTvd = _.last(this.$store.getters.surveyData.tvd);
    }

    for(const formationTop of this.formationTops) {
      if(currentTvd >= formationTop.tvdStart && currentTvd <= formationTop.tvdEnd) {
        this.activeFormationTopId = formationTop._id;
        break;
      }
    }
    if(this.activeFormationTopId === null && this.formationTops.length > 0) {
      this.activeFormationTopId = this.formationTops[0]._id;
    }
  }

  async mounted(): Promise<void> {
    this.updateActiveFormationTopId();
    this.expandedFormationTopId = this.activeFormationTopId;
  }
}
