import {
  SurveyData,
  SurveyZoom,
  SurveyMutation,
  SurveyChartData,
  ExternalSurvey,
  CustomSurveyData,
} from './types';
import { DepthSection } from '../types';

import { queryEngine, queryServer } from '@/services/socket_service';

import { makeRangesSameLength } from '@/utils';
import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

const READONLY_FIELDS = ['wellId', '_id'];

@Module
export class SurveyModule extends VuexModule {
  data?: SurveyData | null = null;
  customData?: CustomSurveyData | null = new Map();
  zoom?: SurveyZoom | null = null;
  _zoomHistory: SurveyZoom[] = [];
  _nextDepth: number | null = null;
  _selectedSection: DepthSection = DepthSection.ALL;
  _externalSurveys: ExternalSurvey[] = [];
  _filteredSurveyDepthRange: [number, number] | null = null;
  _isCleared = false;

  get isSurveyDataCleared(): boolean {
    return this._isCleared;
  }

  get nextSurveyDepth(): number | null {
    return this._nextDepth;
  }

  get surveyData(): SurveyData | undefined {
    if(this.data === null) {
      return undefined;
    }
    return this.data;
  }

  get userCustomSurveyData(): CustomSurveyData | undefined {
    if(this.customData === null) {
      return undefined;
    }
    return this.customData;
  }

  get externalSurveys(): ExternalSurvey[] {
    return this._externalSurveys;
  }

  get filteredSurveyDepthRange(): [number, number] | undefined {
    if(this._filteredSurveyDepthRange === null) {
      return undefined;
    }
    return this._filteredSurveyDepthRange;
  }

  @Mutation
  setFilteredSurveyDepthRange(range: [number, number] | null) {
    this._filteredSurveyDepthRange = range;
  }

  get surveySelectedSection(): DepthSection {
    return this._selectedSection;
  }

  get lastPlottedSurveyMd(): number | undefined {
    if(this.data === null) {
      return undefined;
    }
    let lastMd = -1;
    if(this.data.md !== undefined && _.last(this.data.md) !== undefined) {
      lastMd = _.last(this.data.md);
    }

    return lastMd;
  }

  get surveyChartZoom(): SurveyZoom | undefined {
    if(this.zoom === null) {
      return undefined;
    }
    return this.zoom;
  }

  get zoomHistory(): SurveyZoom[] {
    return this._zoomHistory;
  }

  @Mutation
  setExternalSurveys(surveys: ExternalSurvey[]): void {
    this._externalSurveys = surveys;
  }

  @Mutation
  setSurveySelectedSection(section: DepthSection): void {
    this._selectedSection = section;
  }

  @Mutation
  setNextSurveyDepth(nextDepth: number): void {
    this._nextDepth = nextDepth;
  }

  @Mutation
  setSurveyData(data: SurveyData): void {
    this.data = data;
  }

  @Mutation
  setCustomData(customData: CustomSurveyData): void {
    this.customData = customData;
  }

  @Mutation
  setSurveyChartZoom(payload: { zoom: SurveyZoom, shouldAddToHistory?: boolean, axesIndexes?: number[] }): void {
    // axesIndexes: indexes of ranges which influence on calculating.
    // indexes: 0 for WE, 1 for NS, 2 for TVD, 3 for VS
    // if axesIndexes is undefined all ranges will be used
    const defaultAzimuth = { isAzimuthIncluded: true };
    const ranges = [payload.zoom.ew, payload.zoom.ns, payload.zoom.tvd, payload.zoom.vs];
    const correctedRange = makeRangesSameLength(ranges, payload.axesIndexes);
    const zoom: SurveyZoom = { ew: undefined, ns: undefined, tvd: undefined, vs: undefined };
    zoom.ew = correctedRange[0];
    zoom.ns = correctedRange[1];
    zoom.tvd = correctedRange[2];
    zoom.vs = correctedRange[3];
    // TODO: it might be necessary, see https://gitlab.corpglory.com/nvicta/web-server/-/commit/c41edde753799dbd82cb8281acdae7f24743b80d
    zoom.isAzimuthIncluded = !!payload.zoom.isAzimuthIncluded;
    this.zoom = _.defaultsDeep(zoom, defaultAzimuth);
    const shouldAddToHistory = payload.shouldAddToHistory === undefined ? true : payload.shouldAddToHistory;
    if(shouldAddToHistory === true) {
      store.commit(SurveyMutation.APPEND_ITEM_TO_ZOOM_HISTORY, payload.zoom);
    }
  }

  @Mutation
  appendItemToZoomHistory(surveyChartZoom: SurveyZoom): void {
    if(!_.isEqual(_.last(this._zoomHistory), surveyChartZoom)) {
      this._zoomHistory.push(surveyChartZoom);
    }
  }

  @Mutation
  deleteLastItemFromZoomHistory(): void {
    this._zoomHistory.pop();
  }

  @Mutation
  clearSurveyData(): void {
    this.data = null;
    this._nextDepth = null;
    this._isCleared = true;
  }

  @Mutation
  setSurveyDataCleared(isCleared: boolean): void {
    this._isCleared = isCleared;
  }

  @Mutation
  appendSurveyData(newData: SurveyData): void {
    const surveyFields = Object.keys(this.data);
    const respFields = Object.keys(newData);

    surveyFields.forEach((key: string) => {
      if(!respFields.includes(key)) {
        throw new Error(`No "${key}" field in /survey-data response`);
      }
      this.data[key] = this.data[key].concat(newData[key]);
    });
  }

  @Action({ rawError: true })
  async fetchExternalSurveys(): Promise<void> {
    const surveysTable = this.context.getters.currentWell.surveysTable;
    if(surveysTable === undefined) {
      this.context.commit(SurveyMutation.SET_EXTERNAL_SURVEYS, []);
      return;
    }

    const surveys = await queryEngine(
      'get-external-surveys',
      { surveysTable }
    );
    this.context.commit(SurveyMutation.SET_EXTERNAL_SURVEYS, surveys);
  }

  @Action({ rawError: true })
  async updateSurveyItem(payload: { md: number, updateQuery: any }): Promise<void> {
    const event = 'survey-data/patch';
    const params = {
      wellId: this.context.getters.currentWellId,
      md: payload.md,
      updateQuery: payload.updateQuery,
    };
    await queryServer(event, params);
  }

  @Action({ rawError: true })
  async deleteSurvey(md: number): Promise<void> {
    const event = 'survey-data/delete';
    const params = {
      wellId: this.context.getters.currentWellId,
      md,
    };
    await queryServer(event, params);
  }

  @Action({ rawError: true })
  async deleteSurveysInRange(mdRange: [number, number]): Promise<void> {
    const event = 'survey-data/delete';
    const params = {
      wellId: this.context.getters.currentWellId,
      mdFrom: _.min(mdRange),
      mdTo: _.max(mdRange),
    };
    await queryServer(event, params);
  }

  @Action({ rawError: true })
  async fetchSurveyData(): Promise<void> {
    const startDepth = this.context.getters.nextSurveyDepth || undefined;

    const event = 'survey-data/get';
    const params = {
      wellId: this.context.getters.currentWellId,
      from: startDepth,
    };

    if(this.context.getters.isSurveyDataCleared) {
      this.context.commit(SurveyMutation.SET_SURVEY_DATA_CLEARED, false);
    }

    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data) || _.isEmpty(resp.data.md)) {
      return;
    }

    if(this.context.getters.isSurveyDataCleared) {
      this.context.commit(SurveyMutation.SET_SURVEY_DATA_CLEARED, false);
      return;
    }

    this.context.commit(SurveyMutation.SET_NEXT_SURVEY_DEPTH, _.max(resp.data.md) + 1);

    if(this.context.getters.surveyData === undefined) {
      this.context.commit(SurveyMutation.SET_SURVEY_DATA, resp.data);
    } else {
      this.context.commit(SurveyMutation.APPEND_SURVEY_DATA, resp.data);
    }
  }
}
