













import { isRouteAllowed, RouteName } from '@/permissions';
import { getRoutePathByName } from '@/router';

import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class WellsHeader extends Vue {
  @Prop({ required: true })
  route: RouteName;

  addObject(): void {
    this.$router.push(getRoutePathByName(this.route));
  }

  get isImageDisabled(): boolean {
    return !isRouteAllowed(this.route);
  }
}
