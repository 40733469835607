import { AxisRanges, DrillingOptimizationTabAction, DrillingOptimizationTabMutation, FounderPlotMode } from './types';

import { WellId } from '@/store/modules/types';

import { queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class FounderModule extends VuexModule {
  _selectedRpmRange: [number, number] = [20, 80];
  _selectedRPM: [number, number] = [20, 80];
  _axisRanges: AxisRanges = { x: null, y: null };
  _selectedFounderMode = FounderPlotMode.ROP_VS_WOB;
  _founderSelectedFormationTopId: string | null = null;
  _founderWellIds: WellId[] = [];
  _founderData: { [key: string]: any } | null = null;
  _founderDepthData: { [key: string]: any } | null = null;

  get founderAxisRanges(): AxisRanges {
    return this._axisRanges;
  }

  @Mutation
  setFounderYaxisRange(range: [number, number] | null): void {
    this._axisRanges.y = range;
  }

  @Mutation
  setFounderXaxisRange(range: [number, number] | null): void {
    this._axisRanges.x = range;
  }

  get selectedFounderRPM(): [number, number] {
    return this._selectedRPM;
  }

  @Mutation
  setFounderRPM(rpm: [number, number]): void {
    this._selectedRPM = rpm;
  }

  get selectedFounderRpmRange(): [number, number] {
    return this._selectedRpmRange;
  }

  @Mutation
  setFounderRpmRange(range: [number, number]): void {
    this._selectedRpmRange = range;
  }

  get founderPlotMode(): FounderPlotMode {
    return this._selectedFounderMode;
  }

  @Mutation
  setFounderPlotMode(mode: FounderPlotMode): void {
    this._selectedFounderMode = mode;
  }

  get founderSelectedFormationTopId(): string | null {
    return this._founderSelectedFormationTopId;
  }

  @Mutation
  setFounderSelectedFormationTopId(id: string): void {
    this._founderSelectedFormationTopId = id;
  }

  get founderWells(): WellId[] {
    // TODO: it's hack, need to refactor
    if(this._founderWellIds.length === 0) {
      return [store.getters.currentWell._id];
    }
    return this._founderWellIds;
  }

  get founderData(): { [key: string]: any } | undefined {
    if(this._founderData === null) {
      return undefined;
    }
    return this._founderData;
  }

  get founderDepthData(): { [key: string]: any } | undefined {
    if(this._founderDepthData === null) {
      return undefined;
    }
    return this._founderDepthData;
  }

  @Mutation
  setFounderData(data: { [key: string]: any }): void {
    if(this._founderData === null || data === null) {
      this._founderData = data;
      return;
    }

    this._founderData = _.cloneDeep(data);
  }

  @Mutation
  setFounderDepthData(data: { [key: string]: any }): void {
    if(this._founderDepthData === null || data === null) {
      this._founderDepthData = data;
      return;
    }

    this._founderDepthData = _.cloneDeep(data);
  }

  @Mutation
  removeFounderWell(wellId: WellId): void {
    this._founderWellIds = this._founderWellIds.filter((id: WellId) => id !== wellId);
    delete this._founderData[wellId];
    delete this._founderDepthData[wellId];
    this._founderData = _.cloneDeep(this._founderData);
    this._founderDepthData = _.cloneDeep(this._founderDepthData);
  }

  @Action({ rawError: true })
  async addFounderWell(wellId: WellId): Promise<void> {
    this._founderWellIds.push(wellId);

    await store.dispatch(DrillingOptimizationTabAction.FETCH_FOUNDER_DATA);
    await store.dispatch(DrillingOptimizationTabAction.FETCH_FOUNDER_DEPTH_DATA);
  }

  @Action({ rawError: true })
  async fetchFounderData(): Promise<void> {
    const event = 'drilling-recommendation/get/compare';
    const params = {
      wellIds: this.context.getters.founderWells,
    };

    const resp = await queryServer(event, params);

    if(resp === undefined) {
      return;
    }

    this.context.commit(DrillingOptimizationTabMutation.SET_FOUNDER_DATA, resp.data);
  }

  @Action({ rawError: true })
  async fetchFounderDepthData(): Promise<void> {
    const event = 'depth-data/get/compare';
    const params = {
      wellIds: this.context.getters.founderWells,
      traces: ['depth', 'rpm', 'wob', 'rop', 'diffPressure', 'mse'],
      caching: false,
    };

    const resp = await queryServer(event, params);

    if(resp === undefined) {
      return;
    }
    this.context.commit(DrillingOptimizationTabMutation.SET_FOUNDER_DEPTH_DATA, resp.data);
  }
}
