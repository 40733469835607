import {
  DirectionalGuidanceSubTab, SurveySubTab, DEFAULT_SURVEY_TRACES,
  SlideSheetsTrace, DEFAULT_SLIDE_SHEETS_TRACES,
} from './types';
import { DashboardPresetAction } from '@/store/modules/dashboard_preset/types';
import { RowsRepPage } from '@/store/modules/types';

import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

import store from '@/store';

import * as _ from 'lodash';

@Module
export class DirectionalGuidanceTabModule extends VuexModule {
  _activeSubTab = DirectionalGuidanceSubTab.SURVEYS;
  _selectedSurveyMd: number | null = null;
  _selectedSurveyTraces: string[] | null = null;
  _selectedPseudoSurveyTraces: string[] | null = null;
  _selectedSlideSheetsTraces: SlideSheetsTrace[] | null = null;
  _selectedRowsAmount = RowsRepPage.ROW_20;
  _selectedPseudoRowsAmount = RowsRepPage.ROW_20;
  _surveysTablePage = 0;
  _pseudoSurveysTablePage = 0;
  _slideSheetTablePage = 0;

  _activeSubTabIsChangedByUser = false;

  get activeDirectionalGuidanceSubTab(): DirectionalGuidanceSubTab {
    return this._activeSubTab;
  }

  get directionalGuidanceSubTabIsChangedByUser(): boolean {
    return this._activeSubTabIsChangedByUser;
  }

  @Mutation
  setActiveSubTab(tab: DirectionalGuidanceSubTab): void {
    this._activeSubTab = tab;
    this._activeSubTabIsChangedByUser = true;
  }

  get selectedSurveyMd(): number | null {
    return this._selectedSurveyMd;
  }

  @Mutation
  selectSurveyMd(md: number | null): void {
    this._selectedSurveyMd = md;
  }

  get selectedSurveyTraces(): string[] {
    if(this._selectedSurveyTraces === null) {
      return _.clone(DEFAULT_SURVEY_TRACES);
    }
    return this._selectedSurveyTraces;
  }

  @Mutation
  setSelectedSurveyTraces(traces: string[]): void {
    if(traces === null || traces === undefined || traces.length === 0) {
      return;
    }
    this._selectedSurveyTraces = traces;
  }

  get selectedPseudoSurveyTraces(): string[] {
    if(this._selectedPseudoSurveyTraces === null) {
      return _.clone(DEFAULT_SURVEY_TRACES);
    }
    return this._selectedPseudoSurveyTraces;
  }

  @Mutation
  setSelectedPseudoSurveyTraces(traces: string[]): void {
    if(traces === null || traces === undefined || traces.length === 0) {
      return;
    }
    this._selectedPseudoSurveyTraces = traces;
  }

  @Mutation
  toggleSurveyTrace(payload: { trace: string, tab: SurveySubTab }): void {
    let data;
    let presetPath;
    switch(payload.tab) {
      case SurveySubTab.REAL:
        data = this._selectedSurveyTraces;
        presetPath = 'directionalGuidanceSurveys';
        break;
      case SurveySubTab.PSEUDO:
        data = this._selectedPseudoSurveyTraces;
        presetPath = 'directionalGuidancePseudoSurveys';
        break;
      default:
        throw new Error(`Unknown type of Survey Tab: ${payload.tab}`);
    }
    const traceIndex = data.indexOf(payload.trace);
    if(traceIndex !== -1) {
      data.splice(traceIndex, 1);
    } else {
      data.push(payload.trace);
    }
    store.dispatch(DashboardPresetAction.UPDATE_PRESET, { [presetPath]: data });
  }

  get selectedSlideSheetsTraces(): SlideSheetsTrace[] {
    if(this._selectedSlideSheetsTraces === null) {
      return _.clone(DEFAULT_SLIDE_SHEETS_TRACES);
    }
    return this._selectedSlideSheetsTraces;
  }

  @Mutation
  setSelectedSlideSheetsTraces(traces: SlideSheetsTrace[]): void {
    if(traces === null || traces === undefined || traces.length === 0) {
      // TODO: remove
      this._selectedSlideSheetsTraces = _.clone(DEFAULT_SLIDE_SHEETS_TRACES);
      return;
    }
    this._selectedSlideSheetsTraces = traces;
  }

  @Mutation
  toggleSlideSheetTrace(trace: SlideSheetsTrace): void {
    // TODO: remove duplications
    const traceIndex = this._selectedSlideSheetsTraces.indexOf(trace);
    if(traceIndex !== -1) {
      this._selectedSlideSheetsTraces.splice(traceIndex, 1);
    } else {
      this._selectedSlideSheetsTraces.push(trace);
    }
    store.dispatch(DashboardPresetAction.UPDATE_PRESET, { directionalGuidanceSlideSheets: this._selectedSlideSheetsTraces });
  }

  get selectedRowsAmount(): RowsRepPage {
    return this._selectedRowsAmount;
  }

  @Mutation
  setSelectedRowsAmount(amount: RowsRepPage): void {
    this._selectedRowsAmount = amount;
  }

  get selectedPseudoRowsAmount(): RowsRepPage {
    return this._selectedPseudoRowsAmount;
  }

  @Mutation
  setSelectedPseudoRowsAmount(amount: RowsRepPage): void {
    this._selectedPseudoRowsAmount = amount;
  }

  get surveysTablePage(): number {
    return this._surveysTablePage;
  }

  @Mutation
  setSurveysTablePage(page: number): void {
    this._surveysTablePage = page;
  }

  get pseudoSurveysTablePage(): number {
    return this._pseudoSurveysTablePage;
  }

  @Mutation
  setPseudoSurveysTablePage(page: number): void {
    this._pseudoSurveysTablePage = page;
  }

  get slideSheetTablePage(): number {
    return this._slideSheetTablePage;
  }

  @Mutation
  setSlideSheetTablePage(page: number): void {
    this._slideSheetTablePage = page;
  }
}
