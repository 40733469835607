















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OptionsToggle extends Vue {
  selectedOption: string = null;

  @Prop({ required: true })
  options: string[];

  get computedSelectedOption(): string {
    return this.selectedOption || this.options[0];
  }

  set computedSelectedOption(option: string) {
    this.selectedOption = option;
  }

  selectOption(option: string) {
    this.computedSelectedOption = option;
    this.$emit('input', this.computedSelectedOption);
  }
}
