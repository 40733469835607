import store from '@/store';
import router from '@/router';
import { AuthMutation } from '@/store/modules/auth/types';

const FORBIDDEN_STATUS_CODE = 403;

interface AxiosError extends Error {
  config?: { url: string, params: string, data: string };
  response?: { data: { message: string }, status: number };
}

export interface SocketAPIError extends Error {
  message: string;
  status?: number;
}

export function handleAPIError(
  err: AxiosError,
  titleMessage: string,
  silent: boolean = false
): void {
  let title = titleMessage;

  console.log(err);
  if(err.config !== undefined) {
    const url = err.config.url;

    title += `; ${url}`;
  }

  if(err.response === undefined) {
    const message = `Server at ${err.config.url} is not available`;

    if(!silent) {
      store.dispatch('alertError', { title, message });
    }
    return;
  }

  let message = err.message;
  if(err.response !== undefined) {
    message = err.response.data.message;
  }

  if(!silent) {
    store.dispatch('alertError', { title, message });
  }

  if(err.response.status === FORBIDDEN_STATUS_CODE) {
    store.commit(AuthMutation.CLEAR_TOKEN);
    router.push('/login');
  }
}

export function handleSocketApiError(
  err: SocketAPIError,
  titleMessage: string,
  silent: boolean = false
): void {
  const title = titleMessage;
  const message = err.message;
  if(!silent) {
    store.dispatch('alertError', { title, message });
  }

  if(err.status === FORBIDDEN_STATUS_CODE) {
    store.commit(AuthMutation.CLEAR_TOKEN);
    router.push('/login');
  }
}

export function handleEngineError(
  err: any,
  titleMessage: string,
  silent: boolean = false
): void {
  const title = titleMessage;
  const message = err.message;
  if(!silent) {
    store.dispatch('alertError', { title, message });
  }
}
