import { WellId } from '../types';

export enum ToolfaceType {
  GTF = 'gtf',
  MTF = 'mtf',
}

export type ToolfaceData = {
  wellId: WellId;
  time: number;
  depth: number;
  value: number;
  type: ToolfaceType;
};

export enum ToolfaceDataMutation {
  SET_TOOLFACE_DATA = 'setToolfaceData',
  SET_LAST_TIME = 'setToolfaceDataLastTime',
}

export enum ToolfaceDataAction {
  FETCH_TOOLFACE_DATA = 'fetchToolfaceData',
}
