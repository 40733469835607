import { TraceConfig } from '../types';

export type DepthData = {
  wellId: string;
  depth: number[];
  doc: number[];
  mse: number[];
  mseTorsion: number[];
  mseThrust: number[];
  sppaQ2: number[];
  docWobRatio: number[];
  drillingEfficiency: number[];
  rop: number[];
  bitAggressiveness: number[];
  wob: number[];
  rpm: number[];
  rpmDownhole: number[];
  torque: number[];
  torqueDownhole: number[];
  pumpPressure: number[];
  diffPressure: number[];
  pumpRate: number[];
  rigState: number[];
  toolface: number[];
  estimatedMD: [number, number][];
  estimatedTVD: [number, number][];
  estimatedNS: [number, number][];
  estimatedEW: [number, number][];
  estimatedVS: [number, number][];
  estimatedInclination: [number, number][];
  estimatedAzimuth: [number, number][];
  rotaryBuildRate: [number, number][];
  slidingBuildRate: [number, number][];
  rotaryTurnRate: [number, number][];
  slidingTurnRate: [number, number][];
  slideScore: [number, number][];
  formationChangeDepth: number[];
  section: DepthSection[];
  avgSlideToolface: number[];
  slideDistance: number[];
  recommendedActionDepth: number[];
  recommendedToolface: number[];
  recommendedAction: string[];
  stickSlip: number[];
  time: number[];
  bitUsage: number[];
  interpolatedTvd: number[];
  bitTFA: number[];
  bitSize: number[];
  motorRPG: number[];
  inclination: number[];
  azimuth: number[];
  tvd: number[];
  ns: number[];
  ew: number[];
  vs: number[];
  hookload: number[];
  magneticToolface: number[];
  gravityToolface: number[];
  pulseAmplitude: number[];
  pulseConfidence: number[];
  SPA: number[];
  SPL: number[];
  shockLevel: number[];
  possum: number[];
  tfds: number[];
  steer: number[];
  propeff: number[];
  prds: number[];
  in_trgt: number[];
  azi_trgt: number[];
  spm1: number[];
  spm2: number[];
  spm3: number[];
  correctedDepth: number[];
  currentDepthGap: number[];
};

export type DepthDataLastEstimatedValues = {
  estimatedAzimuth: number;
  estimatedEW: number;
  estimatedInclination: number;
  estimatedMD: number;
  estimatedNS: number;
  estimatedTVD: number;
  estimatedVS: number;
  rotaryBuildRate: number;
  slidingTurnRate: number;
  rotaryTurnRate: number;
  slidingBuildRate: number;
};

export enum ProjectionTraces {
  ESTIMATED_AZIMUTH = 'estimatedAzimuth',
  ESTIMATED_EW = 'estimatedEW',
  ESTIMATED_INCLINATION = 'estimatedInclination',
  ESTIMATED_MD = 'estimatedMD',
  ESTIMATED_NS = 'estimatedNS',
  ESTIMATED_TVD = 'estimatedTVD',
  ESTIMATED_VS = 'estimatedVS',
  ROTARY_BUILD_RATE = 'rotaryBuildRate',
  SLIDING_TURN_RATE = 'slidingTurnRate',
  ROTARY_TURN_RATE = 'rotaryTurnRate',
  SLIDING_BUIL_DRATE = 'slidingBuildRate',
}

export enum DepthDataMutation {
  SET_LAST_FETCHED_DEPTH = 'setLastFetchedDepth',
  SET_DEPTH_BORDERS = 'setDepthBorders',
  SET_DEPTH_DATA_FILTERS = 'setDepthDataFilters',
  SET_DEPTH_DATA_FILTER_OPERATOR = 'setDepthDataFilterOperator',
  SET_LAST_ESTIMATED_VALUES = 'setLastEstimatedValues',
  SET_LIVE_DEPTH_INTERVAL = 'setLiveDepthInterval',
  SET_DEPTH_DATA_BUCKET_LIMIT = 'setDepthDataBucketLimit',
  SET_SECOND_DEPTH_BORDER = 'setSecondDepthBorder',
  SET_DEPTH_HISTORICAL_DATA = 'setDepthHistoricalData',
  SET_DEPTH_LIVE_DATA = 'setDepthLiveData',
  APPEND_DEPTH_LIVE_DATA = 'appendDepthLiveData',
  COPY_DEPTH_LIVE_DATA_TO_HISTORICAL = 'copyDepthLiveDataToHistorical',
  CLEAR_DEPTH_HISTORICAL_DATA = 'clearDepthHistoricalData',
  SET_LOCAL_DEPTH_BORDERS = 'setLocalDepthBorders',
  SET_SECOND_LOCAL_DEPTH_BORDER = 'setSecondLocalDepthBorder',
  SET_DEPTH_DATA_FETCHING = 'setDepthDataFetching',
  APPEND_GAMMA_TO_LIVE_DEPTH_DATA = 'appendGammaToLiveDepthData',
}

export enum DepthDataAction {
  FETCH_DEPTH_DATA_RAW = 'fetchDepthDataRaw',
  FETCH_DEPTH_DATA_BY_INTERVAL = 'fetchDepthDataByInterval',
  FETCH_LIVE_DEPTH_DATA = 'fetchLiveDepthData',
  FETCH_HISTORICAL_DEPTH_DATA = 'fetchHistoricalDepthData',
  FETCH_HISTORICAL_DEPTH_DATA_BY_DEPTH = 'fetchHistoricalDepthDataByDepth',
  FETCH_WELL_SUMMARY_DEPTH_DATA = 'fetchWellSummaryDepthData',
  REFETCH_DEPTH_DATA = 'refetchDepthData',
}

export enum DepthSection {
  SURFACE = 'Surface',
  INTERMEDIATE_1 = 'Intermediate #1',
  INTERMEDIATE_2 = 'Intermediate #2',
  CURVE = 'Curve',
  LATERAL = 'Lateral',
}

export enum GaugeDepthTrace {
  DOC = 'doc',
  MSE = 'mse',
  MSE_TORSION = 'mseTorsion',
  MSE_THRUST = 'mseThrust',
  SPPA_Q2 = 'sppaQ2',
  DOC_WOB_RATIO = 'docWobRatio',
  DRILLING_EFFICIENCY = 'drillingEfficiency',
  ROP = 'rop',
  BIT_AGGRESSIVENESS = 'bitAggressiveness',
  WOB = 'wob',
  RPM = 'rpm',
  RPM_DOWNHOLE = 'rpmDownhole',
  TORQUE = 'torque',
  TORQUE_DOWNHOLE = 'torqueDownhole',
  PUMP_PRESSURE = 'pumpPressure',
  DIFF_PRESSURE = 'diffPressure',
  PUMP_RATE = 'pumpRate',
  RIG_STATE = 'rigState',
  TOOLFACE = 'toolface',
  SLIDE_SCORE = 'slideScore',
  FORMATION_CHANGE_DEPTH = 'formationChangeDepth',
  AVG_SLIDE_TOOLFACE = 'avgSlideToolface',
  SLIDE_DISTANCE = 'slideDistance',
  BIT_USAGE = 'bitUsage',
  INTERPOLATED_TVD = 'interpolatedTvd',
  BIT_TFA = 'bitTFA',
  BIT_SIZE = 'bitSize',
  MOTOR_RPG = 'motorRPG',
  INCLINATION = 'inclination',
  AZIMUTH = 'azimuth',
  TVD = 'tvd',
  NS = 'ns',
  EW = 'ew',
  VS = 'vs',
  HOOKLOAD = 'hookload',
  MAGNETIC_TOOLFACE = 'magneticToolface',
  GRAVITY_TOOLFACE = 'gravityToolface',
  PULSE_AMPLITUDE = 'pulseAmplitude',
  PULSE_CONFIDENCE = 'pulseConfidence',
  SPA = 'SPA',
  SPL = 'SPL',
  SHOCK_LEVEL = 'shockLevel',
  POSSUM = 'possum',
  TFDS = 'tfds',
  STEER = 'steer',
  PROPEFF = 'propeff',
  PRDS = 'prds',
  IN_TRGT = 'in_trgt',
  AZI_TRGT = 'azi_trgt',
  SPM1 = 'spm1',
  SPM2 = 'spm2',
  SPM3 = 'spm3',
  STICK_SLIP = 'stickSlip',
  RECOMMENDED_RPM = 'recommendedRPM',
  RECOMMENDED_WOB = 'recommendedWOB',
  RECOMMENDED_DIFF_PRESSURE = 'recommendedDiffPressure',
  RECOMMENDED_FLOW_RATE = 'recommendedFlowRate',
  RECOMMENDED_EFFICIENCY = 'recommendedEfficiency',
  RECOMMENDED_ROP = 'recommendedROP',
  RECOMMENDED_MSE = 'recommendedMSE',
  RECOMMENDED_ACTION_DEPTH = 'recommendedActionDepth',
  RECOMMENDED_TOOLFACE = 'recommendedToolface',
}

export enum DepthTrace {
  DEPTH = 'depth',
  DOC = 'doc',
  MSE = 'mse',
  MSE_TORSION = 'mseTorsion',
  MSE_THRUST = 'mseThrust',
  SPPA_Q2 = 'sppaQ2',
  DOC_WOB_RATIO = 'docWobRatio',
  DRILLING_EFFICIENCY = 'drillingEfficiency',
  ROP = 'rop',
  BIT_AGGRESSIVENESS = 'bitAggressiveness',
  WOB = 'wob',
  RPM = 'rpm',
  RPM_DOWNHOLE = 'rpmDownhole',
  TORQUE = 'torque',
  TORQUE_DOWNHOLE = 'torqueDownhole',
  PUMP_PRESSURE = 'pumpPressure',
  DIFF_PRESSURE = 'diffPressure',
  PUMP_RATE = 'pumpRate',
  RIG_STATE = 'rigState',
  TOOLFACE = 'toolface',
  SLIDE_SCORE = 'slideScore',
  FORMATION_CHANGE_DEPTH = 'formationChangeDepth',
  AVG_SLIDE_TOOLFACE = 'avgSlideToolface',
  SLIDE_DISTANCE = 'slideDistance',
  BIT_USAGE = 'bitUsage',
  INTERPOLATED_TVD = 'interpolatedTvd',
  BIT_TFA = 'bitTFA',
  BIT_SIZE = 'bitSize',
  MOTOR_RPG = 'motorRPG',
  INCLINATION = 'inclination',
  AZIMUTH = 'azimuth',
  TVD = 'tvd',
  NS = 'ns',
  EW = 'ew',
  VS = 'vs',
  HOOKLOAD = 'hookload',
  MAGNETIC_TOOLFACE = 'magneticToolface',
  GRAVITY_TOOLFACE = 'gravityToolface',
  PULSE_AMPLITUDE = 'pulseAmplitude',
  PULSE_CONFIDENCE = 'pulseConfidence',
  SPA = 'SPA',
  SPL = 'SPL',
  SHOCK_LEVEL = 'shockLevel',
  POSSUM = 'possum',
  TFDS = 'tfds',
  STEER = 'steer',
  PROPEFF = 'propeff',
  PRDS = 'prds',
  IN_TRGT = 'in_trgt',
  AZI_TRGT = 'azi_trgt',
  SPM1 = 'spm1',
  SPM2 = 'spm2',
  SPM3 = 'spm3',
  STICK_SLIP = 'stickSlip',

  RECOMMENDED_RPM = 'recommendedRPM',
  RECOMMENDED_WOB = 'recommendedWOB',
  RECOMMENDED_DIFF_PRESSURE = 'recommendedDiffPressure',
  RECOMMENDED_FLOW_RATE = 'recommendedFlowRate',
  RECOMMENDED_EFFICIENCY = 'recommendedEfficiency',
  RECOMMENDED_ROP = 'recommendedROP',
  RECOMMENDED_MSE = 'recommendedMSE',
  RECOMMENDED_ACTION_DEPTH = 'recommendedActionDepth',
  RECOMMENDED_TOOLFACE = 'recommendedToolface',

  GAMMA = 'gamma',

  SURVEY_INCLINATION = 'surveyInclination',
  SURVEY_AZIMUTH = 'surveyAzimuth',
  SURVEY_NS = 'surveyNs',
  SURVEY_EW = 'surveyEw',
  SURVEY_VS = 'surveyVs',
  SURVEY_TVD = 'surveyTvd',

  DM_LIVE_INCLINATION = 'dmLiveInclination',
  DM_LIVE_AZIMUTH = 'dmLiveAzimuth',
  DM_TEMP = 'dmTemp',
  DM_INTERPOLATED_TVD = 'dmInterpolatedTvd',

  PULSER_PINC = 'pulserPinc',
}

export const DEPTH_TRACE_TO_NAME_AND_UNIT_MAPPING: { [trace: string]: TraceConfig } = {
  [DepthTrace.DEPTH]: { name: 'Depth', unit: 'ft', min: 0, max: 40000 },
  [DepthTrace.DOC]: { name: 'DOC', unit: 'in/rev', min: 0, max: 10 },
  [DepthTrace.MSE]: { name: 'MSE', unit: 'kpsi', min: 0, max: 100 },
  [DepthTrace.MSE_TORSION]: { name: 'MSE Torsion', unit: 'kpsi', min: 0, max: 100 },
  [DepthTrace.MSE_THRUST]: { name: 'MSE Thrust', unit: 'kpsi', min: 0, max: 100 },
  [DepthTrace.SPPA_Q2]: { name: 'SPPA Q2', unit: '', min: 0, max: 1 },
  [DepthTrace.DOC_WOB_RATIO]: { name: 'DOC / WOB Ratio', unit: '', min: 0, max: 1 },
  [DepthTrace.DRILLING_EFFICIENCY]: { name: 'Drilling Efficiency', unit: '%', min: 0, max: 100 },
  [DepthTrace.ROP]: { name: 'ROP', unit: 'ft/hr', min: 0, max: 1000 },
  [DepthTrace.BIT_AGGRESSIVENESS]: { name: 'Bit Aggressiveness', unit: '', min: 0, max: 1 },
  [DepthTrace.WOB]: { name: 'WOB', unit: 'klb', min: 0, max: 100 },
  [DepthTrace.RPM]: { name: 'RPM', unit: 'rpm', min: 0, max: 200 },
  [DepthTrace.RPM_DOWNHOLE]: { name: 'RPM Downhole', unit: 'rpm', min: 0, max: 200 },
  [DepthTrace.TORQUE]: { name: 'Torque', unit: 'ft.lb', min: 0, max: 50000 },
  [DepthTrace.TORQUE_DOWNHOLE]: { name: 'Torque Downhole', unit: 'ft.lb', min: 0, max: 50000 },
  [DepthTrace.PUMP_PRESSURE]: { name: 'Pump Pressure', unit: 'psi', min: 0, max: 10000 },
  [DepthTrace.DIFF_PRESSURE]: { name: 'Diff Pressure', unit: 'psi', min: 0, max: 5000 },
  [DepthTrace.PUMP_RATE]: { name: 'Pump Rate', unit: 'gpm', min: 0, max: 1000 },
  [DepthTrace.RIG_STATE]: { name: 'Rig State', unit: '', min: 0, max: 19 },
  [DepthTrace.TOOLFACE]: { name: 'Toolface', unit: 'deg', min: 0, max: 360 },
  [DepthTrace.FORMATION_CHANGE_DEPTH]: { name: 'Formation Change', unit: 'ft.lb', min: 0, max: 20000 },
  [DepthTrace.AVG_SLIDE_TOOLFACE]: { name: 'Average Slide Toolface', unit: 'deg', min: 0, max: 360 },
  [DepthTrace.SLIDE_DISTANCE]: { name: 'Slide Distance', unit: 'ft', min: 0, max: 100 },
  [DepthTrace.BIT_USAGE]: { name: 'Bit Usage', unit: 'ft', min: 0, max: 30000 },
  [DepthTrace.INTERPOLATED_TVD]: { name: 'Interpolated TVD', unit: 'ft', min: 0, max: 20000 },
  [DepthTrace.BIT_TFA]: { name: 'Bit TFA', unit: 'in^2', min: 0, max: 2 },
  [DepthTrace.BIT_SIZE]: { name: 'Bit Size', unit: 'in', min: 0, max: 20 },
  [DepthTrace.MOTOR_RPG]: { name: 'Motor RPG', unit: 'rev/gal', min: 0, max: 1 },
  [DepthTrace.INCLINATION]: { name: 'Inclination', unit: 'deg', min: 0, max: 360 },
  [DepthTrace.AZIMUTH]: { name: 'Azimuth', unit: 'deg', min: 0, max: 360 },
  [DepthTrace.TVD]: { name: 'TVD', unit: 'ft', min: 0, max: 20000 },
  [DepthTrace.NS]: { name: 'NS', unit: 'ft', min: 0, max: 20000 },
  [DepthTrace.EW]: { name: 'EW', unit: 'ft', min: 0, max: 20000 },
  [DepthTrace.VS]: { name: 'VS', unit: 'ft', min: 0, max: 20000 },
  [DepthTrace.HOOKLOAD]: { name: 'Hookload', unit: 'klb', min: 0, max: 500 },
  [DepthTrace.MAGNETIC_TOOLFACE]: { name: 'Magnetic Toolface', unit: 'deg', min: 0, max: 360 },
  [DepthTrace.GRAVITY_TOOLFACE]: { name: 'Gravity Toolface', unit: 'deg', min: 0, max: 360 },
  [DepthTrace.PULSE_AMPLITUDE]: { name: 'Pulse Amplitude', unit: '', min: 0, max: 10 },
  [DepthTrace.PULSE_CONFIDENCE]: { name: 'Pulse Confidence', unit: '', min: 0, max: 10 },
  [DepthTrace.SPA]: { name: 'SPA', unit: '', min: 0, max: 100 },
  [DepthTrace.SPL]: { name: 'SPL', unit: '', min: 0, max: 100 },
  [DepthTrace.SHOCK_LEVEL]: { name: 'Shock Level', unit: '', min: 0, max: 100 },
  [DepthTrace.POSSUM]: { name: 'Possum', unit: '', min: 0, max: 100 },
  [DepthTrace.TFDS]: { name: 'TFDS', unit: '', min: 0, max: 360 },
  [DepthTrace.STEER]: { name: 'Steer', unit: '', min: 0, max: 360 },
  [DepthTrace.PROPEFF]: { name: 'Propeff', unit: '', min: 0, max: 100 },
  [DepthTrace.PRDS]: { name: 'PRDS', unit: '', min: 0, max: 100 },
  [DepthTrace.IN_TRGT]: { name: 'Inc Target', unit: 'deg', min: 0, max: 360 },
  [DepthTrace.AZI_TRGT]: { name: 'Azi Target', unit: 'deg', min: 0, max: 360 },
  [DepthTrace.SPM1]: { name: 'SPM 1', unit: 'spm', min: 0, max: 1000 },
  [DepthTrace.SPM2]: { name: 'SPM 2', unit: 'spm', min: 0, max: 1000 },
  [DepthTrace.SPM3]: { name: 'SPM 3', unit: 'spm', min: 0, max: 1000 },
  [DepthTrace.SLIDE_SCORE]: { name: 'Slide Score', unit: '%', min: 0, max: 100 },
  [DepthTrace.STICK_SLIP]: { name: 'Stick Slip', unit: '', min: 0, max: 200 },
  [DepthTrace.RECOMMENDED_RPM]: { name: 'Rec. RPM', unit: 'rpm', min: 0, max: 200 },
  [DepthTrace.RECOMMENDED_WOB]: { name: 'Rec. WOB', unit: 'klb', min: 0, max: 100 },
  [DepthTrace.RECOMMENDED_DIFF_PRESSURE]: { name: 'Rec. Diff Pressure', unit: 'psi', min: 0, max: 5000 },
  [DepthTrace.RECOMMENDED_FLOW_RATE]: { name: 'Rec. Flow Rate', unit: 'gpm', min: 0, max: 1000 },
  [DepthTrace.RECOMMENDED_EFFICIENCY]: { name: 'Rec. Efficiency', unit: '%', min: 0, max: 100 },
  [DepthTrace.RECOMMENDED_ROP]: { name: 'Rec. ROP', unit: 'ft/hr', min: 0, max: 1000 },
  [DepthTrace.RECOMMENDED_MSE]: { name: 'Rec. MSE', unit: 'kpsi', min: 0, max: 100 },
  [DepthTrace.RECOMMENDED_ACTION_DEPTH]: { name: 'Rec. Action Depth', unit: 'ft', min: 0, max: 20000 },
  [DepthTrace.RECOMMENDED_TOOLFACE]: { name: 'Rec. Toolface', unit: 'deg', min: 0, max: 360 },
  [DepthTrace.GAMMA]: { name: 'Gamma', unit: 'mEv', min: 0, max: 200 },
  [DepthTrace.SURVEY_INCLINATION]: { name: 'Survey: Inclination', unit: 'deg', min: 0, max: 360 },
  [DepthTrace.SURVEY_AZIMUTH]: { name: 'Survey: Azimuth', unit: 'deg', min: 0, max: 360 },
  [DepthTrace.SURVEY_NS]: { name: 'Survey: NS', unit: 'ft', min: 0, max: 20000 },
  [DepthTrace.SURVEY_EW]: { name: 'Survey: EW', unit: 'ft', min: 0, max: 20000 },
  [DepthTrace.SURVEY_VS]: { name: 'Survey: WS', unit: 'ft', min: 0, max: 20000 },
  [DepthTrace.SURVEY_TVD]: { name: 'Survey: TVD', unit: 'ft', min: 0, max: 20000 },
  [DepthTrace.DM_LIVE_INCLINATION]: { name: 'DM: Live Inclination', unit: 'deg', min: 0, max: 360 },
  [DepthTrace.DM_LIVE_AZIMUTH]: { name: 'DM: Live Azimuth', unit: 'deg', min: 0, max: 360 },
  [DepthTrace.DM_TEMP]: { name: 'DM: Temp', unit: '', min: 0, max: 20000 },
  [DepthTrace.DM_INTERPOLATED_TVD]: { name: 'DM: Interpolated TVD', unit: 'ft', min: 0, max: 20000 },
  [DepthTrace.PULSER_PINC]: { name: 'Pulser: PINC', unit: 'deg', min: 0, max: 360 },
};

export const RigStateLabels = {
  0: 'Other',
  1: 'Rotary Drilling',
  2: 'Slide Drilling',
  3: 'In Slips Circulating',
  4: 'In Slips Non-circulating',
  5: 'Stationary Circulation',
  6: 'Washing Down',
  7: 'Washing Up',
  8: 'Stationary Reaming With Circulation',
  9: 'Reaming In With Circulation',
  10: 'Reaming Out With Circulation',
  11: 'Stationary On Bottom',
  12: 'Stationary Off Bottom',
  13: 'Slack Off Without Circulation',
  14: 'Pick Up Without Circulation',
  15: 'Stationary Reaming Without Circulation',
  16: 'Reaming In Without Circulation',
  17: 'Reaming Out Without Circulation',
  18: 'Tripping In',
  19: 'Tripping Out',
};

export enum LiveModeDepthInterval {
  FT_1000 = 1000,
  FT_500 = 500,
  FT_250 = 250,
  FT_100 = 100,
}
