






































































import { handleAPIError } from './services/error_service';
import { AuthAction } from './store/modules/auth/types';
import AuthorizedLayout from './views/AuthorizedLayout.vue';
import Preloader from '@/components/Preloader.vue';
import Tooltip from '@/components/Tooltip.vue';
import GlobalTooltip from '@/components/GlobalTooltip.vue';

import { AppMutation, LeftTab } from '@/store/modules/app/types';
import { DirectionalGuidanceSubTab, DirectionalGuidanceTabMutation } from '@/store/modules/tabs/left_tabs/directional_guidance_tab/types';
import { NotificationAction } from '@/store/modules/notification/types';
import { DirectionalRecommendationMutation } from './store/modules/directional_recommendation/types';

import { RouteName } from './permissions';
import { POSTHOG_API_KEY, PRODUCT_FRUITS_API_KEY } from './config';
import { getRandomInt } from './utils';

import posthog from 'posthog-js';
import { productFruits } from 'product-fruits';

import { Component, Vue, Watch } from 'vue-property-decorator';
import * as _ from 'lodash';

@Component({ components: { Preloader, AuthorizedLayout, Tooltip, GlobalTooltip } })
export default class App extends Vue {
  loading = false;

  @Watch('isRobot', { immediate: true })
  onIsRobotChange(): void {
    if(this.isRobot) {
      document.body.classList.add('is-robot');
    }
  }

  get isRobot(): boolean {
    return this.$store.getters.wellSummaryRobot;
  }

  get isTooltipDisplayed(): boolean {
    return this.$store.getters.isTooltipDisplayed;
  }

  get tooltipX(): number {
    return this.$store.getters.tooltipX;
  }

  get tooltipY(): number {
    return this.$store.getters.tooltipY;
  }

  get tooltipContent(): string {
    return this.$store.getters.tooltipContent;
  }

  get isLoggedIn(): boolean {
    return this.$store.getters.isLoggedIn;
  }

  get leftTabTooltips(): Tooltip {
    return this.$store.getters.leftTabTooltips;
  }

  get isLeftTabTooltipDisplayed(): boolean {
    return this.$store.getters.isLeftTabTooltipsDisplayed;
  }

  get isPdfViewRouteActive(): boolean {
    return this.isDailyPdfActive || this.isPdfMultiWellsRouteActive;
  }

  get routeName(): string {
    return this.$route.name;
  }

  @Watch('routeName')
  onRouteName(): void {
    // TODO: replace it with click listener
    this.$store.commit(AppMutation.SET_GLOBAL_TOOLTIP, { isVisible: false, positionX: 0, positionY: 0, content: '' });
  }

  get isPdfMultiWellsRouteActive(): boolean {
    return this.$route.name === RouteName.PDF_MULTI_WELLS;
  }

  get isWellSummaryRouteActive(): boolean {
    return this.$route.name === RouteName.WELL_SUMMARY;
  }

  get isWellsCompareRouteActive(): boolean {
    return this.$route.name === RouteName.WELLS_COMPARE;
  }

  get isMWDToolCheckRouteActive(): boolean {
    return this.$route.name === RouteName.MWD_TOOL_CHECK;
  }

  get isDailyPdfActive(): boolean {
    return this.$route.name === RouteName.DAILY_REPORTS;
  }

  getLeftTabTooltipX(idx: number): number {
    return this.leftTabTooltips[idx].x;
  }

  getLeftTabTooltipY(idx: number): number {
    return this.$store.getters.leftTabTooltipY;
  }

  getLeftTabTooltipContent(idx: number): string {
    return this.leftTabTooltips[idx].content;
  }

  onRouterLoadingChange(loading: boolean): void {
    this.loading = loading;
  }

  globalMouseMove(event): void {
    const targetTooltipAttr = event?.target?.getAttribute('global-tooltip');
    const parentTooltipAttr = event?.target?.parentElement?.getAttribute('global-tooltip');
    if(_.isNil(targetTooltipAttr) && _.isNil(parentTooltipAttr)) {
      this.$store.commit(AppMutation.SET_GLOBAL_TOOLTIP, { isVisible: false, positionX: 0, positionY: 0, content: '' });
      return;
    }
    const target = _.isNil(targetTooltipAttr) ? event.target.parentElement : event.target;
    const content = target.getAttribute('tooltip-content');
    const offsetX = target.getAttribute('tooltip-offset-x') || 0;
    const offsetY = target.getAttribute('tooltip-offset-y') || 0;
    const rect = target.getBoundingClientRect();
    // TODO: add check for window borders overlapping
    // "10" value is hardcoded to look nicer
    const positionX = rect.x - 10;
    const positionY = rect.y - rect.height - 10;
    this.$store.commit(AppMutation.SET_GLOBAL_TOOLTIP, {
      isVisible: true, positionX, positionY, content, offsetX: +offsetX, offsetY: +offsetY,
    });
  }

  async created(): Promise<void> {
    try {
      this.loading = true;
      await this.$store.dispatch(AuthAction.CHECK_SERVER_CONNECTION);
      // TODO: add debounce or smth
      document.body.addEventListener('mousemove', this.globalMouseMove);
      this.loading = false;

      if(POSTHOG_API_KEY) {
        // eslint-disable-next-line camelcase
        posthog.init(POSTHOG_API_KEY, { api_host: 'https://app.posthog.com' });
      }

      if(PRODUCT_FRUITS_API_KEY) {
        let uniqueId = localStorage.getItem('guestUid');
        if(!uniqueId) {
          uniqueId = getRandomInt(10000, 99999).toString();
          localStorage.setItem('guestUid', uniqueId);
        }
        productFruits.init(PRODUCT_FRUITS_API_KEY, 'en', { username: `guest_${uniqueId}` });
      }
    } catch(e) {
      const silent = true;
      handleAPIError(e, `Can't connect to server`, silent);
      this.loading = false;
    }
  }

  async closeNotification(item, close): Promise<void> {
    await this.$store.dispatch(NotificationAction.CHANGE_NOTIFICATION_VISIBILITY, { id: item.id, hidden: true });
    close();
  }

  // TODO: type
  async applyNotification(item, close): Promise<void> {
    // go to recommendation tab
    this.$store.commit(AppMutation.SET_ACTIVE_LEFT_TAB, LeftTab.DIRECTIONAL_GUIDANCE);
    this.$store.commit(DirectionalGuidanceTabMutation.SET_ACTIVE_SUB_TAB, DirectionalGuidanceSubTab.RECOMMENDATIONS);
    // select survey for recommendation
    this.$store.commit(DirectionalGuidanceTabMutation.SELECT_SURVEY_MD, item.data.md);
    this.$store.commit(DirectionalRecommendationMutation.UNSELECT_RECOMMENDATIONS);

    await this.closeNotification(item, close);
  }
}
