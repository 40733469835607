import { WellId } from '@/store/modules/types';

export enum DailyReportMutation {
  SET_SELECTED_DATE = 'setSelectedDate',
  SET_DAILY_REPORTS = 'setDailyReports',
  INIT_TIME_RANGE = 'initTimeRange',
  SET_ADD_DAILY_REPORT_MODAL_ACTIVE = 'setAddDailyReportModalActive',
  APPEND_DAILY_REPORTS = 'appendDailyReports',
}

export enum DailyReportAction {
  FETCH_DAILY_REPORTS = 'fetchDailyReports',
  UPDATE_DAILY_REPORT = 'updateDailyReport',
  CREATE_DAILY_REPORT = 'createDailyReport',
  DELETE_DAILY_REPORT = 'deleteDailyReport',
}

export enum ReportOption {
  BHA = 'bha',
  DRILLING_FLUIDS = 'drilling fluids',
  DRILLING_SUMMARY = 'drilling summary',
  DRILLING_PERFORMANCE = 'drilling performance',
  RIG_ACTIVITY = 'rig activity',
}

export type ReportConfig = {
  label: string;
  visible: boolean;
};

export type ReportConfigs = {
  [ReportOption.BHA]: ReportConfig;
  [ReportOption.DRILLING_FLUIDS]: ReportConfig;
  [ReportOption.DRILLING_SUMMARY]: ReportConfig;
  [ReportOption.DRILLING_PERFORMANCE]: ReportConfig;
  [ReportOption.RIG_ACTIVITY]: ReportConfig;
};

export enum Phase {
  TH = 'Top Hole',
  TAN = 'Tangent',
  CRV = 'Curve',
  LAT = 'Lateral',
  RM = 'Rig Move',
  CSG = 'Casing',
}

export enum Activity {
  '1A' = 'Moving rig with trucks',
  '1B' = 'Rig up, trucks released, ready to pick up tools',
  '1C' = 'Rigging down prior to Rig Release',
  '1D' = 'Rig down after Rig Release',
  '1E' = 'Skid or walk rig',
  '1F' = 'Rig up / Rig down top drive',
  '1G' = 'Clean mud system or mud pits',
  '1H' = 'Skidding Rig',
  '1I' = 'Rig up / Tear Down',
  '2A' = 'Drill vertical hole',
  '2B' = 'Drill deviated hole, directional well only',
  '2C' = 'Drill curve',
  '2D' = 'Drill lateral',
  '2E' = 'Control drilling - Sidetrack, whipstock, kick off cement',
  '2F' = 'Drill shoe track / floats',
  '2G' = 'Drill curve sliding',
  '2H' = 'Drill lateral sliding',
  '2I' = 'Drill vertical sliding',
  '2J' = 'Slide Drilling',
  '2K' = 'Rotary Drilling',
  '3A' = 'Reaming undergauge hole',
  '3B' = 'Hole Problems',
  '3C' = 'Reaming fill to bottom',
  '3D' = 'Back Reaming',
  '4A' = 'Handling of core tools at surface',
  '4B' = 'Actual on bottom Coring time',
  '4C' = 'Trip out for core barrels or tool',
  '4D' = 'Trip in with core tools or equipment',
  '4E' = 'Wash or ream with core bbls on trip, incl. circ. and drop ball',
}

export type DailyReport = {
  wellId: WellId;
  startTime: number;
  endTime: number;
  hours: number;
  startMD: number;
  endMD: number;
  bha: string | null;
  phase: Phase | null;
  activity: Activity | null;
  description: string;
  _id?: string;
};

export const DEFAULT_DAILY_REPORT = {
  wellId: '',
  startTime: 0,
  endTime: 0,
  hours: 0,
  startMD: 0,
  endMD: 0,
  bha: null,
  phase: null,
  activity: null,
  description: '',
};
