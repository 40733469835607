import { ToolfaceType } from '../toolface_data/types';
import { Activity } from '../types';

export enum SlideSheetMutation {
  SET_SLIDE_SHEETS = 'setSlideSheets',
  CLEAR_SLIDE_SHEETS = 'clearSlideSheets',
  SET_LAST_DEPTH = 'setSlideSheetsLastDepth',
  REMOVE_SLIDE_SHEET = 'removeSlideSheet',
}

export enum SlideSheetAction {
  FETCH_SLIDE_SHEETS = 'fetchSlideSheets',
  REFETCH_SLIDE_SHEETS = 'refetchSlideSheets',
  DELETE_SLIDE_SHEET = 'deleteSlideSheet',
  ADD_SLIDE_SHEET = 'addSlideSheet',
  UPDATE_SLIDE_SHEET = 'updateSlideSheet',
}

export type SlideSheet = {
  wellId: string;
  startTime: number;
  endTime: number;
  startMD: number;
  endMD: number;
  activity: Activity;
  trueSlideDistance: number;
  targetDistance: number;
  averageToolface: number;
  targetToolface: number;
  toolfaceEfficiency: number;
  targetScore: number;
  averageROP: number;
  averageWOB: number;
  averageDiffPressure: number;
  toolfaceType: ToolfaceType;
  _id: string;
};
