import { ToolfaceData, ToolfaceDataMutation } from './types';

import { queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

const MWD_TAIL_LENGTH = 10;

@Module
export class ToolfaceDataModule extends VuexModule {
  _toolfaceData: ToolfaceData[] | null = null;
  _time: number | undefined;

  get toolfaceDataTail(): ToolfaceData[] | undefined {
    if(this._toolfaceData === null) {
      return undefined;
    }
    return this._toolfaceData;
  }

  @Mutation
  setToolfaceData(toolfaceData: ToolfaceData[]): void {
    if(toolfaceData === undefined || toolfaceData.length === 0) {
      return;
    }
    const timeList = _.map(toolfaceData, (el: ToolfaceData) => el.time);
    const lastTime = _.max(timeList);
    store.commit(ToolfaceDataMutation.SET_LAST_TIME, lastTime);

    if(this._toolfaceData === null) {
      this._toolfaceData = _.takeRight(toolfaceData, MWD_TAIL_LENGTH);
    } else {
      this._toolfaceData = _.takeRight([...this._toolfaceData, ...toolfaceData], MWD_TAIL_LENGTH);
    }
  }

  @Mutation
  setToolfaceDataLastTime(time: number): void {
    this._time = time;
  }

  @Action({ rawError: true })
  async fetchToolfaceData(): Promise<void> {
    let from = this._time;
    if(from !== undefined) {
      from += 1;
    }

    const event = 'toolface-data/get';
    const params = {
      wellId: this.context.getters.currentWellId,
      from,
      limit: 10,
    };

    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }

    this.context.commit(ToolfaceDataMutation.SET_TOOLFACE_DATA, resp.data);
  }
}
