import { handleEngineError, handleSocketApiError, SocketAPIError } from './error_service';

import { SERVER_URL } from '@/config';
import store from '@/store';
import router from '@/router';

import * as Sentry from '@sentry/vue';

import SocketIO, { Socket } from 'socket.io-client';

// TODO: SocketService class
let apiSocket: Socket;
let apiToken = '';

type SocketResp = {
  err: boolean;
  message?: string;
  data?: any;
};

type SocketAPIResp = {
  data?: any;
  err?: SocketAPIError;
};

export function createServerSocket(): Promise<void> {
  return new Promise<void>((resolve: any, reject: any) => {
    apiSocket = SocketIO(SERVER_URL, { auth: { token: apiToken }, query: { wellId: store.getters.currentWellId || router.currentRoute.name }});

    apiSocket.on('connect', () => { resolve(); });
    apiSocket.on(
      'connect_error',
      (err: Error) => { handleSocketApiError(err, 'Server connection error'); reject(); }
    );
  });
}

export async function queryServer(message: string, payload: any): Promise<any | undefined> {
  if(apiSocket === undefined) {
    await createServerSocket();
  }

  const resp = await new Promise<SocketAPIResp>((resolve: any) => {
    apiSocket.emit(
      message,
      payload,
      (resp: any) => resolve(resp)
    );
  });

  if(resp?.err !== undefined) {
    handleSocketApiError(resp.err, `${message} error`);
    Sentry.captureException(new Error(resp.err.message), { tags: { eventName: message }});
    return;
  }
  return resp;
}

export function setToken(token: string): void {
  apiToken = token;
}

export async function queryEngine(message: string, payload: any): Promise<any | undefined> {
  if(!apiSocket?.connected) {
    await createServerSocket();
  }
  try {
    const resp = await new Promise<SocketResp>((resolve: any) => {
      apiSocket.emit(
        'engine',
        {
          message,
          payload,
        },
        (resp: any) => {
          return resolve(resp);
        }
      );
    });
    console.log(resp);
    if(resp.data === undefined || (resp.err !== undefined && resp.err === true)) {
      throw new Error(`${resp.message}`);
    }
    return resp.data;
  } catch(e) {
    const titleMessage = `Error from Engine for ${message} event`;
    handleEngineError(e, titleMessage);
  }
}

export async function emitEngineEvent(message: string, payload: any): Promise<void> {
  queryServer('engine', { message, payload });
}

export async function subscribeToEngineEvent(event: string, callback: (payload?: any) => any): Promise<void> {
  apiSocket.on(event, callback);
}

export async function subscribeOnceToEngineEvent(event: string, callback: (payload?: any) => any): Promise<void> {
  apiSocket.once(event, callback);
}

export async function unsubscribeFromEngineEvent(event: string): Promise<void> {
  apiSocket.off(event);
}

export function subscribeToServerEvent(event: string, callback: (payload?: any) => any): void {
  if(apiSocket === undefined) {
    createServerSocket();
  }

  apiSocket.on(event, callback);
}

export function subscribeOnceToServerEvent(event: string, callback: (payload?: any) => any): void {
  if(apiSocket === undefined) {
    createServerSocket();
  }

  apiSocket.once(event, callback);
}
