import { handleAPIError } from './error_service';

import store from '@/store';
import { SERVER_URL } from '@/config';

import axios, { AxiosResponse } from 'axios';

export type Query = {
  url: string;
  // POST is supported because of /api/md-range endpoint
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE';
  // for GET
  params?: any;
  // for POST, PATCH, DELETE
  data?: any;
  // to specify returned data type
  responseType?: any;
  timeout?: any;
};

export async function queryApi(query: Query, silent = false): Promise<AxiosResponse | undefined> {
  try {
    if(query.method === 'GET' || query.method === 'DELETE') {
      if(query.params !== undefined && query.params.wellId === undefined) {
        query.params.wellId = store.getters.currentWellId;
      }
    }
    if(query.method === 'POST' || query.method === 'PATCH') {
      if(query.params !== undefined) {
        query.data.wellId = store.getters.currentWellId;
      }
    }
    const resp = await axios({
      timeout: 60 * 1000, // ms
      ...query,
      url: `${SERVER_URL}/${query.url}`,
    });

    return resp;
  } catch(e) {
    if(e.response !== undefined && e.response.status === 404) {
      return;
    }
    const titleMessage = `API query error`;
    handleAPIError(e, titleMessage, silent);
  }
}
