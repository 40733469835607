export enum FrictionFactorEstimateMutation {
  SET_FRICTION_FACTOR_ESTIMATES = 'setFrictionFactorEstimates',
  CLEAR_FRICTION_FACTOR_ESTIMATES = 'clearFrictionFactorEstimates',
  SET_LAST_TIME = 'setFrictionFactorEstimatesLastTime',
}

export enum FrictionFactorEstimateAction {
  FETCH_FRICTION_FACTOR_ESTIMATES = 'fetchFrictionFactorEstimates',
  DELETE_FRICTION_FACTOR_ESTIMATE = 'deleteFrictionFactorEstimate',
}

export type FrictionFactorEstimate = {
  wellId: string;
  time: number;
  depth: number;
  casedHole: number;
  openHole_slackOff: number;
  openHole_pickUp: number;
  openHole_rotation: number;
  _id: string;
};
