

















































































import Modal from './Modal.vue';
import DepthFilter from '@/components/DepthFilter.vue';
import TimeFilter from '@/components/TimeFilter.vue';
import Slider from '@/components/sliders/Slider.vue';

import { SectionRanges } from '@/store/modules/well/types';

import { Component, Vue, Prop } from 'vue-property-decorator';

import * as _ from 'lodash';

@Component({ components: { Modal, DepthFilter, TimeFilter, Slider } })
export default class FilterModal extends Vue {
  @Prop({ required: true })
  isModalActive: boolean;

  @Prop({ required: true })
  id: string;

  @Prop({ required: false, default: null })
  initBorders: [number, number] | null;

  @Prop({ required: false, default: null })
  initValues: [number, number] | null;

  @Prop({ required: false, default: true })
  isSliderVisible: boolean;

  @Prop({ required: false, default: true })
  isFormationSelectorVisible: boolean;

  @Prop({ required: false, default: true })
  isSectionSelectorVisible: boolean;

  @Prop({ required: false, default: false })
  isMwdSelectorVisible: boolean;

  @Prop({ required: false, default: false })
  isRpmVisible: boolean;

  @Prop({ required: false, default: true })
  isClearBtnVisible: boolean;

  @Prop({ required: false })
  rpmRange: [number, number];

  @Prop({ required: false })
  rpm: [number, number];

  @Prop({ required: false })
  x!: number;

  @Prop({ required: false })
  y!: number;

  @Prop({ required: false, default: false })
  windowCenter: boolean;

  @Prop({ required: false, default: false })
  tabRelated: boolean;

  @Prop({ required: false })
  placeHolder: string;

  @Prop({ required: false, default: false })
  closeOnApply: boolean;

  @Prop({ required: false })
  inputFormatter: (value: number) => string;

  @Prop({ required: false, default: 'depth' })
  sliderType: 'depth' | 'time';

  @Prop({ required: false })
  sectionRanges: SectionRanges;

  updatedRpm = { from: 0, to: 0 };

  get isRpmSliderDisplayed(): boolean {
    return this.isRpmVisible && this.rpmRange !== undefined;
  }

  get depthSliderId(): string {
    return 'slider-' + this.id;
  }

  onFilterApply(params: { range: [number, number], section: string }): void {
    this.$emit('apply-filter', { ...params, rpmRange: this.updatedRpm });
    if(this.closeOnApply) {
      this.close();
    }
  }

  onApplyBtnClick(): void {
    // @ts-ignore
    this.$refs[this.depthSliderId].applyFilters();
  }

  onCloseClick(): void {
    this.close();
  }

  clearRanges(): void {
    // @ts-ignore
    this.$refs[this.depthSliderId].clearFilters();
    if(this.rpm) {
      this.updatedRpm = { from: this.rpm[0], to: this.rpm[1] };
    }
  }

  onRpmSliderChange(range: { from: number, to: number }): void {
    this.updatedRpm = range;
  }

  onClearClick(): void {
    this.clearRanges();
  }

  public clearFilters(): void {
    this.clearRanges();
  }

  close(): void {
    this.$emit('close');
  }

  created(): void {
    if(this.rpm !== undefined) {
      this.updatedRpm = { from: this.rpm[0], to: this.rpm[1] };
    }
  }
}
