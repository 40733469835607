export enum PerformanceMetricsMutation {
  REMOVE_PERFORMANCE_METRICS_WELL = 'removePerformanceMetricsWell',
  SET_PERFORMANCE_METRICS_STANDS = 'setPerformanceMetricsStands',
  SET_PERFORMANCE_METRICS_SLIDE_SHEETS = 'setPerformanceMetricsSlideSheets',
  SET_PERFORMANCE_METRICS_WELL_DATA = 'setPerformanceMetricsWellData',
}

export enum PerformanceMetricsAction {
  ADD_PERFORMANCE_METRICS_WELL = 'addPerformanceMetricsWell',
  FETCH_PERFORMANCE_METRICS_STANDS = 'fetchPerformanceMetricsStands',
  FETCH_PERFORMANCE_METRICS_SLIDE_SHEETS = 'fetchPerformanceMetricsSlideSheets',
  FETCH_PERFORMANCE_METRICS_WELL_DATA = 'fetchPerformanceMetricsWellData',
}
