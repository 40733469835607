import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import { User, UserMutation, UserType } from './types';
import { WellId } from '@/store/modules/types';
import { queryApi } from '@/services/server_service';

import store from '@/store';

import * as _ from 'lodash';

const USER_BY_TOKEN_URL = `api/users/token`;
const USERS_URL = `api/users`;
const USER_FAVORITE_WELLS_URL = USERS_URL + '/favorite-wells';

@Module
export class UserModule extends VuexModule {
  _user: User | null = null;
  _users: User[] = [];

  get user(): User | undefined {
    if(this._user === null) {
      return undefined;
    }
    return this._user;
  }

  get users(): User[] | undefined {
    return this._users;
  }

  @Mutation
  setUser(user: User): void {
    this._user = user;
  }

  @Mutation
  deleteLocalUser(username: string): void {
    this._users = _.filter(this._users, (user: User) => user.username !== username);
  }

  @Mutation
  patchUser(activePreset: string): void {
    // TODO: update with any arg
    this._user.activePreset = activePreset;
  }

  @Mutation
  setUsers(users: User[]): void {
    this._users = users;
  }

  @Action({ rawError: true })
  async createUser(user: User): Promise<void> {
    await queryApi({
      url: USERS_URL,
      method: 'POST',
      data: { ...user },
    });
  }

  @Action({ rawError: true })
  async deleteUser(username: string): Promise<void> {
    const resp = await queryApi({
      url: USERS_URL,
      method: 'DELETE',
      data: { username },
    });
    if(resp.status === 200) {
      store.commit(UserMutation.DELETE_LOCAL_USER, username);
    }
  }

  @Action({ rawError: true })
  async updateUser(user: User): Promise<void> {
    await queryApi({
      url: USERS_URL,
      method: 'PATCH',
      data: { user },
    });
  }

  @Action({ rawError: true })
  async setFavoriteWells(favoriteWells: WellId[]): Promise<void> {
    const user = this.context.getters.user;

    await queryApi({
      url: USER_FAVORITE_WELLS_URL,
      method: 'POST',
      data: { username: user.username, favoriteWells },
    });

    user.favoriteWells = favoriteWells;

    this.context.commit(UserMutation.SET_USER, user);
  }

  @Action({ rawError: true })
  async fetchUser(): Promise<void> {
    const token = this.context.getters.apiToken;

    const resp = await queryApi({
      url: USER_BY_TOKEN_URL,
      method: 'GET',
      params: { token },
    });

    if(resp === undefined) {
      return;
    }

    this.context.commit(UserMutation.SET_USER, resp.data);
  }

  @Action({ rawError: true })
  async fetchUsers(): Promise<void> {
    const resp = await queryApi({
      url: USERS_URL,
      method: 'GET',
    });

    if(resp === undefined) {
      return;
    }

    this.context.commit(UserMutation.SET_USERS, resp.data);
  }
}
