import { FormationTop, FormationTopMutation, FormationTopAction } from './types';

import { queryApi } from '@/services/server_service';
import { queryServer } from '@/services/socket_service';

import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';

import { AxiosResponse } from 'axios';
import * as _ from 'lodash';

const FORMATION_TOP_URL = 'api/formation-top';

@Module
export class FormationTopModule extends VuexModule {
  _formationTops: FormationTop[] = [];

  get formationTops(): FormationTop[] | undefined {
    return this._formationTops;
  }

  @Mutation
  setFormationTops(formationTops: FormationTop[]): void {
    if(formationTops.length === 0) {
      return;
    }
    this._formationTops = _.sortBy(formationTops, (formationTop: FormationTop) => formationTop.tvdStart);
  }

  @Mutation
  removeFormationTops(id: string): void {
    this._formationTops = _.filter(this._formationTops, (formationTop: FormationTop) => formationTop._id !== id);
  }

  @Action({ rawError: true })
  async fetchFormationTops(): Promise<void> {
    const event = 'formation-top/get';
    const params = { wellId: this.context.getters.currentWellId };

    const resp = await queryServer(event, params);

    if(resp === undefined) {
      return;
    }

    this.context.commit(FormationTopMutation.SET_FORMATION_TOPS, resp.data);
  }

  @Action({ rawError: true })
  async updateFormationTops(formationTops: FormationTop[]): Promise<AxiosResponse | undefined> {
    const url = FORMATION_TOP_URL + '/multiple';
    const resp = await queryApi({
      url,
      method: 'PATCH',
      data: { items: formationTops, wellId: this.context.getters.currentWellId },
    });
    if(resp) {
      await this.context.dispatch(FormationTopAction.FETCH_FORMATION_TOPS);
    }
    return resp;
  }

  @Action({ rawError: true })
  async addFormationTop(formationTop: FormationTop): Promise<void> {
    const url = FORMATION_TOP_URL;
    await queryApi({
      url,
      method: 'POST',
      data: { ...formationTop },
    });
    await this.context.dispatch(FormationTopAction.FETCH_FORMATION_TOPS);
  }

  @Action({ rawError: true })
  async deleteFormationTop(id: string): Promise<void> {
    const url = FORMATION_TOP_URL;
    const resp = await queryApi({
      url,
      method: 'DELETE',
      params: { id, wellId: this.context.getters.currentWellId },
    });
    if(resp?.data?.success) {
      this.context.commit(FormationTopMutation.REMOVE_FORMATION_TOPS, id);
    }
  }
}
