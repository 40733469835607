import { Rig, RigId } from '../rig/types';
import { Operator, OperatorId } from '@/store/modules/operator/types';
import { Motor, MotorId } from '../motor/types';
import { Section } from '@/store/modules/types';

export enum WellMutation {
  SET_WELLS = 'setWells',
  UPDATE_WELLS = 'updateWells',
  DELETE_WELL = 'deleteWell',
  SET_WELL_ID = 'setWellId',
  SET_TARGET_DEPTH = 'setTargetDepth',
  UPDATE_WELL_FIELDS = 'updateWellFields',
  SET_WELLS_BEING_FETCHED = 'setWellsBeingFetched',
}

export enum WellAction {
  FETCH_WELLS = 'fetchWells',
  REFETCH_WELLS = 'refetchWells',
  ADD_WELL = 'addWell',
  DELETE_WELL_BY_ID = 'deleteWellById',
  FETCH_WELL_BY_ID = 'fetchWellById',
  UPDATE_WELL = 'updateWell',
}

type RemoteDbSync = {
  time: number;
  reason: string;
};

export type Well = {
  _id: string;
  name: string;
  targetDepth: string;
  jobId: string;
  operatorId: OperatorId;
  rigId: RigId;
  operator?: Operator;
  rig?: Rig;
  lastUpdated: number;
  activeMotorId?: MotorId;
  activeMotor?: Motor;
  holeData?: HoleData[];
  casingData?: HoleData[];
  api?: string;
  afe?: string;
  location?: Location;
  sections?: Sections;
  permanentDatum?: string;
  logMeasuredFrom?: string;
  heightOfReference?: number;
  kellyBushingElevation?: number;
  drillFloorElevation?: number;
  groundLevelElevation?: number;
  bhaSynchronization?: boolean;
  lastSurveysSync?: RemoteDbSync;
  lastBhasSync?: RemoteDbSync;
  allowBackwardPushing?: boolean;
  datasourceFormat: DatasourceFormat;
  witsmlConfig?: WitsmlConfig;
};

export enum Direction {
  NORTH = 'N',
  SOUTH = 'S',
  WEST = 'W',
  EAST = 'E',
  NORTH_WEST = 'NW',
  NORTH_EAST = 'NE',
  SOUTH_WEST = 'SW',
  SOUTH_EAST = 'SE',
}
export type HoleData = {
  size: number;
  startDepth: number;
  endDepth: number;
};
export type Coordinate = {
  degrees: number;
  minutes: number;
  seconds: number;
  direction: Direction;
};
export type Location = {
  name: string;
  field: string;
  county: string;
  state: string;
  country: string;
  x: number;
  y: number;
  latitude: Coordinate;
  longitude: Coordinate;
  section: string;
  twnship: string;
  rnge: string;
};
export type Sections = {
  'Surface': number;
  'Intermediate #1': number;
  'Intermediate #2': number;
  'Curve': number;
  'Lateral': number;
};
export type WitsmlConfig = {
  templateId: string;
  well: string;
  wellbore: string;
  timeLog: string;
  depthLog: string;
};
export enum DatasourceFormat {
  WITS = 'wits',
  WITSML = 'witsml',
}
export type SectionRanges = {
  [Section.SURFACE]: [number, number];
  [Section.INTERMEDIATE_1]: [number, number];
  [Section.INTERMEDIATE_2]: [number, number];
  [Section.CURVE]: [number, number];
  [Section.LATERAL]: [number, number];
};

export type SectionsDepth = {
  [Section.SURFACE]: number;
  [Section.INTERMEDIATE_1]: number;
  [Section.INTERMEDIATE_2]: number;
  [Section.CURVE]: number;
  [Section.LATERAL]: number;
};
