import { Bha, BhaMutation, BhaAction, ExternalBhas } from './types';
import { queryApi } from '@/services/server_service';
import { queryEngine, queryServer } from '@/services/socket_service';

import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';

import { AxiosResponse } from 'axios';
import * as _  from 'lodash';

const BHA_URL = 'api/bha';

@Module
export class BhaModule extends VuexModule {
  _bhas: Bha[] | null = null;
  _externalBhas: ExternalBhas | null = null;

  get externalBhas(): ExternalBhas | undefined {
    if(this._externalBhas === null) {
      return undefined;
    }
    return this._externalBhas;
  }

  @Mutation
  setExternalBhas(bhas: ExternalBhas): void {
    this._externalBhas = bhas;
  }

  get bhas(): Bha[] | undefined {
    if(this._bhas === null) {
      return undefined;
    }
    return this._bhas;
  }

  @Mutation
  setBhas(bhas: Bha[]): void {
    if(bhas.length === 0) {
      return;
    }
    this._bhas = bhas;
  }

  @Mutation
  appendBhas(bhas: Bha[]): void {
    this._bhas = _.concat(this._bhas, bhas);
  }

  @Mutation
  clearBhas(): void {
    this._bhas = null;
  }

  @Action({ rawError: true })
  async fetchBhas(): Promise<void> {
    const event = 'bha/get';
    const params = { wellId: this.context.getters.currentWellId };

    const resp = await queryServer(event, params);

    if(resp === undefined) {
      return;
    }

    this.context.commit(BhaMutation.SET_BHAS, resp.data);
  }

  @Action({ rawError: true })
  async fetchBhasById(id: string): Promise<Bha[] | undefined> {
    const event = 'bha/get';
    const params = { wellId: id };
    return await queryServer(event, params);
  }

  @Action({ rawError: true })
  async updateBhas(bhas: Bha[]): Promise<AxiosResponse | undefined> {
    const url = BHA_URL + '/multiple';
    const resp = await queryApi({
      url,
      method: 'PATCH',
      data: { items: bhas },
    });
    if(resp) {
      await this.context.dispatch(BhaAction.FETCH_BHAS);
    }
    return resp;
  }

  @Action({ rawError: true })
  async addBha(bha: Bha): Promise<void> {
    const url = BHA_URL;
    const resp = await queryApi({
      url,
      method: 'POST',
      data: { bhas: [bha] },
    });
    if(!resp || _.isEmpty(resp.data)) {
      return;
    }
    bha._id = resp.data[0];
    this.context.commit(BhaMutation.APPEND_BHAS, [bha]);
  }

  @Action({ rawError: true })
  async deleteBha(id: string): Promise<void> {
    const url = BHA_URL;
    await queryApi({
      url,
      method: 'DELETE',
      params: { id },
    });
    await this.context.dispatch(BhaAction.FETCH_BHAS);
  }

  @Action({ rawError: true })
  async fetchExternalBhas(): Promise<void> {
    const wellName = this.context.getters.currentWell.bhaWellName;
    if(!wellName) {
      this.context.commit(BhaMutation.SET_EXTERNAL_BHAS, null);
      return;
    }

    const bhas = await queryEngine(
      'get-external-bhas',
      { wellName }
    );
    this.context.commit(BhaMutation.SET_EXTERNAL_BHAS, bhas);
  }
}
