import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';
import {
  IRangeData,
  MultiwellCompareFiltersAction,
  MultiwellCompareFiltersMutation,
  RangeTypes,
  TRangeType,
} from '@/store/modules/multiwell_compare/types';
import store from '@/store';
import moment from 'moment';

interface IMudTypeFilters {
  rangeType: TRangeType;
  rangeData: IRangeData;
  type: string;
  density: string;
}

@Module
export class MultiwellCompareFilterMudTypeModule extends VuexModule {
  _rangeTypesList: RangeTypes[] = Object.values(RangeTypes);
  _selectedRangeType: TRangeType = null;
  _selectedRangeData: IRangeData = { from: null, to: null };

  _selectedMudType: string;
  _selectedMudDensity: string;

  get rangeTypesList(): TRangeType[] {
    return this._rangeTypesList;
  }

  get selectedRangeType(): TRangeType {
    return this._selectedRangeType;
  }

  get selectedRangeData(): IRangeData {
    return this._selectedRangeData;
  }

  get selectedMudType(): string {
    return this._selectedMudType;
  }

  get selectedMudDensity(): string {
    return this._selectedMudDensity;
  }

  get accessibleData(): string {
    const dataStringsArray: string[] = [];
    if(this.selectedRangeData?.from && this.selectedRangeData?.to) {
      if(this.selectedRangeType === RangeTypes.DEPTH_RANGE) {
        dataStringsArray.push(`Mud type ${this.selectedRangeType.toLowerCase()} from ${this.selectedRangeData.from} ft to ${this.selectedRangeData.to} ft`);
      } else if(this.selectedRangeType === RangeTypes.DATE_RANGE) {
        const dateFrom = moment.unix(this.selectedRangeData.from).format('MMM DD, YYYY');
        const dateTo = moment.unix(this.selectedRangeData.to).format('MMM DD, YYYY');

        dataStringsArray.push(`Mud ${this.selectedRangeType.toLowerCase()} from ${dateFrom} to ${dateTo}`);
      }
    }

    if(this.selectedMudType) {
      dataStringsArray.push(`Mud type = ${this.selectedMudType}`);
    }
    if(this.selectedMudDensity) {
      dataStringsArray.push(`Mud density = ${this.selectedMudDensity}`);
    }

    return dataStringsArray.join(';\t ');
  }

  @Mutation
  setSelectedRangeType(rangeType: TRangeType) {
    this._selectedRangeType = rangeType;
  }

  @Mutation
  setSelectedRangeData(rangeData: IRangeData) {
    this._selectedRangeData = rangeData ? ({ ...rangeData }) : undefined;
  }

  @Mutation
  setSelectedMudType(type: string) {
    this._selectedMudType = type;
  }

  @Mutation
  setSelectedMudDensity(density: string) {
    this._selectedMudDensity = density;
  }

  @Action
  setMudTypeFilters(fullMudType: IMudTypeFilters): void {
    store.commit(MultiwellCompareFiltersMutation.SET_MUD_TYPE_RANGE_TYPE, fullMudType.rangeType);
    store.commit(MultiwellCompareFiltersMutation.SET_MUD_TYPE_RANGE_DATA, fullMudType.rangeData);
    store.commit(MultiwellCompareFiltersMutation.SET_MUD_TYPE_MUD_TYPE, fullMudType.type);
    store.commit(MultiwellCompareFiltersMutation.SET_MUD_TYPE_MUD_DENSITY, fullMudType.density);
  }

  @Action
  clearSelectedRange(): void {
    store.commit(MultiwellCompareFiltersMutation.SET_MUD_TYPE_RANGE_TYPE, null);
    store.commit(MultiwellCompareFiltersMutation.SET_MUD_TYPE_RANGE_DATA, { from: null, to: null });
  }

  @Action
  clearMudFields(): void {
    store.commit(MultiwellCompareFiltersMutation.SET_MUD_TYPE_MUD_TYPE, null);
    store.commit(MultiwellCompareFiltersMutation.SET_MUD_TYPE_MUD_DENSITY, null);
  }

  @Action
  async clearMudTypeFilters(): Promise<void> {
    await store.dispatch(MultiwellCompareFiltersAction.CLEAR_MUD_TYPE_RANGE);
    await store.dispatch(MultiwellCompareFiltersAction.CLEAR_MUD_TYPE_FIELDS);
  }
}
