import { WellId } from '../../types';

export type DrillingAdvisorSettings = {
  wellId: WellId;
  offsetWells: WellId[];
  recommendationPeriod: number;
  lookBackDistance: number;
  lookBackDistanceFromLastFormationTop: boolean;
  _id?: string;
  paramRanges: DrillingAdvisorParamRanges;
};

export type DrillingAdvisorParamRanges = {
  depth: [number, number];
  rpm: [number, number];
  wob: [number, number];
  diffPressure: [number, number];
  interpolatedTvd: [number, number];
  bitUsage: [number, number];
  pumpRate: [number, number];
  bitTFA: [number, number];
  bitSize: [number, number];
  motorRPG: [number, number];
};

export enum DrillingAdvisorSettingsMutation {
  SET_DRILLING_ADVISOR_SETTINGS = 'setDrillingAdvisorSettings',
}

export enum DrillingAdvisorSettingsAction {
  FETCH_DRILLING_ADVISOR_SETTINGS = 'fetchDrillingAdvisorSettings',
  UPDATE_DRILLING_ADVISOR_SETTINGS = 'updateDrillingAdvisorSettings',
}
