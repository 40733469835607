import { WellId } from '../types';

export type DirectionalRecomendationId = string;
export type BitProjection = {
  projMD: number;
  projInc: number[];
  projAzm: number[];
  projTVD: number[];
  projVS: number[];
  projEW: number[];
  projNS: number[];
};

export type RotateProjection = {
  ns: number;
  ew: number;
  tvd: number;
  vs: number;
  azimuth: number;
  inclination: number;
  md: number;
  distanceToPlan: [number, number, number];
};

export enum CreatedType {
  LANDING = 'Landing',
  AUTO = 'Auto',
  MULTI_STEP = 'Multi-Step',
}

export enum Action {
  ROTATE = 'rotate',
  SLIDE = 'slide',
}

export type DirectionalRecommendation = {
  wellId: WellId;
  time: number;
  tvd: number[];
  ns: number[];
  ew: number[];
  vs: number[];
  inclination: number[];
  azimuth: number[];
  md: number[];
  dls: number[];
  br: number[];
  tr: number[];
  recommendedActions: string[];
  recommendedActionLengths: number[];
  recommendedToolfaces: number[];
  currentAction: string;
  currentToolface: number;
  distanceToPlan: number;
  active: boolean;
  motorYield: number;
  rtr: number;
  rbr: number;
  createdType: CreatedType;
  landingInc: number;
  landingAzm: number;
  landingTvd: number;
  recommendedActionStartDepths: number[];
  surveyMd: number;
  bitProjection: BitProjection;
  // it's only in auto directonal recommendations
  rotateProjection?: RotateProjection;
  planDetails: string;
  _id: DirectionalRecomendationId;
};

export enum DirectionalRecommendationTrace {
  TIME = 'time',
  TVD = 'tvd',
  NS = 'ns',
  EW = 'ew',
  VS = 'vs',
  INCLINATION = 'inclination',
  AZIMUTH = 'azimuth',
  MD = 'md',
  DLS = 'dls',
  BR = 'br',
  TR = 'tr',
  RECOMMENDED_ACTIONS = 'recommendedActions',
  RECOMMENDED_ACTION_LENGTHS = 'recommendedActionLengths',
  RECOMMENDED_TOOLFACES = 'recommendedToolfaces',
  CURRENTACTION = 'currentAction',
  CURRENT_TOOLFACE = 'currentToolface',
  DISTANCE_TO_PLAN = 'distanceToPlan',
  ACTIVE = 'active',
  MOTORYIELD = 'motorYield',
  RTR = 'rtr',
  RBR = 'rbr',
  CREATED_TYPE = 'createdType',
  LANDING_INC = 'landingInc',
  LANDING_AZM = 'landingAzm',
  LANDING_TVD = 'landingTvd',
  RECOMMENDED_ACTION_START_DEPTHS = 'recommendedActionStartDepths',
  SURVEY_MD = 'surveyMd',
  BIT_PROJECTION = 'bitProjection',
  // it's only in auto directonal recommendations
  ROTATE_PROJECTION = 'rotateProjection',
  PLAN_DETAILS = 'planDetails',
}

export enum DirectionalRecommendationMutation {
  SET_RECOMMENDATIONS = 'setDirectionalRecommendations',
  SET_TIMESTAMP = 'setDirectionalRecommendationTimestamp',
  SET_SELECTED_RECOMMENDATION_TIMESTAMP = 'setSelectedRecommendationTimestamp',
  SET_ACTIVE_RECOMMENDATION = 'setActiveRecommendation',
  DELETE_RECOMMENDATION = 'deleteRecommendation',
  CLEAR_RECOMMENDATIONS = 'clearRecommendations',
  UNSELECT_RECOMMENDATIONS = 'unselectRecommendations',
  APPEND_RECOMMENDATION = 'appendDirectionalRecommendation',
}

export enum DirectionalRecommendationAction {
  FETCH_RECOMMENDATIONS = 'fetchDirectionalRecommendations',
  DELETE_DIRECTIONAL_RECOMMENDATION = 'deleteDirectionalRecommendation',
  SET_RECOMMENDATION_AS_ACTIVE = 'setRecommendationAsActive',
}
