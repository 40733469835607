export type WellId = string;

export enum Section {
  SURFACE = 'Surface',
  INTERMEDIATE_1 = 'Intermediate #1',
  INTERMEDIATE_2 = 'Intermediate #2',
  CURVE = 'Curve',
  LATERAL = 'Lateral',
}
// TODO: remove duplicates
export enum DepthSection {
  ALL = 'All',
  SURFACE = 'Surface',
  INTERMEDIATE_1 = 'Intermediate #1',
  INTERMEDIATE_2 = 'Intermediate #2',
  CURVE = 'Curve',
  LATERAL = 'Lateral',
}

export enum Activity {
  SLIDE = 'Slide',
  ROTATE = 'Rotate',
}

export type TraceConfig = {
  name: string;
  unit: string;
  min?: number;
  max?: number;
  decimals?: number;
};

export enum RowsRepPage {
  ROW_10 = 10,
  ROW_20 = 20,
  ROW_50 = 50,
}
