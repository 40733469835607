
















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class NotificationCenterHeader extends Vue {
  @Prop({ required: true })
  route: string;

  get isGoBack(): boolean {
    return !!this.$route?.params?.createAlert;
  }

  goBack(): void {
    this.$router.go(-1);
  }
}
