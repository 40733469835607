
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class extends Vue {
  @Prop({ required: false, default: '' })
  plugHTML: string;

  @Prop({ required: false, default: '' })
  tabName: string;
}
