














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Tooltip extends Vue {
  @Prop({ required: true })
  visible!: boolean;

  @Prop({ required: true })
  x!: number;

  @Prop({ required: true })
  y!: number;

  @Prop({ required: false })
  minWidth!: string;
}
