import { UserNotificationSettings, UserNotificationSettingsMutation } from './types';

import { queryServer } from '@/services/socket_service';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class UserNotificationSettingsModule extends VuexModule {
  _settings: UserNotificationSettings | null = null;

  get userNotificationSettings(): UserNotificationSettings | null {
    return this._settings;
  }

  @Mutation
  setUserNotificationSettings(settings: UserNotificationSettings): void {
    this._settings = _.cloneDeep(settings);
  }

  @Action({ rawError: true })
  async fetchUserNotificationSettings(): Promise<void> {
    const event = 'user-notification-settings/get';
    const params = {
      userId: this.context.getters.user._id,
    };

    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }
    this.context.commit(UserNotificationSettingsMutation.SET_SETTINGS, resp.data);
  }

  @Action({ rawError: true })
  async updateUserNotificationSettings(updateQuery: any): Promise<void> {
    const event = 'user-notification-settings/patch';
    const params = {
      userId: this.context.getters.user._id,
      updateQuery,
    };
    await queryServer(event, params);
  }
}
