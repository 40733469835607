import { TimeTrace, TIME_TRACE_TO_NAME_AND_UNIT_MAPPING } from '@/store/modules/well_data/types';
import { TimeTraceConfigs, TraceConfig, RenderType, TimeChartsMutation, TimeChartsAction } from './types';

import { queryServer } from '@/services/socket_service';
import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

// it is not fair, but i'm tired of broken trace selector for every new trace
const DEFAULT_TRACE_COLOR = 'white';
const DEFAULT_TRACE_AUTO_SCALE = true;
const DEFAULT_TRACE_MIN_MAX = 0;
const DEFAULT_TRACE_RENDER_TYPE = RenderType.LINE;

@Module
export class TimeChartsModule extends VuexModule {
  _isConfigSet = false;
  _timeTraceConfigs: Map<
    TimeTrace,
    TraceConfig
  > | null = null;

  // zoom ranges are always with corrected timestamps
  _timeChartsHistoricalZoomRange: null | [number, number] = null;
  _timeChartsLiveZoomRange: null | [number, number] = null;
  _timeChartsTraces: TimeTrace[][] | null = null;

  get timeChartsTraces(): TimeTrace[][] | undefined {
    if(this._timeChartsTraces === null) {
      return undefined;
    }
    return this._timeChartsTraces;
  }

  get timeChartsZoomRange(): [number, number] | undefined {
    // it is corrected values
    if(store.getters.liveMode) {
      return store.getters.timeChartsLiveZoomRange;
    } else {
      return store.getters.timeChartsHistoricalZoomRange;
    }
  }

  get timeChartsHistoricalZoomRange(): [number, number] | undefined {
    if(this._timeChartsHistoricalZoomRange === null) {
      return undefined;
    }
    return this._timeChartsHistoricalZoomRange;
  }

  get timeChartsLiveZoomRange(): [number, number] | undefined {
    if(this._timeChartsLiveZoomRange === null) {
      return undefined;
    }
    return this._timeChartsLiveZoomRange;
  }

  get timeTraceConfigs(): TimeTraceConfigs | null {
    return this._timeTraceConfigs;
  }

  get isTimeTraceConfigSet(): boolean {
    return this._isConfigSet;
  }

  get timeTraceLabel(): (trace: TimeTrace) => string {
    return (trace: TimeTrace) => {
      const config = TIME_TRACE_TO_NAME_AND_UNIT_MAPPING[trace];
      if(config) {
        const unit = config.unit ? ` [${config.unit}]` : '';
        return `${config.name}${unit}`;
      }
      return trace;
    };
  }

  get timeTraceColor(): (trace: TimeTrace) => string {
    return (trace: TimeTrace) => {
      const color = this._timeTraceConfigs.get(trace)?.color;
      return _.isNil(color) ? DEFAULT_TRACE_COLOR : color;
    };
  }

  get timeTraceAutoScale(): (trace: TimeTrace) => boolean {
    return (trace: TimeTrace) => {
      const autoScale = this._timeTraceConfigs.get(trace)?.autoScale;
      return _.isNil(autoScale) ? DEFAULT_TRACE_AUTO_SCALE : autoScale;
    };
  }

  get timeTraceMaxValue(): (trace: TimeTrace) => number {
    return (trace: TimeTrace) => {
      const max = this._timeTraceConfigs.get(trace)?.max;
      return _.isNil(max) ? DEFAULT_TRACE_MIN_MAX : max;
    };
  }

  get timeTraceMinValue(): (trace: TimeTrace) => number {
    return (trace: TimeTrace) => {
      const min = this._timeTraceConfigs.get(trace)?.min;
      return _.isNil(min) ? DEFAULT_TRACE_MIN_MAX : min;
    };
  }

  get timeTraceField(): (trace: TimeTrace) => string {
    return (trace: TimeTrace) => {
      return trace;
    };
  }

  get timeTraceRenderType(): (trace: TimeTrace) => RenderType {
    return (trace: TimeTrace) => {
      const renderType = this._timeTraceConfigs.get(trace)?.renderType;
      return _.isNil(renderType) ? DEFAULT_TRACE_RENDER_TYPE : renderType;
    };
  }

  @Mutation
  setTimeChartsTraces(traces: TimeTrace[][]): void {
    this._timeChartsTraces = traces.map(
      (chartTraces: TimeTrace[]) => {
        return [
          ...chartTraces.filter((trace: TimeTrace) => trace !== TimeTrace.RIG_STATE),
          TimeTrace.RIG_STATE,
        ];
      }
    );
  }

  @Mutation
  setTimeChartsLiveZoomRange(range: [number, number] | null): void {
    const borders = store.getters.wellBorderCorrectedTimestamps;
    if(_.isEmpty(range) || _.isEmpty(borders)) {
      this._timeChartsLiveZoomRange = null;
      return;
    }

    const updateRange: [number, number] = [Math.max(range[0], borders[0]), Math.min(range[1], borders[1])];
    this._timeChartsLiveZoomRange = updateRange;
  }

  @Mutation
  setTimeChartsHistoricalZoomRange(range: [number, number] | null): void {
    if(!range) {
      this._timeChartsHistoricalZoomRange = null;
      return;
    }
    const borders = store.getters.wellBorderCorrectedTimestamps;
    const updateRange: [number, number] = [Math.max(range[0], borders[0]), Math.min(range[1], borders[1])];
    this._timeChartsHistoricalZoomRange = updateRange;
  }

  @Mutation
  setTimeTraceScale(payload: { trace: TimeTrace, autoScale: boolean }): void {
    this._timeTraceConfigs.get(payload.trace).autoScale = payload.autoScale;
  }

  @Action
  updateTimeTraceScale(payload: { trace: TimeTrace, autoScale: boolean }): void {
    store.commit(TimeChartsMutation.SET_TIME_TRACE_SCALE, payload);
    const updateQuery = { [`traces.${payload.trace}.autoScale`]: payload.autoScale };
    store.dispatch(TimeChartsAction.UPDATE_TIME_TRACE_CONFIG, updateQuery);
  }

  @Mutation
  setTimeTraceMaxValue(payload: { trace: TimeTrace, value: number }): void {
    this._timeTraceConfigs.get(payload.trace).max = payload.value;
  }

  @Action
  updateTimeTraceMaxValue(payload: { trace: TimeTrace, value: number }): void {
    store.commit(TimeChartsMutation.SET_TIME_TRACE_MAX_VALUE, payload);
    const updateQuery = { [`traces.${payload.trace}.max`]: payload.value };
    store.dispatch(TimeChartsAction.UPDATE_TIME_TRACE_CONFIG, updateQuery);
  }

  @Mutation
  setTimeTraceMinValue(payload: { trace: TimeTrace, value: number }): void {
    this._timeTraceConfigs.get(payload.trace).min = payload.value;
  }

  @Action
  updateTimeTraceMinValue(payload: { trace: TimeTrace, value: number }): void {
    store.commit(TimeChartsMutation.SET_TIME_TRACE_MIN_VALUE, payload);
    const updateQuery = { [`traces.${payload.trace}.min`]: payload.value };
    store.dispatch(TimeChartsAction.UPDATE_TIME_TRACE_CONFIG, updateQuery);
  }

  @Mutation
  setTimeTraceColor(payload: { trace: TimeTrace, color: string }): void {
    this._timeTraceConfigs.get(payload.trace).color = payload.color;
  }

  @Action
  updateTimeTraceColor(payload: { trace: TimeTrace, color: string }): void {
    store.commit(TimeChartsMutation.SET_TIME_TRACE_COLOR, payload);
    const updateQuery = { [`traces.${payload.trace}.color`]: payload.color };
    store.dispatch(TimeChartsAction.UPDATE_TIME_TRACE_CONFIG, updateQuery);
  }

  @Mutation
  setTimeTraceConfigStatus(isSet: boolean): void {
    this._isConfigSet = isSet;
  }

  @Mutation
  setTimeTraceConfigs(config: any): void {
    const traces = _.keys(config.traces);
    if(this._timeTraceConfigs === null) {
      this._timeTraceConfigs = new Map();
    }
    traces.forEach((trace: TimeTrace | string) => {
      this._timeTraceConfigs.set(trace as TimeTrace, config.traces[trace]);
    });
    this._isConfigSet = true;
  }

  // TODO: temporary, move to separate store module
  @Action({ rawError: true })
  async fetchTimeTraceConfig(): Promise<void> {
    const event = 'trace-config/get';
    // TODO: TraceType enum
    const params = { userId: this.context.getters.user._id, presetName: this.context.getters.user.activePreset, type: 'time' };
    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data) || _.isEmpty(resp.data.traces)) {
      return;
    }
    this.context.commit(TimeChartsMutation.SET_TIME_TRACE_CONFIGS, resp.data);
  }

  @Action({ rawError: true })
  async updateTimeTraceConfig(updateQuery: any): Promise<void> {
    const event = 'trace-config/patch';
    const params = {
      userId: this.context.getters.user._id,
      updateQuery,
      presetName: this.context.getters.user.activePreset,
      type: 'time',
    };
    queryServer(event, params);
  }
}
