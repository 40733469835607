import { KeyField } from '@/store/modules/well_summary/well_summary_configuration/types';
import { IpdValues, IptValues } from '@/store/modules/well_summary/types';

export type WellSummaryExportPresetId = string;

export type WellSummaryExportPreset = {
  presetName: string;
  field: KeyField;
  depthResolution: IpdValues;
  timeResolution: IptValues;
  printType: string;
};

export type WellSummaryExportOptions = {
  _id: WellSummaryExportPresetId;
  userId: string;
  name: string;
  presets: WellSummaryExportPreset[] | null;
};

export enum WellSummaryExportOptionsMutation {
  SET_WELL_SUMMARY_EXPORT_OPTIONS = 'setWellSummaryExportOptions',
  ADD_PRESET_TO_WELL_SUMMARY_EXPORT_OPTIONS = 'addPresetToWellSummaryExportOption',
  ADD_EXPORT_ERROR_MESSAGE = 'addExportErrorMessage',
  CLEAR_EXPORT_ERROR_MESSAGE = 'clearExportErrorMessage',
  DELETE_PRESET_FROM_WELL_SUMMARY_EXPORT_OPTIONS = 'deletePresetFromWellSummaryExportOption',
  SELECT_WELL_SUMMARY_EXPORT_OPTIONS_PRESET_NAME = 'selectWellSummaryExportOptionsPresetName',
  DELETE_WELL_SUMMARY_EXPORT_OPTIONS_BY_PRESET_ID = 'deleteWellSummaryExportOptionsById',
}

export enum WellSummaryExportOptionsAction {
  FETCH_WELL_SUMMARY_EXPORT_OPTIONS = 'fetchWellSummaryExportOptions',
  DELETE_WELL_SUMMARY_EXPORT_OPTIONS = 'deleteWellSummaryExportOptions',
  UPDATE_WELL_SUMMARY_EXPORT_OPTIONS = 'updateWellSummaryExportOptions',
  ADD_WELL_SUMMARY_EXPORT_OPTIONS = 'addWellSummaryExportOptions',
}
