import { queryApi } from '@/services/server_service';

import { MwdToolData, MwdToolDataMutation } from './types';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

const MWD_TOOL_DATA_URL = `api/mwd-tool-data`;

@Module
export class MwdToolDataModule extends VuexModule {
  _mwdToolData: MwdToolData[] = [];

  get mwdToolData(): MwdToolData[] {
    return this._mwdToolData;
  }

  @Mutation
  setMwdToolData(mwdToolData: MwdToolData[]): void {
    this._mwdToolData = mwdToolData;
  }

  @Mutation
  deleteLocalMwdToolData(toolId: string): void {
    this._mwdToolData = _.filter(this._mwdToolData, (item: MwdToolData) => item._id !== toolId);
  }

  @Action({ rawError: true })
  async fetchMwdToolData(): Promise<void> {
    const resp = await queryApi({
      url: MWD_TOOL_DATA_URL,
      method: 'GET',
    });

    if(resp === undefined) {
      return;
    }

    this.context.commit(MwdToolDataMutation.SET_MWD_TOOL_DATA, resp.data);
  }

  @Action({ rawError: true })
  async deleteMwdToolData(toolId: string): Promise<void> {
    const resp = await queryApi({
      url: MWD_TOOL_DATA_URL,
      method: 'DELETE',
      data: { mwdToolDataId: toolId },
    });
    if(resp.status === 200) {
      this.context.commit(MwdToolDataMutation.DELETE_LOCAL_MWD_TOOL_DATA, toolId);
    }
  }
}
