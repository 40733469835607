import { DepthTrace } from '@/store/modules/depth_data/types';

export enum DepthChartsMutation {
  SET_DEPTH_TRACE_SCALE = 'setDepthTraceScale',
  SET_DEPTH_TRACE_MAX_VALUE = 'setDepthTraceMaxValue',
  SET_DEPTH_TRACE_MIN_VALUE = 'setDepthTraceMinValue',
  SET_DEPTH_TRACE_COLOR = 'setDepthTraceColor',
  SET_DEPTH_TRACE_OFFSET = 'setDepthTraceOffset',
  SET_DEPTH_TRACE_CONFIGS = 'setDepthTraceConfigs',
  SET_DEPTH_TRACE_CONFIG_STATUS = 'setDepthTraceConfigStatus',
  SET_DEPTH_CHARTS_HISTORICAL_ZOOM_RANGE = 'setDepthChartsHistoricalZoomRange',
  SET_DEPTH_CHARTS_LIVE_ZOOM_RANGE = 'setDepthChartsLiveZoomRange',
  SET_DEPTH_CHARTS_TRACES = 'setDepthChartsTraces',
}

export enum DepthChartsAction {
  FETCH_DEPTH_TRACE_CONFIG = 'fetchDepthTraceConfig',
  UPDATE_DEPTH_TRACE_CONFIG = 'updateDepthTraceConfig',
  UPDATE_DEPTH_TRACE_COLOR = 'updateDepthTraceColor',
  UPDATE_DEPTH_TRACE_SCALE = 'updateDepthTraceScale',
  UPDATE_DEPTH_TRACE_MAX_VALUE = 'updateDepthTraceMaxValue',
  UPDATE_DEPTH_TRACE_MIN_VALUE = 'updateDepthTraceMinValue',
  UPDATE_DEPTH_TRACE_OFFSET = 'updateDepthTraceOffset',
}

export type DepthTraceConfigs = Map<DepthTrace, TraceConfig>;

export type TraceConfig = {
  color: string;
  autoScale: boolean;
  renderType: RenderType;
  max: number;
  min: number;
  offset?: number;
};

export enum RenderType {
  LINE = 'line',
  POINT = 'point',
}
