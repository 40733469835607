export enum MotorStallDataMutation {
  SET_MOTOR_STALL_DATA = 'setMotorStallData',
  APPEND_MOTOR_STALL_ITEM = 'appendMotorStallItem',
}

export enum MotorStallDataAction {
  FETCH_MOTOR_STALL_DATA = 'fetchMotorStallData',
}

export type MotorStallData = {
  wellId: string;
  diffPressure: [number, number];
  diffPressureTimerange: [number, number];
  torque: [number, number];
  torqueTimerange: [number, number];
  _id: string;
};
