import { TraceConfig } from '../types';

export enum WellDataMutation {
  SET_WELL_DATA = 'setWellData',
  APPEND_WELL_DATA = 'appendWellData',
  SET_WELL_BORDER_TIMESTAMPS = 'setWellBorderTimestamps',
  SET_WELL_DATA_LAST_FETCHED_TIMESTAMP = 'setWellDataLastFetchedTimestamp',
  SET_WELL_DATA_BUCKET_LIMIT = 'setWellDataBucketLimit',
  SET_SECOND_TIME_BORDER = 'setSecondTimeBorder',
  APPEND_LIVE_WELL_DATA = 'appendLiveWellData',
  COPY_LIVE_DATA_TO_HISTORICAL = 'copyLiveDataToHistorical',
  CLEAR_HISTORICAL_DATA = 'clearHistoricalData',
  SET_WELL_LIVE_DATA = 'setWellLiveData',
  SET_WELL_HISTORICAL_DATA = 'setWellHistoricalData',
  SET_WELL_BORDER_CORRECTED_TIMESTAMPS = 'setWellBorderCorrectedTimestamps',
  SET_SECOND_CORRECTED_TIME_BORDER = 'setSecondCorrectedTimeBorder',
  SET_WELL_DATA_LAST_FETCHED_CORRECTED_TIMESTAMP = 'setWellDataLastFetchedCorrectedTimestamp',
  SET_WELL_DATA_FETCHING = 'setWellDataFetching',
  APPEND_GAMMA_TO_LIVE_WELL_DATA = 'appendGammaToLiveWellData',
}

export enum WellDataAction {
  FETCH_WELL_DATA_BORDERS = 'fetchWellDataBorders',
  FETCH_WELL_DATA = 'fetchWellData',
  FETCH_LIVE_WELL_DATA = 'fetchLiveWellData',
  FETCH_HISTORICAL_WELL_DATA = 'fetchHistoricalWellData',
  FETCH_WELL_DATA_RAW = 'fetchWellDataRaw',
  FETCH_HISTORICAL_WELL_DATA_BY_NON_KEYS = 'fetchHistoricalWellDataByNonKeys',
  FETCH_RAW_WELL_DATA_FOR_TIME_CHARTS = 'fetchRawWellDataForTimeCharts',
  REFETCH_WELL_DATA = 'refetchWellData',
}

export enum GaugeTimeTrace {
  HOLE_DEPTH = 'holeDepth',
  BIT_DEPTH = 'bitDepth',
  BLOCK_HEIGHT = 'blockHeight',
  RPM = 'rpm',
  TORQUE = 'torque',
  MSE = 'mse',
  DIFF_PRESSURE = 'diffPressure',
  PUMP_PRESSURE = 'pumpPressure',
  PUMP_RATE = 'pumpRate',
  TOOLFACE = 'toolface',
  HOOKLOAD = 'hookload',
  SPA = 'SPA',
  SPL = 'SPL',
  PULSE_AMPLITUDE = 'pulseAmplitude',
  PULSE_CONFIDENCE = 'pulseConfidence',
  RIG_STATE = 'rigState',
  MAGNETIC_TOOLFACE = 'magneticToolface',
  GRAVITY_TOOLFACE = 'gravityToolface',
  SPM1 = 'spm1',
  SPM2 = 'spm2',
  SPM3 = 'spm3',
  WOB = 'wob',
  ROP = 'rop',
  AMP_V_FLOW = 'ampVFlow',
  FLOW_V_PRESSURE = 'flowVPressure',
  SHOCK_LEVEL = 'shockLevel',
  POSSUM = 'possum',
  TFDS = 'tfds',
  STEER = 'steer',
  PRDS = 'prds',
  IN_TRGT = 'in_trgt',
  AZI_TRGT = 'azi_trgt',
  ESTIMATED_WOB = 'estimatedWob',
  WOB_DEVIATION = 'wobDeviation',
  DOWNHOLE_RPM = 'downholeRpm',
  DOWNHOLE_TORQUE = 'downholeTorque',
  DRILLING_EFFICIENCY = 'drillingEfficiency',
  PROPEFF = 'propeff',
  ESTIMATED_DIFF_PRESSURE = 'estimatedDiffPressure',
  DIFF_PRESSURE_V_WOB = 'diffPressureVWOB',
  CHOKE_POSITION = 'chokePosition',
  CHOKE_PRESSURE = 'chokePressure',
  DESIRED_PRESSURE = 'desiredPressure',
  FLOW_OUT = 'flowOut',
  BLOCK_SPEED = 'blockSpeed',
}

// it'd be better to do something like `enum TimeTrace { ...GaugeTimeTrace, otherTraces }`
// but it's not supported by TypeScript
export enum TimeTrace {
  HOLE_DEPTH = 'holeDepth',
  BIT_DEPTH = 'bitDepth',
  BLOCK_HEIGHT = 'blockHeight',
  RPM = 'rpm',
  TORQUE = 'torque',
  MSE = 'mse',
  DIFF_PRESSURE = 'diffPressure',
  PUMP_PRESSURE = 'pumpPressure',
  PUMP_RATE = 'pumpRate',
  TOOLFACE = 'toolface',
  HOOKLOAD = 'hookload',
  SPA = 'SPA',
  SPL = 'SPL',
  PULSE_AMPLITUDE = 'pulseAmplitude',
  PULSE_CONFIDENCE = 'pulseConfidence',
  RIG_STATE = 'rigState',
  MAGNETIC_TOOLFACE = 'magneticToolface',
  GRAVITY_TOOLFACE = 'gravityToolface',
  SPM1 = 'spm1',
  SPM2 = 'spm2',
  SPM3 = 'spm3',
  WOB = 'wob',
  ROP = 'rop',
  AMP_V_FLOW = 'ampVFlow',
  FLOW_V_PRESSURE = 'flowVPressure',
  SHOCK_LEVEL = 'shockLevel',
  POSSUM = 'possum',
  TFDS = 'tfds',
  STEER = 'steer',
  PRDS = 'prds',
  IN_TRGT = 'in_trgt',
  AZI_TRGT = 'azi_trgt',
  ESTIMATED_WOB = 'estimatedWob',
  WOB_DEVIATION = 'wobDeviation',
  DOWNHOLE_RPM = 'downholeRpm',
  DOWNHOLE_TORQUE = 'downholeTorque',
  DRILLING_EFFICIENCY = 'drillingEfficiency',
  PROPEFF = 'propeff',
  ESTIMATED_DIFF_PRESSURE = 'estimatedDiffPressure',
  DIFF_PRESSURE_V_WOB = 'diffPressureVWOB',
  CHOKE_POSITION = 'chokePosition',
  CHOKE_PRESSURE = 'chokePressure',
  DESIRED_PRESSURE = 'desiredPressure',
  FLOW_OUT = 'flowOut',
  BLOCK_SPEED = 'blockSpeed',

  SURVEY_INCLINATION = 'surveyInclination',
  SURVEY_AZIMUTH = 'surveyAzimuth',
  SURVEY_NS = 'surveyNs',
  SURVEY_EW = 'surveyEw',
  SURVEY_VS = 'surveyVs',
  SURVEY_TVD = 'surveyTvd',

  DM_LIVE_INCLINATION = 'dmLiveInclination',
  DM_LIVE_AZIMUTH = 'dmLiveAzimuth',
  DM_TEMP = 'dmTemp',
  DM_INTERPOLATED_TVD = 'dmInterpolatedTvd',

  PULSER_PINC = 'pulserPinc',

  GAMMA = 'gamma',

  PUMP_RECYCLES = 'pumpRecycles',
}

export const TIME_TRACE_TO_NAME_AND_UNIT_MAPPING: { [trace: string]: TraceConfig } = {
  [TimeTrace.HOLE_DEPTH]: { name: 'Hole Depth', unit: 'ft', min: 0, max: 40000 },
  [TimeTrace.BIT_DEPTH]: { name: 'Bit Depth', unit: 'ft', min: 0, max: 40000 },
  [TimeTrace.BLOCK_HEIGHT]: { name: 'Block Height', unit: 'ft', min: 0, max: 150 },
  [TimeTrace.RPM]: { name: 'RPM', unit: 'rpm', min: 0, max: 200 },
  [TimeTrace.TORQUE]: { name: 'Torque', unit: 'ft.lb', min: 0, max: 50000 },
  [TimeTrace.MSE]: { name: 'MSE', unit: 'kpsi', min: 0, max: 100 },
  [TimeTrace.DIFF_PRESSURE]: { name: 'Diff Pressure', unit: 'psi', min: 0, max: 5000 },
  [TimeTrace.PUMP_PRESSURE]: { name: 'Pump Pressure', unit: 'psi', min: 0, max: 10000 },
  [TimeTrace.PUMP_RATE]: { name: 'Pump Rate', unit: 'gpm', min: 0, max: 1000 },
  [TimeTrace.TOOLFACE]: { name: 'Toolface', unit: 'deg', min: 0, max: 360 },
  [TimeTrace.HOOKLOAD]: { name: 'Hookload', unit: 'klb', min: 0, max: 500 },
  [TimeTrace.SPA]: { name: 'SPA', unit: '', min: 0, max: 100 },
  [TimeTrace.SPL]: { name: 'SPL', unit: '', min: 0, max: 100 },
  [TimeTrace.PULSE_AMPLITUDE]: { name: 'Pulse Amplitude', unit: '', min: 0, max: 10 },
  [TimeTrace.PULSE_CONFIDENCE]: { name: 'Pulse Confidence', unit: '', min: 0, max: 10 },
  [TimeTrace.RIG_STATE]: { name: 'Rig State', unit: '', min: 0, max: 19 },
  [TimeTrace.MAGNETIC_TOOLFACE]: { name: 'Magnetic Toolface', unit: 'deg', min: 0, max: 360 },
  [TimeTrace.GRAVITY_TOOLFACE]: { name: 'Gravity Toolface', unit: 'deg', min: 0, max: 360 },
  [TimeTrace.SPM1]: { name: 'SPM1', unit: 'spm', min: 0, max: 1000 },
  [TimeTrace.SPM2]: { name: 'SPM2', unit: 'spm', min: 0, max: 1000 },
  [TimeTrace.SPM3]: { name: 'SPM3', unit: 'spm', min: 0, max: 1000 },
  [TimeTrace.WOB]: { name: 'WOB', unit: 'klb', min: 0, max: 100 },
  [TimeTrace.ROP]: { name: 'ROP', unit: 'ft/hr', min: 0, max: 1000 },
  [TimeTrace.AMP_V_FLOW]: { name: 'Amp / Flow Ratio', unit: '', min: 0, max: 5, decimals: 1 },
  [TimeTrace.FLOW_V_PRESSURE]: { name: 'Flow / Pressure Ratio', unit: 'gpm/psi', min: 0, max: 1, decimals: 2 },
  [TimeTrace.SHOCK_LEVEL]: { name: 'Shock Level', unit: '', min: 0, max: 100 },
  [TimeTrace.POSSUM]: { name: 'Possum', unit: '', min: 0, max: 100 },
  [TimeTrace.TFDS]: { name: 'TFDS', unit: '', min: 0, max: 360 },
  [TimeTrace.STEER]: { name: 'Steer', unit: '', min: 0, max: 360 },
  [TimeTrace.PRDS]: { name: 'PRDS', unit: '', min: 0, max: 100 },
  [TimeTrace.IN_TRGT]: { name: 'Inclination Target', unit: 'deg', min: 0, max: 360 },
  [TimeTrace.AZI_TRGT]: { name: 'Azimuth Target', unit: 'deg', min: 0, max: 360 },
  [TimeTrace.ESTIMATED_WOB]: { name: 'Estimated WOB', unit: 'klb', min: 0, max: 100 },
  [TimeTrace.WOB_DEVIATION]: { name: 'WOB Deviation', unit: '%', min: 0, max: 100 },
  [TimeTrace.DOWNHOLE_RPM]: { name: 'Downhole RPM', unit: '', min: 0, max: 200 },
  [TimeTrace.DOWNHOLE_TORQUE]: { name: 'Downhole Torque', unit: 'ft.lb', min: 0, max: 50000 },
  [TimeTrace.DRILLING_EFFICIENCY]: { name: 'Drilling Efficiency', unit: '%', min: 0, max: 100 },
  [TimeTrace.PROPEFF]: { name: 'Propeff', unit: '', min: 0, max: 100 },
  [TimeTrace.ESTIMATED_DIFF_PRESSURE]: { name: 'Estimated Diff Pressure', unit: 'psi', min: 0, max: 5000 },
  [TimeTrace.DIFF_PRESSURE_V_WOB]: { name: 'Diff Pressure / WOB Ratio', unit: 'psi/klb', min: 0, max: 30 },
  [TimeTrace.CHOKE_POSITION]: { name: 'Choke Position', unit: '% open', min: 0, max: 100 },
  [TimeTrace.CHOKE_PRESSURE]: { name: 'Choke Pressure', unit: 'psi', min: 0, max: 3000 },
  [TimeTrace.DESIRED_PRESSURE]: { name: 'Desired Pressure', unit: 'psi', min: 0, max: 3000 },
  [TimeTrace.FLOW_OUT]: { name: 'Flow Out', unit: '%', min: 0, max: 100 },
  [TimeTrace.BLOCK_SPEED]: { name: 'Block Speed', unit: 'ft/s', min: 0, max: 10 },
  [TimeTrace.SURVEY_INCLINATION]: { name: 'Survey: Inclination', unit: 'deg', min: 0, max: 360 },
  [TimeTrace.SURVEY_AZIMUTH]: { name: 'Survey: Azimuth', unit: 'deg', min: 0, max: 360 },
  [TimeTrace.SURVEY_NS]: { name: 'Survey: NS', unit: 'ft', min: 0, max: 20000 },
  [TimeTrace.SURVEY_EW]: { name: 'Survey: EW', unit: 'ft', min: 0, max: 20000 },
  [TimeTrace.SURVEY_VS]: { name: 'Survey: WS', unit: 'ft', min: 0, max: 20000 },
  [TimeTrace.SURVEY_TVD]: { name: 'Survey: TVD', unit: 'ft', min: 0, max: 20000 },
  [TimeTrace.DM_LIVE_INCLINATION]: { name: 'DM: Live Inclination', unit: 'deg', min: 0, max: 360 },
  [TimeTrace.DM_LIVE_AZIMUTH]: { name: 'DM: Live Azimuth', unit: 'deg', min: 0, max: 360 },
  [TimeTrace.DM_TEMP]: { name: 'DM: Temp', unit: '', min: 0, max: 20000 },
  [TimeTrace.DM_INTERPOLATED_TVD]: { name: 'DM: Interpolated TVD', unit: 'ft', min: 0, max: 20000 },
  [TimeTrace.PULSER_PINC]: { name: 'Pulser: PINC', unit: 'deg', min: 0, max: 360 },
  [TimeTrace.GAMMA]: { name: 'Gamma', unit: 'mEv', min: 0, max: 200 },
  [TimeTrace.PUMP_RECYCLES]: { name: 'Pump Recycles', unit: '', min: 0, max: 1000 },
};

export type WellData = {
  time: number[];
  rpm: number[];
  wob: number[];
  rop: number[];
  diffPressure: number[];
  pumpRate: number[];
  pumpPressure: number[];
  spm1: number[];
  spm2: number[];
  spm3: number[];
  toolface: number[];
  magneticToolface: number[];
  gravityToolface: number[];
  pulseAmplitude: number[];
  pulseConfidence: number[];
  SPA: number[];
  SPL: number[];
  torque: number[];
  bitDepth: number[];
  holeDepth: number[];
  hookload: number[];
  blockHeight: number[];
  rigState: number[];
  drillingEfficiency: number[];
  mse: number[];
  dysfunctionCode: number[];
  ampVFlow: number[];
  flowVPressure: number[];
  shockLevel: number[];
  possum: number[];
  tfds: number[];
  steer: number[];
  prds: number[];
  in_trgt: number[];
  azi_trgt: number[];
  propeff: number[];
  estimatedWob: number[];
  wobDeviation: number[];
  downholeRpm: number[];
  downholeTorque: number[];
  estimatedDiffPressure: number[];
  diffPressureVWOB: number[];
  correctedTimestamp: number[];
  currentTimeGap: number[];
  chokePosition: number[];
  chokePressure: number[];
  desiredPressure: number[];
  flowOut: number[];
  blockSpeed: number[];
};

export type TimeChartsZoom = {
  x: [Date, Date];
};
