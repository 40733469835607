
































import Modal from '../Modal.vue';

import { UserAction } from '@/store/modules/users/types';

import { Component, Prop, Vue } from 'vue-property-decorator';
import { DashboardPresetAction, DashboardPresetMutation } from '@/store/modules/dashboard_preset/types';

@Component({ components: { Modal } })
export default class DeletePresetConfirmationModal extends Vue {
  @Prop({ required: false, default: false })
  isModalActive!: boolean;

  @Prop({ required: true })
  presetName: string;

  get user(): string {
    return this.$store.getters.user.username;
  }

  async onApplyClick(): Promise<void> {
    await this.$store.dispatch(DashboardPresetAction.DELETE_PRESET, this.presetName);
    this.close();
  }

  onCloseClick(): void {
    this.close();
  }

  close(): void {
    this.$emit('close');
  }
}
