import { queryApi } from '@/services/server_service';

import { Rig, RigMutation, RigAction } from './types';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import { AxiosResponse } from 'axios';
import * as _ from 'lodash';

const RIG_URL = `api/rig`;

@Module
export class RigModule extends VuexModule {
  _rigs: Rig[] = [];
  _rigId: string | null = null;
  _rig: Rig | null = null;

  get rigs(): Rig[] {
    return this._rigs;
  }

  get currentRigId(): string | undefined {
    if(this._rigId === null) {
      return undefined;
    }
    return this._rigId;
  }

  get currentRig(): Rig | undefined {
    if(this._rig === null) {
      return undefined;
    }
    return this._rig;
  }

  @Mutation
  setRig(rig: Rig): void {
    this._rig = rig;
  }

  @Mutation
  setRigs(rigs: Rig[]): void {
    this._rigs = rigs;
  }

  @Mutation
  setRigId(rigId: string): void {
    this._rigId = rigId;
  }

  @Mutation
  deleteRig(rigId: string): void {
    this._rigs = this._rigs.filter((rig: Rig) => rig._id !== rigId);
  }

  @Action({ rawError: true })
  async fetchRig(): Promise<void> {
    const url = RIG_URL;
    const params = { rigId: this.currentRigId };
    const resp = await queryApi({
      url,
      method: 'GET',
      params: params,
    });

    if(resp === undefined) {
      return;
    }

    this.context.commit(RigMutation.SET_RIG, resp.data);
  }

  @Action({ rawError: true })
  async fetchRigs(): Promise<void> {
    const url = RIG_URL;
    const resp = await queryApi({
      url,
      method: 'GET',
    });

    if(resp === undefined) {
      return;
    }

    this.context.commit(RigMutation.SET_RIGS, resp.data);
  }

  @Action({ rawError: true })
  async createRig(payload: { name: string, drillingCompanyName: string }): Promise<void> {
    const url = RIG_URL;
    const resp = await queryApi({
      url,
      method: 'POST',
      data: payload,
    });

    if(resp === undefined) {
      return;
    }

    await this.context.dispatch(RigAction.FETCH_RIGS);
  }

  @Action({ rawError: true })
  async updateRig(payload: { id: string, updatedFields: any }): Promise<void> {
    const url = RIG_URL;
    const id = payload.id;
    const updateValues = payload.updatedFields;
    const resp = await queryApi({
      url,
      method: 'PATCH',
      data: {
        id,
        updateValues,
      },
    });

    if(resp === undefined) {
      return;
    }

    await this.context.dispatch(RigAction.FETCH_RIGS);
  }

  @Action({ rawError: true })
  async deleteRigById(rigId: string): Promise<AxiosResponse | undefined> {
    const resp = await queryApi({
      url: RIG_URL,
      method: 'DELETE',
      data: { rigId: rigId },
    });
    if(resp === undefined) {
      return;
    }
    this.context.commit(RigMutation.DELETE_RIG, rigId);
    return resp;
  }
}
