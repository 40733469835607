import { queryApi } from '@/services/server_service';

import { MwdRun, MwdRunMutation } from './types';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import store from '@/store';

import { AxiosResponse } from 'axios';
import * as _ from 'lodash';

const MWD_RUN_URL = `api/mwd-run`;

@Module
export class MwdRunModule extends VuexModule {
  _mwdRuns: MwdRun[] = [];

  get mwdRuns(): MwdRun[] {
    return this._mwdRuns;
  }

  @Mutation
  setMwdRuns(mwdRuns: MwdRun[]): void {
    this._mwdRuns = mwdRuns;
  }

  @Mutation
  deleteMwdRun(mwdRunId: string): void {
    this._mwdRuns = this._mwdRuns.filter((mwdRun: MwdRun) => mwdRun._id !== mwdRunId);
  }

  @Mutation
  appendMwdRun(mwdRun: MwdRun): void {
    this._mwdRuns.push(mwdRun);
  }

  @Action({ rawError: true })
  async deleteMwdRunById(mwdRunId: string): Promise<void> {
    this.context.commit(MwdRunMutation.DELETE_MWD_RUN, mwdRunId);

    await queryApi({
      url: MWD_RUN_URL,
      method: 'DELETE',
      data: { id: mwdRunId, wellId: this.context.getters.currentWellId },
    });
  }

  @Action({ rawError: true })
  async createMwdRun(mwdRun: MwdRun): Promise<void> {
    const resp = await queryApi({
      url: MWD_RUN_URL,
      method: 'POST',
      data: mwdRun,
    });

    if(resp.data === undefined) {
      return;
    }

    mwdRun._id = resp.data.mwdRunId;

    this.context.commit(MwdRunMutation.APPEND_MWD_RUN, mwdRun);
  }

  @Action({ rawError: true })
  async fetchMwdRuns(): Promise<void> {
    const resp = await queryApi({
      url: MWD_RUN_URL,
      method: 'GET',
      params: { wellId: this.context.getters.currentWellId },
    });

    if(resp === undefined || resp.data === undefined) {
      return;
    }

    this.context.commit(MwdRunMutation.SET_MWD_RUNS, resp.data);
  }

  @Action({ rawError: true })
  async updateMwdRuns(): Promise<AxiosResponse | undefined> {
    return queryApi({
      url: MWD_RUN_URL,
      method: 'PATCH',
      data: { wellId: this.context.getters.currentWellId, items: store.getters.mwdRuns },
    });
  }
}
