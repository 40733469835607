






























































































































































































































import {
  AdvancedNotification,
  AggregationType,
  DirectionalRecommendationNotification,
  Notification,
  NotificationAction,
  NotificationMutation,
  NotificationType,
  SimpleNotification,
} from '@/store/modules/notification/types';
import DirectionalRecommendations from '@/components/modals/notification-history-parts/DirectionalRecommendations.vue';
import { AdvancedConfigRule } from '@/store/modules/gauge_data/types';
import { RigStateLabels } from '@/store/modules/depth_data/types';

import EyeIcon from '@/assets/icons/pure-icons/eye.svg.pure';
import EyeStrokeIcon from '@/assets/icons/pure-icons/eye-stroke.svg.pure';

import { Component, Prop, Vue } from 'vue-property-decorator';

import * as _ from 'lodash';

@Component({ components: { DirectionalRecommendations, EyeIcon, EyeStrokeIcon } })
export default class NotificationHistoryContent extends Vue {
  @Prop({ required: true })
  filteredNotifications: Notification[];

  formatTime(timestamp: number): string {
    return new Date(timestamp * 1000).toLocaleString();
  }

  isRecommendation(type: NotificationType): boolean {
    return type === NotificationType.DIRECTIONAL_RECOMMENDATION;
  }

  getNotificationTitleType(notification: DirectionalRecommendationNotification) {
    if(!notification?.type) {
      return 'Unknown Type';
    }
    if(notification.type === NotificationType.DIRECTIONAL_RECOMMENDATION) {
      return `Nvicta's Recommendation at ${notification.md} ft.`;
    }

    return `${_.startCase(notification.type)} Alert`;
  }

  getAggregationString(rule: AdvancedConfigRule | SimpleNotification): string {
    if(rule.aggregationType === AggregationType.INSTANT) {
      return rule.aggregationType;
    } else {
      return `${rule.aggregationType}/${rule.aggregationInterval}`;
    }
  }

  getAdvancedValue(notification: AdvancedNotification, rule: AdvancedConfigRule): number {
    if(rule.aggregationType === AggregationType.INSTANT) {
      return notification.values[rule.trace];
    } else {
      return notification.values[`${rule.trace}/${rule.aggregationType}/${rule.aggregationInterval}`];
    }
  }

  getRigStateLabel(rigState: string): string {
    return RigStateLabels[String(rigState)];
  }

  changeNotificationVisibility(id: string, hidden: boolean): void {
    this.$store.dispatch(NotificationAction.CHANGE_NOTIFICATION_VISIBILITY, { id, hidden });
  }

  onDetailsClick(notification: Notification): void {
    this.$store.commit(NotificationMutation.OPEN_NOTIFICATION_MODAL, notification.time);
  }
}
