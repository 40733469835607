import { queryServer } from '@/services/socket_service';

import { WellSummaryConfiguration, WellSummaryConfigurationMutation, WellSummaryConfigurationAction } from './types';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import store from '@/store';

import * as _ from 'lodash';

const DEFAULT_PRESET = 'default';

@Module
export class WellSummaryConfigurationModule extends VuexModule {
  _configs: WellSummaryConfiguration[] = [];
  _selectedPreset = DEFAULT_PRESET;

  get wellSummaryConfigurations(): WellSummaryConfiguration[] {
    return this._configs;
  }

  get selectedPresetName(): string {
    return this._selectedPreset;
  }

  @Mutation
  selectWellSummaryConfigurationPresetName(name: string): void {
    this._selectedPreset = name;
  }

  @Mutation
  setWellSummaryConfigurations(configs: WellSummaryConfiguration[]): void {
    this._configs = configs;
  }

  @Mutation
  deleteWellSummaryConfigurationById(configId: string): void {
    this._configs = this._configs.filter((config: WellSummaryConfiguration) => config._id !== configId);
  }

  @Action({ rawError: true })
  async deleteWellSummaryConfiguration(payload: { userId: string, name: string, configId: string }): Promise<void> {
    const event = 'well-summary-configuration/delete';
    await queryServer(event, payload);
    this.context.commit(WellSummaryConfigurationMutation.DELETE_WELL_SUMMARY_CONFIGURATION_BY_ID, payload.configId);
  }

  @Action({ rawError: true })
  async addWellSummaryConfiguration(payload: { userId: string, name: string }): Promise<void> {
    const event = 'well-summary-configuration/post';
    await queryServer(event, payload);
    // TODO: fetch one by id instead of refetch
    await store.dispatch(WellSummaryConfigurationAction.FETCH_WELL_SUMMARY_CONFIGURATIONS, payload.userId);
  }

  @Action({ rawError: true })
  async updateWellSummaryConfiguration(payload: { userId: string, name: string, updateQuery: any }): Promise<void> {
    const event = 'well-summary-configuration/patch';
    await queryServer(event, payload);
  }

  @Action({ rawError: true })
  async fetchWellSummaryConfigurations(userId: string): Promise<void> {
    const event = 'well-summary-configuration/get';
    const params = { userId };

    const resp = await queryServer(event, params);

    if(resp === undefined || resp.data === undefined) {
      return;
    }

    this.context.commit(WellSummaryConfigurationMutation.SET_WELL_SUMMARY_CONFIGURATIONS, resp.data);
  }
}
