import { Country, County, State } from '@/store/modules/location/types';
import { MultiwellCompareFiltersMutation } from '@/store/modules/multiwell_compare/types';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

interface ILocationFilters {
  country: Country;
  state: State;
  county: County;
}

@Module
export class MultiwellCompareFilterLocationModule extends VuexModule {
  _selectedCountry: Country = null;
  _selectedState: State = null;
  _selectedCounty: County = null;

  get selectedCountry(): Country {
    return this._selectedCountry;
  }

  get selectedState(): State {
    return this._selectedState;
  }

  get selectedCounty(): County {
    return this._selectedCounty;
  }

  get accessibleData(): string {
    const dataStringsArray: string[] = [];

    if(this.selectedCountry) {
      dataStringsArray.push(`Country = ${this.selectedCountry}`);
    }
    if(this.selectedState) {
      dataStringsArray.push(`State = ${this.selectedState}`);
    }
    if(this.selectedCounty) {
      dataStringsArray.push(`County = ${this.selectedCounty}`);
    }

    return dataStringsArray.join(';\t');
  }

  @Mutation
  setFilterCountry(country: Country = null): void {
    this._selectedCountry = country;
  }

  @Mutation
  setFilterState(state: State = null): void {
    this._selectedState = state;
  }

  @Mutation
  setFilterCounty(county: County = null): void {
    this._selectedCounty = county;
  }

  @Action
  setLocationFilters(fullLocation: ILocationFilters): void {
    store.commit(MultiwellCompareFiltersMutation.SET_LOCATION_COUNTRY, fullLocation.country);
    store.commit(MultiwellCompareFiltersMutation.SET_LOCATION_STATE, fullLocation.state);
    store.commit(MultiwellCompareFiltersMutation.SET_LOCATION_COUNTY, fullLocation.county);
  }

  @Action
  clearLocationFilters(): void {
    store.commit(MultiwellCompareFiltersMutation.SET_LOCATION_COUNTRY);
    store.commit(MultiwellCompareFiltersMutation.SET_LOCATION_STATE);
    store.commit(MultiwellCompareFiltersMutation.SET_LOCATION_COUNTY);
  }
}
