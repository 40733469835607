import { LiveData, LiveDataMutation } from './types';
import { DepthDataMutation } from '../depth_data/types';
import { WellDataMutation } from '../well_data/types';

import { queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class LiveDataModule extends VuexModule {
  _liveData: null | LiveData = null;

  get liveData(): LiveData | undefined {
    if(this._liveData === null) {
      return undefined;
    }
    return this._liveData;
  }

  @Mutation
  setLiveData(data: LiveData): void {
    if(_.isEmpty(data) || _.isEqual(this._liveData, data)) {
      return;
    }
    // TODO: maybe replace -999.25 with undefined
    if(!this._liveData) {
      this._liveData = _.cloneDeep(data);
    } else {
      _.assign(this._liveData, data);
    }

    // TODO: after full sockets implementation live Data will be depricated => move SET_SECOND_<>_BORDER to related module
    store.commit(DepthDataMutation.SET_SECOND_DEPTH_BORDER, Math.max(this._liveData.depth, this._liveData.holeDepth));
    store.commit(WellDataMutation.SET_SECOND_TIME_BORDER, this._liveData.time);
    store.commit(WellDataMutation.SET_SECOND_CORRECTED_TIME_BORDER, this._liveData.correctedTimestamp);
    store.commit(DepthDataMutation.SET_SECOND_LOCAL_DEPTH_BORDER, this._liveData.correctedDepth);
  }

  @Action({ rawError: true })
  async fetchLiveData(): Promise<void> {
    const event = 'live-data/get';

    const params = {
      wellId: this.context.getters.currentWellId,
    };

    const resp = await queryServer(event, params);
    if(resp === undefined || resp.data === undefined) {
      return;
    }

    store.commit(LiveDataMutation.SET_LIVE_DATA, resp.data);
  }
}
