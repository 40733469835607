import * as moment from 'moment';
import * as d3 from 'd3';
import * as _ from 'lodash';

const MS_IN_A_SECOND = 1000;

export type AxisRange = [number, number];

export function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function convertSecToMs(seconds: number): number {
  return seconds * MS_IN_A_SECOND;
}

export function currentTimestampInSeconds(): number {
  return new Date().valueOf() / MS_IN_A_SECOND;
}

/**
 * Finds the closest item to a given number in an array using binary search
 * @argument arr: ascending sorted array
 * @argument num: number to find
 * @returns index of the closest item to `num`
 * @returns -1 if given array is empty
 */
export function findClosest(arr: number[], num: number): number {
  if(arr.length === 0) {
    return -1;
  }

  let lowIdx = 0;
  let highIdx = arr.length - 1;

  while(highIdx - lowIdx > 1) {
    const midIdx = Math.floor((lowIdx + highIdx) / 2);
    if(arr[midIdx] < num) {
      lowIdx = midIdx;
    } else {
      highIdx = midIdx;
    }
  }

  if(num - arr[lowIdx] <= arr[highIdx] - num) {
    return lowIdx;
  }
  return highIdx;
}

export function convertTimestampSecToDate(timestamp: number): Date {
  const timestampInMs = convertSecToMs(timestamp);
  return new Date(timestampInMs);
}

export function degreesToRadians(degrees: number) {
  const pi = Math.PI;
  return degrees * pi / 180;
}

export function getTimeDifferenceWithNow(seconds: number): string {
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: (number: number) => number + 's',
    },
  });
  const msDiff = new Date().valueOf() - seconds * 1000;
  return moment.duration(-msDiff).humanize(true);
}

export function getFormattedDateFromTimestamp(timestamp: number, format: string): string {
  return moment.unix(timestamp).format(format);
}

export function filterObjectOfArrays(
  obj: any, // object of arrays: SurveyData e.g.
  filterField: string, // field that should be filtered: md in SurveyData e.g.
  filterCondition: (array: number) => boolean // function for filtering
): any {
  // TODO: update func and use for DepthData filtering
  const filteredObj = _.cloneDeep(obj);

  // set list of filtered value indexes
  const idxList: number[] = [];
  _.map(filteredObj[filterField], (value: any, idx: number) => {
    if(filterCondition(value)) {
      idxList.push(idx);
    }
  });

  for(const field in filteredObj) {
    if(!_.isArray(filteredObj[field])) {
      continue;
    }
    filteredObj[field] = _.filter(filteredObj[field], (value: string, idx: number) => _.includes(idxList, idx));
  }
  return filteredObj;
}

export function formatValue(value?: string | number | number[] | null, decimals = 2): string {
  if(_.isNil(value) || _.isNaN(value)) {
    return 'not defined';
  }
  if(_.isArray(value)) {
    return _.map(value, (val: number) => formatSingleNumber(val, decimals)).join(', ');
  }

  return formatSingleNumber(value as number, decimals);
}

export function formatSingleNumber(value: number | string, decimals: number): string {
  if(_.isString(value)) {
    return value as string;
  }
  if(value === -999.25) {
    return '-';
  }
  return (value as number).toFixed(decimals);
}

export function parseFloatFromSring(value: string): number {
  return parseFloat(value.replace(/[^\d.]*/g, ''));
}

type DateLike = Date | number | { valueOf: () => number };

const formatMillisecond = d3.timeFormat('%H:%M:%S.%L');
const formatSecond = d3.timeFormat('%H:%M:%S');
const formatMinute = d3.timeFormat('%H:%M');
const formatHour = d3.timeFormat('%d %b %H:%M');
const formatDay = d3.timeFormat('%d %b');
const formatWeek = d3.timeFormat('%d %b');
const formatMonth = d3.timeFormat('%d %B');
const formatYear = d3.timeFormat('%Y');
const everyTickCount = 5;

export function fromDateLikeToDate(d: DateLike): Date {
  if(d instanceof Date) {
    return d;
  }
  if(typeof d === 'number') {
    return new Date(d);
  }
  return new Date(d.valueOf());
}

export function formatTimeTicks(d: number, range?: [number, number], index?: number): string {
  if(!d) {
    return '';
  }
  const date: Date = fromDateLikeToDate(d * 1000);
  if(!index || _.isEmpty(range)) {
    return formatHour(date);
  }
  if(index % 5 === 1) {
    return formatHour(date);
  }
  const interval = range[1] - range[0];
  return (interval < 5 * 60 ? formatSecond
    : interval < 60 * 60 ? formatMinute
    : formatMinute
  )(date);
}

export function wellSummaryFormatTimeTicks(d: number): string {
  const date: Date = fromDateLikeToDate(d * 1000);
  return `${formatDay(date)}|${formatMinute(date)}`;
}

export function formatDepthTicks(d: DateLike, i: number): string {
  // @ts-ignore
  if(this.doubleAxisX === false || i % everyTickCount !== 0) {
    return '';
  }
  // @ts-ignore
  const value = this.getSupXValuesByDate(d);
  if(value === undefined) {
    return '';
  }
  return value.toFixed(0) + ' ft';
}

export function formatColorTicks(d: DateLike, i: number): string {
  // @ts-ignore
  const lastRowValue = this.getLastDataValueByDate(d);
  // @ts-ignore
  if(this.doubleAxisX === true && i % everyTickCount === 0) {
    return '';
  }

  if(lastRowValue === 1 || lastRowValue === 2) {
    return '';
  }
  const date: Date = fromDateLikeToDate(d);
  const formatDate = (d3.timeSecond(date) < date ? formatMillisecond
    : d3.timeMinute(date) < date ? formatSecond
    : d3.timeHour(date) < date ? formatMinute
    : d3.timeDay(date) < date ? formatHour
    : d3.timeMonth(date) < date ? (d3.timeWeek(date) < date ? formatDay : formatWeek)
    : d3.timeYear(date) < date ? formatMonth
    : formatYear
  )(date);
  return formatDate;
}

export function makeRangesSameLength(ranges: AxisRange[] | undefined, includedIndexes?: number[]): (AxisRange | undefined)[] {
  // includedIndexes - indexes of ranges which influence on calculating. All ranges are included by default
  const itemsList = _.map(ranges, (argument: number[]) => {
    if(_.isNil(argument)) {
      return { length: undefined, middleValue: undefined };
    }
    const length = Math.abs(argument[1] - argument[0]);
    const middleValue = _.min(argument) + length / 2;
    return { length, middleValue };
  });
  const lengthList = _.map(itemsList, (row: { length: number, middleValue: number }) => row.length);
  const filteredLengthList = _.filter(
    lengthList,
    (val: string, idx: number) => _.isEmpty(includedIndexes) ? true : _.includes(includedIndexes, idx)
  );
  const maxLength = _.max(filteredLengthList);
  const halfLength = Math.floor(maxLength / 2);
  const middleValues = _.map(itemsList, (row: { length: number, middleValue: number }) => row.middleValue);
  return _.map(middleValues, (value: number) => !_.isNil(value) ? [value - halfLength, value + halfLength] : undefined);
}

export function std(values: number[]): number {
  if(!_.isArray(values)) {
    throw new Error('std takes an array, something else is passed');
  }
  if(values.length === 0) {
    return 0;
  }
  const mean = _.mean(values);
  return Math.sqrt(values.map((value: number) => Math.pow(value - mean, 2)).reduce((a: number, b: number) => a + b) / values.length);
}

export function getListFromString(data: string | (string | number)[]): (string | number)[] {
  if(_.isArray(data)) {
    return data as (string | number)[];
  }
  return (data as string)
    .split(',')
    .filter(
      (el: string) => el.replace(' ', '').length !== 0
    );
}

export function camelCaseToSpaceSeparated(
  value: string,
  replaceMap: Map<string, string> = null,
  capitalizeStrLen = 3
): string {
  if(replaceMap && replaceMap.get(value)) {
    return replaceMap.get(value);
  }
  return _.words(value).map((p: string, i: number) => {
    const mapVal = replaceMap !== null ? replaceMap.get(p) : undefined;
    if(mapVal !== undefined) {
      return mapVal;
    }
    if(p.length <= capitalizeStrLen) {
      return p.toUpperCase();
    }
    return p.charAt(0).toUpperCase() + p.slice(1);
  }).join(' ');
}

export function getDepthQueryBorders(
  liveMode: boolean,
  refetch: boolean,
  lastFetchedDepth?: number,
  depthBorders?: [number?, number?],
  liveInterval?: number
): [number?, number?] {
  let from, to;

  if(liveMode && !refetch) {
    if(lastFetchedDepth !== undefined) {
      from = lastFetchedDepth + 1;
    } else {
      const borders = depthBorders;
      if(borders !== undefined && borders[1] !== undefined && liveInterval !== undefined) {
        from = borders[1] - liveInterval;
      }
    }
  }

  return [from, to];
}

export function uid(len: number = 10): string {
  // https://stackoverflow.com/a/19964557
  return Math.random().toString(36).substr(2, len + 2);
}

export async function sleep(ms: number): Promise<void> {
  await new Promise(
    (resolve: (value: unknown) => void) => { setTimeout(resolve, ms); }
  );
}

export function isMobile(): boolean {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  const MOBILE_MAX_WIDTH = 700;
  const isMobile = toMatch.some((toMatchItem: RegExp) => {
    return navigator.userAgent.match(toMatchItem);
  });
  return isMobile && window.innerWidth < MOBILE_MAX_WIDTH;
}
