import { StateTransitionReport, StateTransitionReportMutation, DrillingState } from './types';

import { queryServer } from '@/services/socket_service';

import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class StateTransitionReportModule extends VuexModule {
  _reports: StateTransitionReport[] = [];

  get stateTransitionReports(): StateTransitionReport[] {
    return this._reports;
  }

  @Mutation
  setStateTransitionReports(reports: StateTransitionReport[]): void {
    if(reports.length === 0) {
      return;
    }
    this._reports = _.orderBy(reports, (stateTransitionReport: StateTransitionReport) => stateTransitionReport.holeDepth, 'desc');
  }

  @Mutation
  appendStateTransitionReports(report: StateTransitionReport): void {
    this._reports.push(_.clone(report));
    this._reports = _.orderBy(this._reports, (stateTransitionReport: StateTransitionReport) => stateTransitionReport.holeDepth, 'desc');
  }

  @Action({ rawError: true })
  async fetchStateTransitionReports(): Promise<void> {
    const event = 'state-transition-report/get';
    const params = { wellId: this.context.getters.currentWellId };

    const resp = await queryServer(event, params);
    if(resp === undefined) {
      return;
    }

    this.context.commit(StateTransitionReportMutation.SET_STATE_TRANSITION_REPORTS, resp.data);
  }
}
