






import DashboardHeader from '@/components/layout/headers/DashboardHeader.vue';
import WellsHeader from '@/components/layout/headers/WellsHeader.vue';
import WellSettingsHeader, { SettingsType } from '@/components/layout/headers/WellSettingsHeader.vue';
import WellSummaryHeader from '@/components/layout/headers/WellSummaryHeader.vue';
import MultiwellsCompareHeader from '@/components/layout/headers/MultiwellsCompareHeader.vue';
import NotificationCenterHeader from '@/components/layout/headers/NotificationCenterHeader.vue';
import MWDToolCheckHeader from '@/components/layout/headers/MWDToolCheckHeader.vue';
import AdminPageHeader from '@/components/layout/headers/AdminHeader.vue';
import LoginHeader from '@/components/layout/headers/LoginHeader.vue';

import { RouteName } from '@/permissions';

import { Component, Vue } from 'vue-property-decorator';
import { VueConstructor } from 'vue';

const ACTIVE_SETTINGS_ROUTES = [
  RouteName.WELL_SETTINGS,
  RouteName.RIG_SETTINGS,
  RouteName.USER,
  RouteName.ADD_WELL,
];

@Component({
  components: {
    DashboardHeader,
    WellsHeader,
    MultiwellsCompareHeader,
    MWDToolCheckHeader,
    WellSettingsHeader,
    WellSummaryHeader,
    NotificationCenterHeader,
    AdminPageHeader,
  },
})
export default class HeaderContainer extends Vue {
  get activeHeaderComponent(): VueConstructor<Vue> | null {
    const routeName = this.$route.name;
    switch(routeName) {
      case RouteName.WELL_DASHBOARD:
        return DashboardHeader;
      case RouteName.WELLS_PAGE:
      case RouteName.RIGS:
      case RouteName.USER_SETTINGS:
      case RouteName.WITSML_TEMPLATE:
      case RouteName.DAILY_REPORTS:
        return WellsHeader;
      case RouteName.MWD_TOOL_CHECK:
        return MWDToolCheckHeader;
      case RouteName.NOTIFICATION_CENTER:
        return NotificationCenterHeader;
      case RouteName.WELLS_COMPARE:
        return MultiwellsCompareHeader;
      case RouteName.WELL_SETTINGS:
      case RouteName.RIG_SETTINGS:
      case RouteName.USER:
        return WellSettingsHeader;
      case RouteName.WELL_SUMMARY:
        return WellSummaryHeader;
      case RouteName.ADMIN:
        return AdminPageHeader;
      case RouteName.LOGIN:
      case RouteName.HYDRAULICS_SIMULATOR:
        return LoginHeader;
      default:
        return null;
    }
  }

  get isLogoHidden(): boolean {
    const routeName = this.$route.name;

    switch(routeName) {
      case RouteName.WELLS_PAGE:
      case RouteName.WELLS_COMPARE:
      case RouteName.RIGS:
      case RouteName.ADMIN:
      case RouteName.USER_SETTINGS:
      case RouteName.WITSML_TEMPLATE:
      case RouteName.DAILY_REPORTS:
      case RouteName.NOTIFICATION_CENTER:
      case RouteName.MWD_TOOL_CHECK:
        return true;
      default:
        return false;
    }
  }

  get addObjectRoute(): string {
    const routeName = this.$route.name;
    switch(routeName) {
      case RouteName.RIGS:
        return RouteName.ADD_RIG;
      case RouteName.WELLS_PAGE:
        return RouteName.ADD_WELL;
      default:
        return '';
    }
  }

  get settingsObjectType(): SettingsType {
    return this.$route.name === RouteName.WELL_SETTINGS
      ? SettingsType.WELL
      : SettingsType.RIG;
  }

  get isSettingsRouteActive(): boolean {
    return ACTIVE_SETTINGS_ROUTES.indexOf(this.$route.name as RouteName) >= 0;
  }

  get isRobot(): boolean {
    return this.$store.getters.wellSummaryRobot;
  }
}
