import { queryApi } from '@/services/server_service';
import { queryServer } from '@/services/socket_service';

import { DrillingAdvisorSettings, DrillingAdvisorSettingsMutation, DrillingAdvisorSettingsAction } from './types';

import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';

import { AxiosResponse } from 'axios';
import * as _ from 'lodash';

const URL = 'api/drilling-advisor-settings';

@Module
export class DrillingAdvisorSettingsModule extends VuexModule {
  _drillingAdvisorSettings: DrillingAdvisorSettings | null = null;

  get drillingAdvisorSettings(): DrillingAdvisorSettings | undefined {
    if(this._drillingAdvisorSettings === null) {
      return undefined;
    }
    return this._drillingAdvisorSettings;
  }

  @Mutation
  setDrillingAdvisorSettings(drillingAdvisorSettings: DrillingAdvisorSettings): void {
    if(!_.isEmpty(drillingAdvisorSettings)) {
      this._drillingAdvisorSettings = drillingAdvisorSettings;
    }
  }

  @Action({ rawError: true })
  async fetchDrillingAdvisorSettings(): Promise<void> {
    const event = 'drilling-advisor-settings/get';
    const params = { wellId: this.context.getters.currentWellId };

    const resp = await queryServer(event, params);

    if(resp === undefined) {
      return;
    }

    this.context.commit(DrillingAdvisorSettingsMutation.SET_DRILLING_ADVISOR_SETTINGS, resp.data);
  }

  @Action({ rawError: true })
  async updateDrillingAdvisorSettings(settings: DrillingAdvisorSettings): Promise<AxiosResponse | undefined> {
    const resp = await queryApi({
      url: URL,
      method: 'POST',
      data: settings,
    });
    if(resp) {
      await this.context.dispatch(DrillingAdvisorSettingsAction.FETCH_DRILLING_ADVISOR_SETTINGS);
    }
    return resp;
  }
}
