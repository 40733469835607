
import Slider from './Slider.vue';
import { Component } from 'vue-property-decorator';

const TIMESTAMP_COEFFICIENT = 1000;

@Component
export default class NvictaTimeSlider extends Slider {
  /* Overrides */
  movingInputToOffset: number = 115;

  dataAdapterConvert(value) {
    const dateString = new Date(value * TIMESTAMP_COEFFICIENT).toLocaleString().split(',');
    return `${dateString[0]} \n ${dateString[1]}`;
  }

  dataInputConvert(value: string): number {
    const [date, time] = value.split(/\s+/);
    const jsDate = date.split('.').reverse().join('-');

    return new Date(`${jsDate}T${time}`).getTime() / TIMESTAMP_COEFFICIENT;
  }
}
