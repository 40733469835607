









import { Component, Vue } from 'vue-property-decorator';
import * as _ from 'lodash';

@Component
export default class WellSummaryHeader extends Vue {
  goToSettings(): void {
    const wellId = this.$store.getters.currentWellId;

    if(wellId === undefined) {
      this.$router.push('/');
    } else {
      this.$router.push(`/well/${wellId}/settings`);
    }
  }
}
