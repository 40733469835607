import {
  DepthData,
  DepthDataMutation,
  DepthDataLastEstimatedValues,
  DepthDataAction,
  LiveModeDepthInterval, DepthTrace,
} from './types';
import { AppMutation } from '../app/types';
import { DepthChartsMutation } from '../tabs/left_tabs/drilling_data_tab/depth_charts/types';
import { DrillingDataSubTab } from '../tabs/left_tabs/types';
import { GammaData } from '../gamma_data/types';

import { DataBucket } from '@/models/bucket';
import { queryServer } from '@/services/socket_service';

import store from '@/store';
import { findClosest } from '@/utils';

import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';

import * as _ from 'lodash';

const KEY_FIELD = 'correctedDepth';
const CACHING_MULTIPLIER = 2; // increase data limit in live mode due to caching
const DEFAULT_LIVE_DEPTH_INTERVAL = 100;
const TOOLFACE_DATA_LENGTH = 10;

@Module
export class DepthDataModule extends VuexModule {
  _liveDepthData: DataBucket<DepthData> = new DataBucket(DEFAULT_LIVE_DEPTH_INTERVAL * CACHING_MULTIPLIER, KEY_FIELD);
  _historicalDepthData: DepthData | null = null;
  _lastFetchedDepth: number | null = null;

  _lastFetchedHeatmapsDepth: number | null = null;

  _depthBorders: [number, number] | null = null;
  _localDepthBorders: [number, number] | null = null; // only for Depth Charts
  _lastEstimatedValues: DepthDataLastEstimatedValues | null = null;
  _liveDepthInterval: LiveModeDepthInterval = DEFAULT_LIVE_DEPTH_INTERVAL;
  _isDepthDataFetching = false;

  get depthData(): DepthData | undefined {
    const liveMode = this.context.getters.liveMode;
    if(liveMode) {
      return this._liveDepthData.data as DepthData;
    } else {
      return this._historicalDepthData || undefined;
    }
  }

  get queryProjections(): { [key: string]: number } {
    const depthTracesProjection = { depth: 1, correctedDepth: 1, currentDepthGap: 1, rigState: 1 };
    const traces = this.context.getters.depthChartsTraces.reduce((x: DepthTrace[], y: DepthTrace[]) => [...x, ...y]);
    traces.forEach((trace: DepthTrace) => {
      depthTracesProjection[trace] = 1;
    });

    const directionalPlotProjection = {
      estimatedAzimuth: 1,
      estimatedEW: 1,
      estimatedInclination: 1,
      estimatedMD: 1,
      estimatedNS: 1,
      estimatedTVD: 1,
      estimatedVS: 1,
      rotaryBuildRate: 1,
      rotaryTurnRate: 1,
      slidingBuildRate: 1,
    };
    return {
      ...depthTracesProjection,
      ...directionalPlotProjection,
    };
  }

  get isDepthDataFetching(): boolean {
    return this._isDepthDataFetching;
  }

  @Mutation
  setDepthHistoricalData(data: DepthData): void {
    if(data === undefined || data.correctedDepth.length === 0) {
      return;
    }
    this._historicalDepthData = data;
  }

  @Mutation
  setDepthLiveData(data: DepthData): void {
    if(data === undefined || data.correctedDepth.length === 0) {
      return;
    }
    const lastDepth = _.last(data.depth);
    const lastCorrectedDepth = _.last(data.correctedDepth);
    const leftBorder = lastCorrectedDepth - store.getters.liveDepthInterval;
    const liveZoomRange = [leftBorder, lastCorrectedDepth];
    store.commit(DepthChartsMutation.SET_DEPTH_CHARTS_LIVE_ZOOM_RANGE, liveZoomRange);
    this._liveDepthData.setData(data);
    store.commit(DepthDataMutation.SET_LAST_FETCHED_DEPTH, lastCorrectedDepth);
    store.commit(DepthDataMutation.SET_SECOND_DEPTH_BORDER, lastDepth);
    store.commit(DepthDataMutation.SET_LAST_ESTIMATED_VALUES, data);
  }

  @Mutation
  appendGammaToLiveDepthData(gammaData: GammaData[]): void {
    if(_.isEmpty(gammaData) || _.isEmpty((this._liveDepthData.data as any)?.gamma)) {
      return;
    }
    const offset = store.getters.depthTraceOffset(DepthTrace.GAMMA);
    for(const row of gammaData) {
      const gammaIndex = findClosest((this._liveDepthData.data as DepthData).depth, row.holeDepth + offset);
      (this._liveDepthData.data as any).gamma[gammaIndex] = row.correctedGamma;
    }
  }

  @Mutation
  appendDepthLiveData(data: DepthData): void {
    if(data === undefined || data.correctedDepth.length === 0) {
      return;
    }
    const lastDepth = _.last(data.depth);
    const lastCorrectedDepth = _.last(data.correctedDepth);
    const leftBorder = lastCorrectedDepth - store.getters.liveDepthInterval;
    const liveZoomRange = [leftBorder, lastCorrectedDepth];
    store.commit(DepthChartsMutation.SET_DEPTH_CHARTS_LIVE_ZOOM_RANGE, liveZoomRange);
    // TODO: add projection fields to filter data
    this._liveDepthData.appendData(data);
    store.commit(DepthDataMutation.SET_LAST_FETCHED_DEPTH, lastCorrectedDepth);
    store.commit(DepthDataMutation.SET_SECOND_DEPTH_BORDER, lastDepth);
    store.commit(DepthDataMutation.SET_LAST_ESTIMATED_VALUES, data);
  }

  @Mutation
  copyDepthLiveDataToHistorical(): void {
    if(this._historicalDepthData !== null) {
      return;
    }
    this._historicalDepthData = _.cloneDeep(this._liveDepthData.data);
  }

  @Mutation
  clearDepthHistoricalData(): void {
    this._historicalDepthData = null;
  }

  @Action({ rawError: true })
  async fetchLiveDepthData(): Promise<void> {
    const depthBorders = this.context.getters.localDepthBorders;
    const border = _.last(depthBorders);

    const liveDepthInterval = this.context.getters.liveDepthInterval;
    const from = border - liveDepthInterval;

    const depthInterval = [from, undefined];
    const data = await this.context.dispatch(
      DepthDataAction.FETCH_DEPTH_DATA_BY_INTERVAL,
      { depthInterval, caching: true, key: 'correctedDepth', interval: liveDepthInterval }
    );
    if(data === undefined) {
      return;
    }
    store.commit(DepthDataMutation.SET_DEPTH_LIVE_DATA, data);
  }

  @Action({ rawError: true })
  async fetchHistoricalDepthData(depthInterval: [number, number]): Promise<void> {
    // TODO: add fetchDepthData and define there: is it historical data or live by depthInterval[1]
    store.commit(DepthDataMutation.SET_DEPTH_DATA_FETCHING, true);
    store.commit(DepthChartsMutation.SET_DEPTH_CHARTS_HISTORICAL_ZOOM_RANGE, depthInterval);
    const data = await this.context.dispatch(
      DepthDataAction.FETCH_DEPTH_DATA_BY_INTERVAL,
      { depthInterval, caching: true, key: 'correctedDepth' }
    );
    if(data === undefined) {
      return;
    }
    store.commit(DepthDataMutation.SET_DEPTH_HISTORICAL_DATA, data);
    store.commit(DepthDataMutation.SET_DEPTH_DATA_FETCHING, false);
    if(store.getters.liveMode) {
      store.commit(AppMutation.TOGGLE_LIVE_MODE, DrillingDataSubTab.DEPTH);
    }
  }

  @Action({ rawError: true })
  async fetchHistoricalDepthDataByDepth(depthInterval: [number, number]): Promise<void> {
    // TODO: add fetchDepthData and define there: is it historical data or live by depthInterval[1]
    store.commit(DepthDataMutation.SET_DEPTH_DATA_FETCHING, true);
    const data = await this.context.dispatch(
      DepthDataAction.FETCH_DEPTH_DATA_BY_INTERVAL,
      { depthInterval, caching: true, key: 'depth' }
    );
    if(data === undefined) {
      return;
    }
    store.commit(DepthDataMutation.SET_DEPTH_HISTORICAL_DATA, data);
    const startIdx = findClosest(data.depth, depthInterval[0]);
    const endIdx = findClosest(data.depth, depthInterval[1]);
    store.commit(DepthChartsMutation.SET_DEPTH_CHARTS_HISTORICAL_ZOOM_RANGE, [data.correctedDepth[startIdx], data.correctedDepth[endIdx]]);
    store.commit(DepthDataMutation.SET_DEPTH_DATA_FETCHING, false);
    if(store.getters.liveMode) {
      store.commit(AppMutation.TOGGLE_LIVE_MODE, DrillingDataSubTab.DEPTH);
    }
  }

  @Action({ rawError: true })
  async fetchWellSummaryDepthData(payload: { projection: any, key: string, returnResult: boolean }): Promise<any> {
    const event = 'depth-data/get';
    const params = {
      wellId: this.context.getters.currentWellId,
      projection: {
        depth: 1,
        ...payload.projection,
      },
      key: payload.key,
    };

    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data) || (_.isEmpty(resp.data.depth) && _.isEmpty(resp.data.tvd))) {
      return undefined;
    }

    if(payload.returnResult) {
      return resp.data;
    }

    store.commit(DepthDataMutation.SET_DEPTH_HISTORICAL_DATA, resp.data);
  }

  @Action({ rawError: true })
  async fetchDepthDataByInterval(
    payload: {
      depthInterval: [number?, number?];
      caching?: boolean;
      key?: 'depth' | 'correctedDepth';
      interval?: number; // TODO: rename <depthInterval> to <depthRange> and <interval> to <depthInterval>
    }
  ): Promise<DepthData | undefined> {
    const event = 'depth-data/get';
    const params = {
      wellId: this.context.getters.currentWellId,
      from: payload.depthInterval[0],
      to: payload.depthInterval[1],
      projection: this.context.getters.queryProjections,
      caching: payload.caching,
      key: payload.key,
      depthInterval: payload.interval,
    };
    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data) || _.isEmpty(resp.data[payload.key || 'depth'])) {
      return undefined;
    }
    return resp.data;
  }

  get depthBorders(): number[] | undefined {
    if(this._depthBorders === null) {
      return undefined;
    }
    return this._depthBorders;
  }

  // TODO: rename to correctedDepthBorders
  get localDepthBorders(): number[] | undefined {
    if(!this._localDepthBorders) {
      return undefined;
    }
    return this._localDepthBorders;
  }

  get depthDataToolfaceTail(): number[] | undefined {
    if(this._liveDepthData.data === undefined) {
      return undefined;
    }
    return _.takeRight((this._liveDepthData.data as DepthData).toolface, TOOLFACE_DATA_LENGTH);
  }

  get lastFetchedDepth(): number | undefined {
    if(this._lastFetchedDepth === null) {
      return undefined;
    }
    return this._lastFetchedDepth;
  }

  get lastFetchedHeatmapsDepth(): number | undefined {
    if(this._lastFetchedHeatmapsDepth === null) {
      return undefined;
    }
    return this._lastFetchedHeatmapsDepth;
  }

  get depthDataLastEstimatedValues(): DepthDataLastEstimatedValues | undefined {
    if(this._lastEstimatedValues === null) {
      return undefined;
    }
    return this._lastEstimatedValues;
  }

  get liveDepthInterval(): number {
    return this._liveDepthInterval;
  }

  @Mutation
  setLiveDepthInterval(interval: LiveModeDepthInterval): void {
    this._liveDepthInterval = interval;
    store.commit(DepthDataMutation.SET_DEPTH_DATA_BUCKET_LIMIT, interval);
  }

  @Mutation
  setDepthDataBucketLimit(limit: number | null): void {
    if(limit === undefined) {
      return;
    }
    this._liveDepthData.setLimit(limit * CACHING_MULTIPLIER);
  }

  @Mutation
  setLastEstimatedValues(data: DepthData): void {
    if(_.isEmpty(data.estimatedAzimuth)) {
      return;
    }
    this._lastEstimatedValues = {
      estimatedAzimuth: _.last(data.estimatedAzimuth),
      estimatedEW: _.last(data.estimatedEW),
      estimatedInclination: _.last(data.estimatedInclination),
      estimatedMD: _.last(data.estimatedMD),
      estimatedNS: _.last(data.estimatedNS),
      estimatedTVD: _.last(data.estimatedTVD),
      estimatedVS: _.last(data.estimatedVS),
      rotaryBuildRate: _.last(data.rotaryBuildRate),
      rotaryTurnRate: _.last(data.rotaryTurnRate),
      slidingBuildRate: _.last(data.slidingBuildRate),
      slidingTurnRate: _.last(data.slidingTurnRate),
    };
  }

  @Mutation
  setLastFetchedDepth(depth: number | null): void {
    this._lastFetchedDepth = depth;
  }

  @Mutation
  setLastFetchedHeatmapsDepth(depth: number | null): void {
    this._lastFetchedHeatmapsDepth = depth;
  }

  @Mutation
  setDepthDataFetching(isFetching: boolean): void {
    this._isDepthDataFetching = isFetching;
  }

  @Mutation
  setDepthBorders(borders: number[]): void {
    const sortedBorders = [
      Math.floor(_.min(borders)),
      Math.ceil(_.max(borders)),
    ];
    this._depthBorders = sortedBorders as [number, number];
  }

  @Mutation
  setLocalDepthBorders(borders: number[]): void {
    const sortedBorders = [
      Math.floor(_.min(borders)),
      Math.ceil(_.max(borders)),
    ];
    this._localDepthBorders = sortedBorders as [number, number];
  }

  @Mutation
  setSecondLocalDepthBorder(border: number): void {
    if(!this._localDepthBorders || !border || this._localDepthBorders[1] === Math.ceil(border)) {
      return;
    }
    this._localDepthBorders[1] = Math.ceil(border);
  }

  @Mutation
  setSecondDepthBorder(border: number): void {
    if(this._depthBorders === null || !border || this._depthBorders[1] === Math.ceil(border)) {
      return;
    }
    this._depthBorders[1] = Math.ceil(border);
    this._depthBorders = _.clone(this._depthBorders);
  }

  @Action({ rawError: true })
  async fetchDepthDataRaw(payload: { projection: any, key: string, from?: number, to?: number }): Promise<void> {
    const event = 'depth-data/get/raw';
    const params = {
      wellId: this.context.getters.currentWellId,
      projection: {
        depth: 1,
        ...payload.projection,
      },
      key: payload.key,
      from: payload.from,
      to: payload.to,
    };
    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data) || _.isEmpty(resp.data.depth)) {
      return;
    }
    this.context.commit(DepthDataMutation.SET_DEPTH_HISTORICAL_DATA, resp.data);
  }

  @Action({ rawError: true })
  async refetchDepthData(): Promise<void> {
    if(!this.context.getters.liveMode) {
      this.context.dispatch(DepthDataAction.FETCH_HISTORICAL_DEPTH_DATA, this.context.getters.depthChartsZoomRange);
    }
    this.context.dispatch(DepthDataAction.FETCH_LIVE_DEPTH_DATA);
  }
}
