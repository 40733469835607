






























import { Sized } from '@/components/ui-kit';

import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

@Component
export default class DropdownButton extends mixins(Sized) {
  @Prop({ required: false, default: 'select' })
  label!: string;

  @Prop({ required: false, default: false })
  noBoundaries: boolean;

  @Prop({ required: false, default: false })
  listAutoWidth: boolean;

  @Prop({ required: false, default: false })
  isOpenOnTop: boolean;

  dropdownListVisible = false;

  onHeaderClick() {
    this.dropdownListVisible = !this.dropdownListVisible;
  }

  showDropdown(): void {
    this.dropdownListVisible = true;
  }

  close(): void {
    this.dropdownListVisible = false;
  }
}
