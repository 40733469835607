





































import Modal from './Modal.vue';
import { DirectionalRecommendationNotification, Notification, NotificationMutation, NotificationType } from '@/store/modules/notification/types';

import { Component, Vue, Prop } from 'vue-property-decorator';
import * as moment from 'moment';

@Component({ components: { Modal } })
export default class NotificationsModal extends Vue {
  @Prop({ required: true })
  isModalActive: boolean;

  get notifications(): Notification[] {
    return this.$store.getters.notifications;
  }

  formatTime(timestamp: number): string {
    // @ts-ignore
    return moment(timestamp).format('DD MMM YYYY, HH:MM');
  }

  getNotificationText(notification: DirectionalRecommendationNotification) {
    if(notification.type === NotificationType.DIRECTIONAL_RECOMMENDATION) {
      return `Nvicta's Recommendation at ${notification.md} ft.`;
    }
    throw new Error(`Unknown notification type: ${notification.type}`);
  }

  onDetailsClick(notification: Notification): void {
    this.$store.commit(NotificationMutation.OPEN_NOTIFICATION_MODAL, notification.time);
    this.onCloseClick();
  }

  onCloseClick(): void {
    this.$emit('close');
  }
}
