import { queryServer } from '@/services/socket_service';

import {
  DirectionalRecommendationNotification,
  Notification,
  NotificationAction,
  NotificationDatePeriod,
  NotificationDatePeriodSeconds,
  NotificationMutation,
  NotificationType,
  NotificationVisibility,
} from './types';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class NotificationModule extends VuexModule {
  _notifications: Notification[] = [];
  _isModalActive = false;
  _visibilityState: NotificationVisibility = NotificationVisibility.NEW;
  _modalNotificationTime: null | number = null;
  _selectedAlertTypes: string[] = [];
  _selectedAlertLevels: string[] = [];
  _selectedPeriodTime: number = NotificationDatePeriodSeconds.LAST_12H;

  get notifications(): Notification[] {
    return this._notifications;
  }

  get visibleNotifications(): Notification[] {
    return this._notifications.filter(
      (notification: Notification) => notification.hidden === false
    );
  }

  get hiddenNotifications(): Notification[] {
    return this._notifications.filter(
      (notification: Notification) => notification.hidden === true
    );
  }

  get visibilityState(): NotificationVisibility {
    return this._visibilityState;
  }

  get isDirectionalNotificationModalActive(): boolean {
    return this._isModalActive;
  }

  get directionalNotificationModalTime(): number | undefined {
    if(this._modalNotificationTime === null) {
      return undefined;
    }
    return this._modalNotificationTime;
  }

  get modalDirectionalNotification(): DirectionalRecommendationNotification | undefined {
    return _.find(
      this._notifications.filter((notification: Notification) => notification.type === NotificationType.DIRECTIONAL_RECOMMENDATION),
      (notification: DirectionalRecommendationNotification) => Math.ceil(notification.time) === Math.ceil(this._modalNotificationTime)
    );
  }

  get filteredAlertTypes(): string[] {
    return this._selectedAlertTypes;
  }

  get filteredAlertLevels(): string[] {
    return this._selectedAlertLevels;
  }

  get filteredPeriodTime(): number {
    return this._selectedPeriodTime;
  }

  @Mutation
  setVisibilityState(state: NotificationVisibility) {
    this._visibilityState = state;
  }

  @Mutation
  setFilteredAlertTypes(type: string) {
    if(this._selectedAlertTypes.includes(type)) {
      this._selectedAlertTypes = this._selectedAlertTypes.filter((savedType: string) => savedType !== type);
    } else {
      this._selectedAlertTypes.push(type);
    }
  }

  @Mutation
  setFilteredAlertTypesBunch(types: string[]) {
    types.forEach((type: string) => {
      if(this._selectedAlertTypes.includes(type)) {
        this._selectedAlertTypes = this._selectedAlertTypes.filter((savedType: string) => savedType !== type);
      } else {
        this._selectedAlertTypes.push(type);
      }
    });
  }

  @Mutation
  setFilteredAlertLevels(level: string) {
    if(this._selectedAlertLevels.includes(level)) {
      this._selectedAlertLevels = this._selectedAlertLevels.filter((savedLevel: string) => savedLevel !== level);
    } else {
      this._selectedAlertLevels.push(level);
    }
  }

  @Mutation
  setFilteredAlertLevelsBunch(levels: string[]) {
    levels.forEach((level: string) => {
      if(this._selectedAlertLevels.includes(level)) {
        this._selectedAlertLevels = this._selectedAlertLevels.filter((savedLevel: string) => savedLevel !== level);
      } else {
        this._selectedAlertLevels.push(level);
      }
    });
  }

  @Mutation
  setFilterPeriodTime(key: string) {
    this._selectedPeriodTime = +NotificationDatePeriodSeconds[key];
  }

  @Mutation
  openNotificationModal(time: number): void {
    this._modalNotificationTime = time;
    this._isModalActive = true;
  }

  @Mutation
  closeModal(): void {
    this._isModalActive = false;
    this._modalNotificationTime = null;
  }

  @Mutation
  appendNotification(notification: Notification): void {
    this._notifications = [notification, ...this._notifications];
  }

  @Mutation
  setNotifications(notifications: Notification[]): void {
    this._notifications = notifications;
  }

  @Mutation
  setNotificationVisibility(payload: { id: string, hidden: boolean }): void {
    const notification = this._notifications.find((notification: Notification) => notification._id === payload.id);
    notification.hidden = payload.hidden;
  }

  @Mutation
  setMultipleNotificationsVisibility(payload: { ids: string[], hidden: boolean }): void {
    for(const id of payload.ids) {
      const notification = this._notifications.find((notification: Notification) => notification._id === id);
      notification.hidden = payload.hidden;
    }
  }

  /* TODO: ADD OPTIONAL TIME SELECT USING this.filteredPeriodTime */
  @Action({ rawError: true })
  async fetchNotifications(): Promise<void> {
    const event = 'notification/get';
    const params = {
      wellId: this.context.getters.currentWellId,
      userId: this.context.getters.user._id,
    };

    const resp = await queryServer(event, params);

    if(resp === undefined || resp.data === undefined) {
      return;
    }

    this.context.commit(NotificationMutation.SET_NOTIFICATIONS, resp.data);

    const directionalNotifications = this.context.getters.visibleNotifications.filter(
      (notification: Notification) => notification.type === NotificationType.DIRECTIONAL_RECOMMENDATION
    );
    if(directionalNotifications.length > 0) {
      const lastNotification = _.head(directionalNotifications);
      store.commit(NotificationMutation.OPEN_NOTIFICATION_MODAL, lastNotification.time);
    }
  }

  @Action({ rawError: true })
  async closeNotificationModal(): Promise<void> {
    this.context.commit(NotificationMutation.CLOSE_MODAL);
    // hide all notifications
    const promises = [];
    this.context.getters.visibleNotifications.forEach((notification: Notification) => {
      promises.push(store.dispatch(NotificationAction.CHANGE_NOTIFICATION_VISIBILITY, { id: notification._id, hidden: true }));
    });
    await Promise.all(promises);
  }

  @Action({ rawError: true })
  async changeNotificationVisibility(payload: { id: string, hidden: boolean }): Promise<void> {
    const event = 'notification/change-visibility';
    const params = {
      wellId: this.context.getters.currentWellId,
      userId: this.context.getters.user._id,
      notificationId: payload.id,
      hidden: payload.hidden,
    };

    const resp = await queryServer(event, params);

    this.context.commit(NotificationMutation.SET_NOTIFICATION_VISIBILITY, payload);
  }

  @Action({ rawError: true })
  async changeMultipleNotificationsVisibility(payload: { ids: string[], hidden: boolean }): Promise<void> {
    const event = 'notification/change-visibility-multiple';
    const params = {
      wellId: this.context.getters.currentWellId,
      userId: this.context.getters.user._id,
      notificationIds: payload.ids,
      hidden: payload.hidden,
    };

    const resp = await queryServer(event, params);

    this.context.commit(NotificationMutation.SET_MULTIPLE_NOTIFICATIONS_VISIBILITY, payload);
  }
}
