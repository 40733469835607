export type LiveData = {
  // wellData
  time: number;
  holeDepth: number;
  rop: number;
  mse: number;
  drillingEfficiency: number;
  wobDeviation: number;
  pulseAmplitude: number;
  downholeRpm: number;
  rpm: number;
  downholeTorque: number;
  tfds: number;
  possum: number;
  steer: number;
  prds: number;
  in_trgt: number;
  azi_trgt: number;
  shockLevel: number;
  propeff: number;
  correctedTimestamp: number;
  bitDepth: number;
  blockHeight: number;
  rigState: number;
  blockSpeed: number;
  // depthData
  correctedDepth: number;
  avgSlideToolface: number;
  slideDistance: number;
  depth: number;
  slideScore: number[];
  stickSlip: number;
  recommendedAction: string;
  recommendedToolface: number;
  recommendedActionDepth: number;
  // surveyData
  distance2plan: number[];
  azimuth: number;
  inclination: number;
  // motor chart
  diffPressure: number[];
};

export enum LiveDataAction {
  FETCH_LIVE_DATA = 'fetchLiveData',
}

export enum LiveDataMutation {
  SET_LIVE_DATA = 'setLiveData',
}
