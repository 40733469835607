import { SlideSheet, SlideSheetAction, SlideSheetMutation } from './types';

import { queryApi } from '@/services/server_service';
import { emitEngineEvent, queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

const SLIDE_SHEETS_URL = 'api/slide-sheet';

@Module
export class SlideSheetModule extends VuexModule {
  _slideSheets: SlideSheet[] = [];
  _depth: number | undefined;

  get slideSheets(): SlideSheet[] {
    return this._slideSheets;
  }

  @Mutation
  setSlideSheets(slideSheets: SlideSheet[]): void {
    if(slideSheets === undefined || slideSheets.length === 0) {
      return;
    }

    const depthList = _.map(slideSheets, (el: SlideSheet) => el.startMD);
    const lastDepth = _.max(depthList);
    store.commit(SlideSheetMutation.SET_LAST_DEPTH, lastDepth);

    this._slideSheets = [...this._slideSheets, ...slideSheets];
  }

  @Mutation
  setSlideSheetsLastDepth(depth: number): void {
    this._depth = depth;
  }

  @Mutation
  removeSlideSheet(id: string): void {
    this._slideSheets = this._slideSheets.filter((slideSheet: SlideSheet) => slideSheet._id !== id);
  }

  @Mutation
  clearSlideSheets(): void {
    this._slideSheets = [];
    this._depth = undefined;
  }

  @Action({ rawError: true })
  async fetchSlideSheets(): Promise<void> {
    let from = this._depth;
    if(from !== undefined) {
      from += 1;
    }

    const event = 'slide-sheet/get';
    const params = {
      wellId: this.context.getters.currentWellId,
      from,
    };

    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }

    this.context.commit(SlideSheetMutation.SET_SLIDE_SHEETS, resp.data);
  }

  @Action({ rawError: true })
  async deleteSlideSheet(id: string): Promise<void> {
    const url = SLIDE_SHEETS_URL;
    const resp = await queryApi({
      url,
      method: 'DELETE',
      params: {
        wellId: this.context.getters.currentWellId,
        id,
      },
    });

    if(resp === undefined) {
      return;
    }

    this.context.commit(SlideSheetMutation.REMOVE_SLIDE_SHEET, id);
    await emitEngineEvent('slide-sheets-updated', { wellId: this.context.getters.currentWellId });
  }

  @Action({ rawError: true })
  async addSlideSheet(slideSheet: SlideSheet): Promise<void> {
    const url = SLIDE_SHEETS_URL;
    const resp = await queryApi({
      url,
      method: 'POST',
      data: { rows: [slideSheet] },
    });

    if(resp === undefined) {
      return;
    }

    await emitEngineEvent('slide-sheets-updated', { wellId: this.context.getters.currentWellId });
  }

  @Action({ rawError: true })
  async updateSlideSheet(slideSheet: SlideSheet): Promise<void> {
    const url = SLIDE_SHEETS_URL;
    const resp = await queryApi({
      url,
      method: 'PATCH',
      data: {
        wellId: this.context.getters.currentWellId,
        id: slideSheet._id,
        updateValues: { ...slideSheet },
      },
    });

    if(resp === undefined) {
      return;
    }
    await emitEngineEvent('slide-sheets-updated', { wellId: this.context.getters.currentWellId });
  }

  @Action({ rawError: true })
  async refetchSlideSheets(): Promise<void> {
    this.context.commit(SlideSheetMutation.CLEAR_SLIDE_SHEETS);

    if(!store.getters.liveMode) {
      await this.context.dispatch(SlideSheetAction.FETCH_SLIDE_SHEETS);
    }
  }
}
