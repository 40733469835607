
export type PresetGaugeConfiguration = {
  id: string;
  trace: string;
  keyPath: KeyPath;
  title?: string;
  unit?: string;
  stops: number[];
  colors: string[];
  types: GaugeThresholdType[];
  defaultColor: string;
  max: number;
  min: number;
  aggregationType: AggregationType;
  aggregationInterval: AggregationInterval;
};
export enum KeyPath {
  TIME = 'Time',
  DEPTH = 'Depth',
  SURVEY = 'Survey',
  PUMP_RECYCLES = 'Pump Recycles',
  GAMMA = 'Gamma',
}
export enum GaugeThresholdType {
  ERROR = 'Error',
  WARNING = 'Warning',
  OK = 'Ok',
}
export enum AggregationType {
  INSTANT = 'Instant',
  MIN = 'Min',
  MAX = 'Max',
  AVG = 'Avg',
}
export enum TimeAggregationInterval {
  ONE_MIN = '1min',
  TEN_MIN = '10min',
  THIRTY_MIN = '30min',
  ONE_HOUR = '1hr',
  TWO_HOURS = '2hr',
}
export enum DepthAggregationInterval {
  TEN_FT = '10ft',
  THIRTY_FT = '30ft',
  NINETY_FT = '90ft',
  FIVE_HUNDRED_FT = '500ft',
}
export type AggregationInterval = TimeAggregationInterval | DepthAggregationInterval;

export enum PresetGaugesConfigMutation {
  SET_GAUGES_CONFIGURATION = 'setPresetGaugesConfiguration',
  DISPLAY_GAUGE_CONFIGURATION_MODAL = 'displayGaugeConfigurationModal',
  HIDE_GAUGE_CONFIGURATION_MODAL = 'hideGaugeConfigurationModal',
  UPDATE_GAUGE_CONFIGURATION = 'updatePresetGaugeConfiguration',
}

export enum PresetGaugesConfigAction {
  FETCH_GAUGES_CONFIG = 'fetchPresetGaugesConfig',
  SAVE_GAUGE_DATA = 'savePresetGaugeData',
}
