



































































































































































































import Modal from './Modal.vue';

import { AppMutation, LeftTab } from '@/store/modules/app/types';
import { DirectionalGuidanceSubTab, DirectionalGuidanceTabMutation } from '@/store/modules/tabs/left_tabs/directional_guidance_tab/types';
import { Action, DirectionalRecommendation, DirectionalRecommendationMutation } from '@/store/modules/directional_recommendation/types';
import { DirectionalRecommendationNotification, NotificationAction } from '@/store/modules/notification/types';
import { Survey } from '@/store/modules/survey/types';

import { formatValue, getListFromString } from '@/utils';

import { Component, Vue } from 'vue-property-decorator';
import * as _ from 'lodash';

const PROJECTION_FIELDS = {
  projInc: 'Inc',
  projAzm: 'Azm',
  projTVD: 'TVD',
  projVS: 'VS',
  projEW: 'EW',
  projNS: 'NS',
  projCc: 'CC',
  projS2s: 'L/R',
  projAb: 'A/B',
};
export const MAIN_FIELDS = {
  inclination: 'Inc',
  azimuth: 'Azm',
  tvd: 'TVD',
  vs: 'VS',
  ew: 'EW',
  ns: 'NS',
  cc: 'CC',
  s2s: 'L/R',
  ab: 'A/B',
};
const SLIDE_PERFORMANCE_FIELDS = {
  slideSeen: 'Slide Seen',
  tfSeen: 'Avg. Toolface',
  slideScoreSeen: 'Slide Score',
  slideRopSeen: 'Avg. Slide ROP',
};
const RECOMMENDATION_FIELDS = {
  ...MAIN_FIELDS,
  instructions: 'Instructions',
};
const AUTO_FIELDS = {
  motorYield: 'MY',
  rbr: 'RBR',
  rtr: 'RTR',
};
export const DISTANCE_TO_PLAN_FIELDS = {
  cc: 0,
  s2s: 1,
  ab: 2,
  projCc: 0,
  projS2s: 1,
  projAb: 2,
};
const DISTANCE_TO_PLAN_SURVEY_FIELD = 'distance2plan';
const DISTANCE_TO_PLAN_PROJECTION_FIELD = 'projDistance2plan';
export const DISTANCE_TO_PLAN_RECOMENDATION_FIELD = 'distanceToPlan';

@Component({ components: { Modal }, filters: { formatValue } })
export default class DirectionalNotification extends Vue {
  get isModalActive(): boolean {
    return this.$store.getters.isDirectionalNotificationModalActive;
  }

  get notificationTime(): number | undefined {
    return this.$store.getters.directionalNotificationModalTime;
  }

  get modalNotification(): DirectionalRecommendationNotification | undefined {
    return this.$store.getters.modalDirectionalNotification;
  }

  get notificationMd(): number | undefined {
    if(!this.modalNotification) {
      return undefined;
    }
    return this.modalNotification.md;
  }

  get isRotateProjection(): boolean {
    if(!this.recommendationForNotification) {
      return false;
    }
    return !!this.recommendationForNotification.rotateProjection;
  }

  get isSurveyExist(): boolean {
    return this.notificationTime !== undefined && this.surveyForNotification !== undefined;
  }

  get surveyForNotification(): Survey | undefined {
    const surveyData = this.$store.getters.surveyData;
    if(surveyData === undefined) {
      return undefined;
    }
    const surveyIndex = _.indexOf(surveyData.md, this.notificationMd);
    if(surveyIndex === -1) {
      return undefined;
    }
    return _.mapValues(surveyData, (value: Survey) => value[surveyIndex]);
  }

  get recommendationForNotification(): DirectionalRecommendation {
    const recommendations = this.$store.getters.directionalRecommendations;
    return _.find(
      recommendations,
      (recommendation: DirectionalRecommendation) => Math.ceil(recommendation.time) === Math.ceil(this.notificationTime)
    );
  }

  get mainFields(): string[] {
    return _.keys(MAIN_FIELDS);
  }

  get recommendationFields(): { [key: string]: string } {
    return RECOMMENDATION_FIELDS;
  }

  get slidePerformanceFields(): string[] {
    return _.keys(SLIDE_PERFORMANCE_FIELDS);
  }

  get projectionFields(): string[] {
    return _.keys(PROJECTION_FIELDS);
  }

  get distanceToPlanFields(): string[] {
    return _.keys(DISTANCE_TO_PLAN_FIELDS);
  }

  get autoFields(): string {
    return _.keys(AUTO_FIELDS);
  }

  getMainFieldLabel(key: string): string | undefined {
    return MAIN_FIELDS[key];
  }

  getAutoFieldLabel(key: string): string | undefined {
    return AUTO_FIELDS[key];
  }

  getSlidePerformanceFieldLabel(key: string): string | undefined {
    return SLIDE_PERFORMANCE_FIELDS[key];
  }

  getProjectionFieldLabel(key: string): string | undefined {
    return PROJECTION_FIELDS[key];
  }

  getSurveyFieldValue(key: string): number | undefined {
    if(_.includes(this.distanceToPlanFields, key)) {
      const d2p = _.includes(key, 'proj')
        ? this.surveyForNotification[DISTANCE_TO_PLAN_PROJECTION_FIELD]
        : this.surveyForNotification[DISTANCE_TO_PLAN_SURVEY_FIELD];

      if(!d2p) {
        return undefined;
      }
      const idx = DISTANCE_TO_PLAN_FIELDS[key];
      return d2p[idx];
    }
    const value = this.surveyForNotification[key];
    if(_.isArray(value)) {
      return _.first(value);
    }
    return value;
  }

  // TODO: remove duplication with Recommendations tab
  getRecommendationActions(): string[] {
    const actions = this.recommendationForNotification.recommendedActions;
    const actionsList = getListFromString(actions);
    return actionsList as string[];
  }

  getRecommendationStartMd(idx: number): string {
    const mds = this.recommendationForNotification.recommendedActionStartDepths;
    let depth;
    if(mds === undefined || mds.length === 0 || mds[idx] === undefined) {
      depth = '-';
    } else {
      depth = formatValue(mds[idx], 0);
    }
    return `(${depth} ft)`;
  }

  getRecommendationLength(idx: number): string {
    const lengths = this.recommendationForNotification.recommendedActionLengths;
    const lengthsList = getListFromString(lengths);
    return formatValue(lengthsList[idx], 0) + ' ft';
  }

  getRecommendationToolface(action: string, idx: number): string {
    if(_.lowerCase(action).replace(' ', '') === Action.ROTATE) {
      return '';
    }
    const toolfaces = this.recommendationForNotification.recommendedToolfaces;
    const toolfacesList = getListFromString(toolfaces);
    return formatValue(toolfacesList[idx], 0) + ' deg';
  }

  getRecommendationFieldValue(key: string): number | undefined {
    // TODD: remove duplications
    if(_.includes(this.distanceToPlanFields, key)) {
      const d2p = this.recommendationForNotification[DISTANCE_TO_PLAN_RECOMENDATION_FIELD];
      if(!d2p) {
        return undefined;
      }
      const idx = DISTANCE_TO_PLAN_FIELDS[key];
      return d2p[idx];
    }
    const value = this.recommendationForNotification[key];
    if(_.isArray(value)) {
      return _.first(value);
    }
    return value;
  }

  getRotateFieldValue(key: string): number | undefined {
    // TODD: remove duplications
    const rotateData = this.recommendationForNotification.rotateProjection;
    if(!rotateData) {
      return undefined;
    }
    if(_.includes(this.distanceToPlanFields, key)) {
      const d2p = rotateData[DISTANCE_TO_PLAN_RECOMENDATION_FIELD];
      if(!d2p) {
        return undefined;
      }
      const idx = DISTANCE_TO_PLAN_FIELDS[key];
      return d2p[idx];
    }
    const value = rotateData[key];
    if(_.isArray(value)) {
      return _.first(value);
    }
    return value;
  }

  goToDetails(): void {
    // go to recommendation tab
    this.$store.commit(AppMutation.SET_ACTIVE_LEFT_TAB, LeftTab.DIRECTIONAL_GUIDANCE);
    this.$store.commit(DirectionalGuidanceTabMutation.SET_ACTIVE_SUB_TAB, DirectionalGuidanceSubTab.RECOMMENDATIONS);
    // select survey for recommendation
    this.$store.commit(DirectionalGuidanceTabMutation.SELECT_SURVEY_MD, this.modalNotification.md);
    this.$store.commit(DirectionalRecommendationMutation.UNSELECT_RECOMMENDATIONS);
    this.close();
  }

  onCloseClick(): void {
    this.close();
  }

  close(): void {
    this.$store.dispatch(NotificationAction.CLOSE_NOTIFICATION_MODAL);
  }
}
