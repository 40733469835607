




















import Modal from './Modal.vue';

import { User, UserAction } from '@/store/modules/users/types';

import { Component, Vue, Prop } from 'vue-property-decorator';
import * as _ from 'lodash';
import { DashboardPresetAction } from '@/store/modules/dashboard_preset/types';

@Component({ components: { Modal } })
export default class DashboardPresetModal extends Vue {
  @Prop({ required: false, default: false })
  isModalActive!: boolean;

  presetName = '';

  get user(): User {
    return this.$store.getters.user;
  }

  async onApplyClick(): Promise<void> {
    if(this.presetName.length === 0) {
      this.$store.dispatch('alertError', { title: 'Dashboard Preset', message: `Can't add preset with empty name` });
      return;
    }
    await this.$store.dispatch(DashboardPresetAction.ADD_PRESET, this.presetName);
    this.$emit('apply');
    this.close();
  }

  onCloseClick(): void {
    this.close();
  }

  close(): void {
    this.$emit('close');
  }
}
