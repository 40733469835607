

































import { Component, Prop } from 'vue-property-decorator';

import { Sized } from '@/components/ui-kit';
import { mixins } from 'vue-class-component';

@Component
export default class Radio extends mixins(Sized) {
  @Prop({ required: true })
  name: string;

  @Prop({ required: true })
  id: string;

  @Prop({ required: true })
  value!: boolean;

  @Prop({ required: false, default: false })
  disabled: boolean;

  get hasBeforeSlot(): boolean {
    return !!this.$slots.before;
  }

  get hasAfterSlot(): boolean {
    return !!this.$slots.after;
  }

  get checked(): boolean {
    return this.value;
  }

  public onClick(): void {
    if(this.disabled) {
      return;
    }
    this.$emit('input', !this.checked);
  }
}
