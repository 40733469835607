import { DirectionalPlotView, RightTab, DrillingOptimizationSubTab, MotorSubTab } from './types';

import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module
export class RightTabsModule extends VuexModule {
  _activeDirectionalPlotView: DirectionalPlotView = DirectionalPlotView.EW_NS;
  _activeRightTab = RightTab.MWD;
  _activeDrillingOptimizationSubTab = DrillingOptimizationSubTab.HEATMAP;
  _activeMotorPerformanceSubTab = MotorSubTab.MOTOR_CURVE;
  _subTabIsChangedByUser = false;
  _motorSubTabIsChangedByUser = false;
  _is3dViewActive = false;

  get drillingOptimizationSubTabIsChangedByUser(): boolean {
    return this._subTabIsChangedByUser;
  }

  get motorPerformanceSubTabIsChangedByUser(): boolean {
    return this._motorSubTabIsChangedByUser;
  }

  get activeDirectionalPlotView(): DirectionalPlotView {
    return this._activeDirectionalPlotView;
  }

  @Mutation
  setActiveDirectonalPlotView(activeDirectionalPlotView: DirectionalPlotView): void {
    this._activeDirectionalPlotView = activeDirectionalPlotView;
  }

  get is3dViewActive(): boolean {
    return this._is3dViewActive;
  }

  @Mutation
  toggle3dView(): void {
    this._is3dViewActive = !this._is3dViewActive;
  }

  get activeRightTab(): RightTab {
    return this._activeRightTab;
  }

  @Mutation
  setActiveRightTab(tab: RightTab): void {
    this._activeRightTab = tab;
  }

  get activeDrillingOptimizationSubTab(): DrillingOptimizationSubTab {
    return this._activeDrillingOptimizationSubTab;
  }

  @Mutation
  setActiveDrillingOptimizationSubTab(tab: DrillingOptimizationSubTab): void {
    this._subTabIsChangedByUser = true;
    this._activeDrillingOptimizationSubTab = tab;
  }

  get activeMotorPerformanceSubTab(): MotorSubTab {
    return this._activeMotorPerformanceSubTab;
  }

  @Mutation
  setActiveMotorPerformanceSubTab(tab: MotorSubTab): void {
    this._motorSubTabIsChangedByUser = true;
    this._activeMotorPerformanceSubTab = tab;
  }
}
