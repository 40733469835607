import {
  WellSummaryExportOptions,
  WellSummaryExportOptionsAction,
  WellSummaryExportOptionsMutation, WellSummaryExportPreset,
} from '@/store/modules/well_summary/well_summary_export_options/types';

import { queryServer } from '@/services/socket_service';

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import store from '@/store';

import * as _ from 'lodash';

const DEFAULT_PRESET = 'default';
const EVENT_TYPE = 'well-summary-export-options';

@Module
export class WellSummaryExportOptionsModule extends VuexModule {
  _options: WellSummaryExportOptions[] = [];
  _selectedPreset = DEFAULT_PRESET;
  _errors: string[] = [];

  get wellSummaryExportOptions(): WellSummaryExportOptions[] {
    return this._options;
  }

  get selectedExportOptionsPresetName(): string {
    return this._selectedPreset;
  }

  get wellSummaryExportErrorMessages(): string[] {
    return this._errors;
  }

  @Mutation
  addExportErrorMessage(msg: string): void {
    this._errors.push(msg);
  }

  @Mutation
  clearExportErrorMessage(): void {
    this._errors = [];
  }

  @Mutation
  addPresetToWellSummaryExportOption(preset: WellSummaryExportPreset): void {
    const options = this._options.filter((val: WellSummaryExportOptions) => val.name === this._selectedPreset);
    if(_.isEmpty(options)) {
      return;
    }
    const option = options.pop();
    option.presets.push(preset);
  }

  @Mutation
  deletePresetFromWellSummaryExportOption(idx: number): void {
    const options = this._options.filter((val: WellSummaryExportOptions) => val.name === this._selectedPreset);
    if(_.isEmpty(options)) {
      return;
    }
    const option = options.pop();
    option.presets.splice(idx, 1);
  }

  @Mutation
  selectWellSummaryExportOptionsPresetName(name: string): void {
    this._selectedPreset = name;
  }

  @Mutation
  deleteWellSummaryExportOptionsById(id: string): void {
    this._options = this._options.filter((option: WellSummaryExportOptions) => option._id !== id);
  }

  @Mutation
  setWellSummaryExportOptions(options: WellSummaryExportOptions[]): void {
    this._options = options;
  }

  @Action({ rawError: true })
  async deleteWellSummaryExportOptions(payload: { userId: string, name: string, optionId: string }): Promise<void> {
    const event = `${EVENT_TYPE}/delete`;
    await queryServer(event, payload);
    this.context.commit(WellSummaryExportOptionsMutation.DELETE_WELL_SUMMARY_EXPORT_OPTIONS_BY_PRESET_ID, payload.optionId);
  }

  @Action({ rawError: true })
  async addWellSummaryExportOptions(payload: { userId: string, name: string }): Promise<void> {
    const event = `${EVENT_TYPE}/post`;
    await queryServer(event, payload);
    await store.dispatch(WellSummaryExportOptionsAction.FETCH_WELL_SUMMARY_EXPORT_OPTIONS, payload.userId);
  }

  @Action({ rawError: true })
  async updateWellSummaryExportOptions(payload: { userId: string, name: string, updateQuery: any }): Promise<void> {
    const event = `${EVENT_TYPE}/patch`;
    await queryServer(event, payload);
  }

  @Action({ rawError: true })
  async fetchWellSummaryExportOptions(userId: string): Promise<void> {
    const event = `${EVENT_TYPE}/get`;
    const params = { userId };

    const resp = await queryServer(event, params);

    if(resp === undefined || resp.data === undefined) {
      return;
    }

    this.context.commit(WellSummaryExportOptionsMutation.SET_WELL_SUMMARY_EXPORT_OPTIONS, resp.data);
  }
}
