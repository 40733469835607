








































import RemoteTableSelector, { isBhaTableAvailable } from '@/components/utils/RemoteTableSelector.vue';

import { BhaAction, ExternalBhas } from '@/store/modules/bha/types';

import { formatValue } from '@/utils';

import { Component, Vue } from 'vue-property-decorator';
import { WellAction } from '@/store/modules/well/types';
import { emitEngineEvent } from '@/services/socket_service';

const EXTERNAL_BHAS_FIELDS = [
  'DEPTHIN',
  'DEPTHOUT',
  'BHA_NUM',
  'WELL',
  'BITTYPE',
  'TFA',
  'OD',
  'ID',
  'LENGTH',
  'MOTORYIELD',
  'MAX_DIFFP',
  'MAX_TORQUE',
  'MAX_FLOW',
  'BIT_NUM',
  'REV_PER_GAL',
  'BHA_DESC',
  'MAKE',
  'MODEL',
  'WEIGHT',
  'DESCRIPTION',
  'SEQUENCENO',
  'TYPE',
  'MAX_RPM',
  'GRADEIN',
  'GRADEOUT',
  'MOTOR_STAGES',
  'MOTOR_LOBES',
  'STATOR_VENDOR',
  'STATOR_TYPE',
  'DATEIN',
  'DATEOUT',
];

@Component({ components: { RemoteTableSelector }, filters: { formatValue } })
export default class RemoteBhasTable extends Vue {
  isTableAvailable = true;
  wellName = '';
  externalBhaFields = EXTERNAL_BHAS_FIELDS;

  get bhas(): ExternalBhas | undefined {
    return this.$store.getters.externalBhas;
  }

  async onTableChangeClick(): Promise<void> {
    this.isTableAvailable = await isBhaTableAvailable(this.wellName);
    if(!this.isTableAvailable) {
      return;
    }

    const resp = await this.$store.dispatch(
      WellAction.UPDATE_WELL,
      { wellId: this.$store.getters.currentWellId, updatedFields: { bhaWellName: this.wellName }}
    );
    if(!resp) {
      return;
    }
    await emitEngineEvent('bhas-table-name-updated', { wellId: this.$store.getters.currentWellId });
    await this.$store.dispatch(BhaAction.FETCH_EXTERNAL_BHAS);
    await this.$store.dispatch('alertSuccess', { title: 'Remote BHA Table', message: `Well is changed` });
    this.$forceUpdate();
  }

  created(): void {
    if(this.$store.getters.currentWell.bhaWellName !== undefined) {
      this.wellName = this.$store.getters.currentWell.bhaWellName;
    }
  }
}
