import { WellModule } from './modules/well';
import { CompareWellsModule } from './modules/tabs/left_tabs/drilling_data_tab/compare_wells';
import { StandModule } from './modules/stand';
import { SlideSheetModule } from './modules/slide_sheet';
import { SurveyModule } from './modules/survey';
import { PseudoSurveyModule } from './modules/pseudo_survey';
import { ToolfaceDataModule } from './modules/toolface_data';
import { GammaDataModule } from './modules/gamma_data';
import { PulserDataModule } from './modules/pulser_data';
import { DirectionalDataModule } from './modules/directional_data';
import { AuthModule } from './modules/auth';
import { DrillingRecommendationModule } from './modules/drilling_recommendation';
import { DirectionalRecommendationModule } from './modules/directional_recommendation';
import { WellPlanModule } from './modules/well_plan';
import { TooltipModule } from './modules/tooltip';
import { AppModule } from './modules/app';
import { FormationTopModule } from './modules/formation_top';
import { BhaModule } from './modules/bha';
import { DepthDataModule } from './modules/depth_data';
import { WellDataModule } from './modules/well_data';
import { DataProcessorSettingsModule } from './modules/settings/data_processor_settings';
import { DirectionalSettingsModule } from './modules/settings/directional_settings';
import { UserModule } from './modules/users';
import { RigModule } from './modules/rig';
import { MainTabsModule } from './modules/tabs/main_tabs';
import { LeftTabsModule } from './modules/tabs/left_tabs';
import { TimeChartsModule } from './modules/tabs/left_tabs/drilling_data_tab/time_charts';
import { RightTabsModule } from './modules/tabs/right_tabs';
import { DepthChartsModule } from './modules/tabs/left_tabs/drilling_data_tab/depth_charts';
import { DirectionalGuidanceTabModule } from './modules/tabs/left_tabs/directional_guidance_tab';
import { FounderModule } from './modules/tabs/right_tabs/drilling_optimization_tab/founder';
import { TorqueDragDataModule } from './modules/torque_drag';
import { BroomStickModule } from './modules/broom_stick';
import { MotorModule } from './modules/motor';
import { LocationModule } from './modules/location';
import { OperatorModule } from './modules/operator';
import { DirectionalCompanyModule } from './modules/directional_company';
import { LiveDataModule } from './modules/live_data';
import { NotificationModule } from './modules/notification';
import { MwdRunModule } from './modules/mwd_run';
import { WellSummaryConfigurationModule } from './modules/well_summary/well_summary_configuration';
import { WellSummaryModule } from './modules/well_summary';
import { FrictionFactorEstimateModule } from './modules/friction_factor_estimate';
import { NotificationsConfigurationModule } from './modules/gauge_data';
import { BucklingModule } from './modules/buckling';
import { DrillingAdvisorSettingsModule } from './modules/settings/drilling_advisor_settings';
import { WellSummaryExportOptionsModule } from './modules/well_summary/well_summary_export_options';
import { WitsConfigsModule } from './modules/wits_configs';
import { PumpRecycleModule } from './modules/pump_recycle';
import { AntiCollisionSettingsModule } from './modules/settings/anti_collision_settings';
import { PerformanceMetricsModule } from './modules/tabs/left_tabs/performance_metrics_tab';
import { DirectionalPlotModule } from './modules/tabs/right_tabs/directional_plot_tab';
import { ScatterModule } from './modules/tabs/right_tabs/drilling_optimization_tab/scatter';
import { HeatmapModule } from './modules/tabs/right_tabs/drilling_optimization_tab/heatmap';
import { DailyReportsTabModule } from './modules/tabs/left_tabs/directional_guidance_tab/daily_reports';
import { WitsmlTemplateModule } from './modules/witsml_template';
import { MotorTabModule } from './modules/tabs/right_tabs/motor_tab';
import { BuildTurnAnalysisTabModule } from './modules/tabs/left_tabs/directional_guidance_tab/build_turn_analysis_tab';
import MultiwellCompareModule  from '@/store/modules/multiwell_compare';
import { PresetGaugesConfigModule } from '@/store/modules/tabs/gauges';
import { MwdToolDataModule } from '@/store/modules/mwd_tool_data';
import { MwdToolSettingsModule } from '@/store/modules/settings/mwd_tool_settings';
import { ApiTokenModule } from '@/store/modules/api_token';
import { MwdEmDataModule } from '@/store/modules/mwd_em_data';
import { MotorStallDataModule } from '@/store/modules/motor_stall_data';
import { DashboardPresetModule } from '@/store/modules/dashboard_preset';
import { UserNotificationSettingsModule } from '@/store/modules/user_notification_settings';
import { StateTransitionReportModule } from '@/store/modules/state_transition_report';
import { MotorStallSettingsModule } from '@/store/modules/motor_stall_settings';
import { HydraulicsDataModule } from '@/store/modules/hydraulics';
import { HydraulicsSimulatorModule } from '@/store/modules/hydraulics_simulator';

import { NotificationOptions } from 'vue-notification';

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    well: WellModule,
    compareWells: CompareWellsModule,
    stand: StandModule,
    slideSheet: SlideSheetModule,
    frictionFactorEstimate: FrictionFactorEstimateModule,
    survey: SurveyModule,
    pseudoSurvey: PseudoSurveyModule,
    toolfaceData: ToolfaceDataModule,
    gammaData: GammaDataModule,
    pulserData: PulserDataModule,
    directionalData: DirectionalDataModule,
    auth: AuthModule,
    drillingRecommendation: DrillingRecommendationModule,
    directionalRecommendation: DirectionalRecommendationModule,
    wellPlan: WellPlanModule,
    tooltip: TooltipModule,
    app: AppModule,
    formationTop: FormationTopModule,
    bha: BhaModule,
    depthData: DepthDataModule,
    wellData: WellDataModule,
    dataProcessorSettings: DataProcessorSettingsModule,
    directionalSettings: DirectionalSettingsModule,
    user: UserModule,
    rig: RigModule,
    mainTabs: MainTabsModule,
    leftTabs: LeftTabsModule,
    timeCharts: TimeChartsModule,
    depthCharts: DepthChartsModule,
    rightTabs: RightTabsModule,
    directionalGuidanceTab: DirectionalGuidanceTabModule,
    founder: FounderModule,
    torqueDragData: TorqueDragDataModule,
    broomStick: BroomStickModule,
    motor: MotorModule,
    location: LocationModule,
    operator: OperatorModule,
    directionalCompany: DirectionalCompanyModule,
    liveData: LiveDataModule,
    notification: NotificationModule,
    mwdRun: MwdRunModule,
    WellSummaryConfiguration: WellSummaryConfigurationModule,
    WellSummary: WellSummaryModule,
    notificationConfig: NotificationsConfigurationModule,
    Buckling: BucklingModule,
    drillingAdvisorSettings: DrillingAdvisorSettingsModule,
    WellSummaryExportOptions: WellSummaryExportOptionsModule,
    witsConfigs: WitsConfigsModule,
    pumpRecycle: PumpRecycleModule,
    antiCollisionSettings: AntiCollisionSettingsModule,
    performanceMetrics: PerformanceMetricsModule,
    directionalPlot: DirectionalPlotModule,
    scatter: ScatterModule,
    heatmap: HeatmapModule,
    dailyReports: DailyReportsTabModule,
    witsmlTemplate: WitsmlTemplateModule,
    motorTab: MotorTabModule,
    buildTurnAnalysisTab: BuildTurnAnalysisTabModule,
    multiwellCompare: MultiwellCompareModule,
    presetGaugesConfig: PresetGaugesConfigModule,
    mwdToolData: MwdToolDataModule,
    mwdToolSettings: MwdToolSettingsModule,
    apiToken: ApiTokenModule,
    mwdEmData: MwdEmDataModule,
    motorStallData: MotorStallDataModule,
    dashboardPreset: DashboardPresetModule,
    userNotificationSettings: UserNotificationSettingsModule,
    stateTransitionReport: StateTransitionReportModule,
    motorStallSettings: MotorStallSettingsModule,
    hydraulicsData: HydraulicsDataModule,
    hydraulicsSimulator: HydraulicsSimulatorModule,
  },
  actions: {
    // TODO: type for context
    alertSuccess(context, payload: { title: string, message?: string }) {
      Vue.notify({
        group: 'app',
        type: 'success',
        title: payload.title,
        text: payload.message,
        ignoreDuplicates: true,
      } as NotificationOptions);
    },
    alertWarning(context, payload: { title: string, message?: string }) {
      Vue.notify({
        group: 'app',
        type: 'warning',
        duration: 3000,
        title: payload.title,
        text: payload.message,
        ignoreDuplicates: true,
      } as NotificationOptions);
    },
    alertError(context, payload: { title: string, message?: string }) {
      Vue.notify({
        group: 'app',
        type: 'error',
        duration: 10000,
        title: payload.title,
        text: payload.message,
        ignoreDuplicates: true,
      } as NotificationOptions);
    },
    alertEngineNotification(context, payload: { id: string, md: number }) {
      Vue.notify({
        group: 'engine-alerts',
        type: 'success',
        duration: -1,
        id: payload.id,
        data: {
          md: payload.md,
        },
        ignoreDuplicates: true,
      } as NotificationOptions);
    },
  },
});

export default store;
