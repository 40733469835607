import { Tendencies } from '../settings/directional_settings/types';
import { ToolfaceType } from '../toolface_data/types';
import { Section, TraceConfig, WellId } from '../types';

export enum SurveyMutation {
  SET_SURVEY_DATA = 'setSurveyData',
  APPEND_SURVEY_DATA = 'appendSurveyData',
  SET_SURVEY_CHART_ZOOM = 'setSurveyChartZoom',
  CLEAR_SURVEY_DATA = 'clearSurveyData',
  SET_NEXT_SURVEY_DEPTH = 'setNextSurveyDepth',
  APPEND_ITEM_TO_ZOOM_HISTORY = 'appendItemToZoomHistory',
  DELETE_LAST_ITEM_FROM_ZOOM_HISTORY = 'deleteLastItemFromZoomHistory',
  SET_SURVEY_SELECTED_SECTION = 'setSurveySelectedSection',
  SET_EXTERNAL_SURVEYS = 'setExternalSurveys',
  SET_FILTERED_SURVEY_DEPTH_RANGE = 'setFilteredSurveyDepthRange',
  SET_SURVEY_DATA_CLEARED = 'setSurveyDataCleared',
  SET_CUSTOM_DATA = 'setCustomData',
}

export enum SurveyAction {
  FETCH_SURVEY_DATA = 'fetchSurveyData',
  FETCH_EXTERNAL_SURVEYS = 'fetchExternalSurveys',
  UPDATE_SURVEY_ITEM = 'updateSurveyItem',
  DELETE_SURVEY = 'deleteSurvey',
  DELETE_SURVEYS_IN_RANGE = 'deleteSurveysInRange',
}

export type ExternalSurvey = {
  md: number;
  inclination: number;
  azimuth: number;
  tvd: number;
  ns: number;
  ew: number;
};

export type CustomSurveyData = Map<string, Tendencies>;

export type SurveyData = {
  wellId: string;
  ns: number[];
  tvd: number[];
  ew: number[];
  md: number[];
  vs: number[];
  azimuth: number[];
  ca: number[];
  cd: number[];
  dls: number[];
  hti: number[];
  inclination: number[];
  lti: number[];
  rbr: number[][];
  rtr: number[][];
  sbr: number[][];
  section: Section[];
  str: number[][];
  ti: number[];
  time: number[];
  distance2plan: [number, number, number][];
  slideWobSeen: number[];
  slideDiffSeen: number[];
  rotateWobSeen: number[];
  rotateDiffSeen: number[];
  slideRopSeen: number[];
  slideFlowSeen: number[];
  slideRpmSeen: number[];
  rotateRopSeen: number[];
  rotateFlowSeen: number[];
  rotateRpmSeen: number[];
  projInc: [number, number][];
  projAzm: [number, number][];
  projMD: [number, number][];
  projTVD: [number, number][];
  projEW: [number, number][];
  projNS: [number, number][];
  projVS: [number, number][];
  projCD: [number, number][];
  projCA: [number, number][];
  projDistance2plan: [number, number, number][];
  toolfaceType: ToolfaceType[];
  slideScoreSeen: number[];
  slideSeen: number[];
  slideAhead: number[];
  covariance: number[][];
  antiCollision: AntiCollisionItem[][];
};

export type AntiCollisionItem = {
  wellId: WellId;
  SF: number;
  s2c: number;
  sigmaD: number;
  MASD: number;
  ADP: number;
};

export type Survey = {
  ns: number;
  tvd: number;
  ew: number;
  md: number;
  vs: number;
  azimuth: number;
  ca: number;
  cd: number;
  dls: number;
  hti: number;
  inclination: number;
  lti: number;
  rbr: number;
  rtr: number;
  sbr: number;
  section: Section;
  str: number;
  ti: number;
  time: number;
  distance2plan: [number, number, number];
  slideWobSeen: number;
  slideDiffSeen: number;
  rotateWobSeen: number;
  rotateDiffSeen: number;
  slideRopSeen: number;
  slideFlowSeen: number;
  slideRpmSeen: number;
  rotateRopSeen: number;
  rotateFlowSeen: number;
  rotateRpmSeen: number;
  projInc: [number, number];
  projAzm: [number, number];
  projMD: [number, number];
  projTVD: [number, number];
  projEW: [number, number];
  projNS: [number, number];
  projVS: [number, number];
  projCD: [number, number];
  projCA: [number, number];
  projDistance2plan: [number, number, number];
  toolfaceType: ToolfaceType;
  slideScoreSeen: number;
  covariance: number[];
  antiCollision: AntiCollisionItem[];
};

export type SurveyChartData = [number, number, number][];

export type SurveyZoom = {
  ns: [number, number];
  tvd: [number, number];
  ew: [number, number];
  vs?: [number, number];
  isAzimuthIncluded?: boolean;
};

export enum GaugeSurveyTrace {
  NS = 'ns',
  TVD = 'tvd',
  EW = 'ew',
  MD = 'md',
  VS = 'vs',
  CA = 'ca',
  CD = 'cd',
  DLS = 'dls',
  HTI = 'hti',
  LTI = 'lti',
  TI = 'ti',
  INCLINATION = 'inclination',
  AZIMUTH = 'azimuth',
  SLIDE_WOB_SEEN = 'slideWobSeen',
  SLIDE_DIFF_SEEN = 'slideDiffSeen',
  ROTATE_WOB_SEEN = 'rotateWobSeen',
  ROTATE_DIFF_SEEN = 'rotateDiffSeen',
  SLIDE_ROP_SEEN = 'slideRopSeen',
  SLIDE_FLOW_SEEN = 'slideFlowSeen',
  SLIDE_RPM_SEEN = 'slideRpmSeen',
  ROTATE_ROP_SEEN = 'rotateRopSeen',
  ROTATE_FLOW_SEEN = 'rotateFlowSeen',
  ROTATE_RPM_SEEN = 'rotateRpmSeen',
  SLIDE_SCORE_SEEN = 'slideScoreSeen',
}

export enum SurveyTrace {
  NS = 'ns',
  TVD = 'tvd',
  EW = 'ew',
  MD = 'md',
  VS = 'vs',
  AZIMUTH = 'azimuth',
  CA = 'ca',
  CD = 'cd',
  DLS = 'dls',
  HTI = 'hti',
  INCLINATION = 'inclination',
  LTI = 'lti',
  RBR = 'rbr',
  RTR = 'rtr',
  SBR = 'sbr',
  SECTION = 'section',
  STR = 'str',
  TI = 'ti',
  TIME = 'time',
  DISTANCE_2_PLAN = 'distance2plan',
  SLIDE_WOB_SEEN = 'slideWobSeen',
  SLIDE_DIFF_SEEN = 'slideDiffSeen',
  ROTATE_WOB_SEEN = 'rotateWobSeen',
  ROTATE_DIFF_SEEN = 'rotateDiffSeen',
  SLIDE_ROP_SEEN = 'slideRopSeen',
  SLIDE_FLOW_SEEN = 'slideFlowSeen',
  SLIDE_RPM_SEEN = 'slideRpmSeen',
  ROTATE_ROP_SEEN = 'rotateRopSeen',
  ROTATE_FLOW_SEEN = 'rotateFlowSeen',
  ROTATE_RPM_SEEN = 'rotateRpmSeen',
  PROJ_INC = 'projInc',
  PROJ_AZM = 'projAzm',
  PROJ_MD = 'projMD',
  PROJ_TVD = 'projTVD',
  PROJ_EW = 'projEW',
  PROJ_NS = 'projNS',
  PROJ_VS = 'projVS',
  PROJ_CD = 'projCD',
  PROJ_CA = 'projCA',
  PROJ_DISTANCE_2_PLAN = 'projDistance2plan',
  TOOLFACE_TYPE = 'toolfaceType',
  SLIDE_SCORE_SEEN = 'slideScoreSeen',
}

export const SURVEY_TRACE_TO_NAME_AND_UNIT_MAPPING: { [trace: string]: TraceConfig } = {
  [SurveyTrace.NS]: { name: 'NS', unit: 'ft', min: 0, max: 20000 },
  [SurveyTrace.TVD]: { name: 'TVD', unit: 'ft', min: 0, max: 20000 },
  [SurveyTrace.EW]: { name: 'EW', unit: 'ft', min: 0, max: 20000 },
  [SurveyTrace.MD]: { name: 'MD', unit: 'ft', min: 0, max: 40000 },
  [SurveyTrace.VS]: { name: 'VS', unit: 'ft', min: 0, max: 20000 },
  [SurveyTrace.CA]: { name: 'CA', unit: 'deg', min: 0, max: 360 },
  [SurveyTrace.CD]: { name: 'CD', unit: 'ft', min: 0, max: 2000 },
  [SurveyTrace.DLS]: { name: 'DLS', unit: 'deg/100 ft', min: 0, max: 50 },
  [SurveyTrace.HTI]: { name: 'HTI', unit: '', min: 0, max: 50 },
  [SurveyTrace.LTI]: { name: 'LTI', unit: '', min: 0, max: 50 },
  [SurveyTrace.TI]: { name: 'TI', unit: '', min: 0, max: 50 },
  [SurveyTrace.INCLINATION]: { name: 'Inclination', unit: 'deg', min: 0, max: 360 },
  [SurveyTrace.AZIMUTH]: { name: 'Azimuth', unit: 'deg', min: 0, max: 360 },
  [SurveyTrace.SLIDE_WOB_SEEN]: { name: 'Slide WOB Seen', unit: 'klb' },
  [SurveyTrace.SLIDE_DIFF_SEEN]: { name: 'Slide Diff Seen', unit: 'psi' },
  [SurveyTrace.ROTATE_WOB_SEEN]: { name: 'Rotate WOB Seen', unit: 'klb' },
  [SurveyTrace.ROTATE_DIFF_SEEN]: { name: 'Rotate Diff Seen', unit: 'psi' },
  [SurveyTrace.SLIDE_ROP_SEEN]: { name: 'Slide ROP Seen', unit: 'ft/hr' },
  [SurveyTrace.SLIDE_FLOW_SEEN]: { name: 'Slide Flow Seen', unit: 'gpm' },
  [SurveyTrace.SLIDE_RPM_SEEN]: { name: 'Slide RPM Seen', unit: 'rpm' },
  [SurveyTrace.ROTATE_ROP_SEEN]: { name: 'Rotate ROP Seen', unit: 'ft/hr' },
  [SurveyTrace.ROTATE_FLOW_SEEN]: { name: 'Rotate Flow Seen', unit: 'gpm' },
  [SurveyTrace.ROTATE_RPM_SEEN]: { name: 'Rotate RPM Seen', unit: 'rpm' },
  [SurveyTrace.SLIDE_SCORE_SEEN]: { name: 'Slide Score Seen', unit: '%' },
};
