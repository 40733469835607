import { queryServer } from '@/services/socket_service';

import { BroomStick, BroomStickMutation } from './types';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class BroomStickModule extends VuexModule {
  _broomStick: BroomStick | null = null;

  get broomStick(): BroomStick | undefined {
    if(this._broomStick === null) {
      return undefined;
    }
    return this._broomStick;
  }

  @Mutation
  setBroomStick(broomStick: BroomStick): void {
    if(broomStick === undefined) {
      return;
    }

    this._broomStick = _.cloneDeep(broomStick);
  }

  @Mutation
  clearBroomStick(): void {
    this._broomStick = null;
  }

  @Action({ rawError: true })
  async fetchBroomStick(): Promise<void> {
    const event = 'broom-stick/get';
    const params = { wellId: this.context.getters.currentWellId };

    const resp = await queryServer(event, params);

    if(resp === undefined || resp.data === '') {
      return;
    }

    this.context.commit(BroomStickMutation.SET_BROOM_STICK, resp.data);
  }
}
