import { DirectionalRecommendation, DirectionalRecommendationAction, DirectionalRecommendationMutation } from './types';

import { queryApi } from '@/services/server_service';
import { queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class DirectionalRecommendationModule extends VuexModule {
  _directionalRecommendations: DirectionalRecommendation[] | null = null;
  _timestamp: number | undefined;
  _selectedRecommendationTimestamps: number[] | null = null;

  get directionalRecommendations(): DirectionalRecommendation[] | undefined {
    if(this._directionalRecommendations === null) {
      return undefined;
    }
    return this._directionalRecommendations;
  }

  get selectedRecommendationTimestamps(): number[] | undefined {
    if(this._selectedRecommendationTimestamps === null || this._selectedRecommendationTimestamps.length === 0) {
      return undefined;
    }
    return this._selectedRecommendationTimestamps;
  }

  get selectedDirectionalRecommendations(): DirectionalRecommendation[] | undefined {
    const directionalRecommendations = this._directionalRecommendations;
    if(directionalRecommendations === null) {
      return undefined;
    }
    const activeRecommendation = _.find(directionalRecommendations, (recommendation: DirectionalRecommendation) => recommendation.active);
    if(this.selectedRecommendationTimestamps === undefined) {
      if(activeRecommendation !== undefined) {
        return [activeRecommendation];
      } else {
        return [_.last(directionalRecommendations)];
      }
    }
    const floorSelectedTimestamps = _.map(this.selectedRecommendationTimestamps, (timestamp: number) => Math.floor(timestamp));
    return _.filter(
      directionalRecommendations,
      (recommendation: DirectionalRecommendation) => _.includes(floorSelectedTimestamps, Math.floor(recommendation.time)) || recommendation.active
    );
  }

  @Mutation
  setDirectionalRecommendations(recommendations: DirectionalRecommendation[]): void {
    recommendations = recommendations.filter(
      (recommendation: DirectionalRecommendation) => recommendation.time !== undefined && recommendation.md.length > 0
    );
    if(recommendations.length === 0) {
      return;
    }

    const timeList = _.map(recommendations, (el: DirectionalRecommendation) => el.time);
    const lastTime = _.max(timeList);
    store.commit(DirectionalRecommendationMutation.SET_TIMESTAMP, lastTime);

    this._directionalRecommendations = recommendations;
  }

  @Mutation
  appendDirectionalRecommendation(recommendation: DirectionalRecommendation): void {
    if(this._directionalRecommendations !== null) {
      store.commit(DirectionalRecommendationMutation.SET_RECOMMENDATIONS, [...this._directionalRecommendations, recommendation]);
    } else {
      store.commit(DirectionalRecommendationMutation.SET_RECOMMENDATIONS, [recommendation]);
    }
  }

  @Mutation
  setDirectionalRecommendationTimestamp(timestamp: number): void {
    this._timestamp = timestamp;
  }

  @Mutation
  setSelectedRecommendationTimestamp(timestamp: number): void {
    if(this._selectedRecommendationTimestamps === null) {
      this._selectedRecommendationTimestamps = [timestamp];
      return;
    }
    // TODO: move to toggle
    const index = this._selectedRecommendationTimestamps.indexOf(timestamp);
    if(index !== -1) {
      this._selectedRecommendationTimestamps.splice(index, 1);
    } else {
      this._selectedRecommendationTimestamps.push(timestamp);
    }
  }

  @Mutation
  unselectRecommendations(): void {
    this._selectedRecommendationTimestamps = [];
    const activeRecommendation = _.find(this.directionalRecommendations, (recommendation: DirectionalRecommendation) => recommendation.active);
    if(activeRecommendation !== undefined) {
      this._selectedRecommendationTimestamps = [activeRecommendation.time];
    }
  }

  @Mutation
  deleteRecommendation(id: string): void {
    this._directionalRecommendations = this._directionalRecommendations.filter((el: DirectionalRecommendation) => el._id !== id);
  }

  @Mutation
  setActiveRecommendation(id: string): void {
    const activeRecommendation = this._directionalRecommendations.find((recommendation: DirectionalRecommendation) => recommendation._id === id);
    if(activeRecommendation === undefined) {
      return;
    }
    this._directionalRecommendations.forEach((recommendation: DirectionalRecommendation) => recommendation.active = false);
    activeRecommendation.active = true;
  }

  @Mutation
  clearRecommendations(): void {
    this._directionalRecommendations = null;
    this._timestamp = undefined;
  }

  @Action({ rawError: true })
  async fetchDirectionalRecommendations(): Promise<void> {
    let from = this._timestamp;
    if(from !== undefined) {
      from += 1;
    }

    const event = 'directional-recommendation/get';
    const params = {
      wellId: this.context.getters.currentWellId,
      from,
    };

    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }

    this.context.commit(DirectionalRecommendationMutation.SET_RECOMMENDATIONS, resp.data);
  }

  @Action({ rawError: true })
  async deleteDirectionalRecommendation(id: string): Promise<void> {
    if(id === undefined) {
      return;
    }
    const url = `api/directional-recommendation`;
    await queryApi({
      url,
      method: 'DELETE',
      params: {
        wellId: this.context.getters.currentWellId,
        id,
      },
    });
    this.context.commit(DirectionalRecommendationMutation.DELETE_RECOMMENDATION, id);
  }

  @Action({ rawError: true })
  async setRecommendationAsActive(id: string): Promise<void> {
    if(id === undefined) {
      return;
    }
    const url = `api/directional-recommendation`;
    await queryApi({
      url,
      method: 'PATCH',
      data: {
        wellId: this.context.getters.currentWellId,
        id,
        updateValues: { active: true },
      },
    });

    this.context.commit(DirectionalRecommendationMutation.SET_ACTIVE_RECOMMENDATION, id);
  }
}
