















































import FilterModal from '@/components/modals/FilterModal.vue';

import { SectionRanges } from '@/store/modules/well/types';

import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ components: { FilterModal } })
export default class FilterButton extends Vue {
  @Prop({ required: true })
  id: string;

  @Prop({ required: false, default: null })
  initBorders: [number, number];

  @Prop({ required: false, default: null })
  initValues: [number, number];

  @Prop({ required: false, default: true })
  isSliderVisible: boolean;

  @Prop({ required: false, default: false })
  isRpmVisible: boolean;

  @Prop({ required: false, default: true })
  isFormationSelectorVisible: boolean;

  @Prop({ required: false, default: true })
  isSectionSelectorVisible: boolean;

  @Prop({ required: false, default: false })
  isMwdSelectorVisible: boolean;

  @Prop({ required: false, default: true })
  isIconVisible: boolean;

  @Prop({ required: false, default: true })
  isClearBtnVisible: boolean;

  @Prop({ required: false })
  rpmRange: [number, number];

  @Prop({ required: false })
  rpm: [number, number];

  @Prop({ required: false, default: false })
  closeOnApply: boolean;

  @Prop({ required: false })
  placeHolder: string;

  @Prop({ required: false })
  inputFormatter: (value: number) => string;

  @Prop({ required: false, default: 'depth' })
  sliderType: 'depth' | 'time';

  // TODO: use placement(right/left/bottom etc) instead
  @Prop({ required: false, default: null })
  xOffset: number;

  @Prop({ required: false, default: null })
  yOffset: number;

  @Prop({ required: false, default: false })
  windowCenter: boolean;

  @Prop({ required: false, default: false })
  tabRelated: boolean;

  @Prop({ required: false })
  sectionRanges: SectionRanges;

  isModalActive = false;
  xPosition: null | number = null;
  yPosition: null | number = null;

  onFilterBtnClick(event): void {
    if(event !== null && event !== undefined && !this.windowCenter) {
      this.xPosition = event.clientX + 50;
      this.yPosition = event.clientY - 100;
      this.applyOffsets();
    }
    this.isModalActive = true;
    this.$emit('is-filter-modal-opened', true);
  }

  applyOffsets(): void {
    if(this.xOffset) {
      this.xPosition += this.xOffset;
    }
    if(this.yOffset) {
      this.yPosition += this.yOffset;
    }
  }

  onFilterApply(params: { range: [number, number], section: string, rpmRange?: { from: number, to: number }}): void {
    this.$emit('apply-filter', params);
    this.$emit('is-filter-modal-opened', false);
    this.onModalCloseClick();
  }

  get isFilterApplied(): boolean {
    return this.initValues !== null && this.initValues !== undefined;
  }

  public clearFilters(): void {
    // @ts-ignore
    this.$refs[this.id].clearFilters();
  }

  onModalCloseClick(): void {
    this.clearPositions();
    this.isModalActive = false;
  }

  clearPositions(): void {
    this.xPosition = null;
    this.yPosition = null;
  }

  mounted(): void {
    console.log('rerender filter', this.initBorders, this.initValues);
  }
}
