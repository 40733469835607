import { queryApi } from '@/services/server_service';

import { MwdEmData, MwdEmDataMutation } from './types';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

const MWD_EM_DATA_URL = `api/mwd-em-data`;

@Module
export class MwdEmDataModule extends VuexModule {
  _mwdEmData: MwdEmData[] = [];

  get mwdEmData(): MwdEmData[] {
    return this._mwdEmData;
  }

  @Mutation
  setMwdEmData(mwdEmData: MwdEmData[]): void {
    this._mwdEmData = mwdEmData;
  }

  @Action({ rawError: true })
  async fetchMwdEmData(): Promise<void> {
    const resp = await queryApi({
      url: MWD_EM_DATA_URL,
      method: 'GET',
    });

    if(resp === undefined) {
      return;
    }

    this.context.commit(MwdEmDataMutation.SET_MWD_EM_DATA, resp.data);
  }
}
