export const DEFAULT_FORMATION_TOP = {
  wellId: '',
  formationName: '',
  tvdStart: -1.0e4,
  tvdEnd: 1.0e8,
  mdStart: 0,
  mdEnd: 25000,
  baselineMSE: 50.0,
  baselineROP: 80.0,
  color: '#E6E6F8',
};
