import { queryApi } from '@/services/server_service';

import { Country, State, County, LocationMutation } from './types';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

const COUNTRY_URL = `api/country`;
const STATE_URL = `api/state`;
const COUNTY_URL = `api/county`;

@Module
export class LocationModule extends VuexModule {
  _countries: Country[] = [];
  _states: State[] = [];
  _constituencies: County[] = [];

  get countries(): Country[] {
    return this._countries;
  }

  get states(): State[] {
    return this._states;
  }

  get constituencies(): County[] {
    return this._constituencies;
  }

  @Mutation
  setCountries(countries: Country[]): void {
    this._countries = countries;
  }

  @Mutation
  setStates(states: State[]): void {
    this._states = states;
  }

  @Mutation
  setConstituencies(constituencies: County[]): void {
    this._constituencies = constituencies;
  }

  @Action({ rawError: true })
  async fetchCountries(): Promise<void> {
    const resp = await queryApi({
      url: COUNTRY_URL,
      method: 'GET',
    });

    if(resp === undefined) {
      return;
    }

    this.context.commit(LocationMutation.SET_COUNTRIES, resp.data);
  }

  @Action({ rawError: true })
  async fetchStates(countryName: string): Promise<void> {
    if(countryName === undefined) {
      return;
    }
    const resp = await queryApi({
      url: STATE_URL,
      method: 'GET',
      params: { country: countryName },
    });

    if(resp === undefined) {
      return;
    }

    this.context.commit(LocationMutation.SET_STATES, resp.data);
  }

  @Action({ rawError: true })
  async fetchConstituencies(stateAlias: string): Promise<void> {
    if(stateAlias === undefined) {
      return;
    }
    const resp = await queryApi({
      url: COUNTY_URL,
      method: 'GET',
      params: { state: stateAlias },
    });

    if(resp === undefined) {
      return;
    }

    this.context.commit(LocationMutation.SET_CONSTITUENCIES, resp.data);
  }
}
