



































































import Modal from './Modal.vue';
import { AppMutation, WitsPacket } from '@/store/modules/app/types';

import { Component, Vue, Prop } from 'vue-property-decorator';
import * as _ from 'lodash';

@Component({
  components: { Modal },
  filters: {
    date(value: string): string {
      return new Date(+value * 1000).toLocaleString();
    },
  },
})
export default class WitsDebuggerModal extends Vue {
  @Prop({ required: true })
  isModalActive: boolean;

  get witsLiveMode(): boolean {
    return this.$store.getters.witsLiveMode;
  }

  get packets(): WitsPacket[] {
    return this.$store.getters.witsPackets
      .filter((packet: WitsPacket) => packet.raw.wellId === this.$store.getters.currentWellId)
      .reverse();
  }

  toggleWitsLiveMode(): void {
    this.$store.commit(AppMutation.SET_WITS_LIVE_MODE, !this.witsLiveMode);
  }

  getTraceId(trace: string): string {
    if(!this.$store.getters.witsConfigs || _.isEmpty(this.$store.getters.witsConfigs[trace])) {
      return ' (not defined)';
    }
    const id = this.$store.getters.witsConfigs[trace].code;
    return id ? ` (${id})` : '';
  }

  onCloseClick(): void {
    this.$emit('close');
  }
}
