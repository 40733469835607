export enum MotorTabMutation {
  SET_LIVE_DATA = 'setMotorChartLiveData',
  APPEND_LIVE_DATA = 'appendMotorChartLiveData',
  SET_HISTORICAL_DATA = 'setMotorChartHistoricalData',
  SET_LIVE_ZOOM_RANGE = 'setMotorChartLiveZoomRange',
  SET_HISTORICAL_ZOOM_RANGE = 'setMotorChartHistoricalZoomRange',
  SET_DATA_FETCHING = 'setMotorChartDataFetching',
  SET_LIVE_TIME_INTERVAL = 'setMotorLiveTimeInterval',
  SET_MOTOR_WELL_DATA_BUCKET_LIMIT = 'setMotorWellDataBucketLimit',
  COPY_LIVE_DATA_TO_HISTORICAL = 'copyMotorChartLiveDataToHistorical',
  CLEAR_HISTORICAL_DATA = 'clearMotorChartHistoricalData',
  SET_SPIKE_TABLE_ROWS_AMOUNT = 'setSpikeTableRowsAmount',
  SET_SPIKE_TABLE_PAGE = 'setSpikeTablePage',
  SET_LAST_SPIKE_TABLE_PAGE = 'setLastSpikeTablePage',
}

export enum MotorTabAction {
  FETCH_MOTOR_LIVE_WELL_DATA = 'fetchMotorLiveWellData',
  FETCH_MOTOR_HISTORICAL_WELL_DATA = 'fetchMotorHistoricalWellData',
}

export enum MotorLiveModeTimeInterval {
  ONE_HOUR = '1 hour',
  FIFTEEN_MIN = '15 minutes',
}
