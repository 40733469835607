

































import { queryEngine } from '@/services/socket_service';

import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import * as _ from 'lodash';

@Component
export default class RemoteTableSelector extends Vue {
  @Prop({ required: true })
  value: string;

  @Prop({ required: true })
  error: boolean;

  @Prop({ required: true })
  tablesQuery: string;

  @Prop({ required: false, default: 'Select Table' })
  placeholder: string;

  @Prop({ required: false, default: false })
  disabled: boolean;

  tableNames: any | null = null;
  tableSearchText = '';

  tableWellName = 'Loading...';

  @Watch('value')
  onTableChange(): void {
    for(const field in this.tableNames) {
      if(this.tableNames[field] === this.value) {
        this.tableWellName = field;
        return;
      }
    }
    this.tableWellName = this.placeholder;
  }

  get filteredTableNames(): [string, string][] {
    const filteredNames = [];
    for(const field in this.tableNames) {
      const isInclude = _.includes(_.toLower(field), _.toLower(this.tableSearchText));
      if(isInclude === true) {
        filteredNames.push([this.tableNames[field], field]);
      }
    }
    return filteredNames;
  }

  async getTableNames(): Promise<any> {
    const message = this.tablesQuery;
    const payload = {};
    return queryEngine(message, payload);
  }

  onTableSelect(tableName: string): void {
    this.$emit('input', tableName);
    this.tableSearchText = '';
  }

  async created(): Promise<void> {
    this.tableNames = await this.getTableNames();
    this.onTableChange();
  }
}

export async function isSurveyTableAvailable(surveysTable: string): Promise<boolean> {
  const message = 'check-surveys-table-name';
  const payload = { surveysTable };
  return queryEngine(message, payload);
}

export async function isBhaTableAvailable(wellName: string): Promise<boolean> {
  const message = 'get-bhas-from-table';
  const payload = { wellName };
  const resp = await queryEngine(message, payload);
  return !!resp;
}

export async function isWitsmlWellTableAvailable(templateId: string): Promise<boolean> {
  const message = 'get-witsml-well-table';
  const payload = { templateId };
  const resp = await queryEngine(message, payload);

  return true;
}
