












































































import WellSelector from '@/components/selectors/WellSelector.vue';

import { DrillingAdvisorSettings, DrillingAdvisorSettingsAction } from '@/store/modules/settings/drilling_advisor_settings/types';

import { emitEngineEvent } from '@/services/socket_service';

import { Component, Vue } from 'vue-property-decorator';

import * as _ from 'lodash';
import { WellId } from '@/store/modules/types';

const FIELD_MAPPINGS = {
  wellId: 'Well ID',
  offsetWells: 'Offset Wells',
  recommendationPeriod: 'Recommendation Period [ft]',
  lookBackDistance: 'Look Back Distance [ft]',
  lookBackDistanceFromLastFormationTop: 'Allow Backward Pushing',
  _id: '_id',
  paramRanges: 'Parameter Ranges',
};

const PARAM_RANGES_KEY_MAPPINGS = {
  depth: 'Depth [ft]',
  rpm: 'RPM',
  wob: 'WOB [klb]',
  diffPressure: 'Differential Pressure [psi]',
  interpolatedTvd: 'Interpolated TVD [ft]',
  bitUsage: 'Bit Usage [ft]',
  pumpRate: 'Pump Rate [gpm]',
  bitTFA: 'Bit TFA [in^2]',
  bitSize: 'Bit Size [in]',
  motorRPG: 'Motor RPG',
};

const CHECKBOX_FIELDS = ['lookBackDistanceFromLastFormationTop'];
const HIDDEN_FIELDS = ['_id', 'wellId', 'offsetWells'];
// Hide some setting items for now, but still use their default value
const HIDDEN_PARAM_RANGES_KEYS = ['depth', 'interpolatedTvd', 'bitUsage', 'pumpRate', 'bitTFA', 'bitSize', 'motorRPG'];

@Component({ components: { WellSelector } })
export default class DrillingAdvisorSettingsComponent extends Vue {
  get fieldMappings()  {
    return FIELD_MAPPINGS;
  }

  get paramRangesKeyMappings() {
    return PARAM_RANGES_KEY_MAPPINGS;
  }

  get drillingAdvisorSettings(): DrillingAdvisorSettings | undefined {
    return this.$store.getters.drillingAdvisorSettings;
  }

  get dataKeys(): string[] {
    const allKeys = _.keys(this.drillingAdvisorSettings);
    const i = allKeys.indexOf('paramRanges');
    if(i !== -1) {
      allKeys.splice(i, 1);
    }
    return allKeys;
  }

  get paramRangesKeys(): string[] {
    return _.keys(this.drillingAdvisorSettings.paramRanges);
  }

  get paramRange() {
    return (field: string) => {
      return this.drillingAdvisorSettings.paramRanges[field];
    };
  }

  getInputType(field: string): string {
    if(_.includes(CHECKBOX_FIELDS, field)) {
      return 'checkbox';
    }
    return 'number';
  }

  isInputDisabled(field: string): boolean {
    return field === 'lookBackDistance' && this.drillingAdvisorSettings.lookBackDistanceFromLastFormationTop;
  }

  isFieldVisible(field: string): boolean {
    return !_.includes(HIDDEN_FIELDS, field);
  }

  isParamRangesKeyVisible(key: string): boolean {
    return !_.includes(HIDDEN_PARAM_RANGES_KEYS, key);
  }

  async toggleWell(wellId: string): Promise<void> {
    if(!_.includes(this.drillingAdvisorSettings.offsetWells, wellId)) {
      this.drillingAdvisorSettings.offsetWells.push(wellId);
    } else {
      this.drillingAdvisorSettings.offsetWells = _.filter(
        this.drillingAdvisorSettings.offsetWells,
        (offsetWellId: WellId) => offsetWellId !== wellId
      );
    }
  }

  async onApplyClick(): Promise<void> {
    const title = 'Drilling advisor settings';
    if(this.drillingAdvisorSettings.recommendationPeriod < 30) {
      this.$store.dispatch('alertError', { title: title, message: `"recommendationPeriod" value can't be less than 30` });
      return;
    }
    const resp = await this.$store.dispatch(DrillingAdvisorSettingsAction.UPDATE_DRILLING_ADVISOR_SETTINGS, this.drillingAdvisorSettings);
    if(!resp) {
      return;
    }
    await emitEngineEvent('drilling-advisor-settings-updated', { wellId: this.$store.getters.currentWellId });
    await this.$store.dispatch('alertSuccess', { title: title, message: `Changes applied` });
  }
}
