import { queryServer } from '@/services/socket_service';

import { TorqueDragData, TorqueDragDataMutation } from './types';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class TorqueDragDataModule extends VuexModule {
  _torqueDragData: TorqueDragData[] | null = null;
  _md: number | undefined;

  get torqueDragData(): TorqueDragData[] | undefined {
    if(this._torqueDragData === null) {
      return undefined;
    }
    return this._torqueDragData;
  }

  @Mutation
  setTorqueDragData(torqueDragData: TorqueDragData[]): void {
    if(torqueDragData === undefined || torqueDragData.length === 0) {
      return;
    }
    const mdList = _.map(torqueDragData, (el: TorqueDragData) => el.md);
    const lastMd = _.max(mdList);
    store.commit(TorqueDragDataMutation.SET_LAST_MD, lastMd);

    if(this._torqueDragData === null) {
      this._torqueDragData = torqueDragData;
    } else {
      this._torqueDragData = [...this._torqueDragData, ...torqueDragData];
    }
  }

  @Mutation
  setTorqueDragDataLastMd(md: number): void {
    this._md = md;
  }

  @Action({ rawError: true })
  async fetchTorqueDragData(): Promise<void> {
    let from = this._md;
    if(from !== undefined) {
      from += 1;
    }

    const event = 'torque-drag-data/get';
    const params = { wellId: this.context.getters.currentWellId, from };

    const resp = await queryServer(event, params);

    if(resp === undefined) {
      return;
    }

    this.context.commit(TorqueDragDataMutation.SET_TORQUE_DRAG_DATA, resp.data);
  }
}
