































import WellSelector from '@/components/selectors/WellSelector.vue';

import { AntiCollisionSettings, AntiCollisionSettingsAction } from '@/store/modules/settings/anti_collision_settings/types';

import { emitEngineEvent } from '@/services/socket_service';

import { Component, Vue } from 'vue-property-decorator';

import * as _ from 'lodash';
import { WellId } from '@/store/modules/types';

@Component({ components: { WellSelector } })
export default class AntiCollisionSettingsComponent extends Vue {
  get antiCollisionSettings(): AntiCollisionSettings | undefined {
    return this.$store.getters.antiCollisionSettings;
  }

  async toggleWell(wellId: string): Promise<void> {
    if(!_.includes(this.antiCollisionSettings.offsetWellIds, wellId)) {
      this.antiCollisionSettings.offsetWellIds.push(wellId);
    } else {
      this.antiCollisionSettings.offsetWellIds = _.filter(
        this.antiCollisionSettings.offsetWellIds,
        (offsetWellId: WellId) => offsetWellId !== wellId
      );
    }
  }

  async onApplyClick(): Promise<void> {
    const title = 'Anti Collision Settings';
    const resp = await this.$store.dispatch(AntiCollisionSettingsAction.UPDATE_ANTI_COLLISION_SETTINGS, this.antiCollisionSettings);
    if(!resp) {
      return;
    }
    await emitEngineEvent('anti-collision-settings-updated', { wellId: this.$store.getters.currentWellId });
    await this.$store.dispatch('alertSuccess', { title, message: `Changes applied` });
  }
}
