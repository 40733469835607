import { GammaData, GammaDataMutation, GammaDataAction } from './types';
import { WellDataMutation } from '../well_data/types';

import { queryServer } from '@/services/socket_service';
import { DataBucket } from '@/models/bucket';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

const KEY_FIELD = 'time';
const DEFAULT_TIME_INTERVAL = 15 * 60; // sec
const CACHING_MULTIPLIER = 2; // increase data limit in live mode due to caching

@Module
export class GammaDataModule extends VuexModule {
  _gammaData: GammaData[] | null = null;
  _liveData: DataBucket<GammaData> = new DataBucket(DEFAULT_TIME_INTERVAL * CACHING_MULTIPLIER, KEY_FIELD);
  _historicalData?: GammaData[] | null = null;
  _lastFetchedTimestamp: number | null = null;

  get gammaData(): GammaData[] {
    const liveMode = this.context.getters.liveMode;
    if(liveMode) {
      return this._liveData.data as GammaData[];
    } else {
      return this._historicalData || [];
    }
  }

  @Mutation
  copyLiveDataToHistorical(): void {
    if(this._historicalData !== null) {
      return;
    }
    this._historicalData = _.cloneDeep(this._liveData.data);
  }

  @Mutation
  clearHistoricalData(): void {
    this._historicalData = null;
  }

  @Mutation
  setLiveGammaData(gammaData: GammaData[]): void {
    if(_.isEmpty(gammaData)) {
      return;
    }
    this._liveData.setData(gammaData);
  }

  @Mutation
  appendLiveGammaData(gammaData: GammaData[]): void {
    if(_.isEmpty(gammaData)) {
      return;
    }
    this._liveData.appendData(gammaData);
  }

  @Mutation
  setHistoricalGammaData(data: GammaData[]): void {
    if(_.isEmpty(data)) {
      return;
    }
    this._historicalData = data;
  }

  @Mutation
  setGammaDataBucketLimit(limit: number | null): void {
    if(limit === undefined) {
      return;
    }
    this._liveData.setLimit(limit * CACHING_MULTIPLIER);
  }

  @Mutation
  clearGammaData(): void {
    this._gammaData = null;
  }

  @Action({ rawError: true })
  async fetchGammaData(
    payload: { timeBorders?: [number?, number?], maxDocsCount?: number }
  ): Promise<GammaData[]> {
    const event = 'gamma-data/get';
    const params = {
      wellId: this.context.getters.currentWellId,
      from: payload?.timeBorders ? payload.timeBorders[0] : undefined,
      to: payload?.timeBorders ? payload.timeBorders[1] : undefined,
      maxDocsCount: payload?.maxDocsCount,
    };

    const resp = await queryServer(event, params);
    if(resp === undefined || _.isEmpty(resp.data)) {
      return undefined;
    }
    return resp.data;
  }

  @Action({ rawError: true })
  async fetchLiveGammaData(): Promise<void> {
    const liveInterval = store.getters.liveTimeIntervalInSeconds;
    const timeBorders = [store.getters.fromToQueryLiveWellData(liveInterval), undefined];
    const data = await this.context.dispatch(
      GammaDataAction.FETCH_GAMMA_DATA,
      { timeBorders, caching: true }
    );
    if(data === undefined) {
      return;
    }
    store.commit(GammaDataMutation.SET_LIVE_GAMMA_DATA, data);
  }

  @Action({ rawError: true })
  async fetchHistoricalGammaData(
    payload: { timeBorders: [number, number], setFetching: boolean }
  ): Promise<void> {
    const setFetching = payload.setFetching || false;
    if(setFetching) {
      store.commit(WellDataMutation.SET_WELL_DATA_FETCHING, true);
    }
    const data = await this.context.dispatch(
      GammaDataAction.FETCH_GAMMA_DATA,
      { timeBorders: payload.timeBorders, caching: true }
    );
    if(data === undefined) {
      return;
    }
    store.commit(GammaDataMutation.SET_HISTORICAL_GAMMA_DATA, data);
  }
}
