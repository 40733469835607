export enum MotorStallSettingsMutation {
  SET_MOTOR_STALL_SETTINGS = 'setMotorStallSettings',
}

export enum MotorStallSettingsAction {
  FETCH_MOTOR_STALL_SETTINGS = 'fetchMotorStallSettings',
  UPDATE_MOTOR_STALL_SETTINGS = 'updateMotorStallSettings',
}

export type MotorStallSettings = {
  wellId: string;
  interval: number;
  diffPressureIncrease: number;
  torqueIncrease: number;
  movingAverageFactor: number;
  _id: string;
};
