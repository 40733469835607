import { queryServer } from '@/services/socket_service';

import { ApiToken, ApiTokenAction, ApiTokenMutation } from './types';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class ApiTokenModule extends VuexModule {
  _apiTokens: ApiToken[] = [];

  get apiTokens(): ApiToken[] {
    return this._apiTokens;
  }

  @Mutation
  setApiTokens(apiTokens: ApiToken[]): void {
    this._apiTokens = _.cloneDeep(apiTokens);
  }

  @Mutation
  appendApiToken(apiToken: ApiToken): void {
    if(_.isNil(apiToken)) {
      return;
    }
    this._apiTokens.push(_.clone(apiToken));
  }

  @Action({ rawError: true })
  async fetchApiTokens(): Promise<void> {
    const event = 'api-token/get';

    const resp = await queryServer(event, {});

    if(resp === undefined || resp.data === '') {
      return;
    }
    this.context.commit(ApiTokenMutation.SET_API_TOKENS, resp.data);
  }

  @Action({ rawError: true })
  async createApiToken(): Promise<void> {
    const event = 'api-token/post';

    const resp = await queryServer(event, {});

    if(resp === undefined || resp.data === '') {
      return;
    }
    this.context.commit(ApiTokenMutation.APPEND_API_TOKEN, resp.data);
  }

  @Action({ rawError: true })
  async deleteApiToken(tokenId): Promise<void> {
    const event = 'api-token/delete';

    await queryServer(event, { tokenId });

    await this.context.dispatch(ApiTokenAction.FETCH_API_TOKENS);
  }
}
