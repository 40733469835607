export enum StandOption {
  CONNECTION_TIME_BREAKDOWNS = 'connection_time_breakdowns',
  ROTATING_ROP = 'rotating_rop',
  SLIDING_EFFICIENCY = 'sliding_efficiency',
  SLIDING_ROP = 'sliding_rop',
  ACTIVITY_BREAKDOWNS = 'activity_breakdowns',
}

export enum LeftTabsMutation {
  TOGGLE_SUBTAB_VISIBILITY = 'toggleSubtabVisibility',
  SET_CONNECTION_TIME_BREAKDOWN_TYPE = 'setConnectionTimeBreakdownType',
  SET_SUBTAB_CONFIGS = 'setSubTabConfigs',
  TOGGLE_DEPTH_VS_DAYS_CHART_VISIBILITY = 'toggleDepthVsDaysChartVisibility',
  SET_ACTIVE_DRILLING_DATA_SUB_TAB = 'setActiveDrillingDataSubTab',
  SET_ACTIVE_PERFORMANCE_METRICS_SUB_TAB = 'setActivePerformanceMetricsSubTab',
}

export enum DrillingDataSubTab {
  TIME = 'Time Charts',
  DEPTH = 'Depth Charts',
  COMPARE_WELLS = 'Compare Wells',
}

export enum PerformanceMetricsSubTab {
  PERFORMANCE_METRICS = 'Performance Metrics',
  STATE_TRANSITION_REPORTS = 'State Transition Reports',
}

export type SubTabConfig = {
  label: string;
  fields: string[];
  legend: string[];
  visible: boolean;
};

export type SubTabConfigs = {
  [StandOption.ACTIVITY_BREAKDOWNS]: SubTabConfig;
  [StandOption.CONNECTION_TIME_BREAKDOWNS]: SubTabConfig;
  [StandOption.ROTATING_ROP]: SubTabConfig;
  [StandOption.SLIDING_EFFICIENCY]: SubTabConfig;
  [StandOption.SLIDING_ROP]: SubTabConfig;
};

export enum BreakdownType {
  SLIPS = 'Slips',
  PUMPS = 'Pumps',
}
