import Vue, { VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';
import VanillaTooltip from '@/components/vanillaTooltip';

const tooltipInstancesMap: { [uid: string]: VanillaTooltip } = {};

Vue.directive('tooltip', {
  inserted: (el: HTMLElement, binding: DirectiveBinding, { data: { attrs }}: VNode): void => {
    const tooltip = new VanillaTooltip(el, attrs['tooltip-content']);
    tooltipInstancesMap[tooltip.uid] = tooltip;
    el.setAttribute('uid', tooltip.uid);
  },
  unbind: (el: HTMLElement, binding: DirectiveBinding, { data: { attrs: { title }}}: VNode) => {
    const uid = el.getAttribute('uid');
    tooltipInstancesMap[uid]?.removeRelatives();
    delete tooltipInstancesMap[uid];
  },
});
