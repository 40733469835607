export enum AuthMutation {
  SET_TOKEN = 'setToken',
  CLEAR_TOKEN = 'clearToken',
  SET_ERROR = 'setError',
}

export enum AuthAction {
  LOGOUT = 'logout',
  LOGIN = 'login',
  CHECK_SERVER_CONNECTION = 'checkServerConnection',
}
