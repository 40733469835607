






























































































import { Sized } from '@/components/ui-kit';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { mixins } from 'vue-class-component';

import * as _ from 'lodash';

export type Option = any | { checked: boolean, disabled: boolean, key: FieldKey, value: string };
export type FieldKey = string;
export type Field = {
  key: FieldKey; value: string;
  disabled?: boolean; checked?: boolean;
  option: Option;
};

const DEFAULT_VALUE_FORMATTER = (option: string) => String(option);
const DEFAULT_KEY_FORMATTER = (option: string) => String(option);

@Component
export default class SearchableSelector extends mixins(Sized) {
  @Prop({ required: true })
  placeholder!: string;

  @Prop({ required: true })
  options!: Option[];

  @Prop({ required: false, default: () => [] })
  selectedFieldKeys: FieldKey[];

  @Prop({ required: false, default: Number.POSITIVE_INFINITY })
  maxSelectedFields: number;

  @Prop({ required: false, default: true })
  multi: boolean;

  @Prop({ required: false, default: false })
  disabled: boolean;

  @Prop({ required: false, default: '' })
  id: string;

  @Prop({ required: false, default: () => DEFAULT_VALUE_FORMATTER })
  valueFormatter: (option: Option) => string;

  @Prop({ required: false, default: () => DEFAULT_KEY_FORMATTER })
  keyFormatter: (option: Option) => string;

  @Prop({ required: false, default: true })
  searchable: boolean;

  @Prop({ required: false })
  width: string;

  @Prop({ required: false, default: false })
  noBoundaries: boolean;

  @Prop({ required: false, default: '' })
  theme: string;

  @Prop({ required: false, default: false })
  listAutoWidth: boolean;

  @Prop({ required: false, default: null })
  differenceTextClick: (e: MouseEvent) => void | null;

  @Prop({ required: false, default: false })
  isOpenOnTop: boolean;

  @Prop({ required: false, default: false })
  selectAll: boolean;

  @Prop({ required: false })
  customItem: string;

  @Prop({ required: false, default: false })
  alwaysPlaceholder: boolean;

  searchText = '';
  selectedFieldKey = '';
  skipFiltering = false; // hack not to hide traces on mounted in single mode
  selectAllValue: boolean = false;

  @Watch('fields', { deep: true })
  onFieldsChange(): void {
    this.selectAllValue = this.fields.every((field: Field) => field.checked);
  }

  @Watch('options', { deep: true })
  @Watch('selectedFieldKeys', { deep: true })
  onOptionsChange(newVal: Option[], oldVal: Option[]): void {
    if(!_.isEqual(newVal, oldVal)) {
      this.resetSelector();
    }
  }

  get isAllFieldsSelected(): boolean {
    return this.options.length === this.selectedFieldKeys.length;
  }

  get inputPlaceholder(): string {
    if(this.alwaysPlaceholder) {
      return this.placeholder;
    }
    if(this.multi === true) {
      return this.selectedFieldKey || this.placeholder;
    } else {
      return _.isNil(this.getSelectedField()?.value) ? this.placeholder : this.getSelectedField().value;
    }
  }

  getSelectedField(): Field | undefined {
    if(this.selectedFieldKey === '') {
      return undefined;
    } else {
      return _.find(this.fields, (f: Field) => f.key === this.selectedFieldKey);
    }
  }

  get fields(): Field[] {
    const res: Field[] = this.options.map((o: Option) => ({
      key: o.key !== undefined ? o.key : this.keyFormatter(o),
      value: o.value !== undefined ? o.value : this.valueFormatter(o),
      option: o,
      checked: o.checked,
      disabled: o.disabled,
    }));
    res.forEach((f: Field) => {
      f.checked = this.isFieldChecked(f);
      f.disabled = this.isFieldDisabled(f);
    });
    return res;
  }

  get filteredFields(): Field[] {
    if(this.skipFiltering || !this.searchText) {
      return this.fields;
    }
    const searchTextLower = _.toLower(this.searchText);
    return this.fields.filter(
      (field: Field) => _.includes(
        _.toLower(field.value), searchTextLower
      )
    );
  }

  get hasFooterSlot(): boolean {
    return !!this.$slots.footer;
  }

  getFormattedField(option: Option): string {
    return this.valueFormatter(option);
  }

  isFieldChecked(field: Field): boolean {
    if(this.multi) {
      if(field.checked === true) {
        return true;
      }
      return _.includes(this.selectedFieldKeys, field.key);
    }
    return field.key === this.selectedFieldKey;
  }

  isFieldDisabled(field: Field): boolean {
    if(field.disabled === true) {
      return true;
    }
    if(field.checked) {
      return false;
    }
    return this.selectedFieldKeys.length >= this.maxSelectedFields;
  }

  onTextHeaderClick(event: MouseEvent) {
    if(this.differenceTextClick) {
      this.differenceTextClick(event);
    }
  }

  onDropdownItemClick($event: Event, field: Field): void {
    if(field.disabled) {
      return;
    }
    this.toggleField(field);
    if(this.multi) {
      $event.stopPropagation();
    } else {
      // @ts-ignore
      this.$refs.dropdown.close();
    }
  }

  onDropdownAllClick(event: MouseEvent): void {
    this.$emit('toggle-all-fields');
    this.setPlaceholderForMulti();
  }

  onChange($event: Event) {
    // @ts-ignore
    this.$refs.dropdown.showDropdown();
    this.skipFiltering = false;
  }

  setPlaceholderForMulti() {
    this.selectedFieldKey = this.fields.reduce((acc: string[], field: Field): string[] => {
      if(field.checked) {
        acc.push(field.value);
      }

      return acc;
    }, []).join(', ');
  }

  toggleField(field: Field): void {
    if(field.disabled) {
      return;
    }
    // user of the component should delete key from `selectedFieldKeys` if necessary
    // otherwise field will remain checked because it's in the `selectedFieldKeys` list
    field.checked = !field.checked;
    if(!this.multi) {
      this.searchText = '';
      this.selectedFieldKey = field.key;
    } else {
      this.setPlaceholderForMulti();
    }

    this.$emit('toggle-field', field);
  }

  resetSelector(): void {
    this.searchText = '';
    this.selectedFieldKey = '';
    if(_.isEmpty(this.selectedFieldKeys)) {
      return;
    }
    if(this.multi) {
      this.setPlaceholderForMulti();

      return;
    }
    if(this.selectedFieldKeys.length > 1) {
      throw new Error(`Select more then one key for single mode`);
    }
    this.skipFiltering = true;
    this.selectedFieldKey = this.selectedFieldKeys[0];
  }

  beforeUpdate(): void {
    if(this.multi) {
      this.setPlaceholderForMulti();
    }
  }

  created(): void {
    this.resetSelector();
  }
}
