import { WellId } from '../types';

export type StateTransitionReport = {
  drillingState: DrillingState;
  weightDrilledOff: boolean;
  holeDepth: number;
  wobChangeRate: ChangeRate;
  ropChangeRate: ChangeRate;
  blockHeightChangeRate: ChangeRate;
  blockHeightDecrease: boolean;
  wellId: WellId;
  timeRange: [number, number];
  _id: string;
};

export type ChangeRate = { max: number, min: number, avg: number };

export enum StateTransitionReportTrace {
  DEPTH = 'holeDepth',
  ROP_MAX = 'ropMax',
  WOB_MAX = 'wobMax',
  WOB_MIN = 'wobMin',
  BLOCK_SPEED_MAX = 'blockSpeedMax',
  WEIGHT_DRILLED = 'weightDrilled',
  TIME_START = 'timeStart',
  TIME_END = 'timeEnd',
}

export const STATE_TRANSITION_REPORT_TRACE_TITLE_MAPPING = {
  [StateTransitionReportTrace.DEPTH]: 'Depth',
  [StateTransitionReportTrace.ROP_MAX]: 'Δ ROP (max)',
  [StateTransitionReportTrace.WOB_MAX]: 'Δ WOB (max)',
  [StateTransitionReportTrace.WOB_MIN]: 'Δ WOB (min)',
  [StateTransitionReportTrace.BLOCK_SPEED_MAX]: 'Block Speed (max)',
  [StateTransitionReportTrace.WEIGHT_DRILLED]: 'Weight Drilled off',
  [StateTransitionReportTrace.TIME_START]: 'Start Time',
  [StateTransitionReportTrace.TIME_END]: 'End Time',
};

export enum DrillingState {
  DRILLING = 'drilling',
  NOT_DRILLING = 'not_drilling',
}

export enum StateTransitionReportMutation {
  SET_STATE_TRANSITION_REPORTS = 'setStateTransitionReports',
  APPEND_STATE_TRANSITION_REPORTS = 'appendStateTransitionReports',
}

export enum StateTransitionReportAction {
  FETCH_STATE_TRANSITION_REPORTS = 'fetchStateTransitionReports',
}
