import { DirectionalData, DirectionalDataMutation } from './types';

import { queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class DirectionalDataModule extends VuexModule {
  _directionalData: DirectionalData[] = [];

  get directionalData(): DirectionalData[] {
    return this._directionalData;
  }

  @Mutation
  setDirectionalData(directionalData: DirectionalData[]): void {
    if(_.isEmpty(directionalData)) {
      return;
    }
    this._directionalData = directionalData;
  }

  @Mutation
  appendDirectionalData(directionalData: DirectionalData[]): void {
    if(_.isEmpty(directionalData)) {
      return;
    }
    this._directionalData = [...this._directionalData, ...directionalData];
  }

  @Action({ rawError: true })
  async fetchDirectionalData(maxDocsCount = 1000): Promise<void> {
    const event = 'directional-data/get';
    const params = {
      wellId: this.context.getters.currentWellId,
      maxDocsCount,
    };

    const resp = await queryServer(event, params);
    if(_.isEmpty(resp?.data)) {
      return;
    }

    this.context.commit(DirectionalDataMutation.SET_DIRECTIONAL_DATA, resp.data);
  }
}
