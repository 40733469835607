


















































import {
  AlertLevel,
  Notification,
  NotificationDatePeriod,
  NotificationDatePeriodNames,
  NotificationDatePeriodSeconds,
  NotificationMutation,
  NotificationType,
  NotificationAction,
  NotificationVisibility,
} from '@/store/modules/notification/types';
import { Field } from '@/components/selectors/SearchableSelector.vue';
import { isRouteAllowed, RouteName } from '@/permissions';

import { Component, Prop, Vue } from 'vue-property-decorator';

import * as _ from 'lodash';

let isFirstModalOpen = true;

@Component
export default class NotificationHistoryFilters extends Vue {
  datePeriods = _.keys(NotificationDatePeriod).filter((key: string) => !(parseInt(key) >= 0));
  datePeriodsInSeconds = _.values(NotificationDatePeriodSeconds).filter((val: any) => _.isNumber(val));

  @Prop({ required: true })
  allNotifications: Notification[];

  get isNotificationsDisabled(): boolean {
    return !isRouteAllowed(RouteName.NOTIFICATION_CENTER);
  }

  get alertTypes(): string[] {
    return _.values(NotificationType);
  }

  get alertLevels(): string[] {
    return _.values(AlertLevel);
  }

  get selectedPeriodTime(): number {
    return this.$store.getters.filteredPeriodTime;
  }

  get selectedPeriodTimeKey(): string {
    const idx = _.indexOf(this.datePeriodsInSeconds, this.selectedPeriodTime);
    return this.datePeriods[idx];
  }

  get selectedAlertTypes(): string[] {
    return this.$store.getters.filteredAlertTypes;
  }

  get selectedAlertLevels(): string[] {
    return this.$store.getters.filteredAlertLevels;
  }

  get selectedVisibility(): NotificationVisibility {
    return this.$store.getters.visibilityState;
  }

  get visibilityButtonText(): string {
    return this.selectedVisibility === NotificationVisibility.NEW ? 'Archive All' : 'Unarchive All';
  }

  get visibleNotifications(): Notification[] {
    return this.$store.getters.visibleNotifications;
  }

  get hiddenNotifications(): Notification[] {
    return this.$store.getters.hiddenNotifications;
  }

  get filteredNotification(): Notification[] {
    const notifications = this.selectedVisibility === NotificationVisibility.ARCHIVE ? this.hiddenNotifications : this.visibleNotifications;

    return notifications.filter((notification: Notification) => {
      const type = notification.type || 'Unknown type';
      const level = notification.alertLevel || 'Unknown level';

      return this.$store.getters.filteredAlertTypes.includes(type) &&
        this.$store.getters.filteredAlertLevels.includes(level);
    });
  }

  async selectAlertTypeOption(field: Field): Promise<void> {
    await this.setAlertType(field?.key);
  }

  async selectAlertLevelOption(field: Field): Promise<void> {
    await this.setAlertLevel(field?.key);
  }

  async selectDateRangeOption(field: Field): Promise<void> {
    await this.setPeriodTime(field?.key);
  }

  isAlertTypeSelected(type: string): boolean {
    return this.$store.getters.filteredAlertTypes.includes(type);
  }

  isAlertLevelSelected(type: string): boolean {
    return this.$store.getters.filteredAlertLevels.includes(type);
  }

  getHumanizedAlertType(type: string): string {
    return _.startCase(type);
  }

  getPeriodName(key: string): string {
    return NotificationDatePeriodNames[key];
  }

  getPeriodTime(key: string): number {
    return +NotificationDatePeriodSeconds[key];
  }

  getPeriodKeyByTime(): string {
    return NotificationDatePeriod[this.selectedPeriodTime];
  }

  async setAlertType(type: string): Promise<void> {
    await this.$store.commit(NotificationMutation.SET_ALERT_TYPES, type);
  }

  async setAlertLevel(level: string | undefined): Promise<void> {
    await this.$store.commit(NotificationMutation.SET_ALERT_LEVELS, level);
  }

  async setPeriodTime(key: string): Promise<void> {
    await this.$store.commit(NotificationMutation.SET_PERIOD_TIME, key);
  }

  toggleFilteredNotificationsVisibility(): void {
    const hidden = this.selectedVisibility === NotificationVisibility.ARCHIVE;
    const ids = _.map(this.filteredNotification, (notification: Notification) => notification._id);
    const payload = { hidden: !hidden, ids };
    this.$store.dispatch(NotificationAction.CHANGE_MULTIPLE_NOTIFICATIONS_VISIBILITY, payload);
  }

  async mounted(): Promise<void> {
    if(isFirstModalOpen) {
      await this.$store.commit(NotificationMutation.SET_ALERT_TYPES_BUNCH, this.alertTypes);
      await this.$store.commit(NotificationMutation.SET_ALERT_LEVELS_BUNCH, this.alertLevels);

      isFirstModalOpen = false;
    }
  }
}
