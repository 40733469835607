import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';
import { IRangeData, MultiwellCompareFiltersMutation, TRangeType } from '@/store/modules/multiwell_compare/types';
import store from '@/store';

interface ITrajectoryFilters {
  rangeDepthData: IRangeData;
  rangeKickOffPointData: IRangeData;
  rangeLandingTVDData: IRangeData;
}

@Module
export class MultiwellCompareFilterTrajectoryModule extends VuexModule {
  _rangeDepthData: IRangeData = { from: null, to: null };
  _rangeKickOffPointData: IRangeData = { from: null, to: null };
  _rangeLandingTVDData: IRangeData = { from: null, to: null };

  get selectedRangeDepthData(): IRangeData {
    return this._rangeDepthData;
  }

  get selectedRangeKickOffPointData(): IRangeData {
    return this._rangeKickOffPointData;
  }

  get selectedRangeLandingTVDData(): IRangeData {
    return this._rangeLandingTVDData;
  }

  get accessibleData(): string {
    const dataStringsArray: string[] = [];

    if(this.selectedRangeDepthData?.from && this.selectedRangeDepthData?.to) {
      dataStringsArray.push(`Trajectory Depth from ${this.selectedRangeDepthData?.from} ft to ${this.selectedRangeDepthData?.to} ft`);
    }

    if(this.selectedRangeKickOffPointData?.from && this.selectedRangeKickOffPointData?.to) {
      dataStringsArray.push(`Trajectory Kick-off Point from ${this.selectedRangeKickOffPointData?.from} ft to ${this.selectedRangeKickOffPointData?.to} ft`);
    }

    if(this.selectedRangeLandingTVDData?.from && this.selectedRangeLandingTVDData?.to) {
      dataStringsArray.push(`Trajectory Landing TVD from ${this.selectedRangeLandingTVDData?.from} ft to ${this.selectedRangeLandingTVDData?.to} ft`);
    }

    return dataStringsArray.join(';\t');
  }

  @Mutation
  setSelectedRangeDepthData(depthData: IRangeData) {
    this._rangeDepthData = depthData;
  }

  @Mutation
  setSelectedRangeKickOffPointData(kickOffPointData: IRangeData) {
    this._rangeKickOffPointData = kickOffPointData;
  }

  @Mutation
  setSelectedRangeLandingTVDData(landingTVDData: IRangeData) {
    this._rangeLandingTVDData = landingTVDData;
  }

  @Action
  setTrajectoryFilters(fullTrajectory: ITrajectoryFilters): void {
    store.commit(MultiwellCompareFiltersMutation.SET_TRAJECTORY_DEPTH, fullTrajectory.rangeDepthData);
    store.commit(MultiwellCompareFiltersMutation.SET_TRAJECTORY_KICK_OFF_POINT, fullTrajectory.rangeKickOffPointData);
    store.commit(MultiwellCompareFiltersMutation.SET_TRAJECTORY_LANDING_TVD, fullTrajectory.rangeLandingTVDData);
  }

  @Action
  clearSelectedRanges(): void {
    store.commit(MultiwellCompareFiltersMutation.SET_TRAJECTORY_DEPTH, { from: null, to: null });
    store.commit(MultiwellCompareFiltersMutation.SET_TRAJECTORY_KICK_OFF_POINT, { from: null, to: null });
    store.commit(MultiwellCompareFiltersMutation.SET_TRAJECTORY_LANDING_TVD, { from: null, to: null });
  }
}
