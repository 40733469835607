import { WellId } from '../types';

export type NewUser = {
  name: string;
  username: string;
  password: string;
  email: string;
  type: UserType;
};

export enum UserType {
  ADMIN = 'admin',
  ENGINEER = 'engineer',
  GUEST = 'guest',
}

export type DirectionalAdvisorNotifications = {
  email: WellId[];
  whatsapp: WellId[];
  voice: WellId[];
};

export type User = {
  name: string;
  username: string;
  password: string;
  company: string;
  wellIds: string[];
  rigIds: string[];
  favoriteWells: string[];
  email: string;
  cellPhone: string;
  type: UserType;
  _id: string;
  activePreset: string;
};

export enum UserMutation {
  SET_USER = 'setUser',
  SET_USERS = 'setUsers',
  DELETE_LOCAL_USER = 'deleteLocalUser',
  PATCH_USER = 'patchUser',
}

export enum UserAction {
  FETCH_USER = 'fetchUser',
  FETCH_USERS = 'fetchUsers',
  UPDATE_USER = 'updateUser',
  CREATE_USER = 'createUser',
  DELETE_USER = 'deleteUser',
  SET_FAVORITE_WELLS = 'setFavoriteWells',
}
