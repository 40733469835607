import { MwdToolSettings, MwdToolSettingsMutation } from './types';

import { queryApi } from '@/services/server_service';

import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';

import { AxiosResponse } from 'axios';
import * as _ from 'lodash';

const URL = 'api/mwd-tool-settings';

@Module
export class MwdToolSettingsModule extends VuexModule {
  _mwdToolSettings: MwdToolSettings | null = null;

  get mwdToolSettings(): MwdToolSettings | null {
    return this._mwdToolSettings;
  }

  @Mutation
  setMwdToolSettings(mwdToolSettings: MwdToolSettings): void {
    if(!_.isEmpty(mwdToolSettings)) {
      this._mwdToolSettings = _.cloneDeep(mwdToolSettings);
    }
  }

  @Action({ rawError: true })
  async fetchMwdToolSettings(): Promise<AxiosResponse | undefined> {
    const resp = await queryApi({
      url: URL,
      method: 'GET',
    });
    if(resp?.data) {
      this.context.commit(MwdToolSettingsMutation.SET_MWD_TOOL_SETTINGS, resp.data);
    }
    return resp?.data;
  }

  @Action({ rawError: true })
  async updateMwdToolSettings(updatedSettings: MwdToolSettings): Promise<AxiosResponse | undefined> {
    const resp = await queryApi({
      url: URL,
      method: 'POST',
      data: updatedSettings,
    });
    if(resp) {
      this.context.commit(MwdToolSettingsMutation.SET_MWD_TOOL_SETTINGS, updatedSettings);
    }
    return resp;
  }
}
