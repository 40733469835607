






import { Component, Vue, Prop } from 'vue-property-decorator';
import * as _ from 'lodash';

@Component
export default class ToggleIcon extends Vue {
  @Prop({ required: true })
  isActive!: boolean;

  onToggle(): void {
    this.$emit('toggle');
  }
}
