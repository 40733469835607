import { MotorTabMutation, MotorLiveModeTimeInterval } from './types';

import { WellData, WellDataAction } from '@/store/modules/well_data/types';
import { AppMutation } from '@/store/modules/app/types';
import { MotorSubTab } from '@/store/modules/tabs/right_tabs/types';
import { RowsRepPage } from '@/store/modules/types';

import { DataBucket } from '@/models/bucket';

import store from '@/store';

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import * as _ from 'lodash';

const KEY_FIELD = 'correctedTimestamp';
const DEFAULT_TIME_INTERVAL = 15 * 60; // sec
const CACHING_MULTIPLIER = 1; // no caching

@Module
export class MotorTabModule extends VuexModule {
  _liveData: DataBucket<WellData> = new DataBucket(DEFAULT_TIME_INTERVAL * CACHING_MULTIPLIER, KEY_FIELD);
  _historicalData?: WellData | null = null;
  // zoom ranges are always with corrected timestamps
  _historicalZoomRange: null | [number, number] = null;
  _liveZoomRange: null | [number, number] = null;
  _isDataFetching = false;
  _motorLiveTimeInterval = MotorLiveModeTimeInterval.FIFTEEN_MIN;
  _selectedSpikeTableRowsAmount = RowsRepPage.ROW_10;
  _selectedSpikeTablePage = 0;

  get motorWellData(): WellData | undefined {
    const liveMode = this.context.getters.liveMode;
    if(liveMode) {
      return this._liveData.data as WellData;
    } else {
      return this._historicalData || undefined;
    }
  }

  get motorChartZoomRange(): [number, number] | undefined {
    const liveMode = this.context.getters.liveMode;
    if(liveMode) {
      return this.context.getters.motorChartLiveZoomRange;
    } else {
      return this.context.getters.motorChartHistoricalZoomRange;
    }
  }

  get motorChartLiveZoomRange(): [number, number] | undefined {
    if(_.isEmpty(this._liveZoomRange)) {
      return undefined;
    }
    return this._liveZoomRange;
  }

  get motorChartHistoricalZoomRange(): [number, number] | undefined {
    if(_.isEmpty(this._historicalZoomRange)) {
      return undefined;
    }
    return this._historicalZoomRange;
  }

  get isMotorChartDataFetcing(): boolean {
    return this._isDataFetching;
  }

  get motorLiveTimeInterval(): MotorLiveModeTimeInterval {
    return this._motorLiveTimeInterval;
  }

  get motorLiveTimeIntervalInSeconds(): number {
    switch(this._motorLiveTimeInterval) {
      case MotorLiveModeTimeInterval.FIFTEEN_MIN:
        return 15 * 60;
      case MotorLiveModeTimeInterval.ONE_HOUR:
        return 60 * 60;
      default:
        throw new Error(`Unknown type of ${this._motorLiveTimeInterval}`);
    }
  }

  get selectedSpikeTableRowsAmount(): RowsRepPage {
    return this._selectedSpikeTableRowsAmount;
  }

  get selectedSpikeTablePage(): number {
    return this._selectedSpikeTablePage;
  }

  @Mutation
  setSpikeTableRowsAmount(rows: RowsRepPage): void {
    this._selectedSpikeTableRowsAmount = rows;
  }

  @Mutation
  setSpikeTablePage(pageIndex: number): void {
    this._selectedSpikeTablePage = pageIndex;
  }

  @Mutation
  setLastSpikeTablePage(): void {
    const totalPages = Math.ceil(store.getters.motorStallData.length / store.getters.selectedSpikeTableRowsAmount);
    const lastPage = _.clamp(totalPages - 1, 0, totalPages);
    this._selectedSpikeTablePage = lastPage;
  }

  @Mutation
  setMotorLiveTimeInterval(interval: MotorLiveModeTimeInterval): void {
    this._motorLiveTimeInterval = interval;
    store.commit(MotorTabMutation.SET_MOTOR_WELL_DATA_BUCKET_LIMIT, store.getters.motorLiveTimeIntervalInSeconds);
  }

  @Mutation
  setMotorChartDataFetching(fetchingStatus: boolean): void {
    this._isDataFetching = fetchingStatus;
  }

  @Mutation
  setMotorChartLiveZoomRange(range: [number, number] | null): void {
    if(_.isEmpty(range)) {
      this._liveZoomRange = null;
      return;
    }
    const borders = store.getters.wellBorderCorrectedTimestamps;
    const updatedRange: [number, number] = [Math.max(range[0], borders[0]), Math.min(range[1], borders[1])];
    this._liveZoomRange = updatedRange;
  }

  @Mutation
  setMotorChartHistoricalZoomRange(range: [number, number] | null): void {
    if(_.isEmpty(range)) {
      this._historicalZoomRange = null;
      return;
    }
    const borders = store.getters.wellBorderCorrectedTimestamps;
    const updatedRange: [number, number] = [Math.max(range[0], borders[0]), Math.min(range[1], borders[1])];
    this._historicalZoomRange = updatedRange;
  }

  @Mutation
  setMotorWellDataBucketLimit(limit: number): void {
    if(limit === undefined) {
      return;
    }
    this._liveData.setLimit(limit);
  }

  @Mutation
  setMotorChartLiveData(data: WellData): void {
    if(_.isEmpty(data?.correctedTimestamp)) {
      return;
    }
    const lastCorrectedTime = _.last(data.correctedTimestamp);
    const leftBorder = lastCorrectedTime - store.getters.motorLiveTimeIntervalInSeconds;
    const liveZoomRange = [leftBorder, lastCorrectedTime];
    store.commit(MotorTabMutation.SET_LIVE_ZOOM_RANGE, liveZoomRange);
    this._liveData.setData(data);
  }

  @Mutation
  appendMotorChartLiveData(data: WellData): void {
    if(_.isEmpty(data?.correctedTimestamp)) {
      return;
    }
    const lastCorrectedTime = _.last(data.correctedTimestamp);
    const leftBorder = lastCorrectedTime - store.getters.motorLiveTimeIntervalInSeconds;
    const liveZoomRange = [leftBorder, lastCorrectedTime];
    store.commit(MotorTabMutation.SET_LIVE_ZOOM_RANGE, liveZoomRange);
    // TODO: add projection fields to filter data
    this._liveData.appendData(data);
  }

  @Mutation
  copyMotorChartLiveDataToHistorical(): void {
    this._historicalData = _.cloneDeep(this._liveData.data);
  }

  @Mutation
  clearMotorChartHistoricalData(): void {
    this._historicalData = null;
  }

  @Mutation
  setMotorChartHistoricalData(data: WellData): void {
    if(_.isEmpty(data?.correctedTimestamp)) {
      return;
    }
    this._historicalData = data;
  }

  @Action({ rawError: true })
  async fetchMotorLiveWellData(): Promise<void> {
    const liveInterval = store.getters.motorLiveTimeIntervalInSeconds;
    const timeBorders = [store.getters.fromToQueryLiveWellData(liveInterval), undefined];
    const params = {
      timeBorders,
      projection: {
        torque: 1,
        diffPressure: 1,
        estimatedDiffPressure: 1,
      },
      caching: false,
    };

    const respData = await store.dispatch(WellDataAction.FETCH_WELL_DATA, params);
    if(_.isEmpty(respData?.correctedTimestamp)) {
      return;
    }
    this.context.commit(MotorTabMutation.SET_LIVE_DATA, respData);
  }

  @Action({ rawError: true })
  async fetchMotorHistoricalWellData(timeBorders: [number, number]): Promise<void> {
    store.commit(MotorTabMutation.SET_DATA_FETCHING, true);
    store.commit(MotorTabMutation.SET_HISTORICAL_ZOOM_RANGE, timeBorders);
    const projection = {
      torque: 1,
      diffPressure: 1,
      estimatedDiffPressure: 1,
    };
    const data = await this.context.dispatch(
      WellDataAction.FETCH_WELL_DATA,
      { timeBorders, projection, caching: false }
    );
    if(data === undefined) {
      store.dispatch('alertWarning', { title: 'Motor Performance', message: `Data can't be fetched for range ${timeBorders}` });
      store.commit(MotorTabMutation.SET_DATA_FETCHING, false);
      return;
    }
    store.commit(MotorTabMutation.SET_HISTORICAL_DATA, data);
    store.commit(MotorTabMutation.SET_DATA_FETCHING, false);
    if(store.getters.liveMode) {
      store.commit(AppMutation.TOGGLE_LIVE_MODE, MotorSubTab.STALL_DETECTION);
    }
  }
}
