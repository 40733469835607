import { PerformanceMetricsAction, PerformanceMetricsMutation } from './types';

import { WellId } from '@/store/modules/types';
import { Stand } from '@/store/modules/stand/types';
import { SlideSheet } from '@/store/modules/slide_sheet/types';

import store from '@/store';
import { queryServer } from '@/services/socket_service';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class PerformanceMetricsModule extends VuexModule {
  _performanceMetricsWellIds: WellId[] = [];
  _wellData: { [key: string]: any } | null = null;
  _stands: { [key: string]: Stand[] } | null = null;
  _slideSheets: { [key: string]: SlideSheet[] } | null = null;

  get performanceMetricsStands(): { [key: string]: Stand[] } | undefined {
    if(this._stands === null) {
      return undefined;
    }
    return this._stands;
  }

  get performanceMetricsSlideSheets(): { [key: string]: SlideSheet[] } | undefined {
    if(this._slideSheets === null) {
      return undefined;
    }
    return this._slideSheets;
  }

  get performanceMetricsWellData(): { [key: string]: any } | null {
    return this._wellData;
  }

  get performanceMetricsWells(): WellId[] {
    if(this._performanceMetricsWellIds.length === 0) {
      return [store.getters.currentWell._id];
    }
    return this._performanceMetricsWellIds;
  }

  @Mutation
  setPerformanceMetricsWellData(data: { [key: WellId]: any }): void {
    if(this._wellData === null || data === null) {
      this._wellData = data;
      return;
    }

    this._wellData = _.cloneDeep(data);
  }

  @Mutation
  setPerformanceMetricsStands(data: { [key: WellId]: Stand[] }): void {
    this._stands = _.cloneDeep(data);
  }

  @Mutation
  setPerformanceMetricsSlideSheets(data: { [key: WellId]: SlideSheet[] }): void {
    this._slideSheets = _.cloneDeep(data);
  }

  @Mutation
  removePerformanceMetricsWell(wellId: WellId): void {
    this._performanceMetricsWellIds = this._performanceMetricsWellIds.filter((id: WellId) => id !== wellId);
    delete this._stands[wellId];
    delete this._slideSheets[wellId];
    delete this._wellData[wellId];
  }

  @Action({ rawError: true })
  async addPerformanceMetricsWell(wellId: WellId): Promise <void> {
    this._performanceMetricsWellIds.push(wellId);

    // TODO: fetch data only for a new well
    await Promise.all([
      store.dispatch(PerformanceMetricsAction.FETCH_PERFORMANCE_METRICS_STANDS),
      store.dispatch(PerformanceMetricsAction.FETCH_PERFORMANCE_METRICS_SLIDE_SHEETS),
      store.dispatch(PerformanceMetricsAction.FETCH_PERFORMANCE_METRICS_WELL_DATA),
    ]);
  }

  @Action({ rawError: true })
  async fetchPerformanceMetricsSlideSheets(): Promise <void> {
    const borders = this.context.getters.depthBorders;
    let from, to;
    if(borders !== undefined && borders.length > 0) {
      from = borders[0];
      to = borders[1];
    }

    const event = 'slide-sheet/get/compare';
    const params = {
      wellIds: this.context.getters.performanceMetricsWells,
      from,
      to,
      aggregationTime: this.context.getters.standAggregationTime,
    };

    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }

    this.context.commit(PerformanceMetricsMutation.SET_PERFORMANCE_METRICS_SLIDE_SHEETS, resp.data);
  }

  @Action({ rawError: true })
  async fetchPerformanceMetricsWellData(): Promise <void> {
    const event = 'well-data/get/compare';
    const params = {
      wellIds: this.context.getters.performanceMetricsWells,
      traces: ['time', 'holeDepth', 'correctedTimestamp'],
    };

    const resp = await queryServer(event, params);

    if(resp === undefined) {
      return;
    }

    this.context.commit(PerformanceMetricsMutation.SET_PERFORMANCE_METRICS_WELL_DATA, resp.data);
  }

  @Action({ rawError: true })
  async fetchPerformanceMetricsStands(): Promise <void> {
    const borders = this.context.getters.depthBorders;
    let from, to;
    if(borders !== undefined && borders.length > 0) {
      from = borders[0];
      to = borders[1];
    }

    const event = 'stand-metric/get/compare';
    const params = {
      wellIds: this.context.getters.performanceMetricsWells,
      from,
      to,
      aggregationTime: store.getters.standAggregationTime,
    };

    const resp = await queryServer(event, params);

    if(resp === undefined) {
      return;
    }

    this.context.commit(PerformanceMetricsMutation.SET_PERFORMANCE_METRICS_STANDS, resp.data);
  }
}
