import store from '@/store';
import { AppMutation, LiveModeTimeInterval, WitsPacket, LeftTab, WitsData, FetchingStatus, GlobalTooltipState } from './types';
import { WellDataMutation } from '@/store/modules/well_data/types';
import { TimeChartsMutation } from '../tabs/left_tabs/drilling_data_tab/time_charts/types';
import { DepthDataMutation } from '../depth_data/types';
import { DepthChartsMutation } from '../tabs/left_tabs/drilling_data_tab/depth_charts/types';
import { CompareWellsMutation } from '../tabs/left_tabs/drilling_data_tab/compare_wells/types';
import { DrillingDataSubTab } from '../tabs/left_tabs/types';
import { MotorSubTab } from '../tabs/right_tabs/types';

import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

import * as _ from 'lodash';
import { MotorTabMutation } from '../tabs/right_tabs/motor_tab/types';

const SECONDS_IN_MINUTE = 60;
const DEFAULT_LIVE_TIME_INTERVAL = LiveModeTimeInterval.FIFTEEN_MIN;
const WITS_PACKETS_CONTAINER_SIZE = 10;

@Module
export class AppModule extends VuexModule {
  _wellSummaryFetching: FetchingStatus = FetchingStatus.DEFAULT;
  _wellSummaryRobot: boolean = false;

  _liveMode: boolean = false;
  _liveTimeInterval: LiveModeTimeInterval = DEFAULT_LIVE_TIME_INTERVAL;

  _witsPackets: WitsPacket[] = [];
  _activeLeftTab: LeftTab = LeftTab.DRILLING_DATA;
  // move to tab state module
  _performanceMetricDepthRange: [number, number] | null = null;
  _lastWitsData: WitsData = { azm: undefined, inc: undefined };

  _witsLiveMode = true;

  _leftTabWidth = 60; // percent, right tab width is (100% - leftTabWidth)

  _globalTooltip = { isVisible: false, positionX: 0, positionY: 0, content: '', offsetX: 0, offsetY: 0 };

  get globalTooltip(): GlobalTooltipState {
    return this._globalTooltip;
  }

  @Mutation
  setGlobalTooltip(payload: GlobalTooltipState): void {
    this._globalTooltip = _.clone(payload);
  }

  get witsLiveMode(): boolean {
    return this._witsLiveMode;
  }

  get leftTabWidth(): number {
    return this._leftTabWidth;
  }

  @Mutation
  setLeftTabWidth(width: number): void {
    this._leftTabWidth = width;
  }

  // TODO: move to a separate module
  get wellSummaryRobot(): boolean {
    return this._wellSummaryRobot;
  }

  @Mutation
  setWellSummaryRobot(robot: boolean): void {
    this._wellSummaryRobot = robot;
  }

  // TODO: move to a separate module
  get wellSummaryFetching(): FetchingStatus {
    return this._wellSummaryFetching;
  }

  @Mutation
  setWellSummaryFetching(fetching: FetchingStatus): void {
    this._wellSummaryFetching = fetching;
  }

  get liveMode(): boolean {
    return this._liveMode;
  }

  get liveTimeIntervalInSeconds(): number {
    switch(this._liveTimeInterval) {
      case LiveModeTimeInterval.TWO_HOURS:
        return 120 * SECONDS_IN_MINUTE;
      case LiveModeTimeInterval.ONE_HOUR:
        return 60 * SECONDS_IN_MINUTE;
      case LiveModeTimeInterval.FIFTEEN_MIN:
        return 15 * SECONDS_IN_MINUTE;
      default:
        throw new Error(`Unknown time interval ${this._liveTimeInterval}`);
    }
  }

  get liveTimeInterval(): LiveModeTimeInterval {
    return this._liveTimeInterval;
  }

  get witsPackets(): WitsPacket[] {
    return this._witsPackets;
  }

  get activeLeftTab(): LeftTab {
    return this._activeLeftTab;
  }

  get performanceMetricsDepthRange(): [number, number] | undefined {
    if(this._performanceMetricDepthRange === null) {
      return undefined;
    }
    return this._performanceMetricDepthRange;
  }

  get lastWitsData(): WitsData | undefined {
    if(this._lastWitsData === null) {
      return undefined;
    }
    return this._lastWitsData;
  }

  @Mutation
  setLiveMode(liveMode: boolean): void {
    this._liveMode = liveMode;
  }

  @Mutation
  setWitsLiveMode(witsLiveMode: boolean): void {
    this._witsLiveMode = witsLiveMode;
  }

  @Mutation
  setLiveTimeInterval(interval: LiveModeTimeInterval): void {
    this._liveTimeInterval = interval;
    store.commit(WellDataMutation.SET_WELL_DATA_BUCKET_LIMIT, store.getters.liveTimeIntervalInSeconds);
  }

  @Mutation
  setActiveLeftTab(tab: LeftTab): void {
    this._activeLeftTab = tab;
  }

  @Mutation
  appendWitsPacket(witsPacket: WitsPacket) {
    if(this._witsPackets.length >= WITS_PACKETS_CONTAINER_SIZE) {
      this._witsPackets.shift();
    }

    witsPacket.raw.packet = witsPacket.raw.packet
      .split('\r\n')
      // we remove "&&", "!!", and the last "" element from the packet here
      .slice(1, -2)
      .join('<br/>');

    this._witsPackets.push(witsPacket);
    store.commit(AppMutation.SET_LAST_WITS_DATA, witsPacket);
  }

  @Mutation
  setLastWitsData(witsPacket: WitsPacket) {
    if(witsPacket === undefined || witsPacket.parsed === undefined) {
      return;
    }
    if(witsPacket.parsed.liveAzimuth !== undefined && witsPacket.parsed.liveAzimuth > -999) {
      this._lastWitsData.azm = witsPacket.parsed.liveAzimuth;
    }
    if(witsPacket.parsed.liveInclination !== undefined && witsPacket.parsed.liveInclination > -999) {
      this._lastWitsData.inc = witsPacket.parsed.liveInclination;
    }
    this._lastWitsData = _.clone(this._lastWitsData);
  }

  @Mutation
  setPerformanceMetricsDepthRange(range: [number, number]): void {
    this._performanceMetricDepthRange = range;
  }

  @Mutation
  toggleLiveMode(tab?: DrillingDataSubTab | MotorSubTab): void {
    // if you don't pass `tab` - all vuex modules are updated
    // if you pass a `tab` - all vuex modules except the `tab` one (e.g. Time) are updated
    const currentLiveMode = !this._liveMode;
    store.commit(AppMutation.SET_LIVE_MODE, currentLiveMode);

    if(currentLiveMode) {
      if(!tab || tab !== DrillingDataSubTab.TIME) {
        store.commit(WellDataMutation.CLEAR_HISTORICAL_DATA);
        store.commit(TimeChartsMutation.SET_TIME_CHARTS_HISTORICAL_ZOOM_RANGE, null);
      }

      if(!tab || tab !== DrillingDataSubTab.DEPTH) {
        store.commit(DepthDataMutation.CLEAR_DEPTH_HISTORICAL_DATA);
        store.commit(DepthChartsMutation.SET_DEPTH_CHARTS_HISTORICAL_ZOOM_RANGE, null);
      }

      if(!tab || tab !== MotorSubTab.STALL_DETECTION) {
        store.commit(MotorTabMutation.CLEAR_HISTORICAL_DATA);
        store.commit(MotorTabMutation.SET_HISTORICAL_ZOOM_RANGE, null);
      }

      if(!tab) {
        store.commit(WellDataMutation.CLEAR_HISTORICAL_DATA);
        store.commit(MotorTabMutation.SET_HISTORICAL_ZOOM_RANGE, null);
      }
    } else {
      if(!tab || tab !== DrillingDataSubTab.TIME) {
        store.commit(TimeChartsMutation.SET_TIME_CHARTS_HISTORICAL_ZOOM_RANGE, store.getters.timeChartsLiveZoomRange);
        store.commit(WellDataMutation.COPY_LIVE_DATA_TO_HISTORICAL);
      }

      if(!tab || tab !== DrillingDataSubTab.DEPTH) {
        store.commit(DepthChartsMutation.SET_DEPTH_CHARTS_HISTORICAL_ZOOM_RANGE, store.getters.depthChartsLiveZoomRange);
        store.commit(DepthDataMutation.COPY_DEPTH_LIVE_DATA_TO_HISTORICAL);
      }

      if(!tab || tab !== DrillingDataSubTab.COMPARE_WELLS) {
        store.commit(CompareWellsMutation.SET_LAST_COMPARE_WELLS_DEPTH, null);
      }

      if(!tab || tab !== MotorSubTab.STALL_DETECTION) {
        store.commit(MotorTabMutation.SET_HISTORICAL_ZOOM_RANGE, store.getters.motorChartLiveZoomRange);
        store.commit(MotorTabMutation.COPY_LIVE_DATA_TO_HISTORICAL);
      }
    }
  }
}
