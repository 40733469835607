
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"width":"20","height":"20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M13.396 10.854l-1.084-1.083c.042-.82-.208-1.458-.75-1.917-.541-.458-1.131-.66-1.77-.604L8.708 6.167c.111-.028.299-.066.563-.115.264-.049.507-.073.729-.073 1.014 0 1.865.344 2.552 1.031.687.688 1.031 1.539 1.031 2.552 0 .25-.017.494-.052.73-.035.236-.08.423-.135.562zm2.666 2.667l-1.041-1.042c.5-.389.955-.819 1.364-1.291a6.69 6.69 0 001.032-1.626 7.96 7.96 0 00-3.021-3.343C13.076 5.406 11.611 5 10 5a8.59 8.59 0 00-1.146.073 6.454 6.454 0 00-1.021.219L6.667 4.125a7.762 7.762 0 011.635-.448c.563-.09 1.129-.135 1.698-.135 1.805 0 3.469.451 4.99 1.354a9.492 9.492 0 013.552 3.687c.055.14.107.292.156.459.049.167.073.34.073.52s-.017.354-.052.521c-.035.167-.087.32-.157.459-.32.555-.687 1.08-1.104 1.573-.417.493-.882.961-1.396 1.406zm-.062 4.5l-2.958-2.959a5.992 5.992 0 01-1.334.407 8.829 8.829 0 01-1.708.156c-1.82 0-3.493-.458-5.021-1.375a9.47 9.47 0 01-3.541-3.708 5.1 5.1 0 01-.146-.469 2.006 2.006 0 01-.063-.511c0-.18.021-.36.063-.541.041-.18.09-.34.146-.479a9.485 9.485 0 011.041-1.479c.403-.473.84-.883 1.313-1.23L1.958 3.979a.763.763 0 01-.229-.5c-.014-.194.062-.368.229-.521a.71.71 0 01.511-.229c.187 0 .357.076.51.229l14.042 14.063a.707.707 0 01.229.51c0 .187-.076.35-.229.49-.153.167-.323.25-.511.25-.187 0-.357-.083-.51-.25zM4.812 6.833c-.416.347-.836.754-1.26 1.219a5.53 5.53 0 00-.99 1.51 8.089 8.089 0 003.021 3.365c1.32.827 2.792 1.24 4.417 1.24.333 0 .694-.024 1.083-.073.39-.049.632-.122.729-.219L10.938 13c-.056.041-.188.076-.396.104-.209.028-.39.042-.542.042-1.014 0-1.865-.344-2.552-1.031-.687-.688-1.031-1.539-1.031-2.553 0-.139.017-.298.052-.479a2.79 2.79 0 01.114-.437L4.812 6.833z","fill":"#DBDBDB"}})])
          )
        }
      }
    