import { DepthTrace, LiveModeDepthInterval } from '@/store/modules/depth_data/types';
import { AppMutation } from '../../../../app/types';

import { WellId } from '../../../../types';
import { CompareWellsAction, CompareWellsMutation } from './types';

import { queryServer } from '@/services/socket_service';

import { findClosest } from '@/utils';
import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';
import { DashboardPresetAction, DashboardPresetMutation } from '@/store/modules/dashboard_preset/types';

const DEFAULT_COMPARE_WELLS_LIVE_DEPTH_INTERVAL = 100;
@Module
export class CompareWellsModule extends VuexModule {
  _wellIds: WellId[] = [];
  _traces: [DepthTrace, DepthTrace, DepthTrace, DepthTrace] = [
    DepthTrace.BIT_AGGRESSIVENESS,
    DepthTrace.DIFF_PRESSURE,
    DepthTrace.DOC,
    DepthTrace.DOC_WOB_RATIO,
  ];

  _data: { [key: string]: any } | null = null;

  _chartsZoom: [number, number] | null = null;

  _lastDepth: number | null = null;
  _liveDepthInterval: LiveModeDepthInterval = DEFAULT_COMPARE_WELLS_LIVE_DEPTH_INTERVAL;
  _refetchFlag = false;
  _isDataFetching = false;

  get lastCompareWellsDepth(): number | null {
    if(this._lastDepth === null) {
      return undefined;
    }
    return this._lastDepth;
  }

  get compareWellsChartsZoom(): [number, number] | undefined {
    if(this._chartsZoom === null) {
      return undefined;
    }
    return this._chartsZoom;
  }

  get compareWellsWellIds(): WellId[] {
    // TODO: it's hack, need to refactor
    if(store.getters.currentWell === undefined) {
      return [];
    }
    if(this._wellIds.length === 0) {
      return [store.getters.currentWell._id];
    }
    return this._wellIds;
  }

  get compareWellsTraces(): [DepthTrace, DepthTrace, DepthTrace, DepthTrace] {
    return this._traces;
  }

  get compareWellsData(): { [key: string]: any } | null {
    return this._data;
  }

  get compareWellsLiveDepthInterval(): number {
    return this._liveDepthInterval;
  }

  get compareWellsRefetchFlag(): boolean {
    return this._refetchFlag;
  }

  get isCompareWellsDataFetching(): boolean {
    return this._isDataFetching;
  }

  @Mutation
  setCompareWellsDataFetching(isCompareWellsDataFetching: boolean): void {
    this._isDataFetching = isCompareWellsDataFetching;
  }

  @Mutation
  setCompareWellsRefetchFlag(flag: boolean): void {
    this._refetchFlag = flag;
  }

  @Mutation
  setCompareWellsLiveDepthInterval(interval: LiveModeDepthInterval): void {
    this._liveDepthInterval = interval;
  }

  @Mutation
  clearCompareWells(): void {
    this._data = null;
    this._lastDepth = null;
  }

  @Mutation
  setLastCompareWellsDepth(depth: number | null): void {
    this._lastDepth = depth;
  }

  @Mutation
  setCompareWellsChartsZoom(range: [number, number]): void {
    const borders = store.getters.localDepthBorders;
    if(_.isEmpty(borders) || _.isEmpty(range)) {
      this._chartsZoom = null;
    } else {
      this._chartsZoom = [Math.max(range[0], borders[0]), Math.min(range[1], borders[1])];
    }
  }

  @Mutation
  removeWellFromComparison(wellId: WellId): void {
    this._wellIds = this._wellIds.filter((id: WellId) => id !== wellId);
    delete this._data[wellId];
    this._data = _.cloneDeep(this._data);
  }

  @Mutation
  changeCompareWellsTrace(payload: { idx: number, trace: DepthTrace }): void {
    this._traces[payload.idx] = payload.trace;

    store.commit(CompareWellsMutation.SET_LAST_COMPARE_WELLS_DEPTH, null);
    if(!store.getters.liveMode) {
      store.dispatch(CompareWellsAction.FETCH_COMPARE_WELLS_DATA, this._chartsZoom);
    }
    store.dispatch(DashboardPresetAction.UPDATE_PRESET, { compareCharts: this._traces });
  }

  @Mutation
  setCompareWellsTraces(traces: [DepthTrace, DepthTrace, DepthTrace, DepthTrace]): void {
    this._traces = _.clone(traces);
  }

  @Mutation
  setCompareWellsData(payload: { data: { [key: string]: any }, depthInterval: [number, number] }): void {
    for(let wellIdx = 1; wellIdx < this._wellIds.length; wellIdx++) {
      const currentWellData = payload.data[this._wellIds[0]];
      const data = payload.data[this._wellIds[wellIdx]];
      for(let depthIdx = 0; depthIdx < data.depth.length; depthIdx++) {
        const currentWellDepthIdx = findClosest(currentWellData.depth, data.depth[depthIdx]);
        data.correctedDepth[depthIdx] = currentWellData.correctedDepth[currentWellDepthIdx];
      }
    }
    this._data = _.cloneDeep(payload.data);

    if(payload.depthInterval) {
      store.commit(CompareWellsMutation.SET_COMPARE_WELLS_CHARTS_ZOOM, payload.depthInterval);
    } else {
      const currentWellData = this._data[store.getters.currentWellId];
      const lastFetchedDepth = _.last(currentWellData.correctedDepth);
      const firstFetchedDepth = _.first(currentWellData.correctedDepth);
      const leftBorder = lastFetchedDepth - this._liveDepthInterval;
      const liveZoomRange = [_.max([leftBorder, firstFetchedDepth]), lastFetchedDepth];
      store.commit(CompareWellsMutation.SET_COMPARE_WELLS_CHARTS_ZOOM, liveZoomRange);
    }
  }

  @Mutation
  appendCompareWellsData(data: { [key: string]: any }): void {
    if(data === undefined) {
      return;
    }

    const currentWellData = data[store.getters.currentWellId];
    if(currentWellData.correctedDepth === undefined || currentWellData.correctedDepth.length === 0) {
      return;
    }

    const lastFetchedDepth = store.getters.lastCompareWellsDepth;
    if(this._data === null || lastFetchedDepth === undefined) {
      store.commit(CompareWellsMutation.SET_COMPARE_WELLS_DATA, { data });
      store.commit(CompareWellsMutation.SET_LAST_COMPARE_WELLS_DEPTH, _.last(currentWellData.correctedDepth));
      return;
    }

    const depthFields = Object.keys(this._data[store.getters.currentWellId]);
    const respFields = Object.keys(data[store.getters.currentWellId]);

    store.getters.compareWellsWellIds.forEach((wellId: WellId) => {
      depthFields.forEach((key: string) => {
        if(!_.isArray(data[wellId][key]) || data[wellId][key].length === 0) {
          return;
        }

        if(!respFields.includes(key)) {
          throw new Error(`No "${key}" field for well "${wellId}" in /depth-data/compare response`);
        }

        this._data[wellId][key] = this._data[wellId][key].concat(data[wellId][key]);
      });
    });
    // TODO: use deep watcher
    this._data = _.cloneDeep(this._data);

    const lastDepth = _.last(currentWellData.correctedDepth);
    const liveZoomRange = [lastDepth - this._liveDepthInterval, lastDepth];
    store.commit(CompareWellsMutation.SET_COMPARE_WELLS_CHARTS_ZOOM, liveZoomRange);

    store.commit(CompareWellsMutation.SET_LAST_COMPARE_WELLS_DEPTH, lastDepth);
  }

  @Action({ rawError: true })
  async addWellToComparison(wellId: WellId): Promise<void> {
    this._wellIds.push(wellId);

    store.commit(CompareWellsMutation.SET_LAST_COMPARE_WELLS_DEPTH, null);
    // TODO: fetch data only for new well
    if(!store.getters.liveMode) {
      await store.dispatch(CompareWellsAction.FETCH_COMPARE_WELLS_DATA, this._chartsZoom);
    }
  }

  @Action({ rawError: true })
  async fetchCompareWellsData(zoom?: [number, number]): Promise<void> {
    if(zoom !== undefined) {
      if(store.getters.liveMode) {
        this.context.commit(AppMutation.TOGGLE_LIVE_MODE);
      }
      store.commit(CompareWellsMutation.SET_COMPARE_WELLS_CHARTS_ZOOM, zoom);

      store.commit(CompareWellsMutation.SET_COMPARE_WELLS_DATA_FETCHING, true);
    }
    const lastFetchedDepth = this.context.getters.lastCompareWellsDepth;
    const isRefetching = this.context.getters.compareWellsRefetchFlag;

    let from, to;
    if(this.context.getters.liveMode) {
      if(!isRefetching && lastFetchedDepth !== undefined) {
        from = lastFetchedDepth + 1;
      } else {
        if(isRefetching) {
          this.context.commit(CompareWellsMutation.SET_COMPARE_WELLS_REFETCH_FLAG, false);
        }
        const borders = this.context.getters.localDepthBorders;
        const interval = this.context.getters.compareWellsLiveDepthInterval;
        if(borders !== undefined && borders[1] !== undefined) {
          from = borders[1] - interval;
        }
      }

      // do not fetch well comparison data until there are new depth data elements
      if(this.context.getters.lastFetchedDepth <= from) {
        store.commit(CompareWellsMutation.SET_COMPARE_WELLS_DATA_FETCHING, false);
        return;
      }
    } else {
      from = _.first(this._chartsZoom);
      to = _.last(this._chartsZoom);
    }

    if(this.context.getters.compareWellsWellIds.length === 0) {
      store.commit(CompareWellsMutation.SET_COMPARE_WELLS_DATA_FETCHING, false);
      return;
    }

    let caching = false;
    if(isRefetching) {
      caching = true;
    }
    if(!this.context.getters.liveMode) {
      caching = true;
    } else {
      if(!this._data || _.isEmpty(this._data[store.getters.currentWellId].correctedDepth)) {
        caching = true;
      }
    }
    const event = 'depth-data/get/compare';
    const params = {
      wellIds: this.context.getters.compareWellsWellIds,
      traces: ['depth', 'correctedDepth', 'currentDepthGap', ...this.context.getters.compareWellsTraces],
      from,
      to,
      caching,
      key: 'correctedDepth',
    };

    const resp = await queryServer(event, params);

    store.commit(CompareWellsMutation.SET_COMPARE_WELLS_DATA_FETCHING, false);

    if(resp === undefined) {
      return;
    }

    if(!isRefetching && this.context.getters.liveMode) {
      this.context.commit(CompareWellsMutation.APPEND_COMPARE_WELLS_DATA, resp.data);
    } else {
      this.context.commit(CompareWellsMutation.SET_COMPARE_WELLS_DATA, { data: resp.data, depthInterval: zoom });
    }
  }

  @Action({ rawError: true })
  async fetchCompareWellsDataByDepth(zoom: [number, number]): Promise<void> {
    if(store.getters.liveMode) {
      this.context.commit(AppMutation.TOGGLE_LIVE_MODE);
    }

    store.commit(CompareWellsMutation.SET_COMPARE_WELLS_DATA_FETCHING, true);

    const from = _.first(zoom);
    const to = _.last(zoom);

    if(this.context.getters.compareWellsWellIds.length === 0) {
      store.commit(CompareWellsMutation.SET_COMPARE_WELLS_DATA_FETCHING, false);
      return;
    }

    const caching = false;
    const event = 'depth-data/get/compare';
    const params = {
      wellIds: this.context.getters.compareWellsWellIds,
      traces: ['depth', 'correctedDepth', 'currentDepthGap', ...this.context.getters.compareWellsTraces],
      from,
      to,
      caching,
      key: 'depth',
    };

    const resp = await queryServer(event, params);

    store.commit(CompareWellsMutation.SET_COMPARE_WELLS_DATA_FETCHING, false);

    if(resp === undefined) {
      return;
    }

    const data = resp.data[store.getters.currentWellId];
    const correctedZoom = [_.first(data.correctedDepth), _.last(data.correctedDepth)];

    this.context.commit(CompareWellsMutation.SET_COMPARE_WELLS_DATA, { data: resp.data, depthInterval: correctedZoom });
  }
}
