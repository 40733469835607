import { StandOption, SubTabConfigs, BreakdownType, DrillingDataSubTab, PerformanceMetricsSubTab } from './types';

import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

import * as _ from 'lodash';

const SLIPS_FIELDS = ['connectionS2WTime', 'connectionS2STime', 'connectionW2STime'];
const PUMPS_FIELDS = ['connectionP2WTime', 'connectionP2PTime', 'connectionW2PTime'];
const ACTIVITY_BREAKDOWNS_FIELDS = ['slideDistribution', 'slideDistribution', 'slideDistribution']; // TODO: refactor chartwerk
const SLIPS_LEGEND = ['S2W', 'S2S', 'W2S'];
const PUMPS_LEGEND = ['P2W', 'P2P', 'W2P'];

@Module
export class LeftTabsModule extends VuexModule {
  _subtabConfigs: SubTabConfigs = {
    [StandOption.CONNECTION_TIME_BREAKDOWNS]: {
      label: 'Connection Time Breakdowns [min]',
      fields: _.clone(SLIPS_FIELDS),
      legend: _.clone(SLIPS_LEGEND),
      visible: true,
    },
    [StandOption.SLIDING_EFFICIENCY]: {
      label: 'Sliding Efficiency [%]',
      fields: ['slideEfficiency'],
      legend: ['Sliding Efficiency'],
      visible: false,
    },
    [StandOption.ROTATING_ROP]: {
      label: 'Rotating ROP [ft/hr]',
      fields: ['averageRotatingROP'],
      legend: ['Rotating ROP'],
      visible: false,
    },
    [StandOption.SLIDING_ROP]: {
      label: 'Sliding ROP [ft/hr]',
      fields: ['averageSlidingROP'],
      legend: ['Sliding ROP'],
      visible: false,
    },
    [StandOption.ACTIVITY_BREAKDOWNS]: {
      label: 'Activity Breakdowns [%]',
      fields: ACTIVITY_BREAKDOWNS_FIELDS,
      legend: ['Off-Bottom', 'Sliding', 'Rotating'],
      visible: false,
    },
  };

  _isDepthVsDaysChartVisible = false;
  _activeDrillingDataSubTab = DrillingDataSubTab.TIME;
  _selectedBreakdownType = BreakdownType.SLIPS;
  _activeDrillingDataSubTabIsChangedByUser = false;

  _activePerformanceMetricsSubTab = PerformanceMetricsSubTab.PERFORMANCE_METRICS;
  _activePerformanceMetricsSubTabIsChangedByUser = false;

  get drillingDataSubTabIsChangedByUser(): boolean {
    return this._activeDrillingDataSubTabIsChangedByUser;
  }

  get isDepthVsDaysChartVisible(): boolean {
    return this._isDepthVsDaysChartVisible;
  }

  get subtabConfigs(): SubTabConfigs {
    return this._subtabConfigs;
  }

  get activeDrillingDataSubTab(): DrillingDataSubTab {
    return this._activeDrillingDataSubTab;
  }

  get activePerformanceMetricsSubTab(): PerformanceMetricsSubTab {
    return this._activePerformanceMetricsSubTab;
  }

  get selectedBreakdownType(): BreakdownType {
    return this._selectedBreakdownType;
  }

  @Mutation
  setActiveDrillingDataSubTab(tab: DrillingDataSubTab): void {
    this._activeDrillingDataSubTab = tab;
    this._activeDrillingDataSubTabIsChangedByUser = true;
  }

  @Mutation
  setActivePerformanceMetricsSubTab(tab: PerformanceMetricsSubTab): void {
    this._activePerformanceMetricsSubTab = tab;
    this._activePerformanceMetricsSubTabIsChangedByUser = true;
  }

  @Mutation
  toggleDepthVsDaysChartVisibility(): void {
    this._isDepthVsDaysChartVisible = !this._isDepthVsDaysChartVisible;
  }

  @Mutation
  toggleSubtabVisibility(standOption: StandOption): void {
    this._subtabConfigs[standOption].visible = !this._subtabConfigs[standOption].visible;
  }

  @Mutation
  setConnectionTimeBreakdownType(type: BreakdownType): void {
    this._selectedBreakdownType = type;
    switch(type) {
      case BreakdownType.SLIPS:
        this._subtabConfigs[StandOption.CONNECTION_TIME_BREAKDOWNS].fields = _.clone(SLIPS_FIELDS);
        this._subtabConfigs[StandOption.CONNECTION_TIME_BREAKDOWNS].legend = _.clone(SLIPS_LEGEND);
        break;
      case BreakdownType.PUMPS:
        this._subtabConfigs[StandOption.CONNECTION_TIME_BREAKDOWNS].fields = _.clone(PUMPS_FIELDS);
        this._subtabConfigs[StandOption.CONNECTION_TIME_BREAKDOWNS].legend = _.clone(PUMPS_LEGEND);
        break;
      default:
        throw new Error(`Unknown type of breakdown connection time: ${ type }`);
    }
  }

  @Mutation
  setSubTabConfigs(config: SubTabConfigs): void {
    this._subtabConfigs = _.cloneDeep(config);
  }
}
