export type DirectionalCompanyId = string;

export type DirectionalCompany = {
  _id: DirectionalCompanyId;
  name: string;
};

export enum DirectionalCompanyMutation {
  SET_DIRECTIONAL_COMPANIES = 'setDirectionalCompanies',
}

export enum DirectionalCompanyAction {
  FETCH_DIRECTIONAL_COMPANIES = 'fetchDirectionalCompanies',
}
