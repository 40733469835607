import { WellId } from '../../types';
import { NPTDepthVsDaysData, MultiwellCompareWellListGetter, MultiWellsTabMutation, NPTWellData, NptData, MultiWellsTabGetter } from '@/store/modules/multiwell_compare/types';

import { queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import * as _ from 'lodash';

@Module
export class MultiwellCompareNptTabModule extends VuexModule {
  _depthVsDaysData: NPTDepthVsDaysData = {};
  _nptData: { [wellId: WellId]: NptData } = {};
  _selectedDepthRange: [number, number] | null = null;
  _selectedDayTime = 'All';

  get nptData(): { [wellId: WellId]: NptData } {
    return this._nptData;
  }

  get depthVsDaysData(): NPTDepthVsDaysData {
    return this._depthVsDaysData;
  }

  get selectedDepthRange(): [number, number] | undefined {
    return this._selectedDepthRange || undefined;
  }

  get selectedDayTime(): string {
    return this._selectedDayTime;
  }

  @Mutation
  setDepthVsDaysData(data: NPTDepthVsDaysData): void {
    this._depthVsDaysData = _.cloneDeep(data);
  }

  @Mutation
  appendDepthVsDaysDataForWellId(payload: { data: NPTWellData, wellId: string }): void {
    this._depthVsDaysData[payload.wellId] = payload.data;
  }

  @Mutation
  removeDepthVsDaysDataByWellId(wellId: string): void {
    delete this._depthVsDaysData[wellId];
  }

  @Mutation
  setNptData(data: { [wellId: WellId]: NptData }): void {
    this._nptData = _.cloneDeep(data);
  }

  @Mutation
  appendNptDataForWellId(payload: { [wellId: WellId]: NptData }): void {
    this._nptData = { ...this._nptData, ...payload };
  }

  @Mutation
  removeNptDataByWellId(wellId: string): void {
    delete this._nptData[wellId];
  }

  @Mutation
  setSelectedDepthRange(range: [number, number] | undefined): void {
    this._selectedDepthRange = range;
  }

  @Mutation
  setSelectedDayTime(dayTime: string): void {
    this._selectedDayTime = dayTime;
  }

  @Action({ rawError: true })
  async fetchDepthVsDaysDataForAllWells(): Promise<void> {
    const event = 'well-data/get';
    const promises = [];
    for(const wellId of store.getters[MultiwellCompareWellListGetter.GET_SELECTED_WELLS_IDS]) {
      const params = {
        wellId,
        projection: {
          holeDepth: 1,
          correctedTimestamp: 1,
        },
      };
      promises.push(queryServer(event, params));
    }
    const resps = await Promise.all(promises);
    const filteredResps = _.filter(resps, (resp: any) => !_.isEmpty(resp.data));
    const res = {};
    for(const resp of filteredResps) {
      res[resp.data.wellId] = resp.data;
    }
    store.commit(MultiWellsTabMutation.NPT_SET_DEPTH_VS_DAYS_DATA, res);
  }

  @Action({ rawError: true })
  async fetchNptDataForAllWells(): Promise<void> {
    const event = 'npt/get';
    const res = {};
    const range = store.getters[MultiWellsTabGetter.NPT_SELECTED_DEPTH_RANGE] || [];
    const dayTime = this._selectedDayTime !== 'All' ? _.lowerCase(this._selectedDayTime) : undefined;
    await Promise.all(
      store.getters[MultiwellCompareWellListGetter.GET_SELECTED_WELLS_IDS].map(async (wellId: WellId) => {
        const params = {
          wellId,
          from: range[0],
          to: range[1],
          dayTime,
          timeZome: 0, // TODO: set browser time zone hours
        };
        const resp = await queryServer(event, params);
        if(!_.isEmpty(resp.data)) {
          res[wellId] = resp.data;
        }
      })
    );
    store.commit(MultiWellsTabMutation.NPT_SET_NPT_DATA, res);
  }
}
