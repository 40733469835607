

















import { Rig, RigAction, RigMutation } from '@/store/modules/rig/types';

import { Component, Vue, Prop } from 'vue-property-decorator';

import ChevronLeft from '@/assets/icons/pure-icons/chevron-left.svg.pure';

export enum SettingsType {
  WELL = 'well',
  RIG = 'rig',
}

@Component({ components: { ChevronLeft } })
export default class WellSettingsHeader extends Vue {
  @Prop({ required: false, default: SettingsType.WELL })
  type!: SettingsType;

  get settingsTitle(): string {
    if(this.type === SettingsType.WELL) {
      return this.wellSettingsTitle;
    }
    return this.rigSettingsTitle;
  }

  get wellSettingsTitle(): string {
    const well = this.$store.getters.currentWell;
    if(well === undefined) {
      return '';
    }
    const wellName = this.$store.getters.currentWell.name;
    return `"${wellName}" / Settings`;
  }

  get rigSettingsTitle(): string {
    const rigId = this.$router.currentRoute.params.rigId;
    if(!rigId) {
      return '';
    }
    const rig = this.fetchRig(rigId);
    if(!rig) {
      return '';
    }
    return `"${rig.name}" / Settings`;
  }

  fetchRig(rigId: string): Rig {
    this.$store.commit(RigMutation.SET_RIG_ID, rigId);
    this.$store.dispatch(RigAction.FETCH_RIG);
    return this.$store.getters.currentRig;
  }

  goBack(): void {
    this.$router.go(-1);
  }
}
