
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"width":"18","height":"13","fill":"none","xmlns":"http://www.w3.org/2000/svg"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M9 10.146c1 0 1.847-.347 2.542-1.042.694-.695 1.041-1.542 1.041-2.542 0-1-.347-1.847-1.041-2.541C10.847 3.326 10 2.979 9 2.979c-1 0-1.847.347-2.542 1.042-.694.694-1.041 1.541-1.041 2.541s.347 1.847 1.041 2.542C7.153 9.799 8 10.146 9 10.146zm0-1.292c-.639 0-1.18-.222-1.625-.666a2.213 2.213 0 01-.667-1.626c0-.639.222-1.18.667-1.624A2.211 2.211 0 019 4.271c.639 0 1.18.222 1.625.667.445.444.667.985.667 1.624 0 .64-.222 1.181-.667 1.626A2.213 2.213 0 019 8.854zm0 3.771c-1.82 0-3.49-.455-5.01-1.365A9.516 9.516 0 01.438 7.542a3.474 3.474 0 01-.146-.459 2.124 2.124 0 01-.063-.521c0-.18.021-.353.063-.52.041-.167.09-.32.146-.459A9.354 9.354 0 014 1.885C5.528.99 7.195.542 9 .542c1.805 0 3.472.448 5 1.343a9.354 9.354 0 013.562 3.698c.056.14.105.292.146.459.042.167.063.34.063.52s-.021.354-.063.521c-.041.167-.09.32-.146.459a9.516 9.516 0 01-3.552 3.718c-1.52.91-3.19 1.365-5.01 1.365zm0-1.458a8.144 8.144 0 004.375-1.24 8.045 8.045 0 003.042-3.365 7.917 7.917 0 00-3.042-3.343A8.249 8.249 0 009 2a8.3 8.3 0 00-4.385 1.219 7.896 7.896 0 00-3.053 3.343 8.022 8.022 0 003.053 3.365A8.194 8.194 0 009 11.167z","fill":"#DBDBDB"}})])
          )
        }
      }
    