
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"width":"24","height":"24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M6.93 20.333c-.37 0-.694-.138-.972-.416a1.332 1.332 0 01-.416-.973V5.75h-.255a.675.675 0 01-.694-.694.675.675 0 01.694-.695h3.657a.675.675 0 01.695-.694h4.722a.674.674 0 01.694.694h3.658a.675.675 0 01.694.695.674.674 0 01-.694.694h-.255v13.195c0 .37-.139.694-.416.972a1.332 1.332 0 01-.973.416H6.93zm0-14.583v13.195h10.14V5.75H6.93zm2.454 10.51a.674.674 0 00.695.694.674.674 0 00.694-.695V8.412a.674.674 0 00-.694-.694.674.674 0 00-.695.694v7.847zm3.843 0a.675.675 0 00.694.694.674.674 0 00.695-.695V8.412a.674.674 0 00-.695-.694.675.675 0 00-.694.694v7.847zM6.93 5.75v13.195V5.75z","fill":"#DBDBDB"}})])
          )
        }
      }
    