import { WellMutation, WellAction, Well } from './types';
import { WellId } from '../types';

import { queryApi } from '@/services/server_service';

import store from '@/store';

import { sleep } from '@/utils';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import { AxiosResponse } from 'axios';
import * as _ from 'lodash';

const WELL_URL = 'api/well';

@Module
export class WellModule extends VuexModule {
  _wells: Well[] = [];
  _wellId: WellId | null = null;
  _targetDepth: number | null = null;
  _wellsBeingFetched = false;

  get wells(): Well[] {
    return this._wells;
  }

  get currentWellId(): string | undefined {
    if(this._wellId === null) {
      return undefined;
    }
    return this._wellId;
  }

  get currentWell(): Well | undefined {
    return this._wells.find((well: Well) => well._id === this._wellId);
  }

  get targetDepth(): number | undefined {
    if(this._targetDepth === null) {
      return undefined;
    }
    return this._targetDepth;
  }

  @Mutation
  setWellsBeingFetched(wellsBeingFetched: boolean): void {
    this._wellsBeingFetched = wellsBeingFetched;
  }

  @Mutation
  setTargetDepth(depth: number): void {
    this._targetDepth = depth;
  }

  @Mutation
  setWells(wells: Well[]): void {
    this._wells = wells;
  }

  @Mutation
  updateWells(wells: Well[]): void {
    for(const newWell of wells) {
      const existingWell = this._wells.find((well: Well) => well._id === newWell._id);
      if(!existingWell) {
        this._wells.push(newWell);
      } else {
        Object.keys(existingWell).forEach((key: string) => {
          existingWell[key] = newWell[key];
        });
      }
    }
  }

  @Mutation
  async updateWellFields(payload: { wellId: WellId, updateQuery: Well }): Promise<void> {
    while(this._wellsBeingFetched) {
      await sleep(0);
    }
    let well = _.find(this._wells, (well: Well) => well._id === payload.wellId);
    if(_.isNil(well)) {
      well = await store.dispatch(WellAction.FETCH_WELL_BY_ID, payload.wellId);
      if(!_.isNil(well)) {
        this._wells.push(well);
      }
    } else {
      _.assign(well, payload.updateQuery);
    }
  }

  @Mutation
  deleteWell(wellId: WellId): void {
    this._wells = this._wells.filter((well: Well) => well._id !== wellId);
  }

  @Mutation
  setWellId(wellId: WellId): void {
    this._wellId = wellId;
  }

  @Action({ rawError: true })
  async fetchWells(): Promise<void> {
    this.context.commit(WellMutation.SET_WELLS_BEING_FETCHED, true);
    const resp = await queryApi({
      url: WELL_URL,
      method: 'GET',
    });

    if(resp === undefined) {
      return;
    }
    this.context.commit(WellMutation.SET_WELLS, resp.data);
    this.context.commit(WellMutation.SET_WELLS_BEING_FETCHED, false);
  }

  @Action({ rawError: true })
  async refetchWells(): Promise<void> {
    const silent = true;
    const resp = await queryApi({
      url: WELL_URL,
      method: 'GET',
    }, silent);

    if(resp === undefined) {
      return;
    }

    this.context.commit(WellMutation.UPDATE_WELLS, resp.data);
  }

  @Action({ rawError: true })
  async fetchWellById(wellId: WellId): Promise<Well | undefined> {
    const resp = await queryApi({
      url: WELL_URL,
      method: 'GET',
      params: { wellId },
    });

    if(resp === undefined) {
      return;
    }
    return resp.data;
  }

  @Action({ rawError: true })
  async addWell(payload: Well): Promise<void> {
    const resp = await queryApi({
      url: WELL_URL,
      method: 'POST',
      data: { ...payload },
    });
    this.context.commit(WellMutation.SET_WELL_ID, resp.data.wellId);
  }

  @Action({ rawError: true })
  async deleteWellById(wellId: WellId): Promise<void> {
    await queryApi({
      url: WELL_URL,
      method: 'DELETE',
      data: { wellId },
    });
    this.context.commit(WellMutation.DELETE_WELL, wellId);
  }

  @Action({ rawError: true })
  async updateWell(payload: { wellId: WellId, updatedFields: any, shouldRefetch: boolean }): Promise<AxiosResponse | undefined> {
    const shouldRefetch = payload.shouldRefetch !== undefined ? payload.shouldRefetch : true;
    const resp = await queryApi({
      url: WELL_URL,
      method: 'PATCH',
      data: payload,
    });

    if(shouldRefetch && resp) {
      await this.context.dispatch(WellAction.FETCH_WELLS);
    }
    return resp;
  }
}
