
































import Modal from '../Modal.vue';

import { Component, Prop, Vue } from 'vue-property-decorator';

import { UserAction } from '@/store/modules/users/types';
import { ApiTokenAction, TokenId } from '@/store/modules/api_token/types';

@Component({ components: { Modal } })
export default class DeleteTokenConfirmationModal extends Vue {
  @Prop({ required: false, default: false })
  isModalActive!: boolean;

  @Prop({ required: true })
  tokenName: string;

  @Prop({ required: true })
  tokenId: TokenId;

  async onApplyClick(): Promise<void> {
    await this.$store.dispatch(ApiTokenAction.DELETE_API_TOKEN, this.tokenId);

    this.close();
  }

  onCloseClick(): void {
    this.close();
  }

  close(): void {
    this.$emit('close');
  }
}
