import { TraceConfig, WellId } from '../types';

export type GammaData = {
  wellId: WellId;
  time: number;
  holeDepth: number;
  actualDepth: number;
  interpolatedTvd: number;
  rawGamma: number;
  correctedGamma: number;
};

export enum GammaDataMutation {
  SET_GAMMA_DATA = 'setGammaData',
  CLEAR_GAMMA_DATA = 'clearGammaData',
  APPEND_LIVE_GAMMA_DATA = 'appendLiveGammaData',
  SET_LIVE_GAMMA_DATA = 'setLiveGammaData',
  SET_HISTORICAL_GAMMA_DATA = 'setHistoricalGammaData',
}

export enum GammaDataAction {
  FETCH_GAMMA_DATA = 'fetchGammaData',
  FETCH_LIVE_GAMMA_DATA = 'fetchLiveGammaData',
  FETCH_HISTORICAL_GAMMA_DATA = 'fetchHistoricalGammaData',
}

export enum GaugeGammaTrace {
  RAW_GAMMA = 'rawGamma',
  CORRECTED_GAMMA = 'correctedGamma',
}

export const GAMMA_DATA_TRACE_TO_NAME_AND_UNIT_MAPPING: { [trace: string]: TraceConfig } = {
  rawGamma: { name: 'Raw Gamma', unit: 'mEv', min: 0, max: 200 },
  correctedGamma: { name: 'Corrected Gamma', unit: 'mEv', min: 0, max: 200 },
};
