import { DrillingCompany, DrillingCompanyId } from '@/store/modules/drilling_company/types';

export type RigId = string;

export type Rig = {
  _id: RigId;
  drillingCompanyId: DrillingCompanyId;
  drillingCompany?: DrillingCompany;
  blockWeight: number;
  activeWellId?: string;
  name: string;
};

export enum RigMutation {
  SET_RIG = 'setRig',
  SET_RIGS = 'setRigs',
  SET_RIG_ID = 'setRigId',
  DELETE_RIG = 'deleteRig',
}

export enum RigAction {
  FETCH_RIG = 'fetchRig',
  FETCH_RIGS = 'fetchRigs',
  UPDATE_RIG = 'updateRig',
  CREATE_RIG = 'createRig',
  DELETE_RIG_BY_ID = 'deleteRigById',
}
