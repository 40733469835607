export type DirectionalSettings = {
  wellId: string;
  tendencies: Tendencies;
  dlsConstraint: number;
  lookAheadDistance: number;
  alpha: number;
  correctionCriteria: CorrectionCriteria;
  _id?: string;
};

export type Tendencies =  {
  motorYield: number;
  rbr: number;
  rtr: number;
};

export type CorrectionCriteria = {
  inclinationValue: number;
  azimuthValue: number;
  distanceValue: [number, number, number, number];
  lookAhead: number;
};

export enum DirectionalSettingsMutation {
  SET_DIRECTIONAL_SETTINGS = 'setDirectionalSettings',
  SET_DIRECTIONAL_SETTINGS_LOCAL = 'setDirectionalSettingsLocal',
  SET_AUTO_TENDENCIES = 'setAutoTendencies',
}

export enum DirectionalSettingsAction {
  FETCH_DIRECTIONAL_SETTINGS = 'fetchDirectionalSettings',
  UPDATE_DIRECTIONAL_SETTINGS = 'updateDirectionalSettings',
}
