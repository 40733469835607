import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';
import {
  IRangeData,
  MultiwellCompareFiltersAction,
  MultiwellCompareFiltersMutation,
  RangeTypes,
  TRangeType,
} from '@/store/modules/multiwell_compare/types';
import store from '@/store';
import moment from 'moment';

interface IFailuresFilters {
  rangeType: TRangeType;
  rangeData: IRangeData;
  mwd: boolean;
  bit: boolean;
  motor: boolean;
}

@Module
export class MultiwellCompareFilterFailuresModule extends VuexModule {
  _rangeTypesList: RangeTypes[] = Object.values(RangeTypes);
  _selectedRangeType: TRangeType = null;
  _selectedRangeData: IRangeData = { from: null, to: null };
  _selectedMWD: boolean = false;
  _selectedBit: boolean = false;
  _selectedMotor: boolean = false;

  get rangeTypesList(): TRangeType[] {
    return this._rangeTypesList;
  }

  get selectedRangeType(): TRangeType {
    return this._selectedRangeType;
  }

  get selectedRangeData(): IRangeData {
    return this._selectedRangeData;
  }

  get selectedMWD(): boolean {
    return this._selectedMWD;
  }

  get selectedBit(): boolean {
    return this._selectedBit;
  }

  get selectedMotor(): boolean {
    return this._selectedMotor;
  }

  get accessibleData(): string {
    const dataStringsArray: string[] = [];

    if(this.selectedRangeData?.from && this.selectedRangeData?.to) {
      if(this.selectedRangeType === RangeTypes.DEPTH_RANGE) {
        dataStringsArray.push(`Failure ${this.selectedRangeType.toLowerCase()} from ${this.selectedRangeData.from} ft to ${this.selectedRangeData.to} ft`);
      } else if(this.selectedRangeType === RangeTypes.DATE_RANGE) {
        const dateFrom = moment.unix(this.selectedRangeData.from).format('MMM DD, YYYY');
        const dateTo = moment.unix(this.selectedRangeData.to).format('MMM DD, YYYY');

        dataStringsArray.push(`Failure ${this.selectedRangeType.toLowerCase()} from ${dateFrom} to ${dateTo}`);
      }
    }

    if(this.selectedMWD) {
      dataStringsArray.push('MWD failure');
    }
    if(this.selectedBit) {
      dataStringsArray.push('Bit failure');
    }
    if(this.selectedMotor) {
      dataStringsArray.push('Motor failure');
    }

    return dataStringsArray.join(';\t');
  }

  @Mutation
  setSelectedRangeType(rangeType: TRangeType) {
    this._selectedRangeType = rangeType;
  }

  @Mutation
  setSelectedRangeData(rangeData: IRangeData) {
    this._selectedRangeData = rangeData ? ({ ...rangeData }) : undefined;
  }

  @Mutation
  setSelectedMWD(mwd: boolean) {
    this._selectedMWD = mwd;
  }

  @Mutation
  setSelectedBit(bit: boolean) {
    this._selectedBit = bit;
  }

  @Mutation
  setSelectedMotor(motor: boolean) {
    this._selectedMotor = motor;
  }

  @Action
  setFailuresFilters(fullFailures: IFailuresFilters): void {
    store.commit(MultiwellCompareFiltersMutation.SET_FAILURES_RANGE_TYPE, fullFailures.rangeType);
    store.commit(MultiwellCompareFiltersMutation.SET_FAILURES_RANGE_DATA, fullFailures.rangeData);
    store.commit(MultiwellCompareFiltersMutation.SET_FAILURES_MWD, fullFailures.mwd);
    store.commit(MultiwellCompareFiltersMutation.SET_FAILURES_BIT, fullFailures.bit);
    store.commit(MultiwellCompareFiltersMutation.SET_FAILURES_MOTOR, fullFailures.motor);
  }

  @Action
  clearSelectedRange(): void {
    store.commit(MultiwellCompareFiltersMutation.SET_FAILURES_RANGE_TYPE, null);
    store.commit(MultiwellCompareFiltersMutation.SET_FAILURES_RANGE_DATA, { from: null, to: null });
  }

  @Action
  clearSelectedToggles(): void {
    store.commit(MultiwellCompareFiltersMutation.SET_FAILURES_MWD, false);
    store.commit(MultiwellCompareFiltersMutation.SET_FAILURES_BIT, false);
    store.commit(MultiwellCompareFiltersMutation.SET_FAILURES_MOTOR, false);
  }

  @Action
  async clearFailuresFilters(): Promise<void> {
    await store.dispatch(MultiwellCompareFiltersAction.CLEAR_FAILURES_RANGE);
    await store.dispatch(MultiwellCompareFiltersAction.CLEAR_FAILURES_TOGGLES);
  }
}
