import { WellId } from '../../types';

export type AntiCollisionSettings = {
  wellId: WellId;
  offsetWellIds: WellId[];
  _id?: string;
};

export enum AntiCollisionSettingsMutation {
  SET_ANTI_COLLISION_SETTINGS = 'setAntiCollisionSettings',
}

export enum AntiCollisionSettingsAction {
  FETCH_ANTI_COLLISION_SETTINGS = 'fetchAntiCollisionSettings',
  UPDATE_ANTI_COLLISION_SETTINGS = 'updateAntiCollisionSettings',
}
