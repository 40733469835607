export enum DirectionalPlotTabMutation {
  SET_SURVEYS = 'setDirectionalPlotSurveys',
  SET_WELL_PLANS = 'setDirectionalPlotWellPlans',
  SET_SETTINGS_MODAL_ACTIVE = 'setDirectionalPlotSettingsModalActive',
  SET_RECOMMENDATION_MODAL_ACTIVE = 'setDirectionalPlotRecommendationModalActive',
  SET_GO_TO_MODAL_ACTIVE = 'setDirectionalPlotGoToModalActive',
  SET_WELL_PLAN_EDITOR_MODAL_ACTIVE = 'setWellPlanEditorModalActive',
  SET_DIRECTIONAL_PLOT_PSEUDO_SURVEYS_ACTIVE = 'setDirectionalPlotPseudoSurveysActive',
  SET_DIRECTIONAL_PLOT_TARGET_AZIMUTH_MODAL_ACTIVE = 'setDirectionalPlotTargetAzimuthModalActive',
  SET_DIRECTIONAL_PLOT_OFFSET_WELL_IDS = 'setDirectionalPlotOffsetWellIds',
  SET_DIRECTIONAL_PLOT_TARGET_AZIMUTH_INPUT = 'setDirectionalPlotTargetAzimuthInput',
  SET_SELECTED_DEPTH_RANGE = 'setDirectionalPlotSelectedDepthRange',
  SWITCH_LEGEND_ITEM_VISIBILITY = 'switchLegendItemVisibility',
  SWITCH_WELL_ITEMS_VISIBILITY = 'switchWellItemVisibility',
  UPDATE_LEGEND_ITEMS = 'updateLegendItems',
  DELETE_LEGEND_ITEMS = 'deleteLegendItems',
  SET_SELECTED_SURVEY_HEATMAP_METRIC = 'setSelectedSurveyHeatmapMetric',
  SET_CAMERA_VIEW_3D_PLOT = 'setCameraView3dPlot',
  SET_TOOLTIP_CONFIG = 'setDirectionalPlotTooltipConfig',
  UPDATE_TOOLTIP_CONFIG = 'updateDirectionalPlotTooltipConfig',
}

export enum DirectionalPlotTabAction {
  FETCH_SURVEYS = 'fetchDirectionalPlotSurveys',
  FETCH_WELL_PLANS = 'fetchDirectionalPlotWellPlans',
}

export enum IconType {
  POINT = 'point',
  LINE = 'line',
  DOTS = 'dots',
}

export type LegendItem = {
  color: string;
  label: string;
  value?: number | string;
  iconType?: IconType;
  visible?: boolean;
};

export type LegendData = {
  items?: LegendItem[];
  visible?: boolean;
};

export enum DirectionaPlotSurveyHeatmapMetric {
  SLIDE_SEEN = 'Slide Seen',
  SLIDE_AHEAD = 'Slide Ahead',
  SLIDE_ROP_SEEN = 'Slide Rop Seen',
  ROTATE_ROP_SEEN = 'Rotate Rop Seen',
  C_2_C = 'c2c',
  MOTOR_YIELD = 'Motor Yield',
  ROTARY_BUILD = 'Rotary Build',
  ROTARY_WALK = 'Rotary Walk',
}

export type DirectionalPlotHeatmapMetricConfig = {
  field: string; range: [number, number]; idx?: number;
};

export type CameraView3dPlot = {
  x: number;
  y: number;
  z: number;
};

export type DirectionalPlotTooltipConfig = {
  surveyData: string[];
  recommendation: string[];
  wellPlan: string[];
  projection: string[];
};
