





























import { Component, Vue, Prop } from 'vue-property-decorator';

export enum ButtonSize {
  SMALL = 64,
  NORMAL = 100,
  LARGE = 144,
}

@Component
export default class NvictaButton extends Vue {
  @Prop({ required: true })
  label!: string;

  @Prop({ required: false, default: '' })
  width: string;

  @Prop({ required: false, default: false })
  disabled: boolean;

  @Prop({ required: false, default: false })
  stopPropagation: boolean;

  @Prop({ required: false, default: false })
  active: boolean;

  @Prop({ required: false, default: '' })
  theme: string;

  get isArrows(): boolean {
    return !!this.label.match(/[<|>]/g);
  }

  get labelConverted(): string | boolean {
    switch(this.label) {
      case '<<': return 'fa-angles-left';
      case '>>': return 'fa-angles-right';
      case '<': return 'fa-angle-left';
      case '>': return 'fa-angle-right';
      case '...': return 'fa-ellipsis';
      default: return false;
    }
  }

  get widthClassBySize(): string {
    return this.width ? `nvicta-button--width_${this.width}` : '';
  }

  onClick(): void {
    this.$emit('click');
  }
}

