import { Tooltip } from './types';

import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

import * as _ from 'lodash';

const DEFAULT_TOOLTIP: Tooltip = {
  displayed: false,
  x: 0,
  y: 0,
  content: '',
};

@Module
export class TooltipModule extends VuexModule {
  _tooltip: Tooltip = _.clone(DEFAULT_TOOLTIP);
  _leftTabTooltips: Tooltip[] = [
    _.clone(DEFAULT_TOOLTIP),
    _.clone(DEFAULT_TOOLTIP),
    _.clone(DEFAULT_TOOLTIP),
    _.clone(DEFAULT_TOOLTIP),
  ];

  _leftTabTooltipY: number = 0;
  _isLeftTabTooltipDisplayed: boolean = false;

  get isTooltipDisplayed(): boolean {
    return this._tooltip.displayed;
  }

  get isLeftTabTooltipsDisplayed(): boolean {
    return this._isLeftTabTooltipDisplayed;
  }

  get leftTabTooltipY(): number {
    return this._leftTabTooltipY;
  }

  get tooltipX(): number {
    return this._tooltip.x;
  }

  get tooltipY(): number {
    return this._tooltip.y;
  }

  get tooltipContent(): string {
    return this._tooltip.content;
  }

  get leftTabTooltips(): Tooltip[] {
    return this._leftTabTooltips;
  }

  @Mutation
  setTooltip(tooltip: Tooltip) {
    this._tooltip = _.clone(tooltip);
  }

  @Mutation
  setTooltipDisplayed(displayed: boolean): void {
    this._tooltip.displayed = displayed;
  }

  @Mutation
  setTooltipX(x: number): void {
    this._tooltip.x = x;
  }

  @Mutation
  setTooltipY(y: number): void {
    this._tooltip.y = y;
  }

  @Mutation
  setTooltipContent(content: string): void {
    this._tooltip.content = content;
  }

  @Mutation
  setLeftTabTooltip(payload: { index: number, tooltip: Tooltip }): void {
    const tooltip = payload.tooltip;
    this._leftTabTooltips[payload.index] = {
      displayed: this._isLeftTabTooltipDisplayed,
      x: tooltip.x,
      y: this._leftTabTooltipY,
      content: tooltip.content,
    };
  }

  @Mutation
  setLeftTabTooltipPositionY(yPosition: number): void {
    this._leftTabTooltipY = yPosition;
  }

  @Mutation
  setLeftTabTooltipDisplayed(displayed: boolean): void {
    this._isLeftTabTooltipDisplayed = displayed;
  }
}
