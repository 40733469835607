export type DataProcessorSettings = {
  wellId: string;
  hookLoadThresh: number;
  flowrateMinThresh: number;
  flowrateMaxThresh: number;
  pressureThreshold: number;
  rotationThreshold: number;
  rpmLowerThresh: number;
  rpmUpperThresh: number;
  blkHeightThreshold: number;
  standLengthThreshold: number;
  squatLength: number;
  sectionAzimuth?: number;
  _id?: string;
};

export enum DataProcessorSettingsMutation {
  SET_DATA_PROCESSOR_SETTINGS = 'setDataProcessorSettings',
}

export enum DataProcessorSettingsAction {
  FETCH_DATA_PROCESSOR_SETTINGS = 'fetchDataProcessorSettings',
  UPDATE_DATA_PROCESSOR_SETTINGS = 'updateDataProcessorSettings',
}
