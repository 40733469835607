import { DrillingRecommendation, DrillingRecommendationMutation } from './types';

import { queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class DrillingRecommendationModule extends VuexModule {
  _drillingRecommendations: DrillingRecommendation[] = [];
  _activeDrillingRecommendation: DrillingRecommendation | null = null;

  get activeDrillingRecommendation(): DrillingRecommendation | undefined {
    if(this._activeDrillingRecommendation === null) {
      return undefined;
    }
    return this._activeDrillingRecommendation;
  }

  get drillingRecommendations(): DrillingRecommendation[] {
    return this._drillingRecommendations;
  }

  @Mutation
  clearDrillingRecommendations(): void {
    this._drillingRecommendations = [];
  }

  @Mutation
  setActiveDrillingRecommendation(drillingRecommendation: DrillingRecommendation): void {
    this._activeDrillingRecommendation = drillingRecommendation || null;
  }

  @Mutation
  appendDrillingRecommendation(recommendation: DrillingRecommendation): void {
    if(_.isNil(recommendation)) {
      return;
    }
    this._drillingRecommendations.push(recommendation);
    store.commit(DrillingRecommendationMutation.SET_ACTIVE_DRILLING_RECOMMENDATION, recommendation);
  }

  @Mutation
  setDrillingRecommendationRows(rows: DrillingRecommendation[]): void {
    this._drillingRecommendations = rows;
    if(rows.length > 0) {
      const lastRow = _.last(rows);
      store.commit(DrillingRecommendationMutation.SET_ACTIVE_DRILLING_RECOMMENDATION, lastRow);
    }
  }

  @Action({ rawError: true })
  async fetchDrillingRecommendations(): Promise<void> {
    const event = 'drilling-recommendation/get';
    const params = {
      wellId: this.context.getters.currentWellId,
    };
    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }
    this.context.commit(DrillingRecommendationMutation.SET_RECOMMENDATIONS, resp.data);
    console.log('resp.data:', resp.data);
  }
}
