














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ProgressBar extends Vue {
  @Prop({ required: true })
  progress: number; // percent

  @Prop({ required: false, default: '' })
  label: string;
}
