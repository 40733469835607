




































import ApiTokenModal from '@/components/modals/admin/ApiTokenModal.vue';
import AddNewUserModal from '@/components/modals/admin/AddNewUserModal.vue';

import { ApiTokenAction } from '@/store/modules/api_token/types';
import { UserAction } from '@/store/modules/users/types';

import { Component, Vue } from 'vue-property-decorator';

@Component({ components: { ApiTokenModal, AddNewUserModal } })
export default class AdminPageHeader extends Vue {
  isApiModalActive = false;
  isNewUserModalActive = false;

  openApiTokenModal(): void {
    this.isApiModalActive = true;
  }

  closeApiTokenModal(): void {
    this.isApiModalActive = false;
  }

  openNewUserModal(): void {
    this.isNewUserModalActive = true;
  }

  closeNewUserModal(): void {
    this.isNewUserModalActive = false;
  }

  async refetchUsers(): Promise<void> {
    await this.$store.dispatch(UserAction.FETCH_USERS);
  }

  async created(): Promise<void> {
    await this.$store.dispatch(ApiTokenAction.FETCH_API_TOKENS);
  }
}
