

















































import Modal from '../Modal.vue';
import SearchableSelector from '@/components/selectors/SearchableSelector.vue';
import { VUFileSettings } from '@/components/FileUploaderSettings.vue';

import Papa from 'papaparse';

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as _ from 'lodash';

const TIME_AND_DATE_FIELDS = [
  'YYYY/MM/DD',
  'HH:MM:SS',
];

const CSV_FIELDS = [
  'holeDepth',
  'bitDepth',
  'blockHeight',
  'rop',
  'hookload',
  'wob',
  'torque',
  'rpm',
  'pumpPressure',
  'pumpRate',
  'diffPressure',
];

// values should be lowercase with no spaces to prevent extra formatting
const CSV_FIELDS_NAMES = {
  holeDepth: ['holedepth'],
  bitDepth: ['bitdepth'],
  blockHeight: ['blockheight'],
  rop: ['rop', 'rateofpenetration'],
  hookload: ['hookload'],
  wob: ['wob', 'weightonbit'],
  torque: ['torque'],
  rpm: ['rpm', 'revolutionsperminute'],
  pumpPressure: ['pumppressure'],
  pumpRate: ['pumprate'],
  diffPressure: ['diffpressure', 'differentialpressure'],
  ['YYYY/MM/DD']: ['yyyy/mm/dd', 'yyyy:mm:dd'],
  ['HH:MM:SS']: ['hh/mm/ss', 'hh:mm:ss'],
};

@Component({ components: { Modal, SearchableSelector } })
export default class AssociateCSVModal extends Vue {
  @Prop({ required: true })
  isModalActive: boolean;

  @Prop({ required: true })
  files: VUFileSettings[];

  columnNames: string[] = [];

  associates: { [ key: string]: string } = {};

  separateTimeAndDateFields = true;

  startCase = _.startCase;

  get modalName(): string {
    return `CSV Fields Configuration`;
  }

  get csvFields(): string[] {
    return _.concat(
      this.separateTimeAndDateFields ? TIME_AND_DATE_FIELDS : [],
      CSV_FIELDS
    );
  }

  get isApplyDisabled(): boolean {
    return _.keys(this.associates).length !== this.csvFields.length;
  }

  get csvFieldsFromFile(): string[] {
    return _.difference(this.columnNames, _.values(this.associates));
  }

  @Watch('isModalActive')
  async onModalActiveChange(): Promise<void> {
    if(this.isModalActive) {
      try {
        this.columnNames = await getCsvColumns(this.files[0]);
        this.autoFillFields();
      } catch(e) {
        this.files.forEach((file: VUFileSettings) => {
          Vue.set(file, 'error', e);
        });
      }
    } else {
      this.columnNames = [];
    }
  }

  onToggleField(systemField: string, csvValue: string): void {
    Vue.set(this.associates, csvValue, systemField);
  }

  onApplyClick(): void {
    this.$emit('apply', this.associates);
    this.close();
  }

  onCloseClick(): void {
    this.close();
  }

  autoFillFields(): void {
    const formattedCsvFields = _.map(this.columnNames, (column: string) => this.formatFieldName(column));
    for(const field of this.csvFields) {
      const filteredCsvFields = _.filter(formattedCsvFields, (csvField: string) => {
        let entries = 0;
        for(const name of CSV_FIELDS_NAMES[field]) {
          if(_.includes(csvField, name)) {
            entries++;
          }
        }
        return entries === 1;
      });
      if(filteredCsvFields.length === 1) {
        const fieldIndex = formattedCsvFields.indexOf(filteredCsvFields[0]);
        this.associates[field] = this.columnNames[fieldIndex];
      }
    }
  }

  formatFieldName(column: string): string {
    return column.toLocaleLowerCase().replaceAll(' ', '');
  }

  close(): void {
    this.$emit('closeAssociateModal');
  }
}

async function getCsvColumns(file: VUFileSettings): Promise<string[]> {
  return new Promise((resolve: any, reject: any) => {
    try {
      Papa.parse(
        file.file,
        { step: (row: { data: string[] }, parser: any) => { resolve(row.data); parser.abort(); } }
      );
    } catch(e) {
      reject(e);
    }
  });
}
