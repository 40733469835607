export enum BucklingMutation {
  SET_BUCKLING = 'setBuckling',
  CLEAR_BUCKLING = 'clearBuckling',
}

export enum BucklingAction {
  FETCH_BUCKLING = 'fetchBuckling',
}

export enum BucklingOperation {
  PICK_UP = 'Pick Up',
  SLACK_OFF = 'Slack Off',
  ROTATION = 'Rotation',
}

export enum Operation {
  TRIP_IN = 'tripIn',
  TRIP_OUT = 'tripOut',
  ROTATION = 'rotation',
}

export type BucklingData = {
  md: number[];
  sin: boolean[];
  helical: boolean[];
  frictionFactor: number;
  operation: Operation;
};

export type Buckling = {
  bucklingData: BucklingData[];
};
