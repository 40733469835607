import { MultiwellCompareCoreModule } from '@/store/modules/multiwell_compare/core';
import {
  MultiwellCompareFilterLocationModule,
  MultiwellCompareFilterBHAModule,
  MultiwellCompareFilterTrajectoryModule,
  MultiwellCompareFilterFailuresModule,
  MultiwellCompareFilterPersonnelModule,
  MultiwellCompareFilterMudTypeModule,
  MultiwellCompareFilterDrillingParametersModule,
} from '@/store/modules/multiwell_compare/filters';
import {
  MultiwellCompareDrillingOptimizationTabModule,
  MultiWellsDirectionalTabModule,
  MultiwellCompareNptTabModule,
} from '@/store/modules/multiwell_compare/tabs';
import { MultiwellCompareWellListsModule } from '@/store/modules/multiwell_compare/wellLists';

export default {
  modules: {
    multiwellsCore: {
      namespaced: true,
      modules: { MultiwellCompareCoreModule },
    },
    multiwellsFilters: {
      namespaced: true,
      modules: {
        location: {
          namespaced: true,
          modules: { MultiwellCompareFilterLocationModule },
        },
        bha: {
          namespaced: true,
          modules: { MultiwellCompareFilterBHAModule },
        },
        trajectory: {
          namespaced: true,
          modules: { MultiwellCompareFilterTrajectoryModule },
        },
        failures: {
          namespaced: true,
          modules: { MultiwellCompareFilterFailuresModule },
        },
        personnel: {
          namespaced: true,
          modules: { MultiwellCompareFilterPersonnelModule },
        },
        mudType: {
          namespaced: true,
          modules: { MultiwellCompareFilterMudTypeModule },
        },
        drillingParameters: {
          namespaced: true,
          modules: { MultiwellCompareFilterDrillingParametersModule },
        },
      },
    },
    multiwellsTabsList: {
      namespaced: true,
      modules: {
        drillingOptimization: {
          namespaced: true,
          modules: { MultiwellCompareDrillingOptimizationTabModule },
        },
        directional: {
          namespaced: true,
          modules: { MultiWellsDirectionalTabModule },
        },
        npt: {
          namespaced: true,
          modules: { MultiwellCompareNptTabModule },
        },
      },
    },
    multiwellsWellsLists: {
      namespaced: true,
      modules: { MultiwellCompareWellListsModule },
    },
  },
};
