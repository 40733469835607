import { MainTab } from './types';

import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module
export class MainTabsModule extends VuexModule {
  _activeMainTab = MainTab.MODULES;

  get activeMainTab(): MainTab {
    return this._activeMainTab;
  }

  @Mutation
  setActiveMainTab(tab: MainTab): void {
    this._activeMainTab = tab;
  }
}
