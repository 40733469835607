import { TraceConfig } from '../types';

export enum PumpRecycleMutation {
  SET_PUMP_RECYCLES = 'setPumpRecycles',
  SET_LAST_TIME = 'setPumpRecyclesLastTime',
}

export enum PumpRecycleAction {
  FETCH_PUMP_RECYCLES = 'fetchPumpRecycles',
}

export type PumpRecycle = {
  time: number;
  count: number;
};

export enum GaugePumpRecyclesTrace {
  PUMP_RECYCLES = 'pumpRecycles',
}

export const PUMP_RECYCLES_TRACE_TO_NAME_AND_UNIT_MAPPING: { [trace: string]: TraceConfig } = {
  pumpRecycles: { name: 'Pump Recycles', unit: '', min: 0, max: 20 },
};
