export enum CompareWellsMutation {
  REMOVE_WELL_FROM_COMPARISON = 'removeWellFromComparison',
  CHANGE_COMPARE_WELLS_TRACE = 'changeCompareWellsTrace',
  SET_COMPARE_WELLS_DATA = 'setCompareWellsData',
  SET_COMPARE_WELLS_CHARTS_ZOOM = 'setCompareWellsChartsZoom',
  SET_LAST_COMPARE_WELLS_DEPTH = 'setLastCompareWellsDepth',
  SET_COMPARE_WELLS_LIVE_DEPTH_INTERVAL = 'setCompareWellsLiveDepthInterval',
  SET_COMPARE_WELLS_REFETCH_FLAG = 'setCompareWellsRefetchFlag',
  APPEND_COMPARE_WELLS_DATA = 'appendCompareWellsData',
  CLEAR_COMPARE_WELLS = 'clearCompareWells',
  SET_COMPARE_WELLS_DATA_FETCHING = 'setCompareWellsDataFetching',
  SET_COMPARE_WELLS_TRACES = 'setCompareWellsTraces',
}

export enum CompareWellsAction {
  ADD_WELL_TO_COMPARISON = 'addWellToComparison',
  FETCH_COMPARE_WELLS_DATA = 'fetchCompareWellsData',
  FETCH_COMPARE_WELLS_DATA_BY_DEPTH = 'fetchCompareWellsDataByDepth',
}
