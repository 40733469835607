import { FrictionFactorEstimate, FrictionFactorEstimateMutation } from './types';

import { queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class FrictionFactorEstimateModule extends VuexModule {
  _frictionFactorEstimates: FrictionFactorEstimate[] = [];
  _time: number | undefined;

  get frictionFactorEstimates(): FrictionFactorEstimate[] {
    return this._frictionFactorEstimates;
  }

  @Mutation
  setFrictionFactorEstimates(frictionFactorEstimates: FrictionFactorEstimate[]): void {
    if(frictionFactorEstimates === undefined || frictionFactorEstimates.length === 0) {
      return;
    }

    const timeList = _.map(frictionFactorEstimates, (el: FrictionFactorEstimate) => el.time);
    const lastTime = _.max(timeList);
    store.commit(FrictionFactorEstimateMutation.SET_LAST_TIME, lastTime);

    this._frictionFactorEstimates = [...this._frictionFactorEstimates, ...frictionFactorEstimates];
  }

  @Mutation
  setFrictionFactorEstimatesLastTime(time: number): void {
    this._time = time;
  }

  @Mutation
  clearFrictionFactorEstimates(): void {
    this._frictionFactorEstimates = [];
    this._time = undefined;
  }

  @Action({ rawError: true })
  async fetchFrictionFactorEstimates(): Promise<void> {
    let from = this._time;
    if(from !== undefined) {
      from += 1;
    }

    const event = 'friction-factor-estimate/get';
    const params = {
      wellId: this.context.getters.currentWellId,
      from,
    };

    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }

    this.context.commit(FrictionFactorEstimateMutation.SET_FRICTION_FACTOR_ESTIMATES, resp.data);
  }
}
