export type SettingsItem = {
  min: number;
  max: number;
};

export type EMSettings = {
  'FlowAccel (%)': SettingsItem;
  'Temp (°C)': SettingsItem;
  '20VBatt (V)': SettingsItem;
  '40VBatt (V)': SettingsItem;
  'SSG (rpm)': SettingsItem;
};

export type EYESettings = {
  'Incl (deg)': SettingsItem;
  'MagNorthAz (deg)': SettingsItem;
  'EYEHs (359 dot 92 deg)': SettingsItem;
  'aT (mG)': SettingsItem;
  'bT (nT)': SettingsItem;
  'Dip (deg)': SettingsItem;
  'Tmag (°C)': SettingsItem;
  'Taccel (°C)': SettingsItem;
};

export type GammaSettings = {
  'NormalizedCountRate (cnt/s)': SettingsItem;
  'TotalGamma (gapi)': SettingsItem;
};

export type GammaIncSettings = {
  'Ax (mG)': SettingsItem;
  'Ay (mG)': SettingsItem;
  'Az (mG)': SettingsItem;
  'aT (mG)': SettingsItem;
  'Incl (deg)': SettingsItem;
  'HsTF (deg)': SettingsItem;
  'AccT (°C)': SettingsItem;
};

export type MwdToolSettings = {
  userId: string;
  EM: EMSettings;
  EYE: EYESettings;
  Gamma: GammaSettings;
  GammaInc: GammaIncSettings;
  _id?: string;
};

export enum MwdToolSettingsMutation {
  SET_MWD_TOOL_SETTINGS = 'setMwdToolSettings',
}

export enum MwdToolSettingsAction {
  FETCH_MWD_TOOL_SETTINGS = 'fetchMwdToolSettings',
  UPDATE_MWD_TOOL_SETTINGS = 'updateMwdToolSettings',
}
