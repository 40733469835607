import { DepthTrace } from '../depth_data/types';
import { TimeTrace } from '../well_data/types';
import { SlideSheetsTrace } from '../tabs/left_tabs/directional_guidance_tab/types';
import { SurveyTrace } from '../survey/types';

export enum DashboardPresetMutation {
  SET_PRESETS = 'setDashboardPresets',
  SET_ACTIVE_PRESET = 'setActiveDashboardPreset',
  APPEND_PRESET = 'appendDashboardPreset',
  REMOVE_PRESET = 'removeDashboardPreset',
}

export enum DashboardPresetAction {
  FETCH_PRESETS = 'fetchDashboardPresets',
  UPDATE_PRESET = 'updateDashboardPreset',
  ADD_PRESET = 'addDashboardPreset',
  DELETE_PRESET = 'deleteDashboardPreset',
}

export type DashboardPreset = {
  userId: string;
  name: string;
  timeCharts: TimeTrace[][];
  depthCharts: DepthTrace[][];
  compareCharts: DepthTrace[];
  directionalGuidanceSurveys: SurveyTrace[];
  directionalGuidancePseudoSurveys: SurveyTrace[];
  directionalGuidanceSlideSheets: SlideSheetsTrace[];
  directionalPlotSurveyTooltip: string[];
  directionalPlotRecomendationTooltip: string[];
  directionalPlotWellPlanTooltip: string[];
  directionalPlotProjectionTooltip: string[];
  isFormationTopColorsActive: boolean;
  _id?: string;
};
