export enum DrillingOptimizationTabMutation {
  SET_FOUNDER_PLOT_MODE = 'setFounderPlotMode',
  SET_FOUNDER_RPM = 'setFounderRPM',
  SET_FOUNDER_RPM_RANGE = 'setFounderRpmRange',
  SET_FOUNDER_Y_AXIS_RANGE = 'setFounderYaxisRange',
  SET_FOUNDER_X_AXIS_RANGE = 'setFounderXaxisRange',
  SET_FOUNDER_SELECTED_FORMATION_TOP_ID = 'setFounderSelectedFormationTopId',
  SET_FOUNDER_DATA = 'setFounderData',
  REMOVE_FOUNDER_WELL = 'removeFounderWell',
  SET_FOUNDER_DEPTH_DATA = 'setFounderDepthData',
}

export enum DrillingOptimizationTabAction {
  ADD_FOUNDER_WELL = 'addFounderWell',
  FETCH_FOUNDER_DATA = 'fetchFounderData',
  FETCH_FOUNDER_DEPTH_DATA = 'fetchFounderDepthData',
}

export type AxisRanges = {
  x: [number, number] | null;
  y: [number, number] | null;
};

export enum FounderPlotMode {
  ROP_VS_WOB = 'ROP vs. WOB',
  ROP_VS_DIFF = 'ROP vs. Diff. Pressure',
  MSE_VS_WOB = 'MSE vs. WOB',
  MSE_VS_DIFF = 'MSE vs. Diff. Pressure',
  DIFF_VS_WOB = 'Diff. Pressure vs. WOB',
}
