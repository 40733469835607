import { queryApi } from '@/services/server_service';
import { queryServer } from '@/services/socket_service';

import { DataProcessorSettings, DataProcessorSettingsMutation, DataProcessorSettingsAction } from './types';

import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';

import { AxiosResponse } from 'axios';
import * as _ from 'lodash';

const URL = 'api/data-processor-settings';

@Module
export class DataProcessorSettingsModule extends VuexModule {
  _dataProcessorSettings: DataProcessorSettings | null = null;

  get dataProcessorSettings(): DataProcessorSettings | undefined {
    if(this._dataProcessorSettings === null) {
      return undefined;
    }
    return this._dataProcessorSettings;
  }

  @Mutation
  setDataProcessorSettings(dataProcessorSettings: DataProcessorSettings): void {
    if(!_.isEmpty(dataProcessorSettings)) {
      this._dataProcessorSettings = dataProcessorSettings;
    }
  }

  @Action({ rawError: true })
  async fetchDataProcessorSettings(): Promise<void> {
    const event = 'data-processor-settings/get';
    const params = { wellId: this.context.getters.currentWellId };

    const resp = await queryServer(event, params);

    if(resp === undefined) {
      return;
    }

    this.context.commit(DataProcessorSettingsMutation.SET_DATA_PROCESSOR_SETTINGS, resp.data);
  }

  @Action({ rawError: true })
  async updateDataProcessorSettings(settings: DataProcessorSettings): Promise<AxiosResponse | undefined> {
    const resp = await queryApi({
      url: URL,
      method: 'POST',
      data: settings,
    });
    if(resp) {
      await this.context.dispatch(DataProcessorSettingsAction.FETCH_DATA_PROCESSOR_SETTINGS);
    }
    return resp;
  }
}
