import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import { WitsConfigs, WitsConfigsAction, WitsConfigsMutation } from '@/store/modules/wits_configs/types';

import * as _ from 'lodash';
import { queryApi } from '@/services/server_service';

const URL = 'api/wits-configs';

@Module
export class WitsConfigsModule extends VuexModule {
  _witsConfigs: WitsConfigs | null = null;

  get witsConfigs(): WitsConfigs | undefined {
    if(this._witsConfigs === null) {
      return undefined;
    }
    return this._witsConfigs;
  }

  @Mutation
  setWitsConfigs(witsConfigs: WitsConfigs): void {
    if(!_.isEmpty(witsConfigs)) {
      this._witsConfigs = witsConfigs;
    }
  }

  @Action({ rawError: true })
  async fetchWitsConfigs(): Promise<void> {
    const params = { rigId: this.context.getters.currentRigId };

    const resp = await queryApi({
      url: URL,
      method: 'GET',
      params: params,
    });

    if(resp === undefined) {
      return;
    }

    this.context.commit(WitsConfigsMutation.SET_WITS_CONFIGS, resp.data);
  }

  @Action({ rawError: true })
  async updateWitsConfigs(witsConfigs: WitsConfigs): Promise<void> {
    await queryApi({
      url: URL,
      method: 'POST',
      data: witsConfigs,
    });
    await this.context.dispatch(WitsConfigsAction.FETCH_WITS_CONFIGS);
  }
}
