












import FileUploaderSettings from '@/components/FileUploaderSettings.vue';
import { SERVER_URL } from '@/config';
import { queryApi } from '@/services/server_service';

import { Component, Vue } from 'vue-property-decorator';

@Component({ components: { FileUploaderSettings } })
export default class UploadCSV extends Vue {
  csvUploadUrl = `${SERVER_URL}/api/well-data-upload-proxy?wellId=${this.$store.getters.currentWellId}`;
  format = 'csv';

  get wellId(): string {
    return this.$store.getters.currentWellId;
  }

  async submit(fieldsMapping: { [ key: string]: string }): Promise<void> {
    await queryApi({
      url: 'api/well-data-upload-proxy/process',
      method: 'POST',
      data: { wellId: this.$store.getters.currentWellId, fieldsMapping },
    });
  }
}
