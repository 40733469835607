import { WellData, WellDataMutation, WellDataAction, TimeTrace } from './types';
import { AppMutation } from '../app/types';
import { DepthDataMutation } from '../depth_data/types';
import { TimeChartsMutation } from '../tabs/left_tabs/drilling_data_tab/time_charts/types';
import { DrillingDataSubTab } from '../tabs/left_tabs/types';
import { GammaData } from '../gamma_data/types';

import { DataBucket } from '@/models/bucket';
import { currentTimestampInSeconds, findClosest } from '@/utils';
import { queryServer } from '@/services/socket_service';
import { queryApi } from '@/services/server_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

const KEY_FIELD = 'correctedTimestamp';
const DEFAULT_TIME_INTERVAL = 15 * 60; // sec
const CACHING_MULTIPLIER = 2; // increase data limit in live mode due to caching

@Module
export class WellDataModule extends VuexModule {
  // TODO: move DEFAULT_TIME_INTERVAL to config with defaults
  _liveData: DataBucket<WellData> = new DataBucket(DEFAULT_TIME_INTERVAL * CACHING_MULTIPLIER, KEY_FIELD);
  _historicalData?: WellData | null = null;
  _wellBorderTimestamps: [number, number] | null = null;
  _wellBorderCorrectedTimestamps: [number, number] | null = null; // time borders without gaps
  _wellDataLastFetchedTimestamp: number | null = null;
  _wellDataLastFetchedCorrectedTimestamp: number | null = null;
  _isWellDataFetching = false;

  get wellData(): WellData | undefined {
    const liveMode = this.context.getters.liveMode;
    if(liveMode) {
      return this._liveData.data as WellData;
    } else {
      return this._historicalData || undefined;
    }
  }

  get wellBorderTimestamps(): [number, number] | undefined {
    if(this._wellBorderTimestamps === null) {
      return undefined;
    }
    return this._wellBorderTimestamps;
  }

  get isWellDataFetching(): boolean {
    return this._isWellDataFetching;
  }

  get wellBorderCorrectedTimestamps(): [number, number] | undefined {
    if(this._wellBorderCorrectedTimestamps === null) {
      return undefined;
    }
    return this._wellBorderCorrectedTimestamps;
  }

  get wellDataLastFetchedTimestamp(): number | undefined {
    if(this._wellDataLastFetchedTimestamp === null) {
      return undefined;
    }
    return this._wellDataLastFetchedTimestamp;
  }

  get wellDataLastFetchedCorrectedTimestamp(): number | undefined {
    if(this._wellDataLastFetchedCorrectedTimestamp === null) {
      return undefined;
    }
    return this._wellDataLastFetchedCorrectedTimestamp;
  }

  get fromToQueryLiveWellData(): (liveTimeInterval: number) => number {
    return (liveTimeInterval: number) => {
      const lastFetchedTimestamp = this.context.getters.wellDataLastFetchedCorrectedTimestamp || this.context.getters.wellDataLastFetchedTimestamp;
      const wellBorders = this.context.getters.wellBorderCorrectedTimestamps || this.context.getters.wellBorderTimestamps;
      const rightWellBorder = wellBorders ? wellBorders[1] : undefined;
      const border = lastFetchedTimestamp || rightWellBorder || currentTimestampInSeconds();
      return border - liveTimeInterval;
    };
  }

  @Mutation
  appendLiveWellData(data: WellData): void {
    if(data === undefined || data.time.length === 0) {
      return;
    }
    const lastTime = _.last(data.time);
    const lastCorrectedTime = _.last(data.correctedTimestamp);
    const leftBorder = lastCorrectedTime - store.getters.liveTimeIntervalInSeconds;
    const liveZoomRange = [leftBorder, lastCorrectedTime];
    store.commit(TimeChartsMutation.SET_TIME_CHARTS_LIVE_ZOOM_RANGE, liveZoomRange);
    // TODO: add projection fields to filter data
    this._liveData.appendData(data);
    store.commit(WellDataMutation.SET_WELL_DATA_LAST_FETCHED_TIMESTAMP, lastTime);
    store.commit(WellDataMutation.SET_WELL_DATA_LAST_FETCHED_CORRECTED_TIMESTAMP, lastCorrectedTime);
  }

  @Mutation
  appendGammaToLiveWellData(gammaData: GammaData[]): void {
    if(_.isEmpty(gammaData) || _.isEmpty((this._liveData.data as any)?.gamma)) {
      return;
    }
    for(const row of gammaData) {
      const gammaIndex = findClosest((this._liveData.data as WellData).time, row.time);
      (this._liveData.data as any).gamma[gammaIndex] = row.correctedGamma;
    }
  }

  @Mutation
  copyLiveDataToHistorical(): void {
    if(this._historicalData !== null) {
      return;
    }
    this._historicalData = _.cloneDeep(this._liveData.data);
  }

  @Mutation
  clearHistoricalData(): void {
    this._historicalData = null;
  }

  @Mutation
  setWellLiveData(data: WellData): void {
    if(data === undefined || data.time.length === 0) {
      return;
    }
    const lastTime = _.last(data.time);
    const lastCorrectedTime = _.last(data.correctedTimestamp);
    const leftBorder = lastCorrectedTime - store.getters.liveTimeIntervalInSeconds;
    const liveZoomRange = [leftBorder, lastCorrectedTime];
    store.commit(TimeChartsMutation.SET_TIME_CHARTS_LIVE_ZOOM_RANGE, liveZoomRange);
    this._liveData.setData(data);
    store.commit(WellDataMutation.SET_WELL_DATA_LAST_FETCHED_TIMESTAMP, lastTime);
    store.commit(WellDataMutation.SET_WELL_DATA_LAST_FETCHED_CORRECTED_TIMESTAMP, lastCorrectedTime);
  }

  @Mutation
  setWellHistoricalData(data: WellData): void {
    if(data === undefined || data.time.length === 0) {
      return;
    }
    this._historicalData = data;
  }

  @Mutation
  setWellDataBucketLimit(limit: number | null): void {
    if(limit === undefined) {
      return;
    }
    this._liveData.setLimit(limit * CACHING_MULTIPLIER);
  }

  @Mutation
  setWellBorderTimestamps(timestamps: [number, number]): void {
    this._wellBorderTimestamps = [Math.min(...timestamps), Math.max(...timestamps)];
  }

  @Mutation
  setSecondTimeBorder(border: number): void {
    if(this._wellBorderTimestamps === null || !border || this._wellBorderTimestamps[1] === border) {
      return;
    }
    this._wellBorderTimestamps[1] = border;
  }

  @Mutation
  setWellBorderCorrectedTimestamps(timestamps: [number, number]): void {
    if(_.isEmpty(timestamps) || timestamps[0] === undefined || timestamps[1] === undefined) {
      return;
    }
    this._wellBorderCorrectedTimestamps = [_.min(timestamps), _.max(timestamps)];
  }

  @Mutation
  setSecondCorrectedTimeBorder(border: number): void {
    if(this._wellBorderCorrectedTimestamps === null || !border || this._wellBorderCorrectedTimestamps[1] === border) {
      return;
    }
    this._wellBorderCorrectedTimestamps[1] = border;
  }

  @Mutation
  setWellDataLastFetchedTimestamp(timestamp: number | null): void {
    this._wellDataLastFetchedTimestamp = timestamp;
  }

  @Mutation
  setWellDataLastFetchedCorrectedTimestamp(timestamp: number | null): void {
    this._wellDataLastFetchedCorrectedTimestamp = timestamp;
  }

  @Mutation
  setWellDataFetching(isFetching: boolean): void {
    this._isWellDataFetching = isFetching;
  }

  @Action({ rawError: true })
  async fetchLiveWellData(): Promise<void> {
    const liveInterval = store.getters.liveTimeIntervalInSeconds;
    const timeBorders = [store.getters.fromToQueryLiveWellData(liveInterval), undefined];
    const data = await this.context.dispatch(
      WellDataAction.FETCH_WELL_DATA,
      { timeBorders, caching: true, timeInterval: liveInterval }
    );
    if(data === undefined) {
      return;
    }
    store.commit(WellDataMutation.SET_WELL_LIVE_DATA, data);
  }

  @Action({ rawError: true })
  async fetchHistoricalWellData(timeBorders: [number, number]): Promise<void> {
    store.commit(WellDataMutation.SET_WELL_DATA_FETCHING, true);
    store.commit(TimeChartsMutation.SET_TIME_CHARTS_HISTORICAL_ZOOM_RANGE, timeBorders);
    console.time('welldatafetch');
    const data = await this.context.dispatch(
      WellDataAction.FETCH_WELL_DATA,
      { timeBorders, caching: true }
    );
    console.timeEnd('welldatafetch');
    if(data === undefined) {
      store.dispatch('alertWarning', { title: 'Time Charts', message: `Time Data can't be fetched for range ${timeBorders}` });
      store.commit(WellDataMutation.SET_WELL_DATA_FETCHING, false);
      return;
    }
    store.commit(WellDataMutation.SET_WELL_HISTORICAL_DATA, data);
    store.commit(WellDataMutation.SET_WELL_DATA_FETCHING, false);
    if(store.getters.liveMode) {
      store.commit(AppMutation.TOGGLE_LIVE_MODE, DrillingDataSubTab.TIME);
    }
  }

  @Action({ rawError: true })
  async fetchHistoricalWellDataByNonKeys(payload: { range: [number, number], key: string }): Promise<void> {
    if(payload.key !== 'time' && payload.key !== 'holeDepth') {
      throw new Error(`Unknown type of ${payload.key}`);
    }
    store.commit(WellDataMutation.SET_WELL_DATA_FETCHING, true);
    console.time('welldatafetchnonkey');
    const data = await this.context.dispatch(
      WellDataAction.FETCH_WELL_DATA,
      { timeBorders: payload.range, caching: false, key: payload.key }
    );
    console.timeEnd('welldatafetchnonkey');
    if(data === undefined) {
      store.dispatch('alertWarning', { title: 'Time Charts', message: `Time Data can't be fetched for range ${payload.range}` });
      store.commit(WellDataMutation.SET_WELL_DATA_FETCHING, false);
      return;
    }
    const timeBorders = [_.head(data.correctedTimestamp), _.last(data.correctedTimestamp)];
    store.commit(TimeChartsMutation.SET_TIME_CHARTS_HISTORICAL_ZOOM_RANGE, timeBorders);
    store.commit(WellDataMutation.SET_WELL_HISTORICAL_DATA, data);
    store.commit(WellDataMutation.SET_WELL_DATA_FETCHING, false);
    if(store.getters.liveMode) {
      store.commit(AppMutation.TOGGLE_LIVE_MODE, DrillingDataSubTab.TIME);
    }
  }

  @Action({ rawError: true })
  async fetchWellData(
    payload: {
      timeBorders?: [number?, number?];
      projection?: any;
      caching?: boolean;
      key?: 'time' | 'correctedTimestamp' | 'holeDepth';
      timeInterval?: number; // works only for live data
    }
  ): Promise<WellData | undefined> {
    const event = 'well-data/get';
    const timeTracesProjection = {
      time: 1,
      correctedTimestamp: 1,
      currentTimeGap: 1,
      rigState: 1,
      ...payload.projection,
    };
    if(!payload.projection) {
      const traces = this.context.getters.timeChartsTraces.reduce((x: TimeTrace[], y: TimeTrace[]) => [...x, ...y]);
      traces.forEach((trace: TimeTrace) => {
        timeTracesProjection[trace] = 1;
      });
    }

    const from = payload.timeBorders ? payload.timeBorders[0] : undefined;
    const to = payload.timeBorders ? payload.timeBorders[1] : undefined;
    const params = {
      wellId: this.context.getters.currentWellId,
      from,
      to,
      projection: timeTracesProjection,
      key: payload.key,
      caching: payload.caching,
      timeInterval: payload.timeInterval,
    };
    const resp = await queryServer(event, params);
    if(resp === undefined || _.isEmpty(resp.data) || _.isEmpty(resp.data.time)) {
      return undefined;
    }
    return resp.data;
  }

  @Action({ rawError: true })
  async fetchWellDataBorders(): Promise<void> {
    const url = `api/well-data/borders`;
    const resp = await queryApi({
      url,
      method: 'GET',
      params: { wellId: this.context.getters.currentWellId },
    });

    if(resp === undefined || _.isEmpty(resp.data)) {
      return;
    }

    if(resp.data.time) {
      this.context.commit(WellDataMutation.SET_WELL_BORDER_TIMESTAMPS, resp.data.time);
    }
    if(resp.data.correctedTimestamp) {
      this.context.commit(WellDataMutation.SET_WELL_BORDER_CORRECTED_TIMESTAMPS, resp.data.correctedTimestamp);
    }
    if(resp.data.depth) {
      this.context.commit(DepthDataMutation.SET_DEPTH_BORDERS, resp.data.depth);
    }
    if(resp.data.localDepth) {
      this.context.commit(DepthDataMutation.SET_LOCAL_DEPTH_BORDERS, resp.data.localDepth); // only for depth charts
    }
  }

  @Action({ rawError: true })
  async fetchWellDataRaw(
    payload: { projection: any, range?: [number, number], key?: 'time' | 'correctedTimeStamp' }
  ): Promise<WellData | undefined> {
    const event = 'well-data/get/raw';
    const key = payload.key || 'time';
    const params = {
      wellId: this.context.getters.currentWellId,
      projection: payload.projection,
      from: payload.range[0],
      to: payload.range[1],
      key,
    };
    console.time('fetchWellDataRaw');
    const resp = await queryServer(event, params);
    console.timeEnd('fetchWellDataRaw');

    if(resp === undefined || _.isEmpty(resp.data) || _.isEmpty(resp.data.time)) {
      return undefined;
    }
    return resp.data;
  }

  @Action({ rawError: true })
  async fetchRawWellDataForTimeCharts(): Promise<void> {
    store.commit(WellDataMutation.SET_WELL_DATA_FETCHING, true);
    const timeTracesProjection = {
      time: 1,
      correctedTimestamp: 1,
      currentTimeGap: 1,
      rigState: 1,
    };
    const traces = this.context.getters.timeChartsTraces.reduce((x: TimeTrace[], y: TimeTrace[]) => [...x, ...y]);
    traces.forEach((trace: TimeTrace) => {
      timeTracesProjection[trace] = 1;
    });

    const timeRange = store.getters.timeChartsZoomRange;
    const data = await this.context.dispatch(
      WellDataAction.FETCH_WELL_DATA_RAW,
      { projection: timeTracesProjection, range: timeRange, key: 'correctedTimestamp' }
    );
    if(data === undefined) {
      store.dispatch('alertWarning', { title: 'Time Charts', message: `Raw Time Data can't be fetched for range ${timeRange}` });
      store.commit(WellDataMutation.SET_WELL_DATA_FETCHING, false);
      return;
    }
    // TODO: use insert in certain range instead of set to save cache.
    store.commit(WellDataMutation.SET_WELL_HISTORICAL_DATA, data);
    store.commit(WellDataMutation.SET_WELL_DATA_FETCHING, false);
  }

  @Action({ rawError: true })
  async refetchWellData(): Promise<void> {
    if(!this.context.getters.liveMode) {
      this.context.dispatch(WellDataAction.FETCH_HISTORICAL_WELL_DATA, this.context.getters.timeChartsZoomRange);
    }
    this.context.dispatch(WellDataAction.FETCH_LIVE_WELL_DATA);
  }
}
