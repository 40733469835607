import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, min_value, email } from 'vee-validate/dist/rules';

import Vue from 'vue';

extend('required', {
  ...required,
  message: 'This field is required.',
});

extend('min_value', {
  ...min_value,
  message: 'This field must be a positive number.',
});

extend('email', {
  ...email,
  message: (field: string) => `${field} field must be a valid email`,
});

extend('phone', {
  validate: function (value) {
    const MOBILEREG = /^\d{10}$/;

    // Check for either of these to return true
    return MOBILEREG.test(value);
  },
  message: (field: string) => `${field} must be a valid US phone number (without +1), e.g. 3081357895`,
});

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
