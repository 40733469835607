export enum DirectionalGuidanceSubTab {
  SURVEYS = 'Surveys',
  PSEUDO_SURVEYS = 'Pseudo Surveys',
  WELL_PLANS = 'Well Plans',
  SLIDE_SHEETS = 'Slide Sheets',
  RECOMMENDATIONS = 'Recommendations',
  BUILD_TURN_ANALYSIS = 'Build / Turn Analysis',
  DAILY_REPORTS = 'Daily Reports',
}

export enum DirectionalGuidanceTabMutation {
  SET_ACTIVE_SUB_TAB = 'setActiveSubTab',
  SELECT_SURVEY_MD = 'selectSurveyMd',
  TOGGLE_SURVEY_TRACE = 'toggleSurveyTrace',
  SET_SELECTED_SURVEY_TRACES = 'setSelectedSurveyTraces',
  SET_SELECTED_PSEUDO_SURVEY_TRACES = 'setSelectedPseudoSurveyTraces',
  SET_SELECTED_SLIDE_SHEETS_TRACES = 'setSelectedSlideSheetsTraces',
  TOGGLE_SLIDE_SHEET_TRACE = 'toggleSlideSheetTrace',
  SET_SURVEYS_TABLE_PAGE = 'setSurveysTablePage',
  SET_SLIDE_SHEET_TABLE_PAGE = 'setSlideSheetTablePage',
  SET_SELECTED_ROWS_AMOUNT = 'setSelectedRowsAmount',
  SET_PSEUDO_SURVEYS_TABLE_PAGE = 'setPseudoSurveysTablePage',
  SET_SELECTED_PSEUDO_ROWS_AMOUNT = 'setSelectedPseudoRowsAmount',
}

export enum SurveySubTab {
  REAL = 'REAL',
  PSEUDO = 'PSEUDO',
}

export enum SurveyTrace {
  MD = 'md',
  INCLINATION = 'inclination',
  AZIMUTH = 'azimuth',
  TVD = 'tvd',
  NS = 'ns',
  EW = 'ew',
  DLS = 'dls',
  VS = 'vs',
  HTI = 'hti',
  LTI = 'lti',
  RBR = 'rbr',
  RTR = 'rtr',
  SBR = 'sbr',
  STR = 'str',
  TI = 'ti',
  TIME = 'time',
  DISTANCE_2_PLAN = 'distance2plan',
  SLIDE_AHEAD = 'slideAhead',
  TF_AHEAD = 'tfAhead',
  TF_SEEN = 'tfSeen',
  SLIDE_SEEN = 'slideSeen',
  SLIDE_WOB_SEEN = 'slideWobSeen',
  SLIDE_DIFF_SEEN = 'slideDiffSeen',
  ROTATE_WOB_SEEN = 'rotateWobSeen',
  ROTATE_DIFF_SEEN = 'rotateDiffSeen',
  SLIDE_ROP_SEEN = 'slideRopSeen',
  SLIDE_FLOW_SEEN = 'slideFlowSeen',
  SLIDE_RPM_SEEN = 'slideRpmSeen',
  ROTATE_ROP_SEEN = 'rotateRopSeen',
  ROTATE_FLOW_SEEN = 'rotateFlowSeen',
  ROTATE_RPM_SEEN = 'rotateRpmSeen',
  SLIDE_SCORE_SEEN = 'slideScoreSeen',
}

export const DEFAULT_SURVEY_TRACES = [
  SurveyTrace.MD,
  SurveyTrace.INCLINATION,
  SurveyTrace.AZIMUTH,
  SurveyTrace.TVD,
  SurveyTrace.NS,
  SurveyTrace.EW,
  SurveyTrace.DLS,
  SurveyTrace.VS,
];

export enum SlideSheetsTrace {
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  START_MD = 'startMD',
  END_MD = 'endMD',
  TRUE_SLIDE_DISTANCE = 'trueSlideDistance',
  AVERAGE_TOOLFACE = 'averageToolface',
  TOOLFACE_EFFICIENCY = 'toolfaceEfficiency',
  TARGET_SCORE = 'targetScore',
  AVERAGE_ROP = 'averageROP',
  AVERAGE_WOB = 'averageWOB',
  AVERAGE_DIFFPRESSURE = 'averageDiffPressure',
  ACTIVITY = 'activity',
  TARGET_TOOLFACE = 'targetToolface',
  TARGET_DISTANCE = 'targetDistance',
}

export const DEFAULT_SLIDE_SHEETS_TRACES = [
  SlideSheetsTrace.ACTIVITY,
  SlideSheetsTrace.START_MD,
  SlideSheetsTrace.END_MD,
  SlideSheetsTrace.START_TIME,
  SlideSheetsTrace.END_TIME,
  SlideSheetsTrace.TRUE_SLIDE_DISTANCE,
];

