














import { Component, Vue, Prop } from 'vue-property-decorator';
import * as _ from 'lodash';

@Component
export default class WellSettingsPartBase extends Vue {
  @Prop({ required: false, default: false })
  displayedHeader: boolean;
}
