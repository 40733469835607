import { HeatmapMutation, HeatmapType } from './types';

import { DepthData } from '@/store/modules/depth_data/types';

import { DataBucket } from '@/models/bucket';
import { queryServer } from '@/services/socket_service';
import { getDepthQueryBorders } from '@/utils';
import store from '@/store';

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import * as _ from 'lodash';

const KEY_FIELD = 'depth';

@Module
export class HeatmapModule extends VuexModule {
  // TODO: use socket && add data controller to refetch data sometimes
  _heatmapsDepthDataBucket: DataBucket<DepthData> = new DataBucket(null, KEY_FIELD);
  _heatmapsSelectedFormationTopId: string | null = null;
  _heatmapsType = HeatmapType.MSE;

  get heatmapsDepthData(): DepthData | undefined {
    return this._heatmapsDepthDataBucket.data as DepthData;
  }

  get heatmapsSelectedFormationTopId(): string | null {
    return this._heatmapsSelectedFormationTopId;
  }

  get heatmapsType(): HeatmapType {
    return this._heatmapsType;
  }

  @Mutation
  setHeatmapsType(type: HeatmapType): void {
    this._heatmapsType = type;
  }

  @Mutation
  setHeatmapsSelectedFormationTopId(id: string | null): void {
    this._heatmapsSelectedFormationTopId = id;
  }

  @Mutation
  setHeatmapsDepthData(data: DepthData | null): void {
    if(data === undefined || data.depth.length === 0) {
      return;
    }
    this._heatmapsDepthDataBucket.setData(data);
  }

  @Mutation
  appendHeatmapsDepthData(data: DepthData): void {
    if(data === undefined || data.depth.length === 0) {
      return;
    }
    const lastFetchedHeatmapsDepth = store.getters.lastFetchedHeatmapsDepth;
    if(this._heatmapsDepthDataBucket.data === undefined || lastFetchedHeatmapsDepth === undefined) {
      store.commit(HeatmapMutation.SET_HEATMAPS_DEPTH_DATA, data);
      store.commit(HeatmapMutation.SET_LAST_FETCHED_HEATMAPS_DEPTH, _.last((this._heatmapsDepthDataBucket.data as DepthData).depth));
      return;
    }

    this._heatmapsDepthDataBucket.appendData(data);

    store.commit(HeatmapMutation.SET_LAST_FETCHED_HEATMAPS_DEPTH, _.last((this._heatmapsDepthDataBucket.data as DepthData).depth));
  }

  @Action({ rawError: true })
  async fetchHeatmapsDepthData(refetch = false): Promise<void> {
    const event = 'depth-data/get';
    const liveMode = this.context.getters.liveMode;
    const lastFetchedDepth = this.context.getters.lastFetchedHeatmapsDepth;
    const depthBorders = this.context.getters.depthBorders;

    const [from, to] = getDepthQueryBorders(liveMode, refetch, lastFetchedDepth, depthBorders);

    const params = {
      wellId: this.context.getters.currentWellId,
      from,
      to,
      projection: {
        depth: 1,
        rpm: 1,
        rop: 1,
        wob: 1,
        diffPressure: 1,
        mse: 1,
        drillingEfficiency: 1,
        slidingTurnRate: 1,
      },
    };

    const resp = await queryServer(event, params);

    if(resp === undefined || _.isEmpty(resp.data) || _.isEmpty(resp.data.depth)) {
      return;
    }

    if(liveMode && !refetch) {
      this.context.commit(HeatmapMutation.APPEND_HEATMAPS_DEPTH_DATA, resp.data);
    } else {
      this.context.commit(HeatmapMutation.SET_HEATMAPS_DEPTH_DATA, resp.data);
    }
  }
}
