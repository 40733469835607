import { queryApi } from '@/services/server_service';

import { Operator, OperatorMutation } from './types';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

const OPERATOR_URL = `api/operator`;

@Module
export class OperatorModule extends VuexModule {
  _operators: Operator[] = [];

  get operators(): Operator[] {
    return this._operators;
  }

  @Mutation
  setOperators(operators: Operator[]): void {
    this._operators = operators;
  }

  @Action({ rawError: true })
  async fetchOperators(): Promise<void> {
    const resp = await queryApi({
      url: OPERATOR_URL,
      method: 'GET',
    });

    if(resp === undefined) {
      return;
    }

    this.context.commit(OperatorMutation.SET_OPERATORS, resp.data);
  }
}
