import { WellId } from '../types';

export enum TorqueDragDataMutation {
  SET_TORQUE_DRAG_DATA = 'setTorqueDragData',
  SET_LAST_MD = 'setTorqueDragDataLastMd',
}

export enum TorqueDragDataAction {
  FETCH_TORQUE_DRAG_DATA = 'fetchTorqueDragData',
}

export type TorqueDragData = {
  wellId: WellId;
  md: number;
  pickUpHk: number;
  slackOffHk: number;
  rotationHk: number;
  torque: number;
};
