



























































import Modal from '../Modal.vue';

import { NewUser, UserAction, UserType } from '@/store/modules/users/types';

import { Component, Vue, Prop } from 'vue-property-decorator';
import * as _ from 'lodash';

interface IField {
  title: string;
  configName: string;
  inputType?: string;
  rules?: string;
  placeholder: string;
}

type TFields = IField[];

const EMAIL_MASK = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

@Component({ components: { Modal } })
export default class AddNewUserModal extends Vue {
  userTypes = _.values(UserType);
  fields: TFields = [{
    title: 'Login',
    configName: 'username',
    placeholder: 'User login',
  }, {
    title: 'Name',
    configName: 'name',
    placeholder: 'User name',
  }, {
    title: 'E-mail',
    configName: 'email',
    rules: 'email',
    placeholder: 'User e-mail',
  }, {
    title: 'Password',
    configName: 'password',
    inputType: 'password',
    placeholder: 'User password',
  }];

  newUser: NewUser = {
    username: null,
    name: null,
    email: null,
    password: null,
    type: UserType.ENGINEER,
  };

  @Prop({ required: false, default: false })
  isModalActive!: boolean;

  async errorCatcher(): Promise<boolean> {
    const emptyKeys = [];
    let emailError: boolean = false;
    let nullError: boolean = false;

    for(const field in this.newUser) {
      if(this.newUser.hasOwnProperty(field) && !this.newUser[field]) {
        emptyKeys.push(field);
      }
    }

    if(emptyKeys.length) {
      nullError = true;

      const message = emptyKeys.length > 1
        ? `Required fields ${emptyKeys.join(', ')} are missing`
        : `Required field ${emptyKeys[0]} is missing`;

      await this.$store.dispatch('alertError', {
        title: 'User Creation',
        message,
      });
    }

    if(this.newUser.email && !this.newUser.email.match(EMAIL_MASK)) {
      emailError = true;

      await this.$store.dispatch('alertError', {
        title: 'User Creation',
        message: 'E-mail is invalid',
      });
    }

    return emailError || nullError;
  }

  async createUser(): Promise<void> {
    const isError = await this.errorCatcher();

    if(!isError) {
      await this.$store.dispatch(UserAction.CREATE_USER, this.newUser);
      await this.$store.dispatch('alertSuccess', {
        title: 'User Creation',
        message: 'User has been created',
      });
      this.$emit('created');
      this.close();
    }
  }

  onCloseClick(): void {
    this.close();
  }

  close(): void {
    this.$emit('close');
  }
}
