import { WellId } from '../types';

export type DrillingRecomendationId = string;

export type DrillingRecommendation = {
  wellId: WellId;
  md: number;
  r2w_eff_heatmap: number[][];
  r2d_eff_heatmap: number[][];
  r2w_mse_heatmap: number[][];
  r2d_mse_heatmap: number[][];
  r2w_rop_heatmap: number[][];
  r2d_rop_heatmap: number[][];
  w2d_heatmap: number[][];
  recommendedRPM: number;
  recommendedWOB: number;
  recommendedDiffPressure: number;
  recommendedFlowRate: number;
  flowRange: number[];
  diffPressureRange: number[];
  rpmRange: number[];
  wobRange: number[];
  recommendedEfficiency: number;
  recommendedROP: number;
  recommendedMSE: number;
  otherRecommendations: Recommendation[];
  failureReasonList: FailureReason[];
  time: number;
  _id: string;
};

export enum DrillingRecommendationMutation {
  APPEND_RECOMMENDATION = 'appendDrillingRecommendation',
  SET_RECOMMENDATIONS = 'setDrillingRecommendationRows',
  SET_ACTIVE_DRILLING_RECOMMENDATION = 'setActiveDrillingRecommendation',
  CLEAR_DRILLING_RECOMMENDATIONS = 'clearDrillingRecommendations',
}

export enum DrillingRecommendationAction {
  FETCH_RECOMMENDATIONS = 'fetchDrillingRecommendations',
}

export type Recommendation = {
  well_id: WellId;
  well_role: string;
  cost_opt: number;
  rpm_opt: number;
  wob_opt: number;
  diffPressure_opt: number;
  rop_predicted: number;
  mse_predicted: number;
  grid_search_info: {
    rpm: { range: [number, number], step_size: number };
    wob: { range: [number, number], step_size: number };
    diffPressure: { range: [number, number], step_size: number };
  };
};

export type FailureReason = {
  well_id: WellId;
  failure_reason: string;
};
