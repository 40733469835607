export enum HeatmapMutation {
  SET_HEATMAPS_DEPTH_DATA = 'setHeatmapsDepthData',
  APPEND_HEATMAPS_DEPTH_DATA = 'appendHeatmapsDepthData',
  SET_LAST_FETCHED_HEATMAPS_DEPTH = 'setLastFetchedHeatmapsDepth',
  SET_HEATMAPS_TYPE = 'setHeatmapsType',
  SET_HEATMAPS_SELECTED_FORMATION_TOP_ID = 'setHeatmapsSelectedFormationTopId',
}

export enum HeatmapAction {
  FETCH_HEATMAPS_DEPTH_DATA = 'fetchHeatmapsDepthData',
}

export enum HeatmapType {
  MSE = 'MSE [kpsi]',
  ROP = 'ROP [ft/hr]',
}
