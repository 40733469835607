import { queryApi } from '@/services/server_service';
import { queryServer } from '@/services/socket_service';

import { DirectionalSettings, DirectionalSettingsMutation, DirectionalSettingsAction } from './types';

import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';

import { AxiosResponse } from 'axios';
import * as _ from 'lodash';

const URL = 'api/directional-settings';

@Module
export class DirectionalSettingsModule extends VuexModule {
  _directionalSettings: DirectionalSettings | null = null;
  _directionalSettingsLocal: DirectionalSettings | null = null;
  _autoTendencies: boolean = true;

  get directionalSettings(): DirectionalSettings | undefined {
    if(this._directionalSettings === null) {
      return undefined;
    }
    return this._directionalSettings;
  }

  get directionalSettingsLocal(): DirectionalSettings | undefined {
    if(this._directionalSettingsLocal === null) {
      return undefined;
    }
    return this._directionalSettingsLocal;
  }

  get autoTendencies(): boolean {
    return this._autoTendencies;
  }

  @Mutation
  setDirectionalSettings(directionalSettings: DirectionalSettings): void {
    if(!_.isEmpty(directionalSettings)) {
      this._directionalSettings = directionalSettings;
    }
  }

  @Mutation
  setDirectionalSettingsLocal(directionalSettingsLocal: DirectionalSettings): void {
    if(!_.isEmpty(directionalSettingsLocal)) {
      this._directionalSettingsLocal = directionalSettingsLocal;
    }
  }

  @Mutation
  setAutoTendencies(autoTendencies: boolean): void {
    this._autoTendencies = autoTendencies;
  }

  @Action({ rawError: true })
  async fetchDirectionalSettings(): Promise<void> {
    const event = 'directional-settings/get';
    const params = { wellId: this.context.getters.currentWellId };

    const resp = await queryServer(event, params);

    if(resp === undefined) {
      return;
    }

    this.context.commit(DirectionalSettingsMutation.SET_DIRECTIONAL_SETTINGS, _.cloneDeep(resp.data));
    this.context.commit(DirectionalSettingsMutation.SET_DIRECTIONAL_SETTINGS_LOCAL, _.cloneDeep(resp.data));
  }

  @Action({ rawError: true })
  async updateDirectionalSettings(settings: DirectionalSettings): Promise<AxiosResponse | undefined> {
    const resp = await queryApi({
      url: URL,
      method: 'POST',
      data: settings,
    });
    if(resp) {
      await this.context.dispatch(DirectionalSettingsAction.FETCH_DIRECTIONAL_SETTINGS);
    }
    return resp;
  }
}
