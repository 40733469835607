



































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Modal extends Vue {
  @Prop({ required: false, default: false })
  isModalActive!: boolean;

  @Prop({ required: false, default: true })
  shouldBackgroundClose!: boolean;

  @Prop({ required: false, default: false })
  isBackgroundTransparent!: boolean;

  @Prop({ required: false })
  x!: number;

  @Prop({ required: false })
  y!: number;

  @Prop({ required: false, default: false })
  windowCenter: boolean;

  // reqiures 'position: relative' style in the component where parent modal is declared
  @Prop({ required: false, default: false })
  tabRelated!: boolean; // display modal inside one tab not full page

  isModalClosed = true;

  onModalClick(): void {
    this.isModalClosed = false;
  }

  onBackgroundClick(): void {
    if(this.shouldBackgroundClose === false) {
      return;
    }
    if(this.isModalClosed === true) {
      this.onCloseClick();
    }
    this.isModalClosed = true;
  }

  get modalDialogStyles(): any {
    if(this.windowCenter) {
      return {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        margin: 'auto',
        height: 'fit-content',
      };
    }
    if(!this.x && !this.y) {
      return {};
    }
    return {
      top: `${this.y}px`,
      left: `${this.x}px`,
      position: 'absolute',
    };
  }

  onCloseClick(): void {
    this.$emit('close');
  }
}
