













































































import FileUpload from 'vue-upload-component';

import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import * as _ from 'lodash';

export interface VUFile {
  file: File;
  readonly fileObject: boolean;
  id: string | number;
  size: number;
  name: string;
  type: string;
  active: boolean;
  error: string;
  success: boolean;
  putAction: string;
  postAction: string;
  headers: object;
  data: object;
  timeout: number;
  response: object | string;
  progress: string;
  speed: number;
  xhr: XMLHttpRequest;
  iframe: Element;
}

@Component({ components: { FileUpload } })
export default class FileUploader extends Vue {
  dragHover: boolean = false;

  @Prop({ required: true })
  uploadUrl!: string;

  @Prop({ required: true })
  multiple!: boolean;

  @Prop({ required: false })
  message: string;

  @Prop({ required: false })
  extensions!: string;

  @Prop({ required: false })
  maximumFiles!: number;

  @Prop({ required: false })
  payload!: any;

  @Prop({ required: false })
  wellId!: string;

  @Prop({ required: false })
  customId!: string;

  @Prop({ required: false })
  customAction!: () => Promise<any>;

  files: VUFile[] = [];

  @Ref('upload') readonly uploadRef!: FileUpload;

  @Watch('customId')
  onIdChange(): void {
    this.files = [];
  }

  @Watch('files')
  onSuccess() {
    if(this.files.length === 0) {
      return;
    }
    if(this.multiple === false) {
      if(_.last(this.files).success === true) {
        this.emitSubmit();
      }
    } else {
      const successFiles = this.files.filter((file: VUFile) => file.success === true);
      const maximumFiles = this.maximumFiles || this.files.length;
      if(successFiles.length >= maximumFiles) {
        this.emitSubmit();
      }
    }
  }

  emitSubmit() {
    this.$emit('submit');
  }

  get uploadPayload(): any {
    const defaultPayload = { wellId: this.wellId };
    return _.assign(defaultPayload, this.payload);
  }

  get token() {
    return this.$store.getters.apiToken;
  }
}
