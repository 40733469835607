import { WellId } from '../types';

export enum UserNotificationSettingsMutation {
  SET_SETTINGS = 'setUserNotificationSettings',
}

export enum UserNotificationSettingsAction {
  FETCH_SETTINGS = 'fetchUserNotificationSettings',
  UPDATE_SETTINGS = 'updateUserNotificationSettings',
}

export type WellsList = {
  wellIds: WellId[];
  applyToAll?: boolean;
} | {
  wellIds?: WellId[];
  applyToAll: boolean;
};

export type NotificationChannels = {
  email: WellsList;
  whatsapp: WellsList;
  voice: WellsList;
  dashboard?: WellsList;
};

export type UserNotificationSettings = {
  userId: string;
  enableNotifications: boolean;
  directionalAdvisor: NotificationChannels;
  motorStalls: NotificationChannels;
  _id?: string;
};
