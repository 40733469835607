



































import { DataProcessorSettings, DataProcessorSettingsAction } from '@/store/modules/settings/data_processor_settings/types';

import { emitEngineEvent } from '@/services/socket_service';

import { Component, Vue } from 'vue-property-decorator';

import * as _ from 'lodash';

const FIELD_MAPPINGS = {
  wellId: 'Well ID',
  hookLoadThresh: 'Hook Load Threshold [klb]',
  flowrateMinThresh: 'Flow Rate Min Threshold [gpm]',
  flowrateMaxThresh: 'Flow Rate Max Threshold [gpm]',
  pressureThreshold: 'Pressure Threshold',
  rotationThreshold: 'Rotation Threshold',
  rpmLowerThresh: 'RPM Lower Threshold',
  rpmUpperThresh: 'RPM Upper Threshold',
  blkHeightThreshold: 'Block Height Threshold [ft]',
  standLengthThreshold: 'Stand Length Min Threshold [ft]',
  squatLength: 'Squat Length [ft]',
  sectionAzimuth: 'Section Azimuth [deg]',
  _id: '_id',
};

const CHECKBOX_FIELDS = [];
const TEXT_FIELDS = ['wellId'];
const READONLY_FIELDS = ['wellId'];
const HIDDEN_FIELDS = [
  '_id',
  'wellId',
  'hookLoadThresh',
  'flowrateMinThresh',
  'flowrateMaxThresh',
  'pressureThreshold',
  'rotationThreshold',
  'blkHeightThreshold',
];

@Component
export default class DataProcessorSettingsComponent extends Vue {
  get fieldMappings()  {
    return FIELD_MAPPINGS;
  }

  get dataProcessorSettings(): DataProcessorSettings | undefined {
    return this.$store.getters.dataProcessorSettings;
  }

  get dataKeys(): string[] {
    return _.keys(this.dataProcessorSettings);
  }

  getInputType(field: string): string {
    if(_.includes(CHECKBOX_FIELDS, field)) {
      return 'checkbox';
    }
    if(_.includes(TEXT_FIELDS, field)) {
      return 'text';
    }
    return 'number';
  }

  isInputDisabled(field: string): boolean {
    return _.includes(READONLY_FIELDS, field);
  }

  isFieldVisible(field: string): boolean {
    return !_.includes(HIDDEN_FIELDS, field);
  }

  async onApplyClick(): Promise<void> {
    const resp = await this.$store.dispatch(DataProcessorSettingsAction.UPDATE_DATA_PROCESSOR_SETTINGS, this.dataProcessorSettings);
    if(!resp) {
      return;
    }
    await emitEngineEvent('data-processor-settings-updated', { wellId: this.$store.getters.currentWellId });
    await this.$store.dispatch('alertSuccess', { title: 'Data processor settings', message: `Changes applied` });
  }
}
