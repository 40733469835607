import { DailyReport, DailyReportMutation, DEFAULT_DAILY_REPORT } from './types';

import { Bha } from '@/store/modules/bha/types';

import { queryServer } from '@/services/socket_service';
import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';
import moment from 'moment';

@Module
export class DailyReportsTabModule extends VuexModule {
  _dailyReports: DailyReport[] = [];

  _selectedTimeRange: [number, number] | null = null; // day start / end timestamps (seconds)
  _addDailyReportModalActive = false;

  get dailyReportsSelectedTimeRange(): [number, number] | undefined {
    return this._selectedTimeRange || undefined;
  }

  get dailyReports(): DailyReport[] {
    return this._dailyReports;
  }

  get dailyReportsForSelectedTimeRange(): DailyReport[] {
    return this.dailyReports.filter(({ startTime, endTime }: { startTime: number, endTime: number }) => {
      // TODO: handle undefined
      return startTime >= this.dailyReportsSelectedTimeRange[0] && startTime <= this.dailyReportsSelectedTimeRange[1];
    });
  }

  get dailyReportsBhas(): Bha[] {
    const bhaNames = _.uniq(_.map(this.dailyReportsForSelectedTimeRange, (dailyReport: DailyReport) => dailyReport.bha));
    return _.filter(store.getters.bhas, (bha: Bha) => _.includes(bhaNames, bha.name));
  }

  get addDailyReportModalActive(): boolean {
    return this._addDailyReportModalActive;
  }

  @Mutation
  setSelectedDate(timestamp: number): void {
    this._selectedTimeRange = [
      moment.unix(timestamp).startOf('day').unix(),
      moment.unix(timestamp).endOf('day').unix(),
    ];
  }

  @Mutation
  setDailyReports(dailyReports: DailyReport[]): void {
    this._dailyReports = _.cloneDeep(dailyReports);
  }

  @Mutation
  appendDailyReports(dailyReports: DailyReport[]): void {
    this._dailyReports = [...this._dailyReports, ...dailyReports];
  }

  @Mutation
  initTimeRange(): void {
    const lastDailyReport = _.last(this._dailyReports);
    if(lastDailyReport) {
      store.commit(DailyReportMutation.SET_SELECTED_DATE, lastDailyReport.startTime);
    }
  }

  @Mutation
  setAddDailyReportModalActive(active: boolean): void {
    this._addDailyReportModalActive = active;
  }

  @Action({ rawError: true })
  async fetchDailyReports(): Promise<void> {
    const event = 'daily-report/get';
    const params = { wellId: this.context.getters.currentWellId };

    const resp = await queryServer(event, params);

    if(!resp?.data) {
      return;
    }

    this.context.commit(DailyReportMutation.SET_DAILY_REPORTS, resp.data);
    this.context.commit(DailyReportMutation.INIT_TIME_RANGE);
  }

  @Action({ rawError: true })
  async updateDailyReport(payload: { id: string, updateQuery: Partial<DailyReport> }): Promise<void> {
    const dailyReports = _.cloneDeep(this.context.getters.dailyReports);
    const dailyReportIdx = _.findIndex(dailyReports, (dailyReport: DailyReport) => dailyReport._id === payload.id);
    if(dailyReportIdx < 0) {
      throw new Error(`Can't find Daily Report with id: ${payload.id}`);
    }

    const updatedReport = {
      ...dailyReports[dailyReportIdx], ...payload.updateQuery,
    };

    if(payload.updateQuery.startTime || payload.updateQuery.endTime) {
      // we are converting seconds to hours here
      if(updatedReport.endTime <= updatedReport.startTime) {
        this.context.dispatch('alertError', { title: 'Daily Report update error', message: `Can't set end time <= start time` });
        return;
      }
      updatedReport.hours = (updatedReport.endTime - updatedReport.startTime) / 60 / 60;
    }

    const event = 'daily-report/patch';
    const params = {
      wellId: this.context.getters.currentWellId,
      id: updatedReport._id,
      updateQuery: updatedReport,
    };

    const resp = await queryServer(event, params);
    if(!resp) {
      return;
    }

    dailyReports[dailyReportIdx] = updatedReport;
    this.context.commit(DailyReportMutation.SET_DAILY_REPORTS, dailyReports);
    this.context.dispatch('alertSuccess', { title: 'Daily Report updated' });
  }

  @Action({ rawError: true })
  async createDailyReport(payload: { startTime: number, endTime: number }): Promise<void> {
    const dailyReports = _.cloneDeep(this.context.getters.dailyReports);
    if(payload.endTime <= payload.startTime) {
      this.context.dispatch('alertError', { title: 'Daily Report creation error', message: `Can't set end time <= start time` });
      return;
    }
    // we are converting seconds to hours here
    const hours = (payload.endTime - payload.startTime) / 60 / 60;

    const dailyReport = {
      ...DEFAULT_DAILY_REPORT,
      ...payload,
      hours,
      wellId: this.context.getters.currentWellId,
    };
    const event = 'daily-report/post';
    const params = { dailyReport };

    const resp = await queryServer(event, params);

    if(!resp?.data) {
      return;
    }

    const id = resp.data;

    dailyReports.push({
      ...dailyReport,
      _id: id,
    });
    this.context.commit(DailyReportMutation.SET_DAILY_REPORTS, dailyReports);
    if(dailyReports.length === 1) {
      this.context.commit(DailyReportMutation.INIT_TIME_RANGE);
    }
    this.context.dispatch('alertSuccess', { title: 'Daily Report created' });
  }

  @Action({ rawError: true })
  async deleteDailyReport(id: string): Promise<void> {
    let dailyReports = _.cloneDeep(this.context.getters.dailyReports);
    const event = 'daily-report/delete';
    const params = { wellId: this.context.getters.currentWellId, id };

    const resp = await queryServer(event, params);

    if(!resp?.data) {
      return;
    }

    dailyReports = dailyReports.filter((dailyReport: DailyReport) => dailyReport._id !== id);
    this.context.commit(DailyReportMutation.SET_DAILY_REPORTS, dailyReports);
    this.context.dispatch('alertSuccess', { title: 'Daily Report deleted' });
  }
}
