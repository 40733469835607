import { queryApi } from '@/services/server_service';
import { queryServer } from '@/services/socket_service';

import store from '@/store';

import { WellPlan, WellPlanMutation, WellPlanAction } from './types';
import { Section } from '@/store/modules/types';
import { SectionRanges, SectionsDepth } from '@/store/modules/well/types';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

const WELL_PLAN_URL = 'api/well-plan';

export function getRangesFromSections(sections: SectionsDepth): SectionRanges | undefined {
  // TODO: refactor or test sequence of sections
  if(sections === undefined) {
    return undefined;
  }
  const sortedDepthValues: number[] = _.sortBy(sections);
  const keys: Section[] = _.map(
    sortedDepthValues,
    (keyDepth: string) => _.findKey(sections, (sectionDepth: string) => sectionDepth === keyDepth)
  );
  let sectionDepthStart = 0;
  const sectionRanges = {};
  keys.forEach((key: Section, idx: number) => {
    sectionRanges[key] = [sectionDepthStart, sortedDepthValues[idx]];
    sectionDepthStart = sortedDepthValues[idx];
  });
  return sectionRanges as SectionRanges;
}

export function unionSectionsByFullRanges(sectionsForUpdating: SectionRanges, sections: SectionRanges): SectionRanges {
  const sectionsKeys = _.values(Section);
  sectionsKeys.forEach((key: string) => {
    const allValues = _.concat(sectionsForUpdating[key], sections[key]);
    sectionsForUpdating[key] = [_.min(allValues), _.max(allValues)];
  });
  return sectionsForUpdating;
}

@Module
export class WellPlanModule extends VuexModule {
  _wellPlans: WellPlan[] | null = null;
  _displayedWellPlan: WellPlan | null = null;

  get wellPlans(): WellPlan[] | undefined {
    if(this._wellPlans === null) {
      return undefined;
    }
    return this._wellPlans;
  }

  get wellPlanChart(): [number, number, number][] | undefined {
    if(this.displayedWellPlan === undefined) {
      return undefined;
    }
    return _.zip(
      this.displayedWellPlan.ns,
      this.displayedWellPlan.tvd,
      this.displayedWellPlan.ew,
      this.displayedWellPlan.vs
    );
  }

  get displayedWellPlan(): WellPlan | undefined {
    if(this._displayedWellPlan === null) {
      return undefined;
    }
    return this._displayedWellPlan;
  }

  get wellboreWellPlan(): WellPlan | undefined {
    if(this._wellPlans === null) {
      return undefined;
    }
    const wellboreWellPlan = _.find(this._wellPlans, (wellPlan: WellPlan) => wellPlan.wellbore === true);
    return wellboreWellPlan;
  }

  get wellboreSectionsRanges(): SectionRanges | undefined {
    const sections = store.getters.currentWell.sections;
    return getRangesFromSections(sections);
  }

  @Mutation
  setWellPlans(wellPlans: WellPlan[]): void {
    if(wellPlans.length === 0) {
      return;
    }
    this._wellPlans = wellPlans;
    const wellboreWellPlan = _.find(wellPlans, (wellPlan: WellPlan) => wellPlan.wellbore === true);
    if(wellboreWellPlan !== undefined) {
      store.commit(WellPlanMutation.SET_DISPLAYED_WELL_PLAN, wellboreWellPlan._id);
    } else {
      store.commit(WellPlanMutation.SET_DISPLAYED_WELL_PLAN, _.first(wellPlans)._id);
    }
  }

  @Mutation
  appendWellPlan(wellPlan: WellPlan): void {
    if(_.isNil(this._wellPlans)) {
      this._wellPlans = [wellPlan];
      return;
    }
    this._wellPlans.push(wellPlan);
  }

  @Mutation
  clearWellPlans(): void {
    this._wellPlans = null;
  }

  @Mutation
  setDisplayedWellPlan(id: string): void {
    if(this._wellPlans === null) {
      return undefined;
    }
    const wellPlan = _.find(this._wellPlans, (wellPlan: WellPlan) => wellPlan._id === id);
    this._displayedWellPlan = wellPlan || null;
  }

  @Action({ rawError: true })
  async fetchWellPlans(): Promise<void> {
    const event = 'well-plan/get';
    const params = { wellId: this.context.getters.currentWellId };

    const resp = await queryServer(event, params);

    if(resp === undefined) {
      return;
    }

    this.context.commit(WellPlanMutation.SET_WELL_PLANS, resp.data);
  }

  @Action({ rawError: true })
  async uploadWellPlan(wellPlan: WellPlan): Promise<void> {
    const url = WELL_PLAN_URL;

    await queryApi({
      url,
      method: 'POST',
      data: { wellId: this.context.getters.currentWellId, ...wellPlan },
    });
  }

  @Action({ rawError: true })
  async createWellPlan(wellPlan: WellPlan): Promise<string> {
    const event = 'well-plan/create';
    const params = { wellId: this.context.getters.currentWellId, wellPlan };
    const resp = await queryServer(event, params);

    if(resp === undefined) {
      return undefined;
    }
    wellPlan._id = resp.data;
    store.commit(WellPlanMutation.APPEND_WELL_PLAN, wellPlan);
    return wellPlan._id;
  }

  @Action({ rawError: true })
  async makeWellPlanWellbore(wellPlanId: string): Promise<void> {
    const url = WELL_PLAN_URL;
    await queryApi({
      url,
      method: 'PATCH',
      data: {
        wellId: this.context.getters.currentWellId,
        wellPlanId,
        updateValues: { wellbore: true },
      },
    });
  }

  @Action({ rawError: true })
  async deleteWellPlan(wellPlanId: string): Promise<void> {
    const url = WELL_PLAN_URL;
    await queryApi({
      url,
      method: 'DELETE',
      data: {
        wellId: this.context.getters.currentWellId,
        wellPlanId,
      },
    });
    await this.context.dispatch(WellPlanAction.FETCH_WELL_PLANS);
  }
}
