























import { Component, Vue, Prop } from 'vue-property-decorator';

import * as _ from 'lodash';

@Component
export default class NvictaInput extends Vue {
  @Prop({ required: true })
  value!: number;

  @Prop({ required: false, default: false })
  disabled: boolean;

  @Prop({ required: false, default: 'number' })
  type: string;

  @Prop({ required: false })
  min: number;

  @Prop({ required: false })
  max: number;

  @Prop({ required: false })
  placeholder: string;

  @Prop({ required: false })
  theme: string;

  @Prop({ required: false })
  validationCallback: (value: number) => string | undefined;

  @Prop({ required: false })
  name: string;

  isValid = true;
  validationMessage: string = '';

  get inputType(): string {
    return this.type === 'date' ? 'text' : this.type;
  }

  get inputValue(): number | string {
    return this.type !== 'date' ? this.value : new Date(this.value * 1000).toLocaleString();
  }

  onInput(evt: any): void {
    const formattedValue = this.getFormattedValue(evt);
    this.$emit('input', formattedValue);
    this.validate();
  }

  onBlur(evt: Event): void {
    this.$emit('blur', evt);
  }

  getFormattedValue(evt: Event): number | string {
    const input = evt.target as HTMLInputElement;
    let formattedValue;
    switch(this.type) {
      case 'number':
        formattedValue = Number(input.value);
        break;
      case 'text':
      case 'password':
      case 'date':
        formattedValue = String(input.value);
        break;
      case 'checkbox':
        formattedValue = Boolean(evt);
        break;
      default:
        throw new Error(`Unknown type of ${this.type}`);
    }
    return formattedValue;
  }

  onChange(evt: Event): void {
    const formattedValue = this.getFormattedValue(evt);
    this.$emit('change', formattedValue);
  }

  validate(): void {
    const input = this.$refs.input as HTMLInputElement;
    try {
      if(_.isEmpty(input.value)) {
        this.isValid = false;
        this.validationMessage = 'This field is required';
        return;
      }
      if(this.min !== undefined && this.max !== undefined) {
        this.isValid = this.value >= this.min && this.value <= this.max;
        if(!this.isValid) {
          this.validationMessage = `Value should be between ${this.min} and ${this.max}`;
          return;
        }
      }

      if(this.min !== undefined) {
        this.isValid = this.value >= this.min;
        if(!this.isValid) {
          this.validationMessage = `Value should be greater than ${this.min}`;
          return;
        }
      }

      if(this.max !== undefined) {
        this.isValid = this.value <= this.max;
        if(!this.isValid) {
          this.validationMessage = `Value should be less than ${this.max}`;
          return;
        }
      }

      if(this.validationCallback) {
        const validationMessage = this.validationCallback(this.value);
        this.isValid = validationMessage === undefined;
        if(!this.isValid) {
          this.validationMessage = validationMessage;
          return;
        }
      }

      this.isValid = true;
      this.validationMessage = '';
    } finally {
      this.$emit('validate', this.isValid, input);
    }
  }

  mounted(): void {
    this.validate();
  }
}

