import { DepthData, DepthTrace } from '../depth_data/types';
import { TimeTrace, WellData } from '../well_data/types';
import { LogItem } from '@/components/modals/WellSummaryExportModal.vue';

export type WellSummaryData = WellData | DepthData | undefined;

export type ChartTracesOptions = {
  activeKey: KeyField;
  traces: (TimeTrace | DepthTrace)[][];
};

export type RangeOptions = {
  activeKey: KeyField;
  range: KeyRanges;
};

export type KeyRanges = {
  range: [number, number];
  wellId: string;
};

export type PdfOptions = {
  wellId: string;
  userId: string;
  logItem: LogItem;
};

export enum WellSummaryMutation {
  SET_WELL_SUMMARY_DATA = 'setWellSummaryData',
  SET_WELL_SUMMARY_KEY_FIELD = 'setWellSummaryKeyField',
  SET_WELL_SUMMARY_TRACES_FILTER = 'setWellSummaryTracesFilter',
  SET_WELL_SUMMARY_TABLES_SHOW_FLAGS = 'setWellSummaryTablesShowFlags',
  INIT_WELL_SUMMARY_TRACES_FILTER = 'initWellSummaryTracesFilter',
  SET_WELL_SUMMARY_CHART_TRACES = 'setWellSummaryChartTraces',
  SET_WELL_SUMMARY_LINE_CHART_HEIGHT = 'setWellSummaryLineChartHeight',
  SET_WELL_SUMMARY_LAST_CHART_HEIGHT = 'setWellSummaryLastChartHeight',
  SET_WELL_SUMMARY_ONE_CHART_PART_HEIGHT = 'setWellSummaryOneChartPartHeight',
  SET_WELL_SUMMARY_DATA_HAS_GAPS = 'setWellSummaryDataHasGaps',
  SET_WELL_SUMMARY_RANGE_BY_KEY = 'setWellSummaryRangeByKey',
  SET_WELL_SUMMARY_DEPTH_RESOLUTION = 'setWellSummaryInchPerDepth',
  SET_WELL_SUMMARY_TIME_RESOLUTION = 'setWellSummaryInchPerSecond',
}

export enum WellSummaryAction {
  GENERATE_PDF_TOKEN = 'generateWellSummaryPdfToken',
}

export enum KeyField {
  DEPTH = 'depth',
  TIME = 'time',
  TVD = 'interpolatedTvd',
}

export enum IpdValues {
  ONE_TO_HUNDRED = '1',
  TWO_TO_HUNDRED = '2',
  FIVE_TO_HUNDRED = '5',
}

export enum IptValues {
  ONE_TO_30 = '1800',
  ONE_TO_60 = '3600',
  ONE_TO_360 = '21600',
}
