import {
  ChartTracesOptions,
  IpdValues,
  IptValues,
  KeyField,
  KeyRanges,
  RangeOptions,
  WellSummaryData,
  PdfOptions,
} from './types';
import { TraceRenderType, WellSummaryTraceFilter } from './well_summary_configuration/types';

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import store from '@/store';

import * as _ from 'lodash';
import { DepthTrace } from '../depth_data/types';
import { TimeTrace } from '../well_data/types';
import { TablesShowFlags } from '@/store/modules/well_summary/well_summary_configuration/types';
import { MwdRun } from '@/store/modules/mwd_run/types';
import { queryApi } from '@/services/server_service';

const DEFAULT_PRESET = 'default';
const DEFAULT_CHART_HEIGHT = 200; //px
const DEFAULT_DEPTH_RESOLUTION = [Number.parseInt(IpdValues.ONE_TO_HUNDRED, 0), 100];

@Module
export class WellSummaryModule extends VuexModule {
  _data: WellSummaryData | null = null;
  _keyField: KeyField = KeyField.DEPTH;
  _tracesFilter: Map<string, WellSummaryTraceFilter> | null = null;
  _tablesShowFlags: TablesShowFlags | null = null;
  _tracesDepth: (TimeTrace | DepthTrace)[][] | null = null;
  _tracesTime: (TimeTrace | DepthTrace)[][] | null = null;
  _tracesInterpolatedTvd: (TimeTrace | DepthTrace)[][] | null = null;
  _lineChartHeight = DEFAULT_CHART_HEIGHT;
  _singleChartHeight = DEFAULT_CHART_HEIGHT;
  _lastChartHeight = DEFAULT_CHART_HEIGHT;
  _isWSDataHasGaps = false;
  _depthRange: KeyRanges | null = null;
  _timeRange: KeyRanges  | null = null;
  _interpolatedTvdRange: KeyRanges | null = null;
  _depthResolution = DEFAULT_DEPTH_RESOLUTION;
  _tvdResolution = DEFAULT_DEPTH_RESOLUTION;
  _timeResolution = [1, Number.parseInt(IptValues.ONE_TO_30, 0)];

  get wellSummaryDepthResolution(): number[] {
    return this.wellSummaryKeyField === KeyField.DEPTH ? this._depthResolution : this._tvdResolution;
  }

  get wellSummaryTimeResolution(): number[] {
    return this._timeResolution;
  }

  get wellSummarySurveyTraces(): { key: string, label: string }[] {
    return [
      { key: 'inclination', label: 'Inclination' },
      { key: 'azimuth', label: 'Azimuth' },
      { key: 'ns', label: 'NS' },
      { key: 'ew', label: 'EW' },
      { key: 'vs', label: 'VS' },
      { key: 'tvd', label: 'TVD' },
    ];
  }

  get wellSummaryChartTraces(): (TimeTrace | DepthTrace)[][] {
    switch(this._keyField) {
      case KeyField.DEPTH:
        return this._tracesDepth;
      case KeyField.TIME:
        return this._tracesTime;
      case KeyField.TVD:
        return this._tracesInterpolatedTvd;
      default:
        throw new Error(`Unknown key ${this._keyField}`);
    }
  }

  get wellSummaryChartTracesByActiveKey(): (activeKey) => (TimeTrace | DepthTrace)[][] {
    return (activeKey: KeyField) => {
      switch(activeKey) {
        case KeyField.DEPTH:
          return this._tracesDepth;
        case KeyField.TIME:
          return this._tracesTime;
        case KeyField.TVD:
          return this._tracesInterpolatedTvd;
        default:
          throw new Error(`Unknown key ${activeKey}`);
      }
    };
  }

  get wellSummaryRangeByKey(): (activeKey: KeyField) => KeyRanges | null {
    return (activeKey: KeyField) => {
      switch(activeKey) {
        case KeyField.DEPTH:
          return this._depthRange;
        case KeyField.TIME:
          return this._timeRange;
        case KeyField.TVD:
          return this._interpolatedTvdRange;
        default:
          return null;
      }
    };
  }

  get wellSummaryData(): WellSummaryData {
    if(this._data === null) {
      return undefined;
    }
    return this._data;
  }

  get isWSDataHasGaps(): boolean {
    return this._isWSDataHasGaps;
  }

  get wellSummaryLineChartHeight(): number {
    return this._lineChartHeight;
  }

  get wellSummaryLastChartHeight(): number {
    return this._lastChartHeight;
  }

  get wellSummaryOneChartPartHeight(): number {
    return this._singleChartHeight;
  }

  get extraMwdTraces(): string[] {
    const mwdRuns = store.getters.mwdRuns;
    return _.map(mwdRuns, (mwd: MwdRun, idx: number) => `Gamma: Mwd Run ${idx + 1}`);
  }

  get extraSurveyTraces(): string[] {
    const surveyTraces = this.wellSummarySurveyTraces;
    return _.map(surveyTraces, (trace: { key: string, label: string }) => `Survey: ${trace.label}`);
  }

  get extraTraces(): string[] {
    const result = _.clone(this.extraMwdTraces);
    result.push('Surveys');
    result.push('Gamma');
    return _.concat(result, this.extraSurveyTraces);
  }

  get wellSummaryKeyField(): KeyField {
    return this._keyField;
  }

  get wellSummaryTracesFilter(): Map<string, WellSummaryTraceFilter> | null {
    return this._tracesFilter;
  }

  get wellSummaryTablesShowFlags(): TablesShowFlags {
    return this._tablesShowFlags;
  }

  get wellSummaryTraceMaximum(): (trace) => number | undefined {
    return (trace: TraceRenderType) => {
      if(!this._tracesFilter || !this._tracesFilter.has(trace)) {
        return undefined;
      }
      return this._tracesFilter.get(trace).max || undefined;
    };
  }

  get wellSummaryTraceName(): (trace) => string | undefined {
    return (trace: TraceRenderType) => {
      if(!this._tracesFilter || !this._tracesFilter.has(trace)) {
        return undefined;
      }
      return this._tracesFilter.get(trace).traceName || undefined;
    };
  }

  get wellSummaryTraceThreshold(): (trace) => number | undefined {
    return (trace: TraceRenderType) => {
      if(!this._tracesFilter || !this._tracesFilter.has(trace)) {
        return undefined;
      }
      return this._tracesFilter.get(trace).threshold || undefined;
    };
  }

  get wellSummaryTraceThresholdColor(): (trace) => string | undefined {
    return (trace: TraceRenderType) => {
      if(!this._tracesFilter || !this._tracesFilter.has(trace)) {
        return undefined;
      }
      return this._tracesFilter.get(trace).thresholdColor || undefined;
    };
  }

  get wellSummaryTraceColor(): (trace) => string | undefined {
    return (trace: TraceRenderType) => {
      if(!this._tracesFilter || !this._tracesFilter.has(trace)) {
        return undefined;
      }
      return this._tracesFilter.get(trace).traceColor || undefined;
    };
  }

  get wellSummaryTraceRenderType(): (trace) => TraceRenderType | undefined {
    return (trace: TraceRenderType) => {
      if(!this._tracesFilter || !this._tracesFilter.has(trace)) {
        return undefined;
      }
      return this._tracesFilter.get(trace).traceRenderType || undefined;
    };
  }

  get wellSummaryThresholdRenderType(): (trace) => TraceRenderType | undefined {
    return (trace: TraceRenderType) => {
      if(!this._tracesFilter || !this._tracesFilter.has(trace)) {
        return undefined;
      }
      return this._tracesFilter.get(trace).thresholdRenderType || undefined;
    };
  }

  @Mutation
  setWellSummaryLineChartHeight(lineChartHeight: number): void {
    this._lineChartHeight = lineChartHeight;
  }

  @Mutation
  setWellSummaryLastChartHeight(h: number): void {
    this._lastChartHeight = h;
  }

  @Mutation
  setWellSummaryOneChartPartHeight(h: number): void {
    this._singleChartHeight = h;
  }

  @Mutation
  setWellSummaryChartTraces(options: ChartTracesOptions) {
    switch(options.activeKey) {
      case KeyField.DEPTH:
        this._tracesDepth = options.traces;
        return;
      case KeyField.TIME:
        this._tracesTime = options.traces;
        return;
      case KeyField.TVD:
        this._tracesInterpolatedTvd = options.traces;
        return;
      default:
        throw new Error(`Unknown key ${this._keyField}`);
    }
  }

  @Mutation
  setWellSummaryRangeByKey(options: RangeOptions) {
    switch(options.activeKey) {
      case KeyField.DEPTH:
        this._depthRange = options.range;
        return;
      case KeyField.TIME:
        this._timeRange = options.range;
        return;
      case KeyField.TVD:
        this._interpolatedTvdRange = options.range;
        return;
      default:
        throw new Error(`Unknown key ${this._keyField}`);
    }
  }

  @Mutation
  setWellSummaryInchPerDepth(val: number): void {
    if(this._keyField === KeyField.DEPTH) {
      this._depthResolution = [val, 100];
      return;
    }
    this._tvdResolution = [val, 100];
  }

  @Mutation
  setWellSummaryInchPerSecond(val: number): void {
    this._timeResolution = [1, val];
  }

  @Mutation
  setWellSummaryKeyField(field: KeyField): void {
    this._keyField = field;
  }

  @Mutation
  setWellSummaryData(data: WellSummaryData): void {
    if(data === undefined || _.isEmpty(data[this._keyField])) {
      this._data = null;
      return;
    }
    this._data = data;
  }

  @Mutation
  initWellSummaryTracesFilter(commitObject: any): void {
    const tracesFilterMap: Map<string, WellSummaryTraceFilter> = new Map();
    for(const trace in commitObject) {
      tracesFilterMap.set(trace, commitObject[trace]);
    }
    this._tracesFilter = _.cloneDeep(tracesFilterMap);
  }

  @Mutation
  setWellSummaryTracesFilter(commitObject: any): void {
    let tracesFilter = commitObject.filter;
    const max = commitObject.max;
    const trace = commitObject.trace;
    const threshold = commitObject.threshold;
    const thresholdColor = commitObject.thresholdColor;
    const traceColor = commitObject.traceColor;
    const traceRenderType = commitObject.traceRenderType;
    const thresholdRenderType = commitObject.thresholdRenderType;
    const traceName = commitObject.traceName;
    if(!(tracesFilter instanceof Map)) {
      tracesFilter = new Map<string, WellSummaryTraceFilter>();
    }
    if(!max && !threshold && !traceColor) {
      tracesFilter.delete(trace);
    } else {
      tracesFilter.set(trace, { trace, max, threshold, thresholdColor, traceColor, traceRenderType, thresholdRenderType, traceName });
    }
    this._tracesFilter = _.cloneDeep(tracesFilter);
  }

  @Mutation
  setWellSummaryTablesShowFlags(commitObject: any): void {
    if(_.isNil(this._tablesShowFlags)) {
      this._tablesShowFlags = {};
    }
    this._tablesShowFlags[commitObject.type] = commitObject.isVisible;
    this._tablesShowFlags = _.cloneDeep(this._tablesShowFlags);
  }

  @Mutation
  setWellSummaryDataHasGaps(hasGaps: boolean): void {
    this._isWSDataHasGaps = hasGaps;
  }

  @Action({ rawError: true })
  async generateWellSummaryPdfToken(options: PdfOptions): Promise<string | undefined> {
    const apiObject = {
      wellId: options.wellId,
      userId: options.userId,
      configName: options.logItem.presetName,
      keyField: options.logItem.field,
      ...options.logItem,
    };
    const resp = await queryApi({
      url: 'api/pdf-gen/generate-token',
      method: 'POST',
      data: apiObject,
    });
    if(!resp?.data?.token) {
      return undefined;
    }
    return resp.data.token;
  }
}
