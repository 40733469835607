import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import {
  IRangeData,
  MultiwellCompareFiltersAction,
  MultiwellCompareFiltersMutation,
  RangeTypes,
  TRangeType,
} from '@/store/modules/multiwell_compare/types';
import store from '@/store';
import * as _ from 'lodash';
import moment from 'moment';

interface IBHAFilters {
  rangeType: TRangeType;
  rangeData: IRangeData;
  motorManufacturer: string;
  motorSize: number;
  motorModelNumber: string;
  motorStages: string;
  bitManufacturer: string;
  bitSize: number;
  bitType: string;
  contains: string[];
}

@Module
export class MultiwellCompareFilterBHAModule extends VuexModule {
  _rangeTypesList: RangeTypes[] = Object.values(RangeTypes);
  _selectedRangeType: TRangeType = null;
  _selectedRangeData: IRangeData = { from: null, to: null };

  _selectedMotorManufacturer: string;
  _selectedMotorSize: number;
  _selectedMotorModelNumber: string;
  _selectedMotorStages: string;

  _selectedBitManufacturer: string;
  _selectedBitSize: number;
  _selectedBitType: string;

  _selectedContains: string[];

  get rangeTypesList(): TRangeType[] {
    return this._rangeTypesList;
  }

  get selectedRangeType(): TRangeType {
    return this._selectedRangeType;
  }

  get selectedRangeData(): IRangeData {
    return this._selectedRangeData;
  }

  get selectedMotorManufacturer(): string {
    return this._selectedMotorManufacturer;
  }

  get selectedMotorSize(): number {
    return this._selectedMotorSize;
  }

  get selectedMotorModelNumber(): string {
    return this._selectedMotorModelNumber;
  }

  get selectedMotorStages(): string {
    return this._selectedMotorStages;
  }

  get selectedBitManufacturer(): string {
    return this._selectedBitManufacturer;
  }

  get selectedBitSize(): number {
    return this._selectedBitSize;
  }

  get selectedBitType(): string {
    return this._selectedBitType;
  }

  get selectedContains(): string[] {
    return this._selectedContains;
  }

  get accessibleData(): string {
    const dataStringsArray: string[] = [];
    if(this.selectedRangeData?.from && this.selectedRangeData?.to) {
      if(this.selectedRangeType === RangeTypes.DEPTH_RANGE) {
        dataStringsArray.push(`BHA ${this.selectedRangeType.toLowerCase()} from ${this.selectedRangeData.from} ft to ${this.selectedRangeData.to} ft`);
      } else if(this.selectedRangeType === RangeTypes.DATE_RANGE) {
        const dateFrom = moment.unix(this.selectedRangeData.from).format('MMM DD, YYYY');
        const dateTo = moment.unix(this.selectedRangeData.to).format('MMM DD, YYYY');

        dataStringsArray.push(`BHA ${this.selectedRangeType.toLowerCase()} from ${dateFrom} to ${dateTo}`);
      }
    }

    const dataArray = [{
      name: 'Motor manufacturer',
      value: this.selectedMotorManufacturer,
    }, {
      name: 'Motor size',
      value: this.selectedMotorSize,
    }, {
      name: 'Motor model number',
      value: this.selectedMotorModelNumber,
    }, {
      name: 'Motor stages',
      value: this.selectedMotorStages,
    }, {
      name: 'Bit manufacturer',
      value: this.selectedBitManufacturer,
    }, {
      name: 'Bit size',
      value: this.selectedBitSize,
    }, {
      name: 'Bit type',
      value: this.selectedBitType,
    }, {
      name: 'Contains',
      value: this.selectedContains,
    }];

    dataArray.forEach((data: { [key: string]: string[] | string | number }) => {
      if(!_.isEmpty(data.value)) {
        dataStringsArray.push(`${data.name} = ${_.isArray(data.value) ? (data.value as string[]).join(', ') : data.value}`);
      }
    });

    return dataStringsArray.join(';\t');
  }

  @Mutation
  setSelectedRangeType(rangeType: TRangeType) {
    this._selectedRangeType = rangeType;
  }

  @Mutation
  setSelectedRangeData(rangeData: IRangeData) {
    this._selectedRangeData = rangeData ? ({ ...rangeData }) : undefined;
  }

  @Mutation
  setSelectedMotorManufacturer(manufacturer: string): void {
    this._selectedMotorManufacturer = manufacturer;
  }

  @Mutation
  setSelectedMotorSize(size: number): void {
    this._selectedMotorSize = size;
  }

  @Mutation
  setSelectedMotorModelNumber(modelNumber: string): void {
    this._selectedMotorModelNumber = modelNumber;
  }

  @Mutation
  setSelectedMotorStages(stages: string): void {
    this._selectedMotorStages = stages;
  }

  @Mutation
  setSelectedBitManufacturer(manufacturer: string): void {
    this._selectedBitManufacturer = manufacturer;
  }

  @Mutation
  setSelectedBitSize(size: number): void {
    this._selectedBitSize = size;
  }

  @Mutation
  setSelectedBitType(type: string): void {
    this._selectedBitType = type;
  }

  @Mutation
  setSelectedContains(contains: string[]): void {
    this._selectedContains = contains;
  }

  @Action
  setBHAFilters(fullBHA: IBHAFilters): void {
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_RANGE_TYPE, fullBHA.rangeType);
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_RANGE_DATA, fullBHA.rangeData);
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_MOTOR_MANUFACTURER, fullBHA.motorManufacturer);
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_MOTOR_SIZE, fullBHA.motorSize);
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_MOTOR_MODEL_NUMBER, fullBHA.motorModelNumber);
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_MOTOR_STAGES, fullBHA.motorStages);
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_BIT_MANUFACTURER, fullBHA.bitManufacturer);
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_BIT_SIZE, fullBHA.bitSize);
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_BIT_TYPE, fullBHA.bitType);
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_CONTAINS, fullBHA.contains);
  }

  @Action
  clearSelectedRange(): void {
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_RANGE_TYPE, null);
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_RANGE_DATA, { from: null, to: null });
  }

  @Action
  clearMotorDetails(): void {
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_MOTOR_MANUFACTURER, null);
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_MOTOR_SIZE, null);
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_MOTOR_MODEL_NUMBER, null);
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_MOTOR_STAGES, null);
  }

  @Action
  clearBitDetails(): void {
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_BIT_MANUFACTURER, null);
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_BIT_SIZE, null);
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_BIT_TYPE, null);
  }

  @Action
  clearContains(): void {
    store.commit(MultiwellCompareFiltersMutation.SET_BHA_CONTAINS, []);
  }

  @Action
  async clearBHAFilters(): Promise<void> {
    await store.dispatch(MultiwellCompareFiltersAction.CLEAR_BHA_RANGE);
    await store.dispatch(MultiwellCompareFiltersAction.CLEAR_BHA_MOTOR_DETAILS);
    await store.dispatch(MultiwellCompareFiltersAction.CLEAR_BHA_BIT_DETAILS);
    await store.dispatch(MultiwellCompareFiltersAction.CLEAR_BHA_CONTAINS);
  }
}
