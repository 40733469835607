















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class NvictaSearchInput extends Vue {
  @Prop({ required: true, default: '' })
  value: string;

  @Prop({ required: false, default: '' })
  placeholder: string;

  @Prop({ required: false, default: false })
  disabled: boolean;

  onInput({ target }: { target: HTMLInputElement }): void {
    this.$emit('input', target.value);
  }

  onClear(): void {
    this.$emit('input', '');
  }
}

