import { DepthTrace } from '../../depth_data/types';
import { TimeTrace } from '@/store/modules/well_data/types';

export type WellSummaryConfigId = string;

export enum TraceRenderType {
  LINE = 'Line',
  DASHED = 'Dashed',
  DOTTED = 'Dotted',
}

export enum KeyField {
  DEPTH = 'depth',
  TIME = 'time',
  TVD = 'interpolatedTvd',
}

export type WellSummaryTraceFilter = {
  max: number | null;
  threshold: number | null;
  trace: string;
  thresholdColor: string;
  traceColor: string;
  traceRenderType: TraceRenderType;
  thresholdRenderType: TraceRenderType;
  traceName: string;
};

export type DepthRange = {
  range: number[] | null;
  wellId: string;
};

export type TablesShowFlags = {
  [tableType: string]: boolean;
};

export type KeyConfig = {
  traces: (DepthTrace | TimeTrace)[][];
  resolution: number;
  ranges: { range: [number, number], wellId: string };
};

export type WellSummaryConfiguration = {
  _id: WellSummaryConfigId;
  userId: string;
  name: string;
  tracesFilter: Map<string, WellSummaryTraceFilter> | null;
  tablesShowFlags: TablesShowFlags;
  depth: KeyConfig;
  time: KeyConfig;
  interpolatedTvd: KeyConfig;
  activeKey: KeyField;
  currentSelectorIsDepth: boolean;
};

export enum WellSummaryConfigurationMutation {
  SET_WELL_SUMMARY_CONFIGURATIONS = 'setWellSummaryConfigurations',
  DELETE_WELL_SUMMARY_CONFIGURATION_BY_ID = 'deleteWellSummaryConfigurationById',
  SELECT_WELL_SUMMARY_CONFIGURATION_PRESET_NAME = 'selectWellSummaryConfigurationPresetName',
}

export enum WellSummaryConfigurationAction {
  FETCH_WELL_SUMMARY_CONFIGURATIONS = 'fetchWellSummaryConfigurations',
  DELETE_WELL_SUMMARY_CONFIGURATION = 'deleteWellSummaryConfiguration',
  UPDATE_WELL_SUMMARY_CONFIGURATION = 'updateWellSummaryConfiguration',
  ADD_WELL_SUMMARY_CONFIGURATION = 'addWellSummaryConfiguration',
}
