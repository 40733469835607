






































































import Slider from '@/components/sliders/Slider.vue';

import { DepthSection } from '@/store/modules/types';
import { FormationTop } from '@/store/modules/formation_top/types';
import { MwdRun } from '@/store/modules/mwd_run/types';
import { Bha } from '@/store/modules/bha/types';
import { KeyField } from '@/store/modules/well_summary/types';
import { SectionRanges } from '@/store/modules/well/types';

import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import * as _ from 'lodash';

const DEFAULT_SLIDER_MIN_VALUE = 0;
const MWD_RUN_DEFAULT_NAME = 'All';
const FORMATION_TOP_DEFAULT_NAME = 'All';

@Component({ components: { Slider } })
export default class DepthFilter extends Vue {
  @Prop({ required: true })
  id!: string;

  @Prop({ required: false, default: true })
  isSliderVisible: boolean;

  @Prop({ required: false, default: true })
  isSectionSelectorVisible: boolean;

  @Prop({ required: false, default: false })
  isMwdSelectorVisible: boolean;

  @Prop({ required: false, default: true })
  isFormationSelectorVisible: boolean;

  @Prop({ required: false, default: null })
  initSection: DepthSection;

  @Prop({ required: false, default: null })
  initDepthRange: [number, number] | null;

  @Prop({ required: false, default: 'Filter' })
  placeHolder: string;

  @Prop({ required: false, default: null })
  initBorders: [number, number] | null;

  @Prop({ required: false })
  inputFormatter: (value: number) => string;

  @Prop({ required: false, default: false })
  isResetVisile: boolean;

  @Prop({ required: false, default: true })
  isTitleVisible: boolean;

  @Prop({ required: false, default: false })
  isLocked: boolean;

  @Prop({ required: false })
  sectionRanges: SectionRanges;

  selectedSection: DepthSection = DepthSection.ALL;
  sections = DepthSection;
  selectedMwdRun: MwdRun | null = null;
  sliderValues: [number, number] | null = null;
  depthFilteredRange: [number, number] | null = null; // same as "sliderValues", is used for rerender
  selectedFormationTop: FormationTop | null = null;

  @Watch('initDepthRange')
  onInitValuesChange(): void {
    this.setInitialValues();
  }

  get targetDepth(): number | undefined {
    return this.$store.getters.targetDepth;
  }

  get sliderId(): string {
    return this.id + '-slider';
  }

  get dropdownRef(): string {
    return this.id + 'Dropdown';
  }

  get mwdDropdownRef(): string {
    return this.id + '-mwd-dropdown';
  }

  get formationDropdownRef(): string {
    return this.id + '-formation-dropdown';
  }

  get mwdRuns(): MwdRun[] {
    return this.$store.getters.mwdRuns;
  }

  get mwdRunsNames(): { name: string, id: string }[] {
    const result = [{ name: MWD_RUN_DEFAULT_NAME, id: '0' }];
    this.mwdRuns.forEach((value: MwdRun) => { result.push({ name: value.name, id: value._id }); });
    return result;
  }

  get formationTops(): FormationTop[] {
    const formationTops = this.$store.getters.formationTops;
    if(!formationTops) {
      return [];
    }
    return formationTops;
  }

  get formationTopNames(): { name: string, id: string }[] {
    const result = [{ name: FORMATION_TOP_DEFAULT_NAME, id: '0' }];
    this.formationTops.forEach((value: FormationTop) => { result.push({ name: value.formationName || 'Untitled', id: value._id }); });
    return result;
  }

  get selectedFormationTopName(): string {
    if(this.selectedFormationTop === null) {
      return FORMATION_TOP_DEFAULT_NAME;
    }
    return this.selectedFormationTop.formationName || 'Untitled';
  }

  get bhas(): Bha[] {
    return this.$store.getters.bhas;
  }

  get selectedMwdRunName(): string {
    return this.selectedMwdRun === null ? MWD_RUN_DEFAULT_NAME : this.selectedMwdRun.name;
  }

  get sliderDepthBorders(): [number, number] {
    if(this.initBorders !== null && this.initBorders !== undefined) {
      return this.initBorders;
    }
    const depthBorders = this.$store.getters.depthBorders;
    const rigthBorder = depthBorders === undefined ? this.targetDepth : depthBorders[1];
    return [DEFAULT_SLIDER_MIN_VALUE, _.max([rigthBorder, this.$store.getters.lastPlottedPseudoSurveyMd])];
  }

  get dataSliderValues(): [number, number] | undefined {
    if(this.initDepthRange !== null) {
      return this.initDepthRange;
    }

    if(this.sliderValues !== null) {
      return this.sliderValues;
    }

    return this.sliderDepthBorders;
  }

  // TODO: add type
  get filterOptions(): { section: DepthSection, mwdRun: MwdRun, bha: Bha, range: [number, number], formationTop: FormationTop } {
    const bha = this.selectedMwdRun !== null
      ? this.bhas.filter((value: Bha) => { return value.name === this.selectedMwdRun.bhaId; }).pop()
      : null;
    return {
      section: this.selectedSection,
      mwdRun: this.selectedMwdRun,
      bha: bha,
      range: this.depthFilteredRange,
      formationTop: this.selectedFormationTop,
    };
  }

  get wellSummaryKeyField(): KeyField {
    return this.$store.getters.wellSummaryKeyField;
  }

  selectFormationTop(formationTopId: string): void {
    const formationTop = _.find(this.formationTops, (formationTop: FormationTop) => formationTop._id === formationTopId);
    this.selectedMwdRun = null;
    this.selectedSection = DepthSection.ALL;
    this.setDepthRangeByFormationTop(formationTop);
    this.selectedFormationTop = formationTop || null;
    this.closeDropdown(this.formationDropdownRef);
    this.$emit('apply-filter', this.filterOptions);
  }

  selectSection(section: DepthSection): void {
    this.selectedFormationTop = null;
    this.selectedMwdRun = null;
    this.selectedSection = section;
    this.setDepthRangeBySection(section);
    this.closeDropdown(this.dropdownRef);
    this.$emit('apply-filter', this.filterOptions);
  }

  selectMwdRun(id: string): void {
    const mwdRun = this.mwdRuns.filter((value: MwdRun) => { return value._id === id; }).pop();
    this.selectedSection = DepthSection.ALL;
    this.selectedFormationTop = null;
    if(mwdRun !== undefined) {
      this.selectedMwdRun = mwdRun;
      this.setDepthRangeByMwdRun(mwdRun);
    } else {
      this.selectedMwdRun = null;
    }
    this.closeDropdown(this.mwdDropdownRef);
    this.$emit('apply-filter', this.filterOptions);
  }

  onSliderChange(range: { from: number, to: number }): void {
    if(this.selectedFormationTop !== null) {
      this.selectedFormationTop = null;
    }
    this.sliderValues = [range.from, range.to];
    this.$emit('slider-change', this.sliderValues);
  }

  closeDropdown(ref: string): void {
    // @ts-ignore
    this.$refs[ref].close();
  }

  setDepthRangeBySection(section: DepthSection): void {
    if(section === DepthSection.ALL) {
      this.setDepthRange(this.sliderDepthBorders);
      return;
    }
    const sectionsRanges = this.sectionRanges || this.$store.getters.wellboreSectionsRanges;
    if(sectionsRanges === undefined || sectionsRanges[section] === undefined) {
      this.setDepthRange([this.sliderDepthBorders[0], this.sliderDepthBorders[0]]);
      return;
    }
    this.setDepthRange(sectionsRanges[section]);
  }

  setDepthRangeByFormationTop(formationTop?: FormationTop): void {
    if(!formationTop) {
      this.setDepthRange(this.sliderDepthBorders);
      return;
    }
    this.setDepthRange([formationTop.mdStart, formationTop.mdEnd]);
  }

  setDepthRangeByMwdRun(mwdRun: MwdRun): void {
    if(mwdRun === null) {
      this.setDepthRange(this.sliderDepthBorders);
      return;
    }
    const bha = this.bhas.filter((value: Bha) => { return value.name === mwdRun.bhaId; }).pop();
    if(bha === undefined) {
      this.setDepthRange(this.sliderDepthBorders);
      return;
    }

    if(this.wellSummaryKeyField === 'time') {
      this.setDepthRange([bha.startDate, bha.endDate]);
    } else {
      this.setDepthRange([bha.startDepth, bha.endDepth]);
    }
  }

  setDepthRange(range: [number, number] | undefined): void {
    if(!range) {
      return;
    }
    // we shouldn't pan out of depth borders;
    const leftBorder = _.max([range[0], this.sliderDepthBorders[0]]);
    const rightBorder = _.min([range[1], this.sliderDepthBorders[1]]);
    const resultRange: [number, number] = [leftBorder, _.max([rightBorder, leftBorder])];
    this.sliderValues = resultRange;
    this.depthFilteredRange = resultRange;
  }

  onResetClick(): void {
    this.$emit('reset');
  }

  public clearFilters(): void {
    this.selectedSection = DepthSection.ALL;
    this.selectedMwdRun = null;
    this.selectedFormationTop = null;
    this.setDepthRange(this.sliderDepthBorders);
    this.$emit('apply-filter', { ...this.filterOptions, isClear: true });
  }

  public applyFilters(): void {
    this.selectedSection = DepthSection.ALL;
    this.selectedMwdRun = null;
    this.setDepthRange(this.sliderValues);
    this.$emit('apply-filter', this.filterOptions);
  }

  setInitialValues(): void {
    if(this.initSection !== null && this.initSection !== undefined) {
      this.selectedSection = this.initSection;
    }
    if(this.initDepthRange !== null && this.initDepthRange !== undefined) {
      this.sliderValues = this.initDepthRange;
      this.depthFilteredRange = this.initDepthRange;
    }
  }

  created(): void {
    this.setInitialValues();
  }
}
