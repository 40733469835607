

















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class IconButton extends Vue {
  @Prop({ required: true })
  icon!: string;

  @Prop({ required: false, default: '' })
  classes: string;

  @Prop({ required: false, default: 'dark' })
  theme: 'light' | 'dark';

  @Prop({ required: false, default: 'M' })
  size: 'S' | 'M';

  @Prop({ required: false, default: false })
  disabled: boolean;

  get hasBeforeSlot(): boolean {
    return !!this.$slots.before;
  }

  get hasAfterSlot(): boolean {
    return !!this.$slots.after;
  }

  get emptySlots(): boolean {
    return !this.hasBeforeSlot && !this.hasAfterSlot;
  }

  onClick(): void {
    this.$emit('click');
  }
}

